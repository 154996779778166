import React from "react";

import FormHeaderLogo from "./FormHeaderLogo";
import FormFooter from "./FormFooter";

import "./frmStyle.scss";

const FormStep4 = () => {
  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <FormHeaderLogo />
            </div>
          </div>
          <div className="detailProperty from2">
            <br /><br /><br /><br />
            <p>
              <strong>
                THE TENANT HEREBY COVENANTS WITH THE LANDLORD AS FOLLOWS:
              </strong><br /><br />
              <strong>SAFETY & REPAIRS</strong><br />
              The tenant will not do anything, nor neglect to do anything by
              which fire or health hazard is created. The tenant will comply
              with all fire, health, and sanitation regulations. The tenant
              shall take good care of the premises and keep the same in
              reasonably clean condition. The tenant will be responsible for
              repairing and replacing windows, screens, light bulbs, fuses,
              fixtures, appliances and all floor coverings and counter tops
              damaged, broken, removed, destroyed, stained, or soiled by the
              tenant, or the tenant’s guests or invitees. The tenant will be
              responsible for and promptly pay on demand all charges relating to
              any service supplied to or used on the premises where properly
              chargeable to the tenant through causation or neglect including
              maintenance of yards and sidewalks, plugged toilets, sinks and
              drains etc. The cost of any damage resulting directly or
              indirectly there from. Charges will be levied at $40 per hour plus
              travel time and equipment use if done by the landlord’s own
              forces, or at the charge levied by people he may hire plus 20% to
              cover overseeing. The tenant shall be responsible for all damages
              resulting from the escape of water within or from the leased
              premises, and from the use of defective electrical or other
              appliances of any kind. Leaks in pipes, are to be repaired by the
              landlord within a reasonable time after notice, but the landlord
              shall not be liable or responsible for any damages resulting from
              such leaks or overflow.
            </p>
          </div>

          <div className="midContent">
            <p>
              Together with any natural increase in the tenant’s family, but not
              to exceed a total of 2 Adult unless the landlord consents in
              writing to the occupation of the premises by some other or
              additional person or persons. Each approved additional adult will
              be charged $50.00 extra per month. <br />
              <strong>CONDITION REPORT</strong> <br />
              Prior to the tenant taking possession of the premises, the
              landlord and the tenant shall complete and execute an entry
              condition report detailing the condition of the premises, fixtures
              and appliances, and the said report shall be conclusive evidence
              of the condition of the premises, fixtures, and appliances, an
              exit report shall be similarly completed and executed and all
              charges for repair of damages and cleaning of the premises will be
              based on these reports. When Tenant is moving out- they are
              responsible to get all carpets steam cleaned and provide
              Management with a proof of receipt.
              <br />
              <strong>TENANT INSURANCE</strong> <br />
              The Tenant promises to hold a valid insurance policy, which
              includes the Tenant’s contents in the Home, including contents in
              vehicle. The policy is required to insure the Tenants and any
              guests against loss or damages, including personal injury and
              death. The Tenant promises to carry general comprehensive
              liability insurance to a minimum of $2 Million Liability coverage.
              Must be purchased through Nuera insurance online. **Proof of
              current and accurate Tenant’s Insurance is required before move in
              will be allowed** Tenant Insurance is a mandatory and can lead to
              eviction if tenant does not have it
            </p>
            <br /><br /><br /><br />
            <FormFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStep4;
