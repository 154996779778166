import React, { useState, useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useParams } from "react-router-dom";
import { Card, Col, Row, Button } from "reactstrap";
import BreadcrumbV3 from "../../components/Common/BreadcrumbV3";
import "./kyesStyle.scss";
import { onlyForNumbers, isAlphanumeric } from "../../_util/keyEventHandler";
import axios from "../api/axios";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";
import { toast } from "react-toastify";

const GET_KEYS_AND_FOB_INFO = "keys-and-fob";
const POST_KEYS_AND_FOB_INFO = "keys-and-fob/add";


const Kyes_Fob = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [keys, setKeys] = useState({
    suitKeys: "",
    mailBoxKeys: "",
  });


  const [suitKeyAdd, setSuitKeyAdd] = useState(false);
  const [mailBoxAdd, setMailBoxAdd] = useState(false);

  const [storageAdd, setStorageAdd] = useState(false);
  const [ugAdd, setUgAdd] = useState(false);
  const [regularAdd, setregularAdd] = useState(false);

  const [storage, setStorage] = useState([{ firstInput: "", secondInput: "" }]);
  const [mailBox, setMailBox] = useState([{ firstInput: "", secondInput: "" }]);
  const [suitKeys, setSuitKeys] = useState([{ firstInput: "", secondInput: "" }]);
  const [ugFobs, setUgFobs] = useState({
    isCheck: false,
    inputs: [{
      firstInput: '',
      secondInput: ''
    }]
  });

  const [regularFobs, setRagularFobs] = useState({
    isCheck: false,
    inputs: [{
      firstInput: '',
      secondInput: ''
    }]
  });

  const handleOnKeys = (event) => {
    const { name, value } = event.target;
    setKeys({ ...keys, [name]: value });
  }

  const getInfo = async (id) => {
    try {
      const res = await axios.get(`${GET_KEYS_AND_FOB_INFO}/${id}`);
      debugger;
      if (res && res.data && res.data.status === 200) {
        if (res.data.data) {
          const { suitKeys, mailBoxKeys, storageKeys, ug, regular } = res.data.data;
          // setKeys({ suitKeys, mailBoxKeys });
          setSuitKeys(suitKeys);
          setMailBox(mailBoxKeys);
          setUgFobs(ug);
          setStorage(storageKeys)
          setRagularFobs(regular);
        }
      }

    } catch (error) {

    }
  }

  const handleSubmit = async () => {
    try {
      dispatch(isLoading(true));
      //const { suitKeys, mailBoxKeys } = keys;
      const bodyData = {
        userId: id,
        suitKeys: suitKeys,
        mailBoxKeys: mailBox,
        storage: storage,
        ug: ugFobs,
        regular: regularFobs
      }
      const result = await axios.post(POST_KEYS_AND_FOB_INFO, bodyData);
      toast.success("Updated successfully.");
      await getInfo(id);
      dispatch(isLoading(false));

    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(isLoading(false));
    }

  }

  useEffect(() => {
    if (id) {
      getInfo(id);
    }
  }, [id])

  useEffect(() => {
    if (suitKeys.length == 5) {
      setSuitKeyAdd(true);
    } else if (suitKeys.length < 5) {
      setSuitKeyAdd(false);
    }
  }, [suitKeys.length])


  useEffect(() => {
    if (mailBox.length == 5) {
      setMailBoxAdd(true);
    } else if (mailBox.length < 5) {
      setMailBoxAdd(false);
    }
  }, [mailBox.length])


  useEffect(() => {
    if (storage.length == 5) {
      setStorageAdd(true);
    } else if (storage.length < 5) {
      setStorageAdd(false);
    }
  }, [storage.length])

  useEffect(() => {
    if (ugFobs.inputs.length == 5) {
      setUgAdd(true);
    } else if (ugFobs.inputs.length < 5) {
      setUgAdd(false);
    }
  }, [ugFobs.inputs.length])

  useEffect(() => {
    if (regularFobs.inputs.length == 5) {
      setregularAdd(true);
    } else if (regularFobs.inputs.length < 5) {
      setregularAdd(false);
    }
  }, [regularFobs.inputs.length])

  const add = (index, type) => {

    switch (type) {

      case "suitKey":
        setSuitKeys(s => {
          return [
            ...s,
            {
              firstInput: "",
              secondInput: "",
            }
          ];
        });
        break;

      case "mailBox":
        setMailBox(s => {
          return [
            ...s,
            {
              firstInput: "",
              secondInput: "",
            }
          ];
        });
        break;
      case "Storage":
        setStorage(s => {
          return [
            ...s,
            {
              firstInput: "",
              secondInput: "",
            }
          ];
        });
        break;
      case "UG":
        setUgFobs((prevUgFobs) => ({
          ...prevUgFobs,
          inputs: [
            ...prevUgFobs.inputs,
            {
              firstInput: '',
              secondInput: '',
            },
          ],
        }));
        break;
      case "Regular":
        setRagularFobs((prevRegularFobs) => ({
          ...prevRegularFobs,
          inputs: [
            ...prevRegularFobs.inputs,
            {
              firstInput: '',
              secondInput: '',
            },
          ],
        }));
        break;
    }
  };

  const remove = (index, type) => {
    switch (type) {

      case "suitKey":
        setSuitKeys((prevStorage) => prevStorage.filter((_, i) => i !== index));
        break;

      case "mailBox":
        setMailBox((prevStorage) => prevStorage.filter((_, i) => i !== index));
        break;

      case "Storage":
        setStorage((prevStorage) => prevStorage.filter((_, i) => i !== index));
        break;
      case "UG":
        setUgFobs((prevUgFobs) => ({
          ...prevUgFobs,
          inputs: prevUgFobs.inputs.filter((_, i) => i !== index),
        }));
        break;
      case "Regular":
        setRagularFobs((prevRegularFobs) => ({
          ...prevRegularFobs,
          inputs: prevRegularFobs.inputs.filter((_, i) => i !== index),
        }));
        break;
    }
  };



  const handleInputChange = (index, field, value, type) => {
    switch (type) {
      case "suitKey":
        setSuitKeys((prevStorage) => {
          const updatedStorage = [...prevStorage];
          updatedStorage[index] = {
            ...updatedStorage[index],
            [field]: value,
          };
          return updatedStorage;
        });
        break;
      case "Storage":
        setStorage((prevStorage) => {
          const updatedStorage = [...prevStorage];
          updatedStorage[index] = {
            ...updatedStorage[index],
            [field]: value,
          };
          return updatedStorage;
        });
        break;
      case "mailBox":
        setMailBox((prevStorage) => {
          const updatedStorage = [...prevStorage];
          updatedStorage[index] = {
            ...updatedStorage[index],
            [field]: value,
          };
          return updatedStorage;
        });
        break;
      case "UG":
        setUgFobs((prevUgFobs) => ({
          ...prevUgFobs,
          inputs: prevUgFobs.inputs.map((input, i) =>
            i === index ? { ...input, [field]: value } : input
          ),
        }));
        break;
      case "Regular":
        setRagularFobs((prevRegularFobs) => ({
          ...prevRegularFobs,
          inputs: prevRegularFobs.inputs.map((input, i) =>
            i === index ? { ...input, [field]: value } : input
          ),
        }));
        break;
    }
  };

  const renderButton = (index, type, disabled) => {
    if (index == 0) {
      return <Button type='button' size="sm" disabled={disabled} color="primary" onClick={() => { add(index, type) }}>+</Button>
    } else if (index > 0 && index <= 5) {
      return <Button type='button' size="sm" color="danger" onClick={() => { remove(index, type) }}>×</Button>
    }
  }

  return (
    <div className="page-content">
      <div className="kpiList_page">
        <MetaTags>
          <title>Rentdigicare | Master Sheet</title>
        </MetaTags>
        <div className="container-fluid kye_fobPage">
          <BreadcrumbV3 title={<>Kyes And FOB</>}
            breadcrumbItems={[
              {
                item: "Home",
                link: "/dashboard"
              },
              {
                item: "Master Sheet",
                link: "/master_table"
              },
              {
                item: "Tenant Information",
                link: `/tenant_info/${id}`
              },
              {
                item: "Kyes And FOB",
                link: `/kyes-fob/${id}`
              }
            ]}
          />
          <Row>
            <Col xl={12}>
              <Card>
                <hr class="hr" />

                <div className="cardInner">
                  <h6>SUIT KEYS</h6>
                  {suitKeys.map((item, index) => {
                    return (
                      <div className="fogSec d-flex">
                        <div>
                          <label>Number of keys given</label>
                        </div>
                        <div>
                          <input type="text" maxLength={5} width={200} name="suitKey" value={item.firstInput} onChange={(e) => handleInputChange(index, 'firstInput', e.target.value, 'suitKey')} onKeyDown={onlyForNumbers} />
                        </div>
                        <div>
                          <label>Key Sr. No.</label>
                        </div>
                        <div>
                          <input type="text" maxLength={10} style={{width: 200}} name="suitKey" value={item.secondInput} onChange={(e) => handleInputChange(index, 'secondInput', e.target.value, 'suitKey')} onKeyDown={isAlphanumeric} />
                        </div>
                        <div key={index}>
                          {renderButton(index, 'suitKey', suitKeyAdd)}
                        </div>
                      </div>
                    );
                  })}
                </div>


                {/* <div className="cardInner">
                  <h2>SUIT KEYS</h2>
                  <div className="fogSec d-flex">
                  <div>
                    <label>Number of keys given</label>
                    <input type="text" name="suitKeys" value={keys.suitKeys} onChange={handleOnKeys} onKeyDown={onlyForNumbers} />
                  </div>
                </div>
                </div> */}
                <hr class="hr" />


                <div className="cardInner">
                  <h6>MAIL BOX KEYS</h6>
                  {mailBox.map((item, index) => {
                    return (
                      <div className="fogSec d-flex">
                        <div>
                          <label>Number of keys given</label>
                        </div>
                        <div>
                          <input type="text" maxLength={5} name="mailboxKeys" value={item.firstInput} onChange={(e) => handleInputChange(index, 'firstInput', e.target.value, 'mailBox')} onKeyDown={onlyForNumbers} />
                        </div>
                        <div>
                          <label>Key Sr. No.</label>
                        </div>
                        <div>
                          <input type="text" maxLength={10} style={{width: 200}} name="mailboxKeys" value={item.secondInput} onChange={(e) => handleInputChange(index, 'secondInput', e.target.value, 'mailBox')} onKeyDown={isAlphanumeric} />
                        </div>
                        <div key={index}>
                          {renderButton(index, 'mailBox', mailBoxAdd)}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* <div className="cardInner">
                  <h2>MAIL BOX KEYS</h2>
                  <div className="fogSec d-flex">
                  <div>
                    <label>Number of keys given</label>
                    <input type="text" name="mailBoxKeys" value={keys.mailBoxKeys} onChange={handleOnKeys} onKeyDown={onlyForNumbers} />
                  </div>
                </div>
                </div> */}
                <hr class="hr" />
                <div className="cardInner">
                  <h6>STORAGE KEYS</h6>
                  {storage.map((item, index) => {
                    return (
                      <div className="fogSec d-flex">
                        <div>
                          <label>Number of keys given</label>
                        </div>
                        <div>
                          <input type="text" maxLength={5} name="storageKeys" value={item.firstInput} onChange={(e) => handleInputChange(index, 'firstInput', e.target.value, 'Storage')} onKeyDown={onlyForNumbers} />
                        </div>
                        <div>
                          <label>Key Sr. No.</label>
                        </div>
                        <div>
                          <input type="text" maxLength={10} name="storageKeys" value={item.secondInput} onChange={(e) => handleInputChange(index, 'secondInput', e.target.value, 'Storage')} onKeyDown={isAlphanumeric} />
                        </div>
                        <div key={index}>
                          {renderButton(index, 'Storage', storageAdd)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr class="hr" />
                <div className="cardInner">
                  <h6>FOB</h6>
                  <input checked={ugFobs.isCheck}  onChange={(event) => { setUgFobs({ ...ugFobs, isCheck: event.target.checked }) }} id="checkbox" type="checkbox" />
                  {ugFobs.inputs.map((item, index) => {
                    return (
                      <div className="fogSec d-flex">
                        <div>
                          <label for="checkbox">UG FOB Key</label>
                        </div>
                        <div>
                          <input type="text" maxLength={5} name="mailBoxKeys" value={item.firstInput} onChange={(e) => handleInputChange(index, 'firstInput', e.target.value, 'UG')} onKeyDown={onlyForNumbers} />
                        </div>
                        <div>
                          <label for="checkbox">Key Sr. No.</label>
                        </div>
                        <div>
                          <input type="text" maxLength={10} name="mailBoxKeys" value={item.secondInput} onChange={(e) => handleInputChange(index, 'secondInput', e.target.value, 'UG')} onKeyDown={isAlphanumeric} />
                        </div>
                        <div key={index}>
                          {renderButton(index, 'UG', ugAdd)}
                        </div>
                      </div>
                    );
                  })}
                  <hr class="hr" />
                  <input checked={regularFobs.isCheck} onChange={(event) => { setRagularFobs({ ...regularFobs, isCheck: event.target.checked }) }} id="checkbox" type="checkbox" />

                  {regularFobs.inputs.map((item, index) => {
                    return (
                      <div className="fogSec d-flex">
                        <div>
                          <label for="checkboxRagular">Regular FOB Key</label>
                        </div>

                        <div>
                          <input type="text" maxLength={5} name="mailBoxKeys" value={item.firstInput} onChange={(e) => handleInputChange(index, 'firstInput', e.target.value, 'Regular')} onKeyDown={onlyForNumbers} />
                        </div>

                        <div>
                          <label for="checkboxRagular">Key Sr. No</label>
                        </div>

                        <div>
                          <input type="text" maxLength={10} name="mailBoxKeys" value={item.secondInput} onChange={(e) => handleInputChange(index, 'secondInput', e.target.value, 'Regular')} onKeyDown={isAlphanumeric} />
                        </div>

                        <div key={index}>
                          {renderButton(index, 'Regular', regularAdd)}
                        </div>

                      </div>
                    );
                  })}
                </div>
                <div className="formSubmit">
                  <button type="button" onClick={() => { handleSubmit() }} >Submit</button>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Kyes_Fob;
