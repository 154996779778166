import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import axios from "../api/axios";

const headingData = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const POST_KPI_REPORT = "kpireport/vacancy-report";

const VacancyReport = () => {
  const { userData } = JSON.parse(window.localStorage.getItem("authUser"));

  const [years, setYears] = useState([])
  const [selectYear, setSelectYear] = useState(0)
  const [result, setResult] = useState([])
  const [totals, setTotals] = useState({})
  const [overallResult, setOverallResult] = useState([]);
  const [managersResult, setmanagersResult] = useState([]);

  const getReport = async () => {
    try {
      const body = {
        "role": userData.role,
        "uid": userData.id,
        "selectYear": selectYear
      }
      const _result = await axios.post(POST_KPI_REPORT, body);
      if (_result && _result.data && _result.data.status === 200) {
        const { success, mainReport: { reportSheet, reportTotals }, overallReport, managerReport } = _result.data.data;
        if (success) {
          setResult(reportSheet);
          setTotals(reportTotals);
          setOverallResult(overallReport);
          setmanagersResult(managerReport)
        } else {
          setResult([]);
          setTotals({});
          setOverallResult([]);
          setmanagersResult([]);
        }
      }

    } catch (error) {

    }
  }



  function getYearsFromCurrentToPast(startYear) {

    const endYear = new Date().getFullYear();
    const _years = [];

    for (let year = startYear; year <= endYear; year++) {
      _years.push(year);
    }
    _years.reverse()
    setSelectYear(_years[0]);
    setYears(_years);
  }

  useEffect(() => {
    if (selectYear) {
      getReport();
    }
  }, [selectYear])

  useEffect(() => {
    getYearsFromCurrentToPast(2020);
  }, [])


  return (
    <div className="revReport">
      <Form.Select aria-label="Default select example" value={selectYear} onChange={(event) => {
        setSelectYear(event.target.value)
      }}>
        {years.map((item, index) => (<option key={index} value={item}>{item}</option>))}
      </Form.Select>
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th></th>
              {headingData.map((items, index) => (
                <th key={index}>{items}</th>
              ))}
              <th>Total</th>
              <th>AVG / Month</th>
            </tr>
          </thead>
          <tbody>
            {result.map((item, index) => (
              <tr key={index}>
                <th>{item["title"]}</th>
                {headingData.map((doc, _index) => (<td key={_index}>{item[doc] >= 0 ? item[doc] : "-"}</td>))}
                <td>{item["total"] >= 0 ? item["total"] : "-"}</td>
                <td>{item["avgRent"] >= 0 ? item["avgRent"] : "-"}</td>
              </tr>
            ))}
          </tbody>
          <tfoot className="table-secondary">
            <tr >
              <th >Total</th>
              {headingData.map((doc, _index) => (<td key={_index}>{totals[doc] > 0 ? totals[doc] : "-"}</td>))}
              <td>{totals["total"] > 0 ? totals["total"] : "-"}</td>
              <td>{totals["avgRent"] > 0 ? (totals["avgRent"]).toFixed(2) : "-"}</td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <div className="yearlyReport">
        <Row>
          <Col xl={12} className="mt-3">
            <Card>
              <CardHeader>
                <h4>OVERALL YEARLY REPORT</h4>
              </CardHeader>
              <CardBody>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th></th>
                        {headingData.map((items, index) => (
                          <th key={index}>{items}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {overallResult.map((item, index) => (
                        <tr key={index}>
                          <td>{item["name"].toUpperCase()}</td>
                          {headingData.map((doc, _index) => (<td key={_index}>{item[doc] > 0 ? item[doc] + "%" : "-"}</td>))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="yearlyReport">
        <Row>
          <Col xl={12} className="mt-3">
            <Card>
              <CardHeader>
                <h4>MANAGER'S YEARLY REPORT</h4>
              </CardHeader>
              <CardBody>
                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        <th></th>
                        {headingData.map((items, index) => (
                          <th key={index}>{items}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {managersResult.length > 0 && managersResult.map((ele, index) => (
                        <>
                          <tr> <th>{ele["name"].toUpperCase()}</th></tr>
                          {ele.report.length > 0 && ele.report.map((item) => (
                            <tr key={index}>
                              <td>{item["name"].toUpperCase()}</td>
                              {headingData.map((doc, _index) => (<td key={_index}>{item[doc] > 0 ? item[doc] + "%" : "-"}</td>))}
                            </tr>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VacancyReport;
