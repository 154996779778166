import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import axios from "../api/axios";
import { useState } from "react";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import Select from "react-select";
import { Link } from "react-router-dom";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    FormGroup,
    Collapse,
    Label,
    Form,
    // FormControl,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    ModalFooter,
    ModalBody,
    Button,
    ModalHeader,
  } from "reactstrap";

  import Dropzone from "react-dropzone";

  import "../../assets/css/rentdigicare.css";

  
  const Appliances = () => {

    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [InventoryConfig, setInventoryConfig] = useState({});
    const [CompanyName, setCompanyName] = useState([]);
    const [SelectedCompanyId, setSelectedCompanyId] = useState("");
    let [totalQuantity, settotalQuantity] = useState(0);

    const COMPANY_NAME_URL = `/company/filter/company_list`

    const companyName = async () => {
        const response = await axios.get(COMPANY_NAME_URL);
        const temp = (response.data.companies);
        console.log(temp);
        setCompanyName(temp)
    }

    const propertiesList = async (companyId) => {
        const params = {
          companyID: companyId
        }
        const response = await axios.post('/property/getAllCompanyProperties', params);
        const temp = (response.data.properties);
        if(temp) {
            setProperties(temp);
        }
       
    }


    const getInventoryByType = async (inventoryType) => {
        const response = await axios.get(`/inventory/type/${inventoryType}`)
       
        if (response.status === 200 && response.data) {
            setInventoryConfig(response.data)
        }
    };

      useEffect(() => {
        companyName();
        getInventoryByType('appliances');
      }, []);

    return (
        <React.Fragment>

            <div className="page-content">
                    <MetaTags>
                        <title> Rentdigicare | Appliances</title>
                    </MetaTags>
                    <div className="container-fluid">

                        <Breadcrumbs title="Home" breadcrumbItem="Inventory" breadcrumbItem2="Appliances" />

                        <Row>
                            <Col xl={12}>
                            <Card>
                                <CardHeader>

                                <Form>
                                <FormGroup row>
                                    <Col md="3">
                                    <Label htmlFor="property">Select Company</Label>
                                    <Input type="select" name="SelectedCompany" id="SelectedCompany" onChange={(e) =>{
                                        setSelectedCompanyId(e.target.value);
                                        propertiesList(e.target.value);
                                        }} value={SelectedCompanyId}>
                                        <option value="">Select Company</option>
                                        {CompanyName.map(company => (
                                            
                                          <option key={company._id} value={company._id}>{company.name}</option>
                                        ))}
                                    </Input>
                                    </Col>
                                    <Col md="3">
                                    <Label htmlFor="suit">Select Property</Label>
                                    <Input type="select" name="property" id="property" onChange={handleSorting}>
                                        <option value="">Select Property</option>
                                        {properties.map(property => (
                                            
                                            <option key={property._id} value={property._id}>{property.title}</option>
                                        ))}
                                    </Input>
                                    </Col>
                                </FormGroup>                               
                                </Form>


                                </CardHeader>
                                <CardBody>
                                        <div className="table-responsive">
                                            <div>
                                            <select
                                                onChange={(e) => handleSorting(e)}
                                                style={{
                                                borderRadius: "4px",
                                                marginBottom: "10px",
                                                borderColor: "#d1d1d5",
                                                }}
                                            >
                                                <option>Sorting </option>
                                                <option value={1}>A-Z</option>
                                                <option value={2}>Z-A</option>
                                                <option value={3}>Created Latest</option>
                                                <option value={4}>Created Oldest</option>
                                                <option value={5}>Modified Latest</option>
                                                <option value={6}>Modified Oldest</option>
                                            </select>
                                            </div>

                                        <Table className="table-striped table-bordered mb-0">
                                            <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Item Category</th>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Avail Quantity</th>
                                                        <th scope="col">Order New</th>
                                                        <th scope="col">Order Date</th>
                                                        <th scope="col">Sku No.</th>
                                                    </tr>
                                                </thead>
                                                <tbody> 

                                                {(InventoryConfig && InventoryConfig.categories && InventoryConfig.categories.length > 0) &&
                                                            InventoryConfig.categories.map((category,categoryIndex) => (
                                                                         (
                                                                            <React.Fragment key={categoryIndex}>
                                                                                {category.items.map((item,itemIndex) => (
                                                                                    <tr key={itemIndex}>
                                                                                        <td>{itemIndex + 1}</td>
                                                                                        <td>
                                                                                            {itemIndex == 0 ? (
                                                                                                category.name
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </td>
                                                                                        <td>{item.name} -- {item._id}</td>
                                                                                        <td>{item.description}</td>
                                                                                        <td>
                                                                                            <input type="number" className="form-control" placeholder="Order Qty" onBlur={(e) => 
                                                                                                {
                                                                                                    item.orderQuantity =  Number(e.target.value);
                                                                                                    totalQuantity = totalQuantity + Number(e.target.value);
                                                                                                    settotalQuantity(totalQuantity);
                                                                                                }                                                                                                
                                                                                            }/>
                                                                                        </td>
                                                                                        <td>
                                                                                            <input type="checkbox" />
                                                                                        </td>
                                                                                        <td></td>
                                                                                        <td>{item.sku}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </React.Fragment>
                                                                        )))}
                                                    </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th></th>
                                                        <th>Total Items</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>{totalQuantity}</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                        </Table>
                                        <div className="email-container text-right">
                                            <p> Send Order Email &nbsp;&nbsp;&nbsp;
                                                <FeatherIcon icon="mail" />

                                            </p>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>
                                   


                    </div>

            </div>
    </React.Fragment>
    )
   
  }

  const handleSorting = (e) => {
    let value = [1, -1];
    let newData = {};
    if (e.target.value == 1) {
      newData["title"] = value[0];
    } else if (e.target.value == 2) {
      newData["title"] = value[1];
    } else if (e.target.value == 3) {
      newData["createdAt"] = value[1];
    } else if (e.target.value == 4) {
      newData["createdAt"] = value[0];
    } else if (e.target.value == 5) {
      newData["modifiedAt"] = value[1];
    } else if (e.target.value == 6) {
      newData["modifiedAt"] = value[0];
    } else {
      newData[" "] = " ";
    }

   // getProperties(newData);
  };

  export default Appliances