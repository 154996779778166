import React, { useState } from "react";
import { Row, Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { LIMIT } from "../../constants/comman";

const PaginationConponent = (props) => {
    const { total, handlePage } = props;
    const [totalPage, setTotalPage] = useState(Math.ceil(total / LIMIT));
    const [startPage, setStartPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
        handlePage(page);
        setCurrentPage(page);
        if (page > startPage + LIMIT - 1 || page < startPage) {
            setStartPage(Math.max(1, page - LIMIT / 2));
        }
    }

    return (<Row className="proress-style mt-3">
        <Col xl={12} className="text-end">
            <div className="pagination-bar">
                <Pagination>
                    <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink
                            first
                            onClick={() => handlePageChange(1)}
                        />
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink
                            previous
                            onClick={() => handlePageChange(currentPage - 1)}
                        />
                    </PaginationItem>
                    {Array.from(
                        { length: LIMIT },
                        (_, i) => i + startPage
                    ).map((page) => {
                        if (page > totalPage) return null;
                        return (
                            <PaginationItem
                                key={page}
                                active={currentPage === page}
                            >
                                <PaginationLink
                                    onClick={() => handlePageChange(page)}
                                >
                                    {page}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    })}
                    <PaginationItem disabled={currentPage === totalPage}>
                        <PaginationLink
                            next
                            onClick={() => handlePageChange(currentPage + 1)}
                        />
                    </PaginationItem>
                    <PaginationItem disabled={currentPage === totalPage}>
                        <PaginationLink
                            last
                            onClick={() => handlePageChange(totalPage)}
                        />
                    </PaginationItem>
                </Pagination>
            </div>
        </Col>
    </Row>);
}

export default PaginationConponent