import React from "react";
import Logo_p from "../../../../../assets/images/frmlogo.png";
import leftimg from "../../../../../assets/images/leftimg.png";
import { Col, Form, Input, Label, Row } from "reactstrap";
import SignaturePad from "../../../../../components/Common/SignaturePad";
import { useDispatch, useSelector } from "react-redux";

import "../notifyformStyle.scss";
import { setNOTICE } from "../../../../../store/notifyForm/actions";
import moment from "moment";

const FreezingPipes = (props) => {
  const { propertyInfo, readOnly } = props;
  const dispatch = useDispatch();
  const { NOTICE, error } = useSelector((state) => state.nofityForm);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    dispatch(setNOTICE({ ...NOTICE, [name]: value }));
  };

  const handleSignature = (sign) => {
    dispatch(setNOTICE({ ...NOTICE, Signature: sign }));
  };
  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg propertyFrm4">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <img src={propertyInfo && propertyInfo?.logoUrl ? 'data:image/jpeg;base64,' + propertyInfo.logoUrl : Logo_p} alt="" />{" "}
            </div>
            <div className="propertyName">
              <strong>{propertyInfo && propertyInfo?.title ? propertyInfo.title : ""}</strong>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="residentFrm cmnfrmstyle">
            <Form>
              <Row>
                {error && (
                  <Col
                    md={12}
                    className="d-flex align-items-center justify-content-center mb-3"
                  >
                    <Label style={{ color: "red" }}>Error : {error}</Label>
                  </Col>
                )}
                <Col md={12}>
                  <div className="frmInner">
                    <Label>
                      {" "}
                      <strong>Name:</strong>
                    </Label>
                    <Input
                      placeholder="Name"
                      readOnly={readOnly}
                      type="text"
                      name="Name"
                      value={NOTICE?.Name}
                      onChange={handleOnChange}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <div className="frmInner">
                    <Label>
                      <strong>Signature:</strong>{" "}
                    </Label>
                    {/* <Input placeholder="Signature" /> */}
                    <SignaturePad
                      readOnly={readOnly}
                      sign={NOTICE?.Signature}
                      sendSignature={(sign) => {
                        handleSignature(sign);
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>
                      <strong>Date: </strong>
                    </Label>
                    <Input
                      placeholder="Date"
                      readOnly={true}
                      type="date"
                      name="Date"
                      value={
                        NOTICE?.Date
                          ? moment(NOTICE?.Date).format("YYYY-MM-DD")
                          : ""
                      }
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <br />
            <br />
          </div>
          <div className="detailProperty text-center">
            <strong>NOTICE: FREEZING PIPES</strong>
            <br />
            <p>
              {" "}
              ALL WINDOWS & PATIO DOORS MUST BE KEPT CLOSED TO <br />
              AVOID FREEZING PIPES AT ALL TIMES.
            </p>
          </div>
          <br />

          <div className="midText text-center">
            <strong>
              IF THE PIPES FREEZE DUE TOOPEN WINDOWS OR PATIODOORS, RESIDENT{" "}
              <br />
              WILL BE RESPONSIBLE FOR ALL DAMAGES AND REPAIRS TO <br />
              THE SUITE AND ANY OTHER DAMAGES AS A RESULT. PLEASE MAKE SURE{" "}
              <br />
              YOUR TENANT INSURANCE IS EFFECTIVE AND UP TODATE!! <br />
            </strong>
            <br />
            <br />
            <br />
            <br />
          </div>
          <div className="midImgText d-flex detailProperty">
            <img src={leftimg} alt="" />
            <div className="imgInnerText text-center">
              <p>
                DRAPES AND <br />
                BLINDS CANNOT COVER THE <br />
                HOT WATER HEATING LINES
              </p>
              <p>
                FURNITURE MUST BE <br />
                PLACED AWAY FROM <br />
                RADIATOR
              </p>
            </div>
          </div>
          <br />
          <br />
          <div className="btmLineText text-center detailProperty">
            <p>
              THERMOSTAT IS TO BE SET TO NO LOWER THAN 23 DEGREES DURING <br />
              THE WINTER. DO NOT COVER THE RADIATORS WITH LARGE <br />
              FURNITURE OR DRAPES AS IT WILL NOT ALLOW CIRCULATION <br />
              TO RADIATORS AND CAUSE FREEZING PIPE!!
              <br />
            </p>
            <br />
            <br />
            <p>
              SHOULD YOU NOTICE COLD AIR LEAKING IN FROM THE <br />
              WINDOWS OR PATIO DOOR, INFORM US IMMEDIATELY!!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreezingPipes;
