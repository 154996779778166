import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { setFormData_1 } from "../../../store/leaseForm/actions";
import { onlyForNumbers } from "../../../_util/keyEventHandler";
import FormHeaderLogo from "./FormHeaderLogo";
import FormFooter from "./FormFooter";

import "./frmStyle.scss";

const FULLNAME_LIMIT = 10;

const CheckForFee = ({ readOnly, name, value, onChange }) => {
  return (
    <>
      {["Yes", "No"].map((item, index) => (
        <FormGroup key={index} check className="mx-3">
          <Input
            disabled={readOnly}
            name={name}
            type="radio"
            value={item}
            checked={value === item ? true : false}
            onChange={onChange}
          />
          <Label check>{item}</Label>
        </FormGroup>
      ))}
    </>
  );
};

const FormStep1 = (data, { readOnly }) => {
  const dispatch = useDispatch();
  const { formData1, propertyInfo, mangerInfo, error } = useSelector(
    (state) => state.leaseForm
  );
  console.log(mangerInfo);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    dispatch(setFormData_1({ ...formData1, [name]: value }));
  };

  const handleBuildingAddress = (event) => {
    const { name, value } = event.target;
    let _buildingAddress = { ...formData1.buildingAddress, [name]: value };
    dispatch(
      setFormData_1({ ...formData1, buildingAddress: _buildingAddress })
    );
  };

  const handleRentInfo = (event) => {
    const { name, value } = event.target;
    let _rent = { ...formData1.rent, [name]: value };
    dispatch(setFormData_1({ ...formData1, rent: _rent }));
  };

  const handleOnChangeFullName = (event, index) => {
    const { value } = event.target;
    const _tenant = formData1.tenant.slice();
    _tenant[index].name = value;
    dispatch(setFormData_1({ ...formData1, tenant: _tenant }));
  };

  // const receivedSignature = (data) => {
  //   dispatch(setCustumerSign(data));
  // }

  const addAdditionalName = () => {
    const _tenant = formData1.tenant.slice();
    const _additionalName = formData1.additionalName.slice();
    _additionalName.push({
      name: "name",
      placeholder: `Full Name `,
    });
    _tenant.push({
      name: "",
      signature: "",
      date: "",
    });

    dispatch(
      setFormData_1({
        ...formData1,
        tenant: _tenant,
        additionalName: _additionalName,
      })
    );
  };

  const removeAdditionalName = () => {
    const _tenant = formData1.tenant.slice();
    const _additionalName = formData1.additionalName.slice();
    _additionalName.pop();
    _tenant.pop();
    dispatch(
      setFormData_1({
        ...formData1,
        tenant: _tenant,
        additionalName: _additionalName,
      })
    );
  };

  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <FormHeaderLogo />
            </div>
            <div className="propertyName">
              <h3>RESIDENTIAL TENANCY AGREEMENT</h3>
            </div>
          </div>
          <div className="detailProperty">
            <p>
              Between <br />
              {propertyInfo.title} / {propertyInfo.company}
              <br />
              {propertyInfo.landlord} and {mangerInfo.name}
            </p>
          </div>
          <div className="detailForm cmnfrmstyle">
            <Form>
              <Row>
                {error && (
                  <Col
                    md={12}
                    className="d-flex align-items-center justify-content-center mb-3"
                  >
                    <Label style={{ color: "red" }}>Error : {error}</Label>
                  </Col>
                )}
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Full Name</Label>
                    <Input
                      readOnly={readOnly}
                      placeholder="Full Name"
                      type="text"
                      name="fullName"
                      value={formData1.tenant[0].name}
                      onChange={(event) => {
                        handleOnChangeFullName(event, 0);
                      }}
                    />
                    {!readOnly && (
                      <ButtonGroup>
                        <Button
                          disabled={
                            formData1.additionalName.length < FULLNAME_LIMIT
                              ? false
                              : true
                          }
                          color="info"
                          onClick={addAdditionalName}
                        >
                          +
                        </Button>
                        <Button
                          disabled={
                            formData1.additionalName.length <= 0 ? true : false
                          }
                          color="danger"
                          onClick={removeAdditionalName}
                        >
                          -
                        </Button>
                      </ButtonGroup>
                    )}
                  </div>
                </Col>
                {formData1.additionalName.length > 0 &&
                  formData1.additionalName.map((item, index) => (
                    <Col key={index} md={12}>
                      <div className="frmInner">
                        <Label>{item.placeholder} </Label>
                        <Input
                          type="text"
                          readOnly={readOnly}
                          {...item}
                          value={formData1.tenant[index + 1].name}
                          onChange={(event) => {
                            handleOnChangeFullName(event, index + 1);
                          }}
                        />
                      </div>
                    </Col>
                  ))}
              </Row>
            </Form>
            <span>
              (individually or collectively referred to as the “Tenant”)
            </span>
          </div>
          <p>
            THE TENANCY CREATED BY THIS AGREEMENT IS GOVERNED BY THE RESIDENTIAL
            TENANCIES ACT (THE “ACT”), AND IF THERE IS A CONFLICT BETWEEN THE
            AGREEMENT AND THE ACT, THE ACT PREVAILS.
          </p>
          <p>
            PREMISES: The Landlord hereby leases to the Tenant and the Tenant
            hereby leases from the Landlord the following residential premises
            described as follows:
          </p>
          <div className="detailForm cmnfrmstyle">
            <Form>
              <Row>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Building Name</Label>
                    <Input
                      readOnly={readOnly}
                      placeholder="Building Name"
                      type="text"
                      name="buildingName"
                      value={formData1.buildingAddress.buildingName}
                      onChange={handleBuildingAddress}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Unit Number</Label>
                    <Input
                      readOnly={readOnly}
                      placeholder="Unit Number"
                      type="text"
                      name="unitNumber"
                      value={formData1.buildingAddress.unitNumber}
                      onChange={handleBuildingAddress}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Address</Label>
                    <Input
                      readOnly={readOnly}
                      placeholder="Address"
                      type="text"
                      name="address"
                      value={formData1.buildingAddress.address}
                      onChange={handleBuildingAddress}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>City</Label>
                    <Input
                      readOnly={readOnly}
                      placeholder="City"
                      type="text"
                      name="city"
                      value={formData1.buildingAddress.city}
                      onChange={handleBuildingAddress}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Postal Code</Label>
                    <Input
                      readOnly={readOnly}
                      placeholder="Postal Code"
                      type="text"
                      name="postalCode"
                      value={formData1.buildingAddress.postalCode}
                      onChange={handleBuildingAddress}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="midContent">
            <strong>TERM & NOTICE- </strong> <br />
            {!readOnly && (
              <>
                {" "}
                <Form>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Start Date</Label>
                        <Input
                          readOnly={readOnly}
                          type="date"
                          name="noticeStartDate"
                          value={
                            formData1.noticeStartDate
                              ? moment(formData1.noticeStartDate).format(
                                "YYYY-MM-DD"
                              )
                              : ""
                          }
                          onChange={handleOnChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>End Date</Label>
                        <Input
                          readOnly={readOnly}
                          type="date"
                          name="noticeEndDate"
                          value={
                            formData1.noticeEndDate
                              ? moment(formData1.noticeEndDate).format(
                                "YYYY-MM-DD"
                              )
                              : ""
                          }
                          onChange={handleOnChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <br />
              </>
            )}
            This agreement is for a fixed term, commencing at 12 o’clock noon on{" "}
            {formData1.noticeStartDate
              ? moment(formData1.noticeStartDate).format("LL")
              : "<Start Date>"}{" "}
            ending at 12 o’clock noon on the Last day of{" "}
            {formData1.noticeEndDate
              ? moment(formData1.noticeEndDate).format("LL")
              : "<End Date>"}
            . <br />
            <strong>RENT</strong> <br />
            The tenant shall pay to the landlord without any deductions
            whatsoever as the landlord shall direct from time to time a monthly
            rental sum [here after called “rent”] of:
          </div>
          <div className="btmDetailFrm cmnfrmstyle">
            <Form>
              <Row>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Commencement Date</Label>
                    <Input
                      readOnly={readOnly}
                      placeholder="Commencement Date"
                      type="date"
                      name="commencementDate"
                      value={
                        formData1.rent.commencementDate
                          ? moment(formData1.rent.commencementDate).format(
                            "YYYY-MM-DD"
                          )
                          : ""
                      }
                      onChange={handleRentInfo}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>Monthly Basic Rent ($)</Label>
                    <Input
                      className="amountFiled"
                      readOnly={readOnly}
                      placeholder="Monthly Basic Rent ($)"
                      type="text"
                      name="basicRent"
                      value={formData1.rent.basicRent}
                      onChange={handleRentInfo}
                      onKeyDown={onlyForNumbers}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>Monthly Pet Fee ($)</Label>
                    <CheckForFee
                      name="petFeeCheck"
                      readOnly={readOnly}
                      value={formData1.rent.petFeeCheck}
                      onChange={handleRentInfo}
                    />
                    {formData1.rent.petFeeCheck == "Yes" && (
                      <Input
                        className="amountFiled"
                        readOnly={readOnly}
                        placeholder="Monthly Pet Fee ($)"
                        type="text"
                        name="petFee"
                        value={formData1.rent.petFee}
                        onChange={handleRentInfo}
                        onKeyDown={onlyForNumbers}
                      />
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>Monthly- Underground Parking Fee/ ($)</Label>
                    <CheckForFee
                      name="parkingFeeCheck"
                      readOnly={readOnly}
                      value={formData1.rent.parkingFeeCheck}
                      onChange={handleRentInfo}
                    />
                    {formData1.rent.parkingFeeCheck == "Yes" && (
                      <Input
                        className="amountFiled"
                        readOnly={readOnly}
                        placeholder="Monthly- Underground Parking Fee/ ($)"
                        type="text"
                        name="parkingFee"
                        value={formData1.rent.parkingFee}
                        onChange={handleRentInfo}
                        onKeyDown={onlyForNumbers}
                      />
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>Surface Stall Fee ($)</Label>
                    <CheckForFee
                      name="stallFeeCheck"
                      readOnly={readOnly}
                      value={formData1.rent.stallFeeCheck}
                      onChange={handleRentInfo}
                    />
                    {formData1.rent.stallFeeCheck == "Yes" && (
                      <Input
                        className="amountFiled"
                        readOnly={readOnly}
                        placeholder="Surface Stall Fee ($)"
                        type="text"
                        name="stallFee"
                        value={formData1.rent.stallFee}
                        onChange={handleRentInfo}
                        onKeyDown={onlyForNumbers}
                      />
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>Storage Fee ($)</Label>
                    <CheckForFee
                      name="storageFeeCheck"
                      readOnly={readOnly}
                      value={formData1.rent.storageFeeCheck}
                      onChange={handleRentInfo}
                    />
                    {formData1.rent.storageFeeCheck == "Yes" && (
                      <Input
                        className="amountFiled"
                        readOnly={readOnly}
                        placeholder="Storage Fee ($)"
                        type="text"
                        name="storageFee"
                        value={formData1.rent.storageFee}
                        onChange={handleRentInfo}
                        onKeyDown={onlyForNumbers}
                      />
                    )}
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>TOTAL RENT ($)</Label>
                    <Input
                      className="amountFiled"
                      placeholder="TOTAL RENT"
                      type="text"
                      value={
                        Number(formData1.rent.basicRent) +
                        Number(formData1.rent.petFee) +
                        Number(formData1.rent.parkingFee) +
                        Number(formData1.rent.storageFee)
                      }
                      readOnly
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Incentives</Label>
                    <FormGroup check>
                      <Input
                        disabled={readOnly}
                        name="incentives"
                        type="radio"
                        value="Telus (cable/internet)"
                        checked={
                          formData1.rent.incentives === "Telus (cable/internet)"
                            ? true
                            : false
                        }
                        onChange={handleRentInfo}
                      />
                      <Label check>Telus (cable/internet)</Label>
                    </FormGroup>
                    <FormGroup check className="mx-3">
                      <Input
                        disabled={readOnly}
                        name="incentives"
                        value="Ealt (Early move in incentive)"
                        type="radio"
                        checked={
                          formData1.rent.incentives ===
                            "Ealt (Early move in incentive)"
                            ? true
                            : false
                        }
                        onChange={handleRentInfo}
                      />
                      <Label check>Ealt (Early move in incentive)</Label>
                    </FormGroup>
                    <FormGroup check className="mx-3">
                      <Input
                        disabled={readOnly}
                        name="incentives"
                        value="Both"
                        type="radio"
                        checked={
                          formData1.rent.incentives === "Both" ? true : false
                        }
                        onChange={handleRentInfo}
                      />
                      <Label check>Both</Label>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            </Form>
            <span>
              Rent is due in full no later than the 1st day of each month.
            </span>
            <FormFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStep1;
