import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const SearchableDropdown = ({ options, onSelect }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleItemClick = (value) => {
    onSelect(value);
    setDropdownOpen(false);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className='customDrop'>
      <DropdownToggle caret>
        {searchTerm ? `Search: ${searchTerm}` : 'Select an option'}
      </DropdownToggle>
      <DropdownMenu>
        <input
          type="text"
          className="form-control dropdown-search"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {filteredOptions.map(option => (
          <DropdownItem key={option.value} onClick={() => handleItemClick(option.value)}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default SearchableDropdown;