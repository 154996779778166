import "./styles.css";
import { useEffect, useState } from "react";
import Select from "react-select";
import Dropzone from "react-dropzone";
import FeatherIcon from "feather-icons-react";
import { Row, Collapse, Col, Table } from "reactstrap";
import { Modal, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { formatBytes } from "./helper";
import axios from "../api/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Checkbox = ({ formik, name }) => {
  const [category, subCategory] = name.split(".");
  const { needswork, file, notes } = formik.values[category][subCategory];

  const handleChange = () => {
    formik.setFieldValue(name, {
      needswork: !needswork,
      file: file,
      notes,
    });
  };

  const handleAcceptedFiles = (files) => {
    const processedFiles = files.map((file) => ({
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      name: file.name,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      size: file.size,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath,
    }));

    formik.setFieldValue(name, {
      needswork: needswork,
      file: [...file, ...processedFiles], // Concatenate new files with existing files
      notes: notes,
    });
  };

  return (
    <>
      <div
        className="checkbox-wrapper-3"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div class="form-check form-switch ms-4">
          <input
            className="form-check-input fs-4"
            type="checkbox"
            id={name}
            checked={needswork}
            onChange={handleChange}
          />
        </div>
        {needswork && (
          <>
            <div className="dropzone-previews px-4" id="file-previews">
              {file &&
                file.map((f, i) => (
                  <Row className="align-items-center col" key={i + "-file"}>
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <div
                        className="text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {f.name}
                      </div>
                      <p className="mb-0" style={{ fontSize: "0.8rem" }}>
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                    <button
                      type="button"
                      className="btn btn-soft-danger waves-effect waves-light col-2"
                      onClick={(e) => {
                        formik.setFieldValue(name, {
                          needswork: needswork,
                          file: file.filter((_, index) => index !== i),
                          notes: notes,
                        });
                      }}
                    >
                      <i className="bx bx-trash-alt"></i>
                    </button>
                  </Row>
                ))}
            </div>
            <Dropzone
              onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
              multiple
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FeatherIcon icon="file" className="icon-lg" />
                </div>
              )}
            </Dropzone>
          </>
        )}
      </div>
    </>
  );
};

const ViewInspections = () => {
  const [openAccordion, setOpenAccordion] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [permissionsUi, setPermissionsUi] = useState(false);
  const [inspectionDetailsModal, setInspectionDetailsModal] =
    useState("closed");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    const fetchInspections = async () => {
      try {
        const response = await axios.get("/inspection-report");
        console.log(response.data);
        setInspections(response.data);
      } catch (error) {
        console.error("Error fetching inspection reports:", error);
      }
    };
    fetchInspections();
  }, []);

  const toggleAccordion = (accordionId) => {
    if (openAccordion.includes(accordionId)) {
      setOpenAccordion(openAccordion.filter((id) => id !== accordionId));
    } else {
      setOpenAccordion([...openAccordion, accordionId]);
    }
  };

  return (
    <div>
      <div
        className=""
        style={{
          marginTop: "4.5rem",
          padding: "1rem",
          backgroundColor: "#0D568F",
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between", // Added this line
          alignItems: "center", // Optional: Align items vertically
        }}
      >
        <h2
          className="text-white"
          style={{
            fontWeight: "400",
          }}
        >
          Common Area Inspection Report
        </h2>
        <img src="/images/rentdigicare-logo.svg" alt="rdcare-logo" />
      </div>

      <div className="container-fluid p-5">
        <div className="mb-4">
          <div
            className="d-flex align-items-center justify-content-between mb-3"
            onClick={() => toggleAccordion(1)}
          >
            <h4
              className="text-black"
              style={{ fontWeight: "400", marginRight: "auto" }}
            >
              Filters
            </h4>
            <button className="btn btn-link">
              <FeatherIcon
                icon={openAccordion.includes(1) ? "minus" : "plus"}
              />
            </button>
          </div>
          <Collapse className="p-10" isOpen={openAccordion.includes(1)}>
            <div className="row">
              <div className="col-3">
                <div className="customDatePickerWidth">
                  <label className="ms-2 row" htmlFor="startDate">
                    From Date
                  </label>
                  <DatePicker
                    className="form-control"
                    selected={startDate}
                    onChange={handleStartDateChange}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Select From Date"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="customDatePickerWidth">
                  <label className="ms-2 row" htmlFor="endDate">
                    To Date
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="form-control"
                    placeholderText="Select To Date"
                    dateFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="preparedBy">Property</label>
                  <Select
                    options={[
                      { value: "John", label: "John" },
                      { value: "Jane", label: "Jane" },
                      { value: "Doe", label: "Doe" },
                    ]}
                    placeholder="Select a Property Name"
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="preparedBy">Prepared By</label>
                  <Select
                    options={[
                      { value: "John", label: "John" },
                      { value: "Jane", label: "Jane" },
                      { value: "Doe", label: "Doe" },
                    ]}
                    placeholder="Select Name"
                  />
                </div>
              </div>
            </div>
          </Collapse>
        </div>
        <Table striped>
          <thead>
            <tr className="bg-primary">
              <th className="inspection-table-header-item">Id #</th>
              <th className="inspection-table-header-item">Prepared By</th>
              <th className="inspection-table-header-item">Role</th>
              <th className="inspection-table-header-item">Property</th>
              <th className="inspection-table-header-item">Issues Observed</th>
              <th className="inspection-table-header-item">Date Created</th>
              <th className="inspection-table-header-item">Actions Taken</th>
              <th className="inspection-table-header-item">View Details</th>
            </tr>
          </thead>
          <tbody>
            {inspections.map((report) => (
              <tr key={report._id}>
                <td>{report._id}</td>
                <td>{report.CreatedBy}</td>
                <td>{report.CreatedByType}</td>
                <td>{"True"}</td>
                <td>{"Property Name"}</td>
                <td>
                  <ul>
                    <li>{true ? "Issue 1" : "No Issue 1"}</li>
                    <li>{true ? "Issue 2" : "No Issue 2"}</li>
                    <li>{true ? "Issue 3" : "No Issue 3"}</li>
                    <li>{true ? "Issue 4" : "No Issue 4"}</li>
                  </ul>
                </td>
                <td>{"Timeline"}</td>
                <td>
                  <button
                    className="btn btn-outline-info btn-sm align-items-center justify-content-center"
                    onClick={() => setInspectionDetailsModal("view")}
                  >
                    <i class="bx bx-detail font-size-16 align-middle me-1" />
                    View Details
                  </button>
                </td>
                <ViewDetailsModal
                  show={inspectionDetailsModal !== "closed"}
                  handleClose={() => {
                    setInspectionDetailsModal("closed");
                  }}
                  inspection={report}
                  permissionsUi={permissionsUi}
                  setPermissionsUi={setPermissionsUi}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const ViewDetailsModal = (props) => {
  const { show, handleClose, inspection, permissionsUi, setPermissionsUi } =
    props;

  const PermissionSelect = () => {
    const options = [
      { value: "read", label: "Read" },
      { value: "write", label: "Write" },
      { value: "delete", label: "Delete" },
    ];
    return (
      <Select
        className="mt-4"
        isMulti
        options={options}
        placeholder="Select Permissions"
      />
    );
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdropClassName="inspection-backdrop"
      fullscreen={true}
    >
      <Modal.Header className="inspection-modal-header" closeButton>
        <Modal.Title className="text-white">Inspection Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ActionForm inspection={inspection} />
        <button
          className="btn btn-outline-success btn-xl align-items-center justify-content-center"
          onClick={() => setPermissionsUi(!permissionsUi)}
        >
          <i class="bx bx-chalkboard font-size-16 align-middle me-1" />
          Assign to Team Members
        </button>
        {permissionsUi && <PermissionSelect />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const ActionForm = ({ inspection }) => {
  const [openAccordion, setOpenAccordion] = useState([]);

  const toggleAccordion = (accordionId) => {
    if (openAccordion.includes(accordionId)) {
      setOpenAccordion(openAccordion.filter((id) => id !== accordionId));
    } else {
      setOpenAccordion([...openAccordion, accordionId]);
    }
  };

  const formik = useFormik({
    initialValues: inspection,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const handleAddField = () => {
    const newIssue = {
      issue: false,
      action_taken: [],
      date: "",
      notes: "",
    };
    formik.setFieldValue("mechanical_rooms.issues", [
      ...formik.values.mechanical_rooms.issues,
      newIssue,
    ]);
  };

  const handleRemoveField = (index) => {
    const newIssues = [...formik.values.mechanical_rooms.issues];
    newIssues.splice(index, 1);
    formik.setFieldValue("mechanical_rooms.issues", newIssues);
  };

  return (
    <div>
      <div
        className="d-flex align-items-center justify-content-between mb-4"
        onClick={() => toggleAccordion(2)}
      >
        <h5
          className="text-black"
          style={{ fontWeight: "500", marginRight: "auto" }}
        >
          INTERIOR
        </h5>
        <button className="btn btn-link">
          <FeatherIcon
            icon={openAccordion.includes(2) ? "chevron-up" : "chevron-down"}
          />
        </button>
      </div>
      <Collapse className="p-10" isOpen={openAccordion.includes(2)}>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="col-3">CATEGORY</th>
                <th className="col-3">NEEDS WORK</th>
                <th className="col-6">NOTES</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ENTRANCE LOBBY</td>
                <td>
                  <Checkbox formik={formik} name="interior.entrance_lobby" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.interior.entrance_lobby.notes}
                    onChange={formik.handleChange}
                    name="interior.entrance_lobby.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>ELEVATOR LOBBY</td>
                <td>
                  <Checkbox formik={formik} name="interior.elevator_lobby" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.interior.elevator_lobby.notes}
                    onChange={formik.handleChange}
                    name="interior.elevator_lobby.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>CARPETS</td>
                <td>
                  <Checkbox formik={formik} name="interior.carpets" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.interior.carpets.notes}
                    onChange={formik.handleChange}
                    name="interior.carpets.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>WALLS</td>
                <td>
                  <Checkbox formik={formik} name="interior.walls" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.interior.walls.notes}
                    onChange={formik.handleChange}
                    name="interior.walls.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>LIGHTS</td>
                <td>
                  <Checkbox formik={formik} name="interior.lights" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.interior.lights.notes}
                    onChange={formik.handleChange}
                    name="interior.lights.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>HALLWAY DOORS</td>
                <td>
                  <Checkbox formik={formik} name="interior.hallway_doors" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.interior.hallway_doors.notes}
                    onChange={formik.handleChange}
                    name="interior.hallway_doors.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>STAIRWELL</td>
                <td>
                  <Checkbox formik={formik} name="interior.stairwell" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.interior.stairwell.notes}
                    onChange={formik.handleChange}
                    name="interior.stairwell.notes"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
      <div
        className="d-flex align-items-center justify-content-between mb-3"
        onClick={() => toggleAccordion(3)}
      >
        <h5
          className="text-black"
          style={{ fontWeight: "500", marginRight: "auto" }}
        >
          EXTERIOR
        </h5>
        <button className="btn btn-link">
          <FeatherIcon
            icon={openAccordion.includes(3) ? "chevron-up" : "chevron-down"}
          />
        </button>
      </div>
      <Collapse className="p-10" isOpen={openAccordion.includes(3)}>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="col-3">CATEGORY</th>
                <th className="col-3">NEEDS WORK</th>
                <th className="col-6">NOTES</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ENTRANCE LOBBY</td>
                <td>
                  <Checkbox formik={formik} name="exterior.entrance_lobby" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.entrance_lobby.notes}
                    onChange={formik.handleChange}
                    name="exterior.entrance_lobby.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>LANDSCAPING</td>
                <td>
                  <Checkbox formik={formik} name="exterior.landscaping" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.entrance_lobby.notes}
                    onChange={formik.handleChange}
                    name="exterior.entrance_lobby.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>WINDOWS</td>
                <td>
                  <Checkbox formik={formik} name="exterior.windows" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.windows.notes}
                    onChange={formik.handleChange}
                    name="exterior.windows.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>BUILDING APPEARANCE</td>
                <td>
                  <Checkbox
                    formik={formik}
                    name="exterior.building_appearance"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.building_appearance.notes}
                    onChange={formik.handleChange}
                    name="exterior.building_appearance.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>LIGHTS</td>
                <td>
                  <Checkbox formik={formik} name="exterior.lights" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.lights.notes}
                    onChange={formik.handleChange}
                    name="exterior.lights.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>ENTRANCE DOORS</td>
                <td>
                  <Checkbox formik={formik} name="exterior.entrance_doors" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.entrance_doors.notes}
                    onChange={formik.handleChange}
                    name="exterior.entrance_doors.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>SIDEWALKS</td>
                <td>
                  <Checkbox formik={formik} name="exterior.sidewalks" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.sidewalks.notes}
                    onChange={formik.handleChange}
                    name="exterior.sidewalks.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>GARAGE BIN AREA</td>
                <td>
                  <Checkbox formik={formik} name="exterior.garage_bin_area" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.garage_bin_area.notes}
                    onChange={formik.handleChange}
                    name="exterior.garage_bin_area.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>FENCE</td>
                <td>
                  <Checkbox formik={formik} name="exterior.fence" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.fence.notes}
                    onChange={formik.handleChange}
                    name="exterior.fence.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>PARKING LOT</td>
                <td>
                  <Checkbox formik={formik} name="exterior.parking_lot" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.parking_lot.notes}
                    onChange={formik.handleChange}
                    name="exterior.parking_lot.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>ROOF</td>
                <td>
                  <Checkbox formik={formik} name="exterior.roof" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.exterior.roof.notes}
                    onChange={formik.handleChange}
                    name="exterior.roof.notes"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
      <div
        className="d-flex align-items-center justify-content-between mb-3"
        onClick={() => toggleAccordion(4)}
      >
        <h5
          className="text-black"
          style={{ fontWeight: "500", marginRight: "auto" }}
        >
          PARKADE
        </h5>
        <button className="btn btn-link">
          <FeatherIcon
            icon={openAccordion.includes(4) ? "chevron-up" : "chevron-down"}
          />
        </button>
      </div>
      <Collapse className="p-10" isOpen={openAccordion.includes(4)}>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="col-3">CATEGORY</th>
                <th className="col-3">NEEDS WORK</th>
                <th className="col-6">NOTES</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>GARAGE DOORS</td>
                <td>
                  <Checkbox formik={formik} name="parkade.garage_doors" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.parkade.garage_doors.notes}
                    onChange={formik.handleChange}
                    name="parkade.roof.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>EXIT DOORS</td>
                <td>
                  <Checkbox formik={formik} name="parkade.exit_doors" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.parkade.exit_doors.notes}
                    onChange={formik.handleChange}
                    name="parkade.exit_doors.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>MAINTENANCE DOORS</td>
                <td>
                  <Checkbox formik={formik} name="parkade.maintenance_doors" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.parkade.maintenance_doors.notes}
                    onChange={formik.handleChange}
                    name="parkade.maintenance_doors.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>PARKING STALLS</td>
                <td>
                  <Checkbox formik={formik} name="parkade.parking_stalls" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.parkade.parking_stalls.notes}
                    onChange={formik.handleChange}
                    name="parkade.parking_stalls.notes"
                  />
                </td>
              </tr>
              <tr>
                <td>ELEVATOR LOBBY</td>
                <td>
                  <Checkbox formik={formik} name="parkade.elevator_lobby" />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Notes"
                    value={formik.values.parkade.elevator_lobby.notes}
                    onChange={formik.handleChange}
                    name="parkade.elevator_lobby.notes"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
      <div
        className="d-flex align-items-center justify-content-between mb-3"
        onClick={() => toggleAccordion(5)}
      >
        <h5
          className="text-black"
          style={{ fontWeight: "500", marginRight: "auto" }}
        >
          MECHANICAL ROOMS
        </h5>
        <button className="btn btn-link">
          <FeatherIcon
            icon={openAccordion.includes(5) ? "chevron-up" : "chevron-down"}
          />
        </button>
      </div>
      <Collapse className="p-10" isOpen={openAccordion.includes(5)}>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="col-3">ISSUE</th>
                <th className="col-2">ACTION TAKEN</th>
                <th className="col-2">DATE</th>
                <th className="col-5">NOTES</th>
              </tr>
            </thead>
            <tbody>
              {formik.values.mechanical_rooms.issues.map((issue, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Issue Description"
                      value={issue.notes}
                      onChange={(e) => {
                        const newIssues = [
                          ...formik.values.mechanical_rooms.issues,
                        ];
                        newIssues[index].notes = e.target.value;
                        formik.setFieldValue(
                          "mechanical_rooms.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                  <td>
                    <Select
                      options={[
                        { value: "yes", label: "Yes" },
                        { value: "no", label: "No" },
                      ]}
                      value={
                        issue.issue
                          ? { value: "yes", label: "Yes" }
                          : { value: "no", label: "No" }
                      }
                      onChange={(selectedOption) => {
                        const newIssues = [
                          ...formik.values.mechanical_rooms.issues,
                        ];
                        newIssues[index].issue = selectedOption.value === "yes";
                        formik.setFieldValue(
                          "mechanical_rooms.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      value={issue.date}
                      onChange={(e) => {
                        const newIssues = [
                          ...formik.values.mechanical_rooms.issues,
                        ];
                        newIssues[index].date = e.target.value;
                        formik.setFieldValue(
                          "mechanical_rooms.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={issue.notes}
                      onChange={(e) => {
                        const newIssues = [
                          ...formik.values.mechanical_rooms.issues,
                        ];
                        newIssues[index].notes = e.target.value;
                        formik.setFieldValue(
                          "mechanical_rooms.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={handleAddField}
                  >
                    ADD MORE ISSUE +
                  </button>
                </td>
                {formik.values.mechanical_rooms.issues.length >= 1 && (
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={handleRemoveField}
                    >
                      REMOVE -
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
      <div
        className="d-flex align-items-center justify-content-between mb-3"
        onClick={() => toggleAccordion(6)}
      >
        <h5
          className="text-black"
          style={{ fontWeight: "500", marginRight: "auto" }}
        >
          MISCELLANEOUS ITEMS/OTHER IMPROVEMENTS/LAUNDRY LIST ITEMS/
        </h5>
        <button className="btn btn-link">
          <FeatherIcon
            icon={openAccordion.includes(6) ? "chevron-up" : "chevron-down"}
          />
        </button>
      </div>
      <Collapse className="p-10" isOpen={openAccordion.includes(6)}>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="col-3">ISSUE</th>
                <th className="col-3">LOCATION</th>
                <th className="col-6">NOTES</th>
              </tr>
            </thead>
            <tbody>
              {formik.values.miscellaneous_items.issues.map((issue, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Issue Description"
                      value={issue.issue}
                      onChange={(e) => {
                        const newIssues = [
                          ...formik.values.miscellaneous_items.issues,
                        ];
                        newIssues[index].location = e.target.value;
                        formik.setFieldValue(
                          "miscellaneous_items.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Issue Description"
                      value={issue.location}
                      onChange={(e) => {
                        const newIssues = [
                          ...formik.values.miscellaneous_items.issues,
                        ];
                        newIssues[index].location = e.target.value;
                        formik.setFieldValue(
                          "miscellaneous_items.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={issue.notes}
                      onChange={(e) => {
                        const newIssues = [
                          ...formik.values.mechanical_rooms.issues,
                        ];
                        newIssues[index].notes = e.target.value;
                        formik.setFieldValue(
                          "mechanical_rooms.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={handleAddField}
                  >
                    ADD MORE ISSUE +
                  </button>
                </td>
                {formik.values.mechanical_rooms.issues.length >= 1 && (
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={handleRemoveField}
                    >
                      REMOVE -
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
      <div
        className="d-flex align-items-center justify-content-between mb-3"
        onClick={() => toggleAccordion(6)}
      >
        <h5
          className="text-black"
          style={{ fontWeight: "500", marginRight: "auto" }}
        >
          MONTHLY GAMEPLAN - LEAVE BLANK UNTIL REPORT MEETING
        </h5>
        <button className="btn btn-link">
          <FeatherIcon
            icon={openAccordion.includes(6) ? "chevron-up" : "chevron-down"}
          />
        </button>
      </div>
      <Collapse className="p-10" isOpen={openAccordion.includes(6)}>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="col-3">ISSUE</th>
                <th className="col-3">ASSIGNED TO</th>
                <th className="col-6">DUE DATE</th>
              </tr>
            </thead>
            <tbody>
              {formik.values.monthly_gameplan.issues.map((issue, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Issue Description"
                      value={issue.issue}
                      onChange={(e) => {
                        const newIssues = [
                          ...formik.values.monthly_gameplan.issues,
                        ];
                        newIssues[index].location = e.target.value;
                        formik.setFieldValue(
                          "monthly_gameplan.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                  <td>
                    <Select
                      isMulti
                      options={[
                        { value: "option1", label: "Option 1" },
                        { value: "option2", label: "Option 2" },
                        { value: "option3", label: "Option 3" },
                      ]}
                      // value={formik.values.monthly_gameplan.issues[
                      //   index
                      // ].location.map((loc) => ({ value: loc, label: loc }))} // Ensure the value is an array of objects with 'value' and 'label' properties
                      // onChange={(selectedOptions) => {
                      //   const newIssues = [
                      //     ...formik.values.monthly_gameplan.issues,
                      //   ];
                      //   newIssues[index].location = selectedOptions.map(
                      //     (option) => option.value
                      //   );   formik.setFieldValue(
                      //     "monthly_gameplan.issues",
                      //     newIssues
                      //   );
                      // }}
                      placeholder="Select Staff Members"
                    />
                  </td>
                  <td>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Enter Date"
                      value={issue.date}
                      onChange={(e) => {
                        const newIssues = [
                          ...formik.values.monthly_gameplan.issues,
                        ];
                        newIssues[index].date = e.target.value;
                        formik.setFieldValue(
                          "monthly_gameplan.issues",
                          newIssues
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={handleAddField}
                  >
                    ADD MORE ISSUE +
                  </button>
                </td>
                {formik.values.mechanical_rooms.issues.length >= 1 && (
                  <td>
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={handleRemoveField}
                    >
                      REMOVE -
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
          </table>
        </div>
      </Collapse>
    </div>
  );
};

export { ViewInspections };
