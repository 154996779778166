import React from "react";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { setFormData_2 } from "../../../store/leaseForm/actions";
import { onlyForNumbers } from "../../../_util/keyEventHandler";
import FormHeaderLogo from "./FormHeaderLogo";
import FormFooter from "./FormFooter";

import "./frmStyle.scss";

const FormStep2 = ({ readOnly }) => {
  const { formData1, formData2, error2 } = useSelector((state) => (state.leaseForm));
  const dispatch = useDispatch();

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    dispatch(setFormData_2({ ...formData2, [name]: value }))
  }


  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <FormHeaderLogo />
            </div>
            {error2 && <div className="propertyName">
              <Label style={{ color: "red" }}>
                Error : {error2}
              </Label>
            </div>}
          </div>
          <div className="detailProperty from2">
            <p>
              When two or more persons comprise the tenant for the purposes of
              this agreement, the l andlord may collect the rent due to the
              landlord pursuant to the agreement from anyone, some or all of
              them. The tenant shall pay the rent reserved, without demand, at
              the times and in the manner specified here in provided without
              deduction, abatement or set-off.
            </p>
          </div>

          <div className="midContent">
            <strong>NOTICE OF EARLY LEASE TERMINATION</strong>
            <br />
            If the Tenant wishes to end the Tenancy they MUST submit a Notice of
            Intent to Terminate the Lease minimum 60 Days to the Community
            Manager in writing prior to their intent to vacate. Tenant
            termination of the Tenancy prior to the end of the Fixed Term
            Tenancy requires the payment of 2 Months of Total Rent and any
            additional surcharges for repairs, cleaning if required to the
            rental suite. Lease Break Fee: $400 TELUS Surcharge if lease is
            terminated by tenant + LEASE BREAK Fee of Two Month’s Total Rent.
            <br /><strong>SECURITY DEPOSIT/PET FEE DEPOSIT</strong>
            <br />
            The Landlord acknowledges receipt on (Date): November 2021 from the
            Tenant as a security deposit. The Security Deposit may be utilized
            by the Landlord in such amount as is necessary to provide for any or
            all of the following:
          </div>
          <div className="descriptionFrm cmnfrmstyle midContent">
            <strong>Description</strong>
            <br />
            <Form>
              <Row>
                <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>Security Deposit ($) (Refundable)</Label>
                    <Input className="amountFiled" readOnly={readOnly} placeholder="Security Deposit (Refundable) ($)" type="text" name="securityDeposit" value={formData2.securityDeposit} onChange={handleOnChange} onKeyDown={onlyForNumbers} />
                  </div>
                </Col>
                {formData1.rent.petFeeCheck == "Yes" && <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>Pet Security Fee ($) (Non-Refundable)</Label>
                    <Input className="amountFiled" readOnly={readOnly} placeholder="Pet Security Fee (Non-Refundable) ($)" type="text" name="petSecurityFee" value={formData2.petSecurityFee} onChange={handleOnChange} onKeyDown={onlyForNumbers} />
                  </div>
                </Col>}

                <Col md={12}>
                  <div className="frmInner amountFiled">
                    <Label>TOTAL Security Deposit ($)</Label>
                    <Input className="amountFiled" placeholder="TOTAL Security Deposit ($)" type="text" value={Number(formData2.securityDeposit) + Number(formData2.petSecurityFee)} readOnly />
                  </div>
                </Col>
              </Row>
            </Form>
            <p>
              The landlord is entitled to use and retain the security deposit in
              the event the tenant: does not take possession on the commencement
              of the term of this lease, does not pay the first installment of
              rent, or does not execute a lease agreement satisfactory to both
              parties.{" "}
            </p>
            <p>
              The landlord may apply such amounts as he/she deems necessary to
              provide for:
            </p>
            <ol>
              <li>
                Repairing or replacing any fixtures, fittings, appliances, or
                facilities located in, upon or about the leased premises.
              </li>
              <li>
                Cleaning and arranging for cleaning of the leased premises;
                including appliances, windows, floors, walls, professionally
                steam cleaning the carpets, repairing paint work etc.{" "}
              </li>
              <li>
                {" "}
                To remedy a breach of an obligation of the tenant hereunder at
                any time.
              </li>
              <li>
                {" "}
                The security deposit can NOT be used by the tenant towards any
                rent.
              </li>
            </ol>
            <strong>PETS</strong>
            <br />
            <p>
              Cats and small dogs under 20 lbs., full grown, are allowed with a
              $300.00, pet nonrefundable fee. All animals must be spayed or
              neutered. Dogs must always be on a leash outside of your apartment
              while on Property. You must pick up after your dog. Management
              will also conduct an interview with pet if required. All Pets must
              be approved by management prior to signing the lease or during
              tenancy. Monthly fee of $25.00 per pet will be applied.
            </p>
            <strong>LATE FEE</strong> <br />
            <p>
              The full rent is due on the first day of each month. There will be
              a late payment fee of $75.00 charged if the full rent is paid
              after the 2nd day of the month and an additional $100.00 if paid
              after the 15th of the month. In the event the bank returns cheque
              unpaid; the tenant hereby agrees to pay a $75.00 fee for each
              returned cheque in addition to the $100.00 late fee if paid after
              the 15th of the month. Any funds received will first be applied to
              the outstanding balance. In the event rent is not paid by 1st day
              of the month, tenant will get eviction notice to vacate the
              property, unless payment arrangements have been made with
              management and is approved.
            </p>
            <FormFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStep2;
