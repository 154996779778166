import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, Col, Row } from "reactstrap";
import RevReport from "./RevReport";
import VacancyReport from "./VacancyReport";
import ToReport from "./ToReport";
import "./kpiStyle.scss";

const Kpi_list = () => {

  return (
    <div className="page-content">
      <div className="kpiList_page">
        <MetaTags>
          <title>Rentdigicare | Master Sheet</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="KPI List" />
          <Row>
            <Col xl={12}>
              <Card>
                <Tabs
                  defaultActiveKey="revReport"
                  id="uncontrolled-tab-example"
                  className="mb-3 kpiTabs"
                >
                  <Tab eventKey="revReport" title="Rev Report">
                    <RevReport />
                  </Tab>
                  <Tab eventKey="vacancy" title="Vacancy">
                    <VacancyReport />
                  </Tab>
                  <Tab eventKey="to" title="To">
                    <ToReport />
                  </Tab>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Kpi_list;
