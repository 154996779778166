import React from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

const mimeTypes = {
  bmp: "image/bmp",
  csv: "text/csv",
  odt: "application/vnd.oasis.opendocument.text",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  gif: "image/gif",
  htm: "text/htm",
  html: "text/html",
  jpg: "image/jpg",
  jpeg: "image/jpeg",
  pdf: "application/pdf",
  png: "image/png",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  tiff: "image/tiff",
  txt: "text/plain",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  mp4: "video/mp4",
  webp: "image/webp",
};

const DocumentRenderer = ({ previewUrl, fileType }) => {
  if (!previewUrl) {
    return <div>Loading...</div>;
  }

  const docs = [
    {
      uri: previewUrl,
      fileType: fileType,
    },
  ];

  return (
    <div style={{ minHeight: "70vh",maxHeight: "70vh", overflowY: "auto", width: "100%" }}>
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        style={{ width: "100%" }}
      />
    </div>
  );
};

const DocumentPreviewUI = ({ documentUrl, previewURL }) => {
  if (!documentUrl || !previewURL) {
    return null;
  }

  const documentType = documentUrl.split(".").pop().toLowerCase();

  if (mimeTypes[documentType]) {
    return <DocumentRenderer previewUrl={previewURL} fileType={documentType} />;
  }

  return (
    <div className="p-5">
      Unsupported file type: {documentType} - You will need to download to
      preview.
    </div>
  );
};

export { DocumentPreviewUI };
