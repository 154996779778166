import React, { useEffect, useRef, useState } from "react";
import {
    Row, Col, Button,
    FormGroup,
    Input,
    Label,
    Card,
    CardBody,
} from "reactstrap"
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";
import axios from "../api/axios";
import { toast } from "react-toastify";

const POST_KPI_REPORT_UPLOAD = "kpireport/upload";

const UploadKpi = () => {
    const dispatch = useDispatch();
    const user = JSON.parse(window.localStorage.getItem("authUser"))?.userData;

    const [years, setYears] = useState([])
    const [selectYear, setSelectYear] = useState("")
    const [file, setFile] = useState(null);
    const inputFile = useRef(null);

    const handleUploadFile = (event) => {
        const { files } = event.target;
        setFile(files[0])
    }

    // Function to reset the input element 
    const handleReset = () => {
        if (inputFile.current) {
            console.log("====>", inputFile.current)
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
    };

    const handleSubmit = async () => {
        try {
            if (selectYear == "" && file == null) {
                return;
            }
            dispatch(isLoading(true))

            const formData = new FormData();
            formData.append("report", file);
            const options = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "year": selectYear,
                    "id": user.id
                },
            }
            const res = await axios.post(POST_KPI_REPORT_UPLOAD, formData, options);
            if (res && res.data && res.data.status == 201) {
                toast.success("File Uploaded Successfully !");
                setSelectYear("");
                setFile(null);
                handleReset();
            }
            dispatch(isLoading(false))

        } catch (error) {
            toast.error("Something went wrong!")
            dispatch(isLoading(false))
        }
    }

    function getYearsFromCurrentToPast(startYear) {

        const endYear = new Date().getFullYear();
        const _years = [];

        for (let year = startYear; year <= endYear; year++) {
            _years.push(year);
        }
        _years.reverse()
        setYears(_years);
    }

    useEffect(() => {
        getYearsFromCurrentToPast(2020);
    }, [])

    return (<>
        <div className="page-content">
            <div className="master_page">
                <div className="container-fluid fontSize12">
                    <br></br>
                    <h4 className="cmnHding">Upload KPI File</h4>
                    <div className="innnerFrmData">
                        <Row>
                            <Col md={{
                                offset: 3,
                                size: 4
                            }}
                            >
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={12} className="mb-3">
                                                <FormGroup>
                                                    <Label>Select Year</Label>
                                                    <Form.Select aria-label="Default select example" value={selectYear} onChange={(event) => {
                                                        setSelectYear(event.target.value)
                                                    }}>
                                                        <option value={""}>{"Select"}</option>
                                                        {years.map((item, index) => (<option key={index} value={item}>{item}</option>))}
                                                    </Form.Select>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>Select File (accepted only .xlsx files)</Label>
                                                    <Input
                                                        ref={inputFile}
                                                        name="report"
                                                        type="file"
                                                        accept=".xlsx"
                                                        onChange={(event) => {
                                                            handleUploadFile(event)
                                                        }}
                                                        multiple={false}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12} className="mt-3">
                                                <FormGroup>
                                                    <Button disabled={selectYear !== "" && file !== null ? false : true} color="primary" className="margin5Top" onClick={handleSubmit}>Upload</Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div>
        </div >

    </>)
}

export default UploadKpi;
