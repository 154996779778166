import { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import moment from "moment";
import BreadcrumbV3 from "../../components/Common/BreadcrumbV3";
import { isLoading } from "../../store/actions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Documents from "./NewDocuments";
import { Field, FieldArray, useFormik, FormikProvider } from "formik";
import { useDispatch } from "react-redux";
import Select from "react-select";
import debounce from "lodash/debounce"; // Import debounce from lodash
import Swal from "sweetalert2";
import * as Yup from "yup";

const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);

  // Ensure the date is formatted as YYYY-MM-DD without time zone conversion
  let month = "" + (d.getUTCMonth() + 1);
  let day = "" + d.getUTCDate();
  const year = d.getUTCFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};


const validationSchema = Yup.object().shape({
  applicantDetails: Yup.array()
    .of(
      Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        email: Yup.string()
          .email("Invalid email")
          .required("Email is required"),
        mobile: Yup.string().required("Mobile is required"),
      })
    )
    .min(1, "At least one tenant detail is required"),
  occupantDetails: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      age: Yup.number().required("Age is required"),
      relation: Yup.string().required("Relation is required"),
    })
  ),
  utilityAccounts: Yup.object().shape({
    water: Yup.string().required("Water account is required"),
    heat: Yup.string().required("Heat account is required"),
    electricity: Yup.string().required("Electricity account is required"),
  }),
  leaseDetails: Yup.object().shape({
    leaseStart: Yup.string().required("Lease Start Date is required"),
    leaseEnd: Yup.string().required("Lease End Date is required"),
    insuranceExpiryDate: Yup.string().required(
      "Insurance Expiry Date is required"
    ),
    insuranceCompanyName: Yup.string().required(
      "Insurance Company Name is required"
    ),
  }),
  layout: Yup.string().required("Layout is required"),
  suite: Yup.string().required("Suite Number is required"),
  rentDetails: Yup.object().shape({
    securityDeposit: Yup.string().required("Security Deposit is required"),
    targetRent: Yup.string().required("Target Rent is required"),
    basicRent: Yup.string().required("Basic Rent is required"),
    others: Yup.string().required("Others is required"),
    dogFee: Yup.string().required("Dog Fee is required"),
    catFee: Yup.string().required("Cat Fee is required"),
  }),
  parkingInfo: Yup.array().of(Yup.object().shape({})),
  storageInfo: Yup.array().of(Yup.object().shape({})),
  documents: Yup.array().of(Yup.object().shape({})),
});

const New_Tenant_Info = () => {
  const params = useParams();
  const { property: activeProperty } = params;
  const dispatch = useDispatch();

  const [layoutOptions, setLayoutOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    userId: params.id,
    suite: "",
    applicantDetails: [
      {
        firstname: "",
        lastname: "",
        email: "",
        mobile: "",
      },
    ],
    occupantDetails: [
      {
        firstname: "",
        lastname: "",
        age: "",
        relation: "",
      },
    ],
    utilityAccounts: {
      water: "",
      heat: "",
      electricity: "",
    },
    rentDetails: {
      securityDeposit: "",
      targetRent: "",
      basicRent: "",
      others: "",
      dogFee: "",
      catFee: "",
    },
    leaseDetails: {
      leaseStart: "",
      leaseEnd: "",
      insuranceExpiryDate: "",
      insuranceCompanyName: "",
    },
    layout: "",
    parkingInfo: [],
    storageInfo: [],
    documents: [],
  });

  const getTenantInfo = async (_id) => {
    const GET_TENANT_INFO = "mastersheet/get/old-lease-info";
    try {
      const response = await axios.get(`${GET_TENANT_INFO}/?id=${_id}`);
      console.log(
        response.data.data.leaseDetails?.leaseStart,
        formatDate(response.data.data.leaseDetails?.leaseStart)
      );
      if (response && response.data && response.data.status === 200) {
        const result = response.data.data;
        const updatedValues = {
          userId: params.id,
          suite: result.suite || "",
          layout: result.style || "",
          applicantDetails: result.applicantDetails || [],
          occupantDetails: result.occupantDetails || [],
          utilityAccounts: {
            water: result.utilityAccounts?.water || "",
            heat: result.utilityAccounts?.heat || "",
            electricity: result.utilityAccounts?.electricity || "",
          },
          rentDetails: {
            securityDeposit: result.rentDetails?.securityDeposit || "",
            targetRent: result.rentDetails?.targetRent || "",
            basicRent: result.rentDetails?.basicRent || "",
            others:
              result.rentDetails?.others !== undefined
                ? result.rentDetails.others
                : "",
            dogFee: result.rentDetails?.dogFee || "",
            catFee: result.rentDetails?.catFee || "",
          },
          leaseDetails: {
            leaseStart: formatDate(result.leaseDetails?.leaseStart) || "",
            leaseEnd: formatDate(result.leaseDetails?.leaseEnd) || "",
            insuranceExpiryDate:
              formatDate(result.leaseDetails?.insuranceExpiryDate) || "",
            insuranceCompanyName:
              result.leaseDetails?.insuranceCompanyName || "",
          },
          parkingInfo: result.parkingInfo || [],
          storageInfo: result.storageInfo || [],
          documents: result.documents || [],
        };
        setInitialValues(updatedValues);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPropertyLayouts = async () => {
    const GET_PROPERTY_LAYOUTS = "layout/property-layouts";
    try {
      const response = await axios.post(
        GET_PROPERTY_LAYOUTS,
        { propertyId: activeProperty },
        {
          params: {
            page: 1,
            limit: 10,
          },
        }
      );
      if (response && response.data && response.data.status === 200) {
        setLayoutOptions(
          response.data.layouts.map((layout) => ({
            value: layout._id,
            label: layout.layoutName,
          }))
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateTenantInfo = async (buttonClick = false) => {
    try {
      dispatch(isLoading(true));
      const body = formik.values;
      const PUT_UPDATE_TENANT_INFO = "mastersheet/update/old-lease-info";
      const response = await axios.put(PUT_UPDATE_TENANT_INFO, body);
      if (response.data.status === 201) {
        buttonClick &&
          toast.success("Tenant information is updated successfully.");
        await getTenantInfo(params.id);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
    } finally {
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    getTenantInfo(params.id);
    getPropertyLayouts();
  }, []);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, { setStatus, resetForm }) => {
      try {
        dispatch(isLoading(true));
        await updateTenantInfo(true);
        setInitialValues(values);
      } catch (error) {
        setStatus("Error submitting form");
      } finally {
        dispatch(isLoading(false));
      }
    },
  });

  const debouncedUpdateTenantInfo = debounce(() => updateTenantInfo(), 200);

  const handleBlur = (e) => {
    formik.handleBlur(e);
    debouncedUpdateTenantInfo();
  };

  const calculateTotalRent = (values) => {
    return (
      Number(values.rentDetails?.basicRent || 0) +
      Number(
        values.parkingInfo?.reduce((total, item) => {
          return total + Number(item.amount || 0);
        }, 0) || 0
      ) +
      Number(
        values.storageInfo?.reduce((total, item) => {
          return total + Number(item.amount || 0);
        }, 0) || 0
      ) +
      Number(values.rentDetails?.dogFee || 0) +
      Number(values.rentDetails?.catFee || 0) +
      Number(values.rentDetails?.others || 0)
    );
  };

  const handleCustomSubmit = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length > 0) {
        // Function to gather all error messages into a list
        const gatherErrors = (obj) => {
          return Object.values(obj).reduce((acc, value) => {
            if (typeof value === "string") {
              acc.push(value);
            } else {
              acc = acc.concat(gatherErrors(value));
            }
            return acc;
          }, []);
        };

        // Gather the error messages
        const errorMessages = gatherErrors(errors);

        // Display validation errors in a modal with better UI
        Swal.fire({
          title: "Validation Errors",
          html: `<ul style="text-align: left;">${errorMessages
            .map((msg) => `<li>${msg}</li>`)
            .join("")}</ul>`,
          icon: "error",
          confirmButtonText: "Ok",
          width: "500px",
        });
      } else {
        formik.handleSubmit();
      }
    });
  };

  return (
    <div className="page-content">
      <div className="tenant_infoPage">
        <MetaTags>
          <title>Rentdigicare | Tenant Information</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbV3
            breadcrumbItems={[
              { item: "Home", link: "/dashboard" },
              { item: "Master Sheet", link: "/master_table" },
              {
                item: "Tenant Information",
                link: `/new_tenant_info/${params?.id || ""}`,
              },
            ]}
          />
          <div className="formData">
            <Card>
              <CardBody>
                <FormikProvider value={formik}>
                  <form onSubmit={(e) => e.preventDefault()}>
                    {/* Applicant Details */}
                    <div className="frm_InnerSec1">
                      <h4 className="cmnHding">Tenant Details</h4>
                      <div className="innnerFrmData">
                        <FieldArray name="applicantDetails">
                          {({ push, remove }) => (
                            <>
                              {formik.values.applicantDetails.map(
                                (applicant, index) => (
                                  <Row key={index}>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>First Name</Label>
                                        <Field
                                          name={`applicantDetails[${index}].firstName`}
                                          placeholder="First Name"
                                          className="form-control"
                                          onBlur={handleBlur}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Last Name</Label>
                                        <Field
                                          name={`applicantDetails[${index}].lastName`}
                                          placeholder="Last Name"
                                          className="form-control"
                                          onBlur={handleBlur}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Email</Label>
                                        <Field
                                          name={`applicantDetails[${index}].email`}
                                          placeholder="Email"
                                          className="form-control"
                                          onBlur={handleBlur}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Mobile No.</Label>
                                        <Field
                                          name={`applicantDetails[${index}].mobile`}
                                          placeholder="Mobile No."
                                          className="form-control"
                                          type="number"
                                          onBlur={handleBlur}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col
                                      md={12}
                                      className="text-right mt-2 mb-3"
                                    >
                                      <Button
                                        color="danger"
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                )
                              )}
                              {formik.values.applicantDetails.length < 5 && (
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    push({
                                      firstname: "",
                                      lastname: "",
                                      email: "",
                                      mobile: "",
                                    })
                                  }
                                >
                                  Add Tenant
                                </Button>
                              )}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>

                    {/* Occupant Details */}
                    <div className="frm_InnerSec1">
                      <h4 className="cmnHding">Occupant Details</h4>
                      <div className="innnerFrmData">
                        <FieldArray name="occupantDetails">
                          {({ push, remove }) => (
                            <>
                              {formik.values.occupantDetails.map(
                                (occupant, index) => (
                                  <Row key={index}>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>First Name</Label>
                                        <Field
                                          name={`occupantDetails[${index}].firstName`}
                                          placeholder="First Name"
                                          className="form-control"
                                          onBlur={handleBlur}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Last Name</Label>
                                        <Field
                                          name={`occupantDetails[${index}].lastName`}
                                          placeholder="Last Name"
                                          className="form-control"
                                          onBlur={handleBlur}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Age</Label>
                                        <Field
                                          name={`occupantDetails[${index}].age`}
                                          placeholder="Age"
                                          type="number"
                                          className="form-control"
                                          onBlur={handleBlur}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label>Relation</Label>
                                        <Field
                                          name={`occupantDetails[${index}].relation`}
                                          placeholder="Relation"
                                          className="form-control"
                                          onBlur={handleBlur}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col
                                      md={12}
                                      className="text-right mt-2 mb-3"
                                    >
                                      <Button
                                        color="danger"
                                        onClick={() => remove(index)}
                                      >
                                        Remove
                                      </Button>
                                    </Col>
                                  </Row>
                                )
                              )}
                              {formik.values.occupantDetails.length < 5 && (
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    push({
                                      firstname: "",
                                      lastname: "",
                                      age: "",
                                      relation: "",
                                    })
                                  }
                                >
                                  Add Occupant
                                </Button>
                              )}
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </div>

                    {/* Utility Accounts */}
                    <div className="frm_InnerSec1">
                      <h4 className="cmnHding">Utility Accounts</h4>
                      <div className="innnerFrmData">
                        <Row>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Water Supply Account</Label>
                              <Field
                                name="utilityAccounts.water"
                                placeholder="Water Supply Account"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Heat Supply Account</Label>
                              <Field
                                name="utilityAccounts.heat"
                                placeholder="Heat Supply Account"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup>
                              <Label>Electricity Account</Label>
                              <Field
                                name="utilityAccounts.electricity"
                                placeholder="Electricity Account"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    {/* Parking Setup*/}
                    <div className="frm_InnerSec1 mt-3">
                      <h4 className="cmnHding">Parking </h4>
                      <div className="innnerFrmData">
                        {formik.values.parkingInfo.length > 0 ? (
                          formik.values.parkingInfo.map((item, index) => (
                            <Card key={index}>
                              <CardBody>
                                <Row>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Parking Type
                                      </Label>
                                      <Input
                                        id="exampleSelect"
                                        placeholder="Parking Type"
                                        name="floorType"
                                        value={item.floorType}
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Floor No.
                                      </Label>
                                      <Input
                                        id="exampleSelect"
                                        placeholder="Floor No."
                                        name="floor"
                                        value={item.floor}
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Parking No.
                                      </Label>
                                      <Input
                                        id="exampleSelect"
                                        placeholder="Parking No."
                                        name="parkingLotNo"
                                        value={item.parkingLotNo}
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3}>
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Vehicle No.
                                      </Label>
                                      <Input
                                        id="exampleSelect"
                                        placeholder="Vehicle No."
                                        name="vehicalNo"
                                        value={item.vehicalNo}
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3} className="mt-3">
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Effective Date
                                      </Label>
                                      <Input
                                        name="effectiveDate"
                                        value={
                                          item.effectiveDate
                                            ? moment(item.effectiveDate).format(
                                                "YYYY-MM-DD"
                                              )
                                            : ""
                                        }
                                        placeholder="Effective Date"
                                        type="date"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={3} className="mt-3">
                                    <FormGroup>
                                      <Label for="exampleEmail">Amount</Label>
                                      <Input
                                        name="amount"
                                        placeholder="Amount"
                                        type="Number"
                                        value={item.amount}
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    md={3}
                                    className="mt-3 d-flex align-items-center justify-content-center"
                                  >
                                    <Link
                                      className={`btn btn-primary ${
                                        item._id ? "" : "isaDisable"
                                      } `}
                                      to={{
                                        pathname: `/parking_info/${
                                          item._id ? item._id : ""
                                        }`,
                                        state: { activeProperty },
                                      }}
                                    >
                                      Go to Parking
                                    </Link>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          ))
                        ) : (
                          <Card>
                            <CardBody className="text-center ">
                              Parking Details Not Found
                              <br />
                              <Link
                                className={`btn btn-warning mt-3`}
                                to={{
                                  pathname: "/parking_info/",
                                  state: { activeProperty },
                                }}
                              >
                                Assign Parking
                              </Link>
                            </CardBody>
                          </Card>
                        )}
                      </div>
                    </div>

                    {/* Storage Setup*/}
                    <div className="frm_InnerSec1 mt-3">
                      <h4 className="cmnHding">Storage</h4>
                      <div className="innnerFrmData">
                        {formik.values.storageInfo.length > 0 ? (
                          formik.values.storageInfo.map((item, index) => (
                            <Card key={index}>
                              <CardBody>
                                <Row>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Storage Type
                                      </Label>
                                      <Input
                                        id="exampleSelect"
                                        placeholder="Storage Type"
                                        name="floorType"
                                        value={item.floorType}
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Floor No.
                                      </Label>
                                      <Input
                                        id="exampleSelect"
                                        placeholder="Floor No."
                                        name="floor"
                                        value={item.floor}
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4}>
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Locker No.
                                      </Label>
                                      <Input
                                        id="exampleSelect"
                                        placeholder="Locker No."
                                        name="lockerNo"
                                        value={item.lockerNo}
                                        type="text"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} className="mt-3">
                                    <FormGroup>
                                      <Label for="exampleEmail">
                                        Effective Date
                                      </Label>
                                      <Input
                                        name="effectiveDate"
                                        value={
                                          item.effectiveDate
                                            ? moment(item.effectiveDate).format(
                                                "YYYY-MM-DD"
                                              )
                                            : ""
                                        }
                                        placeholder="Effective Date"
                                        type="date"
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md={4} className="mt-3">
                                    <FormGroup>
                                      <Label for="exampleEmail">Amount</Label>
                                      <Input
                                        name="amount"
                                        placeholder="Amount"
                                        type="Number"
                                        value={item.amount}
                                        readOnly
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    md={4}
                                    className="mt-3 d-flex align-items-center justify-content-center"
                                  >
                                    <Link
                                      className={`btn btn-primary ${
                                        item._id ? "" : "isaDisable"
                                      } `}
                                      to={{
                                        pathname: `/storage_info/${
                                          item._id ? item._id : ""
                                        }`,
                                        state: { activeProperty },
                                      }}
                                    >
                                      Go to Storage
                                    </Link>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          ))
                        ) : (
                          <Card>
                            <CardBody className="text-center">
                              Storage Details Not Found
                              <br />
                              <Link
                                className={`btn btn-warning mt-3`}
                                to={{
                                  pathname: `/storage_info/`,
                                  state: { activeProperty },
                                }}
                              >
                                Assign Storage
                              </Link>
                            </CardBody>
                          </Card>
                        )}
                      </div>
                    </div>

                    {/* Lease Details */}
                    <div className="frm_InnerSec1">
                      <h4 className="cmnHding">Lease Details</h4>
                      <div className="innnerFrmData">
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>Lease Start</Label>
                              <Field
                                name="leaseDetails.leaseStart"
                                placeholder="Lease Start"
                                type="date"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Lease End</Label>
                              <Field
                                name="leaseDetails.leaseEnd"
                                placeholder="Lease End"
                                type="date"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Insurance Expiry Date</Label>
                              <Field
                                name="leaseDetails.insuranceExpiryDate"
                                placeholder="Effective Date"
                                type="date"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Insurance Company</Label>
                              <Field
                                name="leaseDetails.insuranceCompanyName"
                                placeholder="Insurance Company"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    {/* Rent Details */}
                    <div className="frm_InnerSec1">
                      <h4 className="cmnHding">Rent Details</h4>
                      <div className="innnerFrmData">
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>Select Layout</Label>
                              <Select
                                options={layoutOptions}
                                name={"layout"}
                                value={layoutOptions.find(
                                  (option) =>
                                    formik.values.layout === option.label
                                )}
                                onChange={(option) =>
                                  formik.setFieldValue("layout", option.label)
                                }
                                onBlur={handleBlur}
                                placeholder="Layout"
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Suite No. (#)</Label>
                              <Field
                                name="suite"
                                value={formik.values.suite}
                                placeholder="Suite Number"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Security Deposit ($)</Label>
                              <Field
                                name="rentDetails.securityDeposit"
                                value={
                                  formik.values.rentDetails.securityDeposit
                                }
                                placeholder="Security Deposit"
                                type="number"
                                className="form-control"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  formik.setFieldValue(
                                    "rentDetails.securityDeposit",
                                    e.target.value
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Target Rent ($)</Label>
                              <Field
                                name="rentDetails.targetRent"
                                placeholder="Target Rent"
                                type="number"
                                className="form-control"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  formik.setFieldValue(
                                    "rentDetails.targetRent",
                                    e.target.value
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Basic Rent ($)</Label>
                              <Field
                                name="rentDetails.basicRent"
                                placeholder="Basic Rent"
                                type="number"
                                className="form-control"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  formik.setFieldValue(
                                    "rentDetails.basicRent",
                                    e.target.value
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Others ($)</Label>
                              <Field
                                name="rentDetails.others"
                                placeholder="Others"
                                className="form-control"
                                type="number"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  formik.setFieldValue(
                                    "rentDetails.others",
                                    e.target.value
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Dog (Pet Fee) ($)</Label>
                              <Field
                                name="rentDetails.dogFee"
                                placeholder="Dog (Pet Fee)"
                                type="number"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Cat (Pet Fee) ($)</Label>
                              <Field
                                name="rentDetails.catFee"
                                placeholder="Cat (Pet Fee)"
                                type="number"
                                className="form-control"
                                onBlur={handleBlur}
                              />
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>Total Rent ($)</Label>
                              <Field
                                placeholder="Total Rent (BR+PF+SF+DF+CF+Oth)"
                                type="number"
                                className="form-control text-center font-weight-bold"
                                value={calculateTotalRent(formik.values)}
                                readOnly
                                style={{
                                  backgroundColor: "#D4EDDA",
                                  color: "#155724",
                                  fontSize: "1rem",
                                  border: "none",
                                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    {/* Submit Button */}
                    <div className="updateForm">
                      <Button
                        color="primary"
                        className="update_btn mt-3"
                        onClick={handleCustomSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </FormikProvider>
              </CardBody>
            </Card>
            <Documents userId={params.id} documents={formik.values.documents} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { New_Tenant_Info as NewTenantInfo };
