const onlyForNumbers = (event) => {
    // Allow only numbers (0-9) and certain special keys (e.g., backspace, delete, arrow keys)
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];

    // Check if the pressed key is in the list of allowed keys
    if (!allowedKeys.includes(event.key)) {
        event.preventDefault(); // Prevent the input of non-numeric characters
    }

}
const isAlphanumeric = (event) => {
    // Regular expression for alphanumeric characters
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    // Test if the input text matches the regular expression
    if (!alphanumericRegex.test(event.key)) {
        event.preventDefault(); // Prevent the input of special characters
    }
  }

export {
    onlyForNumbers,
    isAlphanumeric
}