import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import moment from "moment";
import BreadcrumbV3 from "../../components/Common/BreadcrumbV3";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Documents from "./Documnets";
import { Modal } from "../../components/Common/Modal";
import NewParking from "../Parking/NewParking";

const GET_TENANT_INFO = "mastersheet";
const PUT_UPDATE_TENANT_INFO = "mastersheet/";

const Tenant_info = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [tenentInfo, setTanentInfo] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
  });
  const [utility, setUtility] = useState({
    water: "",
    heat: "",
    electricity: "",
  });
  const [utilityCheck, setUtilityCheck] = useState({
    water: false,
    heat: false,
    electricity: false,
  });
  const [parkingInfo, setParkingInfo] = useState([]);
  const [parkingAmount, setParkingAmount] = useState("");
  const [storageInfo, setStorageInfo] = useState([]);
  const [petInfo, setPetInfo] = useState({
    noOfPets: "",
    amount: "",
    effectiveDate: "",
  });
  const [padData, setPadData] = useState({
    account: "",
    padChange: "",
    padCancel: "",
    padDelay: "",
    rentMemo: "",
  });
  const [documnets, setDocumnets] = useState([]);
  const [parkingModal, setParkingModal] = useState(false);

  const getTenantInfo = async (_id) => {
    try {
      const responce = await axios.get(`${GET_TENANT_INFO}/?id=${_id}`);
      if (responce && responce.data && responce.data.status === 200) {
        const result = responce.data.data;
        if (result.parkingInfo) {
          setParkingInfo(result.parkingInfo);
          setParkingAmount(result.parkingInfo.amount);
        }
        setTanentInfo({
          ...tenentInfo,
          firstname: result.firstname,
          lastname: result.lastname,
          email: result.email,
          mobile: result.mobile,
        });
        setUtility(result.utilityInfo);
        setPadData(result.pad);
        setDocumnets(result.documnets);
        setStorageInfo(result.storageInfo);
        setUtilityCheck(result.utilitiesCheck);
        if (Object.entries(result.petInfo).length > 0) {
          setPetInfo(result.petInfo);
        } else {
          setPetInfo({ ...petInfo, amount: result.petFee });
        }
      }
    } catch (error) {}
  };

  const updateTenantInfo = async (_id) => {
    try {
      const body = {
        userId: _id,
        utilityData: [
          {
            utilityType: "WATER",
            account: utility.water,
          },
          {
            utilityType: "HEAT",
            account: utility.heat,
          },
          {
            utilityType: "ELECTRICITY",
            account: utility.electricity,
          },
        ],
        parkingData: {
          _id: parkingInfo._id,
          amount: parkingAmount,
        },
        padData: padData,
        storageData: storageInfo,
        petData: petInfo,
        personalData: tenentInfo,
      };
      dispatch(isLoading(true));
      const responce = await axios.put(PUT_UPDATE_TENANT_INFO, body);
      if (responce.data.status === 201) {
        toast.success("Tenant information is updated successfully.");
        await getTenantInfo(id);
      }
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      toast.error("Something went wrong!");
      console.error(error);
    }
  };

  const handleOnchangeUtility = (event) => {
    const { name, value } = event.target;
    setUtility({ ...utility, [name]: value });
  };

  const handleOnChangePersonal = (event) => {
    const { name, value } = event.target;
    setTanentInfo({ ...tenentInfo, [name]: value });
  };

  useEffect(() => {
    if (id) {
      getTenantInfo(id);
    }
  }, [id]);

  return (
    <div className="page-content">
      <div className="tentent_infoPage">
        <MetaTags>
          <title>Rentdigicare | Tenant Information</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbV3
            title={<>Tenant Information</>}
            breadcrumbItems={[
              {
                item: "Home",
                link: "/dashboard",
              },
              {
                item: "Master Sheet",
                link: "/master_table",
              },
              {
                item: "Tenant Information",
                link: `/tenant_info/${id}`,
              },
            ]}
          />
          <div className="formData">
            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Form>
                      <div className="frm_InnerSec1">
                        <h4 className="cmnHding">Personal Details </h4>
                        <div className="innnerFrmData">
                          <Row>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="exampleEmail">First Name</Label>
                                <Input
                                  name="firstname"
                                  value={tenentInfo.firstname}
                                  placeholder="First Name"
                                  type="text"
                                  onChange={handleOnChangePersonal}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="exampleEmail">Last Name</Label>
                                <Input
                                  name="lastname"
                                  value={tenentInfo.lastname}
                                  placeholder="Last Name"
                                  type="text"
                                  onChange={handleOnChangePersonal}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6} className="mt-3">
                              <FormGroup>
                                <Label for="exampleEmail">Email</Label>
                                <Input
                                  name="email"
                                  value={tenentInfo.email}
                                  placeholder="Email"
                                  type="text"
                                  onChange={handleOnChangePersonal}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6} className="mt-3">
                              <FormGroup>
                                <Label for="exampleEmail">Mobile No.</Label>
                                <Input
                                  name="mobile"
                                  value={tenentInfo.mobile}
                                  placeholder="Mobile No."
                                  type="text"
                                  onChange={handleOnChangePersonal}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="frm_InnerSec1">
                        <h4 className="cmnHding">Utility Account </h4>
                        <div className="innnerFrmData">
                          <Row>
                            {/* {utilityCheck.water ? <Col md={6}> */}
                            {utilityCheck?.water != undefined && (
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="exampleEmail">
                                    Water Supply Account
                                  </Label>
                                  <Input
                                    name="water"
                                    value={utility.water}
                                    placeholder="Water Supply Account"
                                    type="text"
                                    onChange={handleOnchangeUtility}
                                  />
                                </FormGroup>
                              </Col>
                            )}
                            {utilityCheck?.heat != undefined ? (
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="exampleEmail">
                                    Heat Supply Account
                                  </Label>
                                  <Input
                                    name="heat"
                                    value={utility.heat}
                                    placeholder="Heat Supply Account"
                                    type="text"
                                    onChange={handleOnchangeUtility}
                                  />
                                </FormGroup>
                              </Col>
                            ) : (
                              ""
                            )}
                            {utilityCheck?.electricity != undefined ? (
                              <Col md={6} className="mt-3">
                                <FormGroup>
                                  <Label for="exampleEmail">
                                    Electricity Account
                                  </Label>
                                  <Input
                                    name="electricity"
                                    value={utility.electricity}
                                    placeholder="Electricity Account"
                                    type="text"
                                    onChange={handleOnchangeUtility}
                                  />
                                </FormGroup>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                          {!utilityCheck?.water &&
                            !utilityCheck?.heat &&
                            !utilityCheck?.electricity && (
                              <Card>
                                <CardBody className="text-center">
                                  Data Not Found
                                </CardBody>
                              </Card>
                            )}
                        </div>
                      </div>
                      <div className="frm_InnerSec1 mt-3">
                        <h4 className="cmnHding">Parking </h4>
                        <div className="innnerFrmData">
                          {parkingInfo.length > 0 ? (
                            parkingInfo.map((item, index) => (
                              <Card key={index}>
                                <CardBody>
                                  <Row>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Parking Type
                                        </Label>
                                        <Input
                                          id="exampleSelect"
                                          placeholder="Parking Type"
                                          name="floorType"
                                          value={item.floorType}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Floor No.
                                        </Label>
                                        <Input
                                          id="exampleSelect"
                                          placeholder="Floor No."
                                          name="floor"
                                          value={item.floor}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Parking No.
                                        </Label>
                                        <Input
                                          id="exampleSelect"
                                          placeholder="Parking No."
                                          name="parkingLotNo"
                                          value={item.parkingLotNo}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Vehicle No.
                                        </Label>
                                        <Input
                                          id="exampleSelect"
                                          placeholder="Vehicle No."
                                          name="vehicalNo"
                                          value={item.vehicalNo}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3} className="mt-3">
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Effective Date
                                        </Label>
                                        <Input
                                          name="effectiveDate"
                                          value={
                                            item.effectiveDate
                                              ? moment(
                                                  item.effectiveDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                          }
                                          placeholder="Effective Date"
                                          type="date"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={3} className="mt-3">
                                      <FormGroup>
                                        <Label for="exampleEmail">Amount</Label>
                                        <Input
                                          name="amount"
                                          placeholder="Amount"
                                          type="Number"
                                          value={item.amount}
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col
                                      md={3}
                                      className="mt-3 d-flex align-items-center justify-content-center"
                                    >
                                      <Link
                                        className={`btn btn-primary ${
                                          item._id ? "" : "isaDisable"
                                        } `}
                                        to={`/parking_info/${
                                          item._id ? item._id : ""
                                        }`}
                                      >
                                        Go to Parking
                                      </Link>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            ))
                          ) : (
                            <Card>
                              <CardBody className="text-center ">
                                Parking Details Not Found
                                <br />
                                <Link
                                  className={`btn btn-warning mt-3`}
                                  to={`/parking_info/`}
                                >
                                  Assign Parking
                                </Link>
                              </CardBody>
                            </Card>
                          )}
                        </div>
                      </div>
                      <div className="frm_InnerSec1 mt-3">
                        <h4 className="cmnHding">Storage</h4>
                        <div className="innnerFrmData">
                          {storageInfo.length > 0 ? (
                            storageInfo.map((item, index) => (
                              <Card key={index}>
                                <CardBody>
                                  <Row>
                                    <Col md={4}>
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Storage Type
                                        </Label>
                                        <Input
                                          id="exampleSelect"
                                          placeholder="Storage Type"
                                          name="floorType"
                                          value={item.floorType}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Floor No.
                                        </Label>
                                        <Input
                                          id="exampleSelect"
                                          placeholder="Floor No."
                                          name="floor"
                                          value={item.floor}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Locker No.
                                        </Label>
                                        <Input
                                          id="exampleSelect"
                                          placeholder="Locker No."
                                          name="lockerNo"
                                          value={item.lockerNo}
                                          type="text"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                      <FormGroup>
                                        <Label for="exampleEmail">
                                          Effective Date
                                        </Label>
                                        <Input
                                          name="effectiveDate"
                                          value={
                                            item.effectiveDate
                                              ? moment(
                                                  item.effectiveDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                          }
                                          placeholder="Effective Date"
                                          type="date"
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md={4} className="mt-3">
                                      <FormGroup>
                                        <Label for="exampleEmail">Amount</Label>
                                        <Input
                                          name="amount"
                                          placeholder="Amount"
                                          type="Number"
                                          value={item.amount}
                                          readOnly
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col
                                      md={4}
                                      className="mt-3 d-flex align-items-center justify-content-center"
                                    >
                                      <Link
                                        className={`btn btn-primary ${
                                          item._id ? "" : "isaDisable"
                                        } `}
                                        to={`/storage_info/${
                                          item._id ? item._id : ""
                                        }`}
                                      >
                                        Go to Storage
                                      </Link>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            ))
                          ) : (
                            <Card>
                              <CardBody className="text-center">
                                Storage Details Not Found
                                <br />
                                <Link
                                  className={`btn btn-warning mt-3`}
                                  to={`/storage_info/`}
                                >
                                  Assign Storage
                                </Link>
                              </CardBody>
                            </Card>
                          )}
                        </div>
                      </div>
                      <div className="frm_InnerSec1 mt-3">
                        <h4 className="cmnHding">Pet Fee </h4>
                        <div className="innnerFrmData">
                          <Row>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="exampleEmail">How many pets</Label>
                                <Input
                                  name="noOfPets"
                                  value={petInfo.noOfPets}
                                  placeholder="How many pets"
                                  onChange={(event) => {
                                    setPetInfo({
                                      ...petInfo,
                                      noOfPets: event.target.value,
                                    });
                                  }}
                                  type="number"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="exampleEmail">Amount</Label>
                                <Input
                                  name="amount"
                                  value={petInfo.amount}
                                  placeholder="Amount"
                                  onChange={(event) => {
                                    setPetInfo({
                                      ...petInfo,
                                      amount: event.target.value,
                                    });
                                  }}
                                  type="number"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4} className="">
                              <FormGroup>
                                <Label for="exampleEmail">Effective Date</Label>
                                <Input
                                  name="effectiveDate"
                                  value={
                                    petInfo.effectiveDate
                                      ? moment(petInfo.effectiveDate).format(
                                          "YYYY-MM-DD"
                                        )
                                      : ""
                                  }
                                  placeholder="Effective Date"
                                  onChange={(event) => {
                                    setPetInfo({
                                      ...petInfo,
                                      effectiveDate: event.target.value,
                                    });
                                  }}
                                  type="date"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <Documents userId={id} docs={documnets} />
                      <div className="updateForm">
                        <Button
                          color="primary"
                          className="update_btn mt-3 "
                          onClick={() => {
                            if (id) {
                              updateTenantInfo(id);
                            }
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tenant_info;
