import React from "react";
import Logo_p from "../../../../../assets/images/frmlogo.png";
import { Col, Form, Input, Label, Row } from "reactstrap";
import SignaturePad from "../../../../../components/Common/SignaturePad";
import { useDispatch, useSelector } from "react-redux";
import "../notifyformStyle.scss";
import { setUNDERGROUND_PARKING } from "../../../../../store/notifyForm/actions";

const UndergroundParkingForm = (props) => {
  const { mangerInfo, propertyInfo, readOnly } = props;
  const dispatch = useDispatch();
  const { UNDERGROUND_PARKING, error } = useSelector((state) => state.nofityForm);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    dispatch(setUNDERGROUND_PARKING({ ...UNDERGROUND_PARKING, [name]: value }));
  };

  const handleSignature = (sign) => {
    dispatch(
      setUNDERGROUND_PARKING({ ...UNDERGROUND_PARKING, Signature: sign })
    );
  };

  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <img src={propertyInfo && propertyInfo?.logoUrl ? 'data:image/jpeg;base64,' + propertyInfo.logoUrl : Logo_p} alt="" />{" "}
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="detailProperty text-start">
            <strong>{propertyInfo && propertyInfo?.title ? propertyInfo.title : ""}</strong>
            <br />
            <p>
              Email : {mangerInfo && mangerInfo?.email ? mangerInfo.email : ""} <br />
              Ph no. : {mangerInfo && mangerInfo?.mobile ? mangerInfo.mobile : ""}
            </p>
            <br />
            <strong>
              Underground Parking Resident Acknowledgment Form:
              <br />
              <br />
              Please read below and sign the form and deliver it back to the
              office. Once we receive this form, <br />
              we will deliver your key to your suite for the Underground storage
              lockers.
            </strong>{" "}
            <br /> <br />
            <p>
              - Storage locker must not be used for any debris, junk or garbage
              material. Only to be used as a locker purpose and items should be
              stored in boxes, totes or shelves nicely. No flammable items to be
              stored at any time. <br />
              - Can be used for bikes, toolbox, car tires or other similar
              items. <br />
              - Residents are welcome to cover their items with tarp nicely to
              avoid any dust or water during winter months. Do not place any
              items on top or in-front of the storage locker. <br />
              - Management will not be liable for any damages or in an event of
              theft of items in storage locker. Residents are solely liable for
              all items stored. <br />
              - Lock change replacement or key replacement fee will be applied
              in an event of lost key or lock ($75 per item). Resident must not
              use their own lock. <br />
              - Management has the right to enter the storage locker in an event
              of emergency or during an inspection if necessary, with written
              notice to resident. <br />
              - Underground parking spot change or if moving out, storage will
              be inspected for any damages. <br />
              - Management has the right to terminate UG parking space if in
              violation. <br />
            </p>
          </div>
          <div className="residentFrm cmnfrmstyle">
            <Form>
              <Row>
                {error && (
                  <Col
                    md={12}
                    className="d-flex align-items-center justify-content-center mb-3"
                  >
                    <Label style={{ color: "red" }}>Error : {error}</Label>
                  </Col>
                )}
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Resident Name:</Label>
                    <Input
                      placeholder="Resident Name"
                      value={UNDERGROUND_PARKING?.ResidentName}
                      name="ResidentName"
                      onChange={handleOnChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Address and Suite #:</Label>
                    <Input
                      placeholder="Address and Suite #"
                      value={UNDERGROUND_PARKING?.AddressSuite}
                      name="AddressSuite"
                      onChange={handleOnChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Underground Stall #: </Label>
                    <Input
                      placeholder="Underground Stall # "
                      value={UNDERGROUND_PARKING?.UndergroundStall}
                      name="UndergroundStall"
                      onChange={handleOnChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Phone #: </Label>
                    <Input
                      placeholder="Phone #  "
                      value={UNDERGROUND_PARKING?.Phone}
                      type="text"
                      maxLength={12}
                      name="Phone"
                      onChange={handleOnChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Signature: </Label>
                    <SignaturePad
                      readOnly={readOnly}
                      sign={UNDERGROUND_PARKING?.Signature}
                      sendSignature={(sign) => {
                        handleSignature(sign);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <div>
              <b>
                Thank You <br />
                {mangerInfo && mangerInfo?.firstname ? mangerInfo.firstname : ""}{" "}{mangerInfo && mangerInfo?.lastname ? mangerInfo.lastname : ""}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UndergroundParkingForm;
