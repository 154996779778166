import React from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import SignaturePad from "../../../components/Common/SignaturePad";
import { setFormData_1, setFormData_7 } from "../../../store/leaseForm/actions";
import FormHeaderLogo from "./FormHeaderLogo";
import FormFooter from "./FormFooter";

import "./frmStyle.scss";

const FormStep7 = ({ readOnly }) => {
  const { formData1, formData7, mangerInfo, error7 } = useSelector((state) => (state.leaseForm));
  const dispatch = useDispatch();

  const handleSignature = (sign, index) => {
    let _tenant = formData1.tenant.slice();
    _tenant[index].signature = sign;
    dispatch(setFormData_1({ ...formData1, tenant: _tenant }));
  }

  const addressFormat = (data) => {
    let addressString = "";
    if (data.buildingName) addressString += data.buildingName + ", ";
    if (data.unitNumber) addressString += data.unitNumber + ", ";
    if (data.address) addressString += data.address + ", ";
    if (data.city) addressString += data.city + ", ";
    if (data.postalCode) addressString += data.postalCode + ",";

    return addressString.slice(0, addressString.length - 1) + ".";

  }

  const handleOnChandge = (event) => {
    const { name, value } = event.target;
    dispatch(setFormData_7({ ...formData7, [name]: value }));

  }

  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <FormHeaderLogo />
            </div>
            {error7 && <div className="propertyName">
              <Label style={{ color: "red" }}>
                Error : {error7}
              </Label>
            </div>}
          </div>
          <div className="">
            <br />
            <br />
            <br />
            <p>
              <strong>WAIVER TERMS & ACCEPTANCE</strong><br />
              The waiver of any terms of this contract at any time shall not be deemed a waiver on any occasion or of any other term.
              Any violation of these conditions shall constitute a breach of the tenancy and the landlord shall thereafter be entitled to
              possession on a 24 hours’ notice. <br /> <br />

              The Tenant does hereby accept the lease of the Premises to be held as tenant, subject to the conditions, restrictions and
              covenants contained within this Agreement or attached hereto. <br /> <br />

              IF THERE IS MORE THAN ONE TENANT, THEIR LIABILITY SHALL BE JOINT IN SEVERAL.  THE DATE OF THIS
              AGREEMENT SHALL BE THE DATE THAT THE LAST OF THE PARTIES HAVE SIGNED. TENANTS:


              <br />
              <br />
            </p>
            <div className=" tenantFrm cmnfrmstyle midContent">
              <b>TENANTS:</b>
              <br />
              <Form>
                <Row className="mb-2">
                  <Col md={4}>
                    <div className="frmInner">
                      <Label>Name Initials </Label>
                      <Input readOnly={readOnly} placeholder="Name Initials" name="nameIntitals" value={formData7.nameIntitals} onChange={(event) => {
                        if (event.target.value.length <= 5) {
                          handleOnChandge(event)
                        }
                      }} />
                    </div>
                  </Col>
                </Row>
                {formData1.tenant.length > 0 && formData1.tenant.map((item, index) => (
                  <Row key={index} className="mb-2">
                    <Col md={4}>
                      <div className="frmInner">
                        <Label>Name</Label>
                        <Input placeholder="Name" value={item.name} readOnly />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="frmInner">
                        <Label>Signature</Label>
                        <SignaturePad readOnly={readOnly} sign={item.signature} sendSignature={(sign) => { handleSignature(sign, index) }} />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="frmInner">
                        <Label>Date</Label>
                        <Input placeholder="Date" type="date" name="date" value={item.date ? moment(item.date).format("YYYY-MM-DD") : ""} readOnly />
                      </div>
                    </Col>
                  </Row>
                ))}
              </Form>
            </div> <br />
            <div className=" tenantFrm cmnfrmstyle midContent">
              {/* <b>LANDLORD:</b> */}
              <b>MANAGER:</b>
              <br />
              <Form>
                <Row className="mb-2">
                  <Col md={4}>
                    <div className="frmInner">
                      <Label>Name</Label>
                      {/* <Input readOnly placeholder="Name" name="name" value={propertyInfo.owner} /> */}
                      <Input readOnly placeholder="Name" name="name" value={mangerInfo.name} />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="frmInner">
                      <Label>Signature</Label>
                      {mangerInfo.signature ? <SignaturePad readOnly={true} sign={mangerInfo.signature} /> : "Signature not provided"}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="frmInner">
                      <Label>Date</Label>
                      <Input readOnly placeholder="Date" type="date" name="date" value={formData7.managerSignDate && moment(formData7.managerSignDate).format("YYYY-MM-DD")} />
                    </div>
                  </Col>
                </Row>
              </Form>
            </div> <br />
            <div className=" tenantFrm cmnfrmstyle midContent">
              <b>Building Address and Contact:</b>
              <br />
              <Form>
                <Row className="mb-2">
                  <Col md={6}>
                    <FormGroup>
                      <Label>Address</Label>
                      <Input readOnly placeholder="Address" type="textarea" value={addressFormat(formData1.buildingAddress)} />
                    </FormGroup>
                  </Col>
                  <Col md={6} >
                    <Label>Contact</Label>
                    <Input readOnly placeholder="Contact" value={`${mangerInfo.email}, ${mangerInfo.mobile}`} />
                  </Col>
                </Row>
              </Form>
            </div>
            <br /> <br /> <br /> <br /> <br /> <br />
            <FormFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStep7;
