import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import jwt_decode from "jwt-decode";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";

import axios from "../api/axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";

const GET_ACCOUNTED_LIST = "/accountand/list";
const POST_ADD_ACCOUNTEND = "/accountand/add";
const PUT_UPDATE_ACCOUNTED = `/accountand/update`;
const PUT_DELETE_ACCOUNTENT = `/accountand/delete`;

const Accountant = () => {
  const dispatch = useDispatch();
  const decode = jwt_decode(window.localStorage.getItem("accessToken"));
  const { userData } = JSON.parse(window.localStorage.getItem("authUser"));

  // form states
  const [managerFirstName, setManagerFirstName] = useState("");
  const [managerLastName, setManagerLastName] = useState("");
  const [managerPhone, setManagerPhone] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerEmailReadOnly, setManagerEmailReadOnly] = useState(false);
  const [managerStatus, setManagerStatus] = useState(true);

  const [check_first, setcheck_first] = useState(true);
  const [check_last, setcheck_last] = useState(true);
  const [check_name, setcheck_name] = useState(true);
  const [check_email, setcheck_email] = useState(true);
  const [check_mobile, setcheck_mobile] = useState(true);
  const [check_status, setcheck_status] = useState(true);
  const [check_time, setcheck_time] = useState(true);
  const [check_privilege, setcheck_privilege] = useState(true);
  const [msg_error, setmsg_error] = useState("");
  const [msg_error1, setmsg_error1] = useState("");
  const [msg_error4, setmsg_error4] = useState("");
  const [msg_error5, setmsg_error5] = useState("");
  const [msg_error6, setmsg_error6] = useState("");
  const [filterData, setFilterData] = useState(null);
  const [editData, setEditData] = useState(false);
  const [editManagerId, setEditManagerId] = useState("");
  const [accountedList, setAccountedList] = useState([]);
  const [modal_large, setmodal_large] = useState(false);

  function tog_large() {
    setmodal_large(!modal_large);
  }


  const getAccountedList = async () => {
    try {
      const response = await axios.get(`${GET_ACCOUNTED_LIST}/${userData.id}`);

      if (response && response.data && response.data.status == 200) {
        setAccountedList(response.data.data);
      }
    } catch (error) {
      console.log(error); // TODO proper error
    }
  };

  // TODO add validation before axios call
  async function addPropertyManager(event) {
    event.preventDefault();

    if (managerFirstName === "") {
      setcheck_first(false);
      setmsg_error("First Name is required!");
    } else {
      setcheck_first(true);
    }

    if (managerFirstName) {
      if (!managerFirstName.match(/^[A-Za-z]+$/)) {
        setcheck_first(false);
        setmsg_error("Only letters are allowed!");
      } else {
        setcheck_first(true);
      }
    }

    if (managerLastName === "") {
      setcheck_last(false);
      setmsg_error1("Last Name is required!");
    } else {
      setcheck_last(true);
    }

    if (managerLastName) {
      if (!managerLastName.match(/^[A-Za-z]+$/)) {
        setcheck_last(false);
        setmsg_error1("Only letters are allowed!");
      } else {
        setcheck_last(true);
      }
    }

    if (!managerEmail) {
      setcheck_email(false);
      setmsg_error4("Email is required!");
    } else {
      setcheck_email(true);
    }

    if (managerEmail) {
      if (!managerEmail.match(/\S+@\S+\.\S+/)) {
        setcheck_email(false);
        setmsg_error4("Please enter valid email!");
      } else {
        setcheck_email(true);
      }
    }

    if (!managerPhone) {
      setcheck_mobile(false);
      setmsg_error5("Mobile number is required!");
    } else {
      setcheck_mobile(true);
    }
    if (managerPhone) {
      if (managerPhone.length !== 10) {
        setcheck_mobile(false);
        setmsg_error5("Please enter valid mobile number!");
      } else {
        setcheck_mobile(true);
      }
    }

    const saveSpinner = document.getElementById("saveSpinner");
    const failedSaveErrorIcon = document.getElementById("failedSaveErrorIcon");
    const managerSaveBtn = document.getElementById("managerSaveBtn");
    const errorSpan = document.getElementById("errorSpan");

    managerSaveBtn.disabled = true;
    saveSpinner.style.display = "inline-block";
    failedSaveErrorIcon.style.display = "none";
    errorSpan.style.display = "none";

    if (!editData) {
      if (
        managerFirstName &&
        check_first &&
        managerLastName &&
        check_last &&
        managerEmail &&
        check_email &&
        managerPhone &&
        check_mobile &&
        managerStatus &&
        check_status
      ) {
        try {

          const response = await axios.post(POST_ADD_ACCOUNTEND, {
            firstName: managerFirstName,
            lastName: managerLastName,
            email: managerEmail,
            mobile: managerPhone,
            isActive: managerStatus,
            companyID: userData.id,
          });

          if (response && response.data && response.data.status === 201) {
            toast.success("Accountand added successfully !");
            await getAccountedList();
            handleCloseModal();
          }

        } catch (error) {
          saveSpinner.style.display = "none";
          managerSaveBtn.disabled = false;
          failedSaveErrorIcon.display = "inline-block";
        }
      }
    } else {

      try {

        const response = await axios.put(`${PUT_UPDATE_ACCOUNTED}`, {
          firstName: managerFirstName,
          lastName: managerLastName,
          email: managerEmail,
          mobile: managerPhone,
          isActive: managerStatus,
          companyID: userData.id,
        });

        if (response && response.data && response.data.status === 201) {
          toast.success("Accountant update successfully !");
          await getAccountedList();
          handleCloseModal();
        }
      } catch (error) {
        saveSpinner.style.display = "none";
        managerSaveBtn.disabled = false;
        failedSaveErrorIcon.display = "inline-block";
      }
    }

    saveSpinner.style.display = "none";
    managerSaveBtn.disabled = false;
    errorSpan.style.display = "inline-block";
  }

  const handleDeleteAccountend = async (id) => {
    try {

      if (window.confirm("Are you sure you want to delete accountant ?")) {
        dispatch(isLoading(true));
        const responce = await axios.put(`${PUT_DELETE_ACCOUNTENT}/${id}`);
        if (responce && responce.data && responce.data.status == 201) {
          await getAccountedList();
          toast.success("Accountand deleted successfully.")
        }
        dispatch(isLoading(false));
      }

    } catch (error) {
      toast.error("Somting went wrong !")
      dispatch(isLoading(false));
    }
  }


  const handleCloseModal = () => {
    tog_large();
    setcheck_first(true);
    setcheck_last(true);
    setcheck_name(true);
    setcheck_email(true);
    setcheck_mobile(true);
    setcheck_status(true);
    setcheck_time(true);
    setcheck_privilege(true);
    setEditData(false);
    setManagerFirstName("");
    setManagerLastName("");
    setManagerStatus(true);
    setManagerEmail("");
    setManagerPhone("");
    setEditManagerId("");
    setManagerEmailReadOnly(false);
  };

  useEffect(() => {
    getAccountedList();
  }, []);

  if (decode.role !== "manager") {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Rentdigicare | Acountant</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Acountant" />
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    <div className="row align-items-center">
                      <div className="col-md-12 d-flex flex-wrap align-items-end justify-content-end">
                        <div className="mb">
                          {(userData.role === "company") ? (
                            <button
                              type="button"
                              onClick={() => {
                                tog_large();
                              }}
                              className="btn btn-light "
                              data-toggle="modal"
                              data-target=".bs-example-modal-lg"
                            >
                              <i className="bx bx-plus me-1"></i>Add Accountant
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table-striped table-bordered mb-0">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Accountant</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Created At</th>
                            <th scope="col">Status</th>
                            {userData.role === "company" && (
                              <th scope="col">Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {accountedList.length > 0 && accountedList.map((doc, index) => (<tr>
                            <td>{index + 1}</td>
                            <td>{doc?.firstName + " " + doc?.lastName}</td>
                            <td>{doc?.email}</td>
                            <td>{doc?.mobile}</td>
                            <td>{moment(doc?.createdAt).format("YYYY-MM-DD")}</td>
                            <td>{doc?.isActive ? "Active" : "In Active"}</td>
                            {userData.role === "company" && (<td >
                              <Button color="none" onClick={() => {
                                handleDeleteAccountend(doc._id);
                              }}>
                                <FeatherIcon
                                  icon="trash-2"
                                  className="text-danger"
                                />
                              </Button>
                              <Button color="none" onClick={() => {
                                setEditData(true);
                                setManagerFirstName(doc.firstName);
                                setManagerLastName(doc.lastName);
                                setManagerEmail(doc.email);
                                setManagerEmailReadOnly(true);
                                setManagerPhone(doc.mobile);
                                setManagerStatus(doc.isActive);
                                tog_large();
                              }}>
                                <FeatherIcon
                                  icon="edit"
                                  className="text-info"
                                />
                              </Button>
                            </td>)}
                          </tr>))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        {/* Edit Update modal for the Accountants */}
        {modal_large && (<Modal
          size="lg"
          isOpen={modal_large}
          toggle={() => {
            handleCloseModal();
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {editData ? "Edit " : "Add"} Accountant
            </h5>
            <button
              onClick={() => {
                handleCloseModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={addPropertyManager}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="formrow-facility-input"
                    >
                      First Name *
                    </Label>
                    <Input
                      placeholder="Enter first name"
                      value={managerFirstName}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (!value) {
                          setcheck_first(false);
                          setmsg_error(
                            "First Name is required!"
                          );
                        } else {
                          setcheck_first(true);
                        }

                        const test =
                          value.match(/^[A-Za-z]+$/);
                        if (!test) {
                          setcheck_first(false);
                          setmsg_error(
                            "Only letters are allowed!"
                          );
                        } else {
                          setcheck_first(true);
                        }
                      }}
                      onChange={(e) => {
                        setManagerFirstName(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="formrow-phone-input"
                    />
                    {check_first ? (
                      ""
                    ) : (
                      <Label style={{ color: "red" }}>
                        {msg_error}
                      </Label>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="formrow-facility-input"
                    >
                      Last Name *
                    </Label>
                    <Input
                      placeholder="Enter last name"
                      value={managerLastName}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (!value) {
                          setcheck_last(false);
                          setmsg_error1(
                            "Last Name is required!"
                          );
                        } else {
                          setcheck_last(true);
                        }
                        const test =
                          value.match(/^[A-Za-z]+$/);
                        if (!test) {
                          setcheck_last(false);
                          setmsg_error1(
                            "Only letters are allowed!"
                          );
                        } else {
                          setcheck_last(true);
                        }
                      }}
                      onChange={(e) => {
                        setManagerLastName(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="formrow-phone-input"
                    />
                    {check_last ? (
                      ""
                    ) : (
                      <Label style={{ color: "red" }}>
                        {msg_error1}
                      </Label>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="formrow-email-input"
                    >
                      Email *
                    </Label>
                    <Input
                      readOnly={managerEmailReadOnly}
                      placeholder="Enter email address"
                      value={managerEmail}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (!value) {
                          setcheck_email(false);
                          setmsg_error4("Email is required!");
                        } else {
                          setcheck_email(true);
                        }
                        const test =
                          value.match(/\S+@\S+\.\S+/);
                        if (!test) {
                          setcheck_email(false);
                          setmsg_error4(
                            "Please enter valid email!"
                          );
                        } else {
                          setcheck_email(true);
                        }
                      }}
                      onChange={(e) => {
                        setManagerEmail(e.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="formrow-email-input"
                    />
                    {check_email ? (
                      ""
                    ) : (
                      <Label style={{ color: "red" }}>
                        {msg_error4}
                      </Label>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="formrow-phone-input"
                    >
                      Mobile *
                    </Label>
                    <Input
                      placeholder="Enter mobile number"
                      value={managerPhone}
                      onBlur={(e) => {
                        const value = e.target.value;
                        if (!value) {
                          setcheck_mobile(false);
                          setmsg_error5(
                            "Mobile number is required!"
                          );
                        } else {
                          setcheck_mobile(true);
                        }
                        const test =
                          value.match(/^[0-9]{10}$/);
                        if (!test) {
                          setcheck_mobile(false);
                          setmsg_error5(
                            "Please enter valid mobile number!"
                          );
                        } else {
                          setcheck_mobile(true);
                        }
                      }}
                      onChange={(e) => {
                        setManagerPhone(e.target.value);
                      }}
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="form-control"
                      id="formrow-phone-input"
                    />
                    {check_mobile ? (
                      ""
                    ) : (
                      <Label style={{ color: "red" }}>
                        {msg_error5}
                      </Label>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="form-Label">
                      Status *
                    </Label>
                    <select
                      value={managerStatus}
                      onChange={(e) => {
                        if (e.target.value == "true") {
                          setManagerStatus(true);
                        } else {
                          setManagerStatus(false);
                        }
                      }}
                      className="form-select"
                    >
                      <option value={true}>Active</option>
                      <option value={false}>In-active</option>
                    </select>
                    {check_status ? (
                      ""
                    ) : (
                      <Label style={{ color: "red" }}>
                        {msg_error6}
                      </Label>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="text-end">
                    <span
                      id="errorSpan"
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        marginRight: "10px",
                        display: "none",
                      }}
                    ></span>
                    <button
                      id="managerSaveBtn"
                      disabled={[
                        check_email,
                        check_first,
                        check_last,
                        check_mobile,
                        check_name,
                        check_status,
                        check_time,
                        check_privilege,
                      ].includes(false)}
                      type="submit"
                      className="btn btn-success save-user"
                    >
                      Save
                      <div
                        id="saveSpinner"
                        style={{
                          display: "none",
                          height: "15px",
                          width: "15px",
                          marginLeft: "5px",
                        }}
                        className="spinner-border"
                        role="status"
                      ></div>
                      <i
                        id="failedSaveErrorIcon"
                        style={{
                          display: "none",
                          marginLeft: "5px",
                        }}
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal>)}
      </React.Fragment>
    );
  } else {
    window.location.replace("/Property");
  }
};

export default Accountant;
