import React, { useEffect, useState } from "react"
import { Stepper } from 'react-form-stepper';
import FeatherIcon from 'feather-icons-react';
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MetaTags } from "react-meta-tags";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Row, Modal, ModalBody } from "reactstrap";

import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormStep5 from "./FormStep5";
import FormStep6 from "./FormStep6";
import FormStep7 from "./FormStep7";
import axios from "../../api/axios";
import { getApplicationInfo, setError, setError2, setError7, setReset } from "../../../store/leaseForm/actions";
import Preview from "./Preview";
import BreadcrumbV3 from "../../../components/Common/BreadcrumbV3";
import { isLoading } from "../../../store/actions";

const connectorStyleConfig = {
    disabledColor: "#bdbdbd",
    activeColor: "#1976d2",
    completedColor: "#1976d2",
    size: 1,
    stepSize: "2em",
    style: "solid"
}

const StepStyleDTO = {
    activeBgColor: "#1976d2",
    activeTextColor: "#ffffff",
    completedBgColor: "#1976d2",
    completedTextColor: "#ffffff",
    inactiveBgColor: "#6B7A90",
    inactiveTextColor: "#ffffff",
}

const GET_LEASE_FORM_INFO = "lease-from/";
const POST_LEASE_FORM_INFO = "lease-from/";
const GET_LEASE_FORM_MANAGER = "lease-from/manager-download-lease/";

const LeaseForm = () => {

    const STEPS = [
        {
            id: 1,
            label: "Step 1",
            active: true,
            completed: false,
            onClick: () => { setStepCount(0) }
        },
        {
            id: 2,
            label: 'Step 2',
            active: false,
            completed: false,
            onClick: () => { setStepCount(1) }

        },
        {
            id: 3,
            label: 'Step 3',
            active: false,
            completed: false,
            onClick: () => { setStepCount(2) }

        },
        {
            id: 4,
            label: 'Step 4',
            active: false,
            completed: false,
            onClick: () => { setStepCount(3) }

        },
        {
            id: 5,
            label: 'Step 5',
            active: false,
            completed: false,
            onClick: () => { setStepCount(4) }

        },
        {
            id: 6,
            label: 'Step 6',
            active: false,
            completed: false,
            onClick: () => { setStepCount(5) }

        },
        {
            id: 7,
            label: 'Step 7',
            active: false,
            completed: false,
            onClick: () => { setStepCount(6) }
        }
    ];
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const { propertyInfo, mangerInfo, formData1, formData2, formData3, formData7 } = useSelector((state) => (state.leaseForm));

    const FORM_OBJECTS = [<FormStep1 {...propertyInfo} />, <FormStep2 />, <FormStep3 readOnly={false} />, <FormStep4 />, <FormStep5 />, <FormStep6 />, <FormStep7 readOnly={true} />];

    const [steps, setSteps] = useState(STEPS);
    const [stepCount, setStepCount] = useState(0);
    const [isPreview, setIsPreview] = useState(false);
    const [costumerEmail, setCostumerEmail] = useState("");
    const [token, setToken] = useState("");
    const [modal, setModal] = useState(false);

    const getLeaseFormInfo = async (_id) => {
        try {

            const result = await axios.get(`${GET_LEASE_FORM_INFO}/?id=${_id}`);
            if (result && result.data && result.data.status == 200); {
                const _result = result.data.data;
                const { costumerEmail, propertyInfo, mangerInfo, formData2, formData3 } = _result;
                const _formData1 = _result.formData1;
                const _tenant = _formData1.tenant.map((item) => {
                    return {
                        name: item.name,
                        signature: "",
                        date: ""
                    }
                });
                delete _formData1.tenant;
                const __formData1 = {
                    tenant: _tenant,
                    ..._result.formData1
                }


                const _formData7 = {
                    nameIntitals: "",
                    managerSignDate: Date.now(),
                }

                let _data = {
                    propertyInfo, mangerInfo,
                    formData1: {
                        ...formData1,
                        ...__formData1
                    },
                    formData2,
                    formData3,
                    formData7: {
                        ...formData7,
                        ..._formData7
                    }
                }

                setCostumerEmail(costumerEmail);
                dispatch(getApplicationInfo(_data));
            }

        } catch (error) {

        }
    }

    const isFormOneValid = () => {
        const { tenant, buildingAddress, noticeStartDate, noticeEndDate, rent } = formData1;
        // Tenant validation
        if (tenant.length > 0) {
            tenant.map((item) => {
                if (!item.name) {
                    dispatch(setError("Full name field is required."));
                    return false
                }
            })
        }

        for (const [key, value] of Object.entries(buildingAddress)) {
            if (key === "buildingName" && !value) {
                dispatch(setError("Building Name field is required."));
                return false
            }

            if (key === "unitNumber" && !value) {
                dispatch(setError("Unit Number field is required."));
                return false
            }

            if (key === "address" && !value) {
                dispatch(setError("Address field is required."));
                return false
            }

            if (key === "city" && !value) {
                dispatch(setError("City field is required."));
                return false
            }

            if (key === "postalCode" && !value) {
                dispatch(setError("Postal Code field is required."));
                return false
            }
        }

        if (!noticeStartDate) {
            dispatch(setError("Notice Start date field is required."));
            return false
        }

        if (!noticeEndDate) {
            dispatch(setError("Notice End date field is required."));
            return false
        }

        for (const [key, value] of Object.entries(rent)) {
            if (key === "commencementDate" && !value) {
                dispatch(setError("Commencement Date field is required."));
                return false
            }

            if (key === "basicRent" && !value) {
                dispatch(setError("Basic Rent field is required."));
                return false
            }

            if (rent.petFeeCheck == "Yes" && key === "petFee" && !value) {
                dispatch(setError("Pet Fee field is required."));
                return false
            }

            if (rent.parkingFeeCheck == "Yes" && key === "parkingFee" && !value) {
                dispatch(setError("Parking Fee field is required."));
                return false
            }

            if (rent.stallFeeCheck == "Yes" && key === "stallFee" && !value) {
                dispatch(setError("Surface Stall Fee field is required."));
                return false
            }

            if (rent.storageFeeCheck == "Yes" && key === "storageFee" && !value) {
                dispatch(setError("Storage Fee field is required."));
                return false
            }

            if (key === "incentives" && !value) {
                dispatch(setError("Incentives field is required."));
                return false
            }
        }

        dispatch(setError(""))
        return true;
    }

    const isFormTwoValid = () => {
        const { rent } = formData1;
        const { securityDeposit, petSecurityFee } = formData2;
        if (!securityDeposit) {
            dispatch(setError2("Security Deposit field is required."));
            return false
        }

        if (rent.petFeeCheck == "Yes" && !petSecurityFee) {
            dispatch(setError2("Pet Security Fee field is required."));
            return false
        }
        dispatch(setError2(""))
        return true;
    }

    const isFormSevenValid = () => {
        const { signature } = mangerInfo;
        if (!signature) {
            dispatch(setError7("Please Provide Manager Signature."));
            return false
        }

        dispatch(setError7(""))
        return true;
    }

    const handleNext = () => {
        let _stepCount = stepCount;
        if (_stepCount == 0 && !isFormOneValid()) {
            return;
        }

        if (_stepCount == 1 && !isFormTwoValid()) {
            return;
        }

        const _steps = steps.slice();
        _steps[_stepCount] = {
            ..._steps[_stepCount],
            active: false,
            completed: true,
            children: <FeatherIcon icon="check" />
        }
        _steps[_stepCount + 1] = {
            ..._steps[_stepCount + 1],
            active: true,
        }

        setSteps(_steps);
        setStepCount(_stepCount + 1);

    }

    const handleBack = () => {
        let _stepCount = stepCount;
        const _steps = steps.slice();
        _steps[_stepCount] = {
            ..._steps[_stepCount],
            active: false,
            completed: false,
            children: ""
        }
        _steps[_stepCount - 1] = {
            ..._steps[_stepCount - 1],
            active: true,
            completed: false,
            children: ""
        }
        setSteps(_steps);
        setStepCount(_stepCount - 1);
    }

    const handlePreview = () => {
        if (stepCount == 6 && !isFormSevenValid()) {
            return;
        }
        setIsPreview(true);

    }

    const handleFinish = async () => {
        try {
            if (window.confirm("Are you sure you want to send lease form ?")) {
                dispatch(isLoading(true))

                const bodyData = {
                    applicationId: id,
                    propertyId: propertyInfo._id,
                    managerId: mangerInfo._id,
                    tenant: formData1.tenant,
                    agreementRange: {
                        startDate: formData1.noticeStartDate,
                        endDate: formData1.noticeEndDate,
                    },
                    buildingAddress: formData1.buildingAddress,
                    rent: formData1.rent,
                    securityDeposit: formData2.securityDeposit,
                    petSecurityFee: formData2.petSecurityFee,
                    familyPersons: formData3.familyPersons,
                    costumerEmail: costumerEmail,
                    utilities: formData3.utilities,
                    appliances: formData3.appliances,
                    nameIntitals: formData7.nameIntitals,
                    managerSignDate: formData7.managerSignDate
                }

                const res = await axios.post(POST_LEASE_FORM_INFO, bodyData);
                if (res && res.data && res.data.status === 201) {
                    const token = res.data.data.token;
                    setToken(token);
                    dispatch(isLoading(false));
                    dispatch(setReset());
                    await getLeaseFormInfo(id);
                    setModal(true)
                }
            }

        } catch (error) {
            dispatch(isLoading(false))
            toast.error("Something went wrong !");
        }
    }


    const handleDownloadClick = async () => {
        dispatch(isLoading(true))

        const { data } = await axios.get(`${GET_LEASE_FORM_MANAGER}${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'arraybuffer'
        });
        const blob = new Blob([data], { type: 'application/pdf' });
        const a = document.createElement('a');
        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;

        // Set the filename
        a.download = id + '_LeaseAgreement.pdf';

        // Trigger a click event to initiate the download
        a.click();

        // Clean up the URL object
        window.URL.revokeObjectURL(a.href);
        dispatch(isLoading(false));
    };

    useEffect(() => {
        if (id) {
            getLeaseFormInfo(id);
        }
    }, [])

    return (
      <>
        <Card>
          <MetaTags>
            <title>Rentdigicare | Lease Form</title>
          </MetaTags>
          <BreadcrumbV3
            title={""}
            breadcrumbItems={[
              {
                item: "Home",
                link: "/dashboard",
              },
              {
                item: "Resident Application",
                link: "/resident_application",
              },
              {
                item: "Lease Form",
                link: `/lease-form/${id}`,
              },
            ]}
          />
          <div id="divToPrint">
            <CardHeader>
              {isPreview ? (
                ""
              ) : (
                <Stepper
                  steps={steps}
                  activeStep={stepCount}
                  connectorStateColors={true}
                  connectorStyleConfig={connectorStyleConfig}
                  styleConfig={StepStyleDTO}
                />
              )}
            </CardHeader>
            <CardBody>
              {isPreview ? <Preview /> : FORM_OBJECTS[stepCount]}
            </CardBody>
          </div>
          <CardFooter>
            <Row>
              <Col md={6} className="text-start">
                <Button
                  disabled={stepCount <= 0 ? true : false}
                  color="primary"
                  onClick={() => {
                    isPreview ? setIsPreview(false) : handleBack();
                  }}
                >
                  Back
                </Button>
              </Col>
              <Col md={6} className="text-end">
                {isPreview ? (
                  <>
                    {" "}
                    <Button color="primary" onClick={handleFinish}>
                      Finish
                    </Button>
                  </>
                ) : stepCount == 6 ? (
                  <Button
                    color="primary"
                    onClick={() => {
                      handlePreview();
                    }}
                  >
                    Preview
                  </Button>
                ) : (
                  <Button
                    disabled={stepCount >= STEPS.length - 1 ? true : false}
                    color="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                )}
              </Col>
            </Row>
          </CardFooter>
        </Card>

        {modal && (
          <Modal backdrop={"static"} centered isOpen={modal}>
            <ModalBody>
              <Card>
                <CardBody className="text-center">
                  <FeatherIcon icon="check-circle" size={100} color="green" />
                  <h4>Lease form send successfully to the applicant.</h4>
                </CardBody>
                <CardFooter className="text-center">
                  <Button
                    color="info"
                    onClick={() => {
                      const win = window.open(
                        `/lease-document/${token}`,
                        "_blank"
                      );
                      win.focus();
                    }}
                  >
                    Checkout the document
                  </Button>
                  <Button
                    className="mx-3"
                    color="primary"
                    onClick={() => {
                      handleDownloadClick();
                    }}
                  >
                    Download
                  </Button>
                  <Button
                    color="success"
                    onClick={() => {
                      history.push("/resident_application");
                    }}
                  >
                    Done
                  </Button>
                </CardFooter>
              </Card>
            </ModalBody>
          </Modal>
        )}
      </>
    );
}

export default LeaseForm;
