import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from "../api/axios";
import moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  FormGroup,
  Collapse,
  Label,
  Form,
  // FormControl,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  ModalFooter,
  ModalBody,
  Button,
  ModalHeader,
} from "reactstrap";

import jwt_decode from "jwt-decode";
import FeatherIcon from "feather-icons-react";
import { toast } from "react-toastify";
import "../../assets/css/rentdigicare.css";


const OrderList = (props) => {

  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [CompanyName, setCompanyName] = useState([]);
  const [SelectedCompanyId, setSelectedCompanyId] = useState("");
  const [SelectedPropertyId, setSelectedPropertyId] = useState("");
  const [orderLists,setOrderLists] = useState([]);
  const [vendorList,setVendorList] = useState([]);
  const [selectedVendorId,setSelectedVendorId] = useState("");
  const [updateId,setUpdateId] = useState("");
  const [approvedStatus,setApprovedStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const decode2 = jwt_decode(window.localStorage.getItem("accessToken"));
    const FILTER_URL = `/property/filter?role=${decode2.role}&domain=${
        decode2.role == "manager" ? decode2.id : decode2.domain
      }`;

     
    const COMPANY_NAME_URL = `/company/filter/company_list`

    const companyName = async () => {
      const response = await axios.get(COMPANY_NAME_URL);
      const temp = (response.data.companies);
      console.log(temp);
      setCompanyName(temp)
  }

  const propertiesList = async (companyId) => {
      const params = {
        companyID: companyId
      }
      const response = await axios.post('/property/getAllCompanyProperties', params);
      const temp = (response.data.properties);
      if(temp) {
          setProperties(temp);
      }
     
  }

  const getVendorsList = async () => {
    try {
      const response = await axios.get('/inventoryvendors/');
      console.log(response.data);
      if (response.status == 200 && response.data.length) {
        setVendorList(response.data);
      }
    } catch (err) {
      console.log(err)
    }
  }


  const handleGetInventoryOrders = async (propertyId) => {
    setSelectedPropertyId(propertyId);
    const params = {
      companyID: SelectedCompanyId,
      propertyID: propertyId
    };
    const response = await axios.post('/orderinventory/orderlist', params);
    if(response.status == 200 && response.data.length) {
      response.data.forEach(order => {
        const newOrderItems = order.orderItems.reduce((categories, categoryObject) => {
          console.log(categoryObject.items);
          const orderCategory = {
            category: categoryObject.categoryId.category.name,
            items: categoryObject.items.map(itemObj => {
              const foundItem = categoryObject.categoryId.category.items.find(item => item._id === itemObj.itemId);
              return {
                ...foundItem,
                itemQuantity: itemObj.itemQuantity
              };
            })
          };
          return [...categories, orderCategory];
        }, []);
        order.newOrderItems = newOrderItems;
        console.log(order);
      });

      setOrderLists(response.data);
    }
  };


  const handleUpdateData = async () => {

    const updateData = {
      approved : approvedStatus,
      vendorId : selectedVendorId,
      approvedBy : decode2.id
    }

    const response = await axios.post(`/orderinventory/update/${updateId}`, updateData);
    if(response.status == 200) {
      handleGetInventoryOrders(SelectedPropertyId);
      setShowModal(false);
      toast.success("Order Updated Successfully");
    }
  };


  const handleSorting = (e) => {
    let value = [1, -1];
    let newData = {};
    if (e.target.value == 1) {
      newData["title"] = value[0];
    } else if (e.target.value == 2) {
      newData["title"] = value[1];
    } else if (e.target.value == 3) {
      newData["createdAt"] = value[1];
    } else if (e.target.value == 4) {
      newData["createdAt"] = value[0];
    } else if (e.target.value == 5) {
      newData["modifiedAt"] = value[1];
    } else if (e.target.value == 6) {
      newData["modifiedAt"] = value[0];
    } else {
      newData[" "] = " ";
    }

   // getProperties(newData);
  };

  useEffect(() => {
    companyName();
    getVendorsList();
  }, []);
  
  return (
    <React.Fragment>

      <div className="page-content">
        <MetaTags>
          <title> Rentdigicare | Order List</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Inventory" breadcrumbItem="Order List" />

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>

                  <Form>
                    <FormGroup row>
                      <Col md="3">
                        <Label htmlFor="property">Select Company</Label>
                        <Input type="select" name="SelectedCompany" id="SelectedCompany" onChange={(e) => {
                          setSelectedCompanyId(e.target.value);
                          propertiesList(e.target.value);
                        }} value={SelectedCompanyId}>
                          <option value="">Select Company</option>
                          {CompanyName.map(company => (

                            <option key={company._id} value={company._id}>{company.name}</option>
                          ))}
                        </Input>

                      </Col>
                      <Col md="3">
                        <Label htmlFor="suit">Select Property</Label>
                        <Input type="select" name="property" id="property" onChange={(e) => {handleGetInventoryOrders(e.target.value)}}
                        value={SelectedPropertyId}>
                          <option value="">Select Property</option>
                          {properties.map(property => (

                            <option key={property._id} value={property._id}>{property.title}</option>
                          ))}
                        </Input>
                      </Col>
                    </FormGroup>
                  </Form>


                </CardHeader>
                <CardBody>

                {(orderLists.length) ?
                  <>
                {orderLists.map((order, i) => (
                  <div className="table-responsive" key={i}>

                    <table className="table-striped table-bordered mb-0 reportcard-list-table">
                    
                      <thead>
                        <tr>
                          <td colSpan={8}>
                            Manager : {order.orderById.ownerFirstName} {order.orderById.ownerLastName}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Item Category</th>
                          <th scope="col">Item Name</th>
                          <th scope="col">Description</th>
                          <th scope="col">Order Quantity</th>
                          <th scope="col">Order Date</th>
                          <th scope="col">Sku No.</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                        {order.newOrderItems.map((obj, categoryIndex) => (


                          <React.Fragment key={categoryIndex}>
                            {obj.items.map((item, itemIndex) => (
                              <tr key={itemIndex}>
                                <td>{(itemIndex + 1)}</td>
                                <td>
                                  {itemIndex == 0 ? (
                                    obj.category
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td>
                                  {item.itemQuantity}
                                </td>
                                
                                <td>
                                  {moment(order.orderDate).format('DD/MM/YYYY')}
                                </td>
                                <td>{item.sku}</td>
                              </tr>
                            ))}
                          </React.Fragment>


                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                        <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col">Total Items</td>
                          <td scope="col">5</td>
                          <td scope="col"></td>
                          <td scope="col"></td>
                          
                        </tr>
                      <tr>
                          {/* <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col"></td> */}
                          
                          <td scope="col" colSpan={6}>
                            
                                <div className="approve-vendor-dropdown">
                                  <Input type="select" name="vendor" id="vendor" onChange={(e) => setSelectedVendorId(e.target.value)}>
                                    <option value="0">Select Vendor</option>
                                    {vendorList.map((vendor) => (
                                      <option key={vendor._id} value={vendor._id}>
                                        {vendor.vendorName}
                                      </option>
                                    ))}
                                  </Input>
                                </div>
                              

                          </td>
                          <td scope="col" className="order-action-btns">
                            <Button color="success" className="mr-2"  onClick={() => {
                               setUpdateId(order._id);
                               setApprovedStatus(true);
                               setShowModal(true);
                            }}>Approve</Button> 
                            {' '}
                            <Button color="danger" className="mr-2" onClick={() => {
                              setUpdateId(order._id);
                              setApprovedStatus(false);
                              setShowModal(true);
                            }}>Reject</Button>

                          </td>
                        </tr>
                        
                      </tfoot>
                    </table>
                  </div>

                ))}
                </>
                  :

                <>
                  <div className="text-center"><h4>No New Order available</h4></div>
                </>

              }


                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>


      
        <Modal isOpen={showModal} toggle={() => setShowModal(false)} className="modal-dialog-centered">
          <ModalHeader toggle={() => setShowModal(false)}>Action</ModalHeader>
          <ModalBody>
           Are you sure you want to {(approvedStatus) ? 'Approve' : 'Reject'} this Order ?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleUpdateData}>Yes</Button>{' '}
            <Button color="secondary" onClick={() => setShowModal(false)}>No</Button>
          </ModalFooter>
        </Modal>
      


    </React.Fragment>
  )
}

export default OrderList;


