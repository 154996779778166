import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import axios from "../api/axios";
import { useState } from "react";
import SignatureCanvas from 'react-signature-canvas'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    FormGroup,
    Collapse,
    Label,
    Form,
    FormFeedback,
    // FormControl,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    ModalFooter,
    ModalBody,
    Button,
    ModalHeader,
  } from "reactstrap";
  import jwt_decode from "jwt-decode";
  import Select from "react-select";
  import { toast } from "react-toastify";
  import FeatherIcon from "feather-icons-react";
  import "../../assets/css/rentdigicare.css";


class rNmPersonalCard extends React.Component {
    
    monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    defaultEmployeeTable = [
        {
            description : "R&M Checklist",
            target : "",
            actualMTD : "",
            gradingCriteria : "",
            feedback : "",
            score : "",
            comment : ""
        },
        {
            description : "RD Care/Sense of Urgency",
            target : "",
            actualMTD : "",
            gradingCriteria : "",
            feedback : "",
            score : "",
            comment : ""
        },
        {
            description : "Overall Site (Exterior & Interior)",
            target : "",
            actualMTD : "",
            gradingCriteria : "",
            feedback : "",
            score : "",
            comment : ""
        },
        {
            description : "Special Projects/Site Improvement",
            target : "",
            actualMTD : "",
            gradingCriteria : "",
            feedback : "",
            score : "",
            comment : ""
        },
        {
            description : "People/Product/Process",
            target : "",
            actualMTD : "",
            gradingCriteria : "",
            feedback : "",
            score : "",
            comment : ""
        },
        {
            description : "Reviews (Google/FB)",
            target : "",
            actualMTD : "",
            gradingCriteria : "",
            feedback : "",
            score : "",
            comment : ""
        }
    ]
    GET_TECHNICAL_STAFF_URL = "/technicalStaff/get_all_technical_staff";
    decode = jwt_decode(window.localStorage.getItem("accessToken"));
    imageData = "";
    empSignature = "";
    examinarSignature = "";
    constructor(props) {
        super(props);
        this.state = {
            headerFrmValid : false,
            headerFormData: {kpiMonth : this.monthNames[0], startDate : "", endDate : ""},
            showEmployeeSignatureModel : false,
            showExaminarSignatureModel : false,
            employeeSignatureImgUrl : "",
            examinarSignatureImgUrl : "",
            defaultEmployeeTable : [...this.defaultEmployeeTable],
            technicalStaff : [],
            rmID : "",
            completedBy : "",
            empDate : "",
            completedDate : ""

            //JSON.parse(JSON.stringify(this.defaultEmployeeTable)),
        }
        this.getTechnicalStaff();
    }
    
    
    getTechnicalStaff = async (e) => {
        try {
          const response = await axios.post(this.GET_TECHNICAL_STAFF_URL, {
            companyId: this.decode.id,
          });
          console.log(response);
          if (response.data?.success) {

            let technicalStaffList = response?.data?.technicalStaff.map((item) => {
                var obj = {};
                obj["label"] = item.first_name + " " + item.last_name;
                obj["value"] = item._id;
                return obj;
              });

            this.setState({technicalStaff : technicalStaffList})
          }
        } catch (error) {
            console.log(error); // TODO proper error
        }
    }

    handleHeaderFieldsChange = (obj,event) => {
        const newHeaderFormData = {...this.state.headerFormData};
        newHeaderFormData[obj] = event.target.value;
        this.setState({headerFormData: newHeaderFormData});

        const headerFormDataKeys = Object.keys(newHeaderFormData);

        const headerFormDataValues = headerFormDataKeys.map(key => newHeaderFormData[key]);
        
        const isHeaderFormComplete = headerFormDataValues.every(val => val !== '');
        this.setState({headerFrmValid: isHeaderFormComplete});

    }

    addColumn = () => {
        const newDefaultTable = [...this.state.defaultEmployeeTable, this.defaultEmployeeTable[0]];
        console.log(newDefaultTable);
        this.setState({ defaultEmployeeTable: newDefaultTable });
    }
    bindTableData = () => {
        const newDefaultTable = [...this.state.defaultEmployeeTable];
        this.setState({ defaultEmployeeTable: newDefaultTable });
    }

    addTableScore = (index) => {
        const newDefaultTable = [...this.state.defaultEmployeeTable];
        console.log(newDefaultTable[index].feedback );
        if(newDefaultTable[index].feedback == "Need Improvement") {
            newDefaultTable[index].score = 1;
        } else if(newDefaultTable[index].feedback == "Meet Expectations") {
            newDefaultTable[index].score = 2;
        } else if(newDefaultTable[index].feedback == "Exceed Expectations") {
            newDefaultTable[index].score = 3;
        } else {
            newDefaultTable[index].score = 0;
        }
        console.log(newDefaultTable[index]);
        this.setState({ defaultEmployeeTable: newDefaultTable });
    }

    
    handleEmployeeTableChange = (event,index,field) => {
        const newDefaultTable = [...this.state.defaultEmployeeTable];
        newDefaultTable[index] = {...newDefaultTable[index], [field]: event.target.value};

        if(field == "feedback") {
            if(event.target.value == "Need Improvement") {
                newDefaultTable[index] = {...newDefaultTable[index], score: 1};
            } else if(event.target.value == "Meet Expectations") {
                newDefaultTable[index] = {...newDefaultTable[index], score: 2};
            } else if(event.target.value == "Exceed Expectations") {
                newDefaultTable[index] = {...newDefaultTable[index], score: 3};

            }
        }
        this.setState({ defaultEmployeeTable: newDefaultTable });
    }

    handleRemoveRow = (index) => {
        const newDefaultTable = [...this.state.defaultEmployeeTable];
        newDefaultTable.splice(index, 1);
        this.setState({ defaultEmployeeTable: newDefaultTable });
    }

    handleSaveScore = async () => {

        const requestData = {
           type : "R&M Personal",
           kpiMonth : this.state.headerFormData.kpiMonth,
           startDate : this.state.headerFormData.startDate,
           endDate : this.state.headerFormData.endDate,
           tableData : this.state.defaultEmployeeTable,
           employee : this.state.rmID,
           employeeSignatureImg : this.state.employeeSignatureImgUrl,
           employeeSignatureDate : this.state.empDate,
           examiner : this.state.completedBy,
           examinerSignatureImg : this.state.examinarSignatureImgUrl,
           examierSignatureDate : this.state.completedDate
        }

        console.log(JSON.stringify(requestData));
       try {
           const response = await axios.post('/reportcard/create', requestData);
           if(response.status == 200) {
               // this.props.history.push('/reportcard'); 
               toast.success(response.message);
           }
       } catch (error) {
           console.log(error);
       }
   }


    render() {
        const { employee } = this.state;
        return (
             <div className="page-content">
                    <MetaTags>
                        <title> Rentdigicare | Inventory</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Home" breadcrumbItem="Report Card" breadcrumbItem2="Employee" />

                        <Row>
                            <Col xl={12}>
                            <Card>
                                <CardHeader>

                                <Form>
                                <FormGroup row>
                                    <Col md="3">
                                    <Label for="kpiMonth">KPI Month</Label>
                                    <Input type="select" name="kpiMonth" id="kpiMonth"
                                      invalid={this.state.headerFormData.kpiMonth === ''}
                                      onChange={(e) => this.handleHeaderFieldsChange('kpiMonth',e)}
                                    >
                                      <option value="">Select Month</option>
                                      {this.monthNames.map((month, index) => (
                                        <option key={index} value={month}>{month}</option>
                                      ))}
                                    </Input>
                                    <FormFeedback>This field is required</FormFeedback>
                                    </Col>
                                    <Col md="3">
                                    <Label for="startDate">Start Date</Label>
                                    <Input
                                          className="form-control"
                                          type="date"
                                          id="startDate"
                                          invalid={this.state.headerFormData.startDate === ''}
                                          onChange={(e) => this.handleHeaderFieldsChange('startDate',e)}
                                        />
                                    <FormFeedback>This field is required</FormFeedback>
                                    </Col>
                                    <Col md="3">
                                    <Label for="endDate">End Date</Label>
                                    <Input
                                          className="form-control"
                                          type="date"
                                          id="endDate"
                                          invalid={this.state.headerFormData.endDate === ''}
                                          onChange={(e) => this.handleHeaderFieldsChange('endDate',e)}
                                        />
                                    <FormFeedback>This field is required</FormFeedback>
                                    </Col>
                                </FormGroup>
                                </Form>

                                </CardHeader>
                                <CardBody>
                                    
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h2>KPI For the Month of {this.state.headerFormData.kpiMonth}
                                            
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                        <Form>
                                            {/* <div className="col-12 text-right mt-4">
                                                <Button color="primary" className="mt-4" size="lg" onClick={this.addColumn}>Add Column</Button>
                                            </div> */}

                                            <table className="table-striped table-bordered mb-0 reportcard-table">
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Target</th>
                                                        <th>Actual MTD</th>
                                                        <th>Criteria For Grading</th>
                                                        <th>Feedback</th>
                                                        <th>Score</th>
                                                        <th>Comments</th>
                                                        <th></th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.defaultEmployeeTable.map((row, index) => (

                                                        <tr key={index}>
                                                            <td>
                                                            <textarea className="form-control" rows="3" placeholder="Description"  defaultValue={row.description} onChange={(e) => this.handleEmployeeTableChange(e,index, 'description')} disabled></textarea>
                                                                {/* <Input type="text" className="form-control" placeholder="Description" value={row.description} onChange={(e) => this.handleEmployeeTableChange(e,index, 'description')} /> */}
                                                                
                                                                </td>
                                                            <td><Input type="text" className="form-control" placeholder="Target" value={row.target} onChange={(e) => this.handleEmployeeTableChange(e,index, 'target')} /></td>
                                                            <td><Input type="text" className="form-control" placeholder="Actual MTD" value={row.actualMTD} onChange={(e) => this.handleEmployeeTableChange(e,index, 'actualMTD')} /></td>
                                                            <td><textarea className="form-control" rows="3" placeholder="Criteria for Grading" defaultValue={row.gradingCriteria} onChange={(e) => this.handleEmployeeTableChange(e,index, 'gradingCriteria')}></textarea></td>
                                                            <td>
                                                                <select className="form-control" value={row.feedback} onChange={(e) => this.handleEmployeeTableChange(e,index, 'feedback')} placeholder="Select Feedback" >
                                                                    <option value="">Select Feedback</option>
                                                                    <option value="Need Improvement">Need Improvement</option>
                                                                    <option value="Meet Expectations">Meet Expectations</option>
                                                                    <option value="Exceed Expectations">Exceed Expectations</option>
                                                                </select>
                                                            </td>
                                                            <td><Input type="text" className="form-control" placeholder="Score" value={row.score} readOnly /></td>
                                                            <td><textarea className="form-control" rows="3" placeholder="Comments"  defaultValue={row.comment} onChange={(e) => this.handleEmployeeTableChange(e,index, 'comment')}></textarea></td>
                                                            <td className="text-center">
                                                                <button className="btn trashrow-btn" onClick={() => this.handleRemoveRow(index)}>
                                                                    <FeatherIcon icon="trash-2" />
                                                                </button>

                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>Total Score</td>
                                                        <td>
                                                            {this.state.defaultEmployeeTable.reduce((a, b) => a + (b.score ? parseInt(b.score) : 0), 0)}
                                                        </td>


                                                        <td></td>
                                                        <td></td>
                                                    </tr>
                                                
                                                </tfoot>
                                            </table>
                                        </Form>
                                        </div>
                                    </div>

                                    <div className="row table-action">
                                        
                                        <div className="col-4">
                                            <label>R & M Personal</label>

                                            <Select
                                                    placeholder="Select a manager"
                                                    className="appsCalender-filter-select"
                                                    options={this.state.technicalStaff}
                                                    onChange={(e) => { this.setState({rmID : e}) }}
                                                />
                                                
                                            {/* <select className="form-control" onChange={(e) => {
                                                alert(e.target.value)
                                            }}>
                                                <option value="">Select Employee</option>
                                                <option value="1">Employee 1</option>
                                                <option value="2">Employee 2</option>
                                                <option value="3">Employee 3</option>
                                            </select> */}
                                        </div>

                                        
                                         <div className="col-4">

                                        {this.state.employeeSignatureImgUrl ? 
                                            <div className="text-center"><img src={this.state.employeeSignatureImgUrl} className="img-thumbnail" style={{maxWidth: "150px"}} /></div> :

                                            <div className="form-group">
                                                <label htmlFor="sign">Signature</label>
                                                <input type="text" className="form-control" id="sign" aria-describedby="signature" readOnly placeholder="Click to Sign" onClick={() => this.setState({ showEmployeeSignatureModel: true })} />
                                            </div>
                                           
                                            // <a className="btn btn-primary" onClick={() => this.setState({ showEmployeeSignatureModel: true })}>Signature</a>
                                        }


                                            
                                            {/* <a className="btn btn-primary" onClick={() => this.setState({ showEmployeeSignatureModel: true })}>Signature</a> */}
                                            
                                            <Modal className="signature-model"
                                                isOpen={this.state.showEmployeeSignatureModel}
                                                toggle={() => this.setState({ showEmployeeSignatureModel: false })}
                                            >
                                                <ModalHeader toggle={() => this.setState({ showEmployeeSignatureModel: false })}>Signature</ModalHeader>
                                                <ModalBody>
                                                    <SignatureCanvas penColor='black' id="empSignature" canvasProps={{className: 'sigCanvas' }} 
                                                      ref={(ref) => {
                                                        this.empSignature = ref;
                                                        //signatureImgUrl
                                                      }}
                                                    />
                                                </ModalBody>
                                                <ModalFooter>
                                                    {/* {this.state.signatureImgUrl} */}
                                                    <Button color="warning" onClick={() => {this.setState({ employeeSignatureImgUrl: "" });this.empSignature.clear();}}>Clear</Button>
                                                    <Button color="primary" onClick={() => {
                                                        this.imageData = this.empSignature.getTrimmedCanvas().toDataURL("image/png");
                                                        this.setState({
                                                            employeeSignatureImgUrl: this.imageData
                                                          });
                                                          this.setState({ showEmployeeSignatureModel: false })
                                                    }}>Ok</Button>
                                                </ModalFooter>
                                            </Modal>



                                            {/* <SignatureCanvas penColor='green'
                                                    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} /> */}

                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label htmlFor="date">Date</label>
                                                <input type="date" className="form-control" id="date" aria-describedby="dateHelp" placeholder="Enter date" onChange={(e) => {this.setState({empDate : e.target.value})}}  />
                                                <small id="dateHelp" className="form-text text-muted">Date of signature</small>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row table-action">
                                        
                                        <div className="col-4">
                                            <label>KPI Completed by</label>
                                            <select className="form-control" onChange={(e) => {
                                                this.setState({completedBy : e.target.value})
                                                }}>
                                                <option value="">Select</option>
                                                <option value="Sunny Dihana">Sunny Dihana</option>
                                                <option value="Vicky Sharma">Vicky Sharma</option>
                                                <option value="Kam Gill">Kam Gill</option>
                                                <option value="Joel">Joel</option>
                                            </select>
                                        </div>

                                        
                                         <div className="col-4">

                                        {this.state.examinarSignatureImgUrl ? 
                                            <div className="text-center"><img src={this.state.examinarSignatureImgUrl} className="img-thumbnail" style={{maxWidth: "150px"}} /></div> :

                                            <div className="form-group">
                                                <label htmlFor="sign">Signature</label>
                                                <input type="text" className="form-control" id="sign" aria-describedby="signature" readOnly placeholder="Click to Sign" onClick={() => this.setState({ showExaminarSignatureModel: true })} />
                                            </div>
                                            
                                            // <a className="btn btn-primary" onClick={() => this.setState({ showExaminarSignatureModel: true })}>Signature</a>
                                        }


                                            
                                            {/* <a className="btn btn-primary" onClick={() => this.setState({ showEmployeeSignatureModel: true })}>Signature</a> */}
                                            
                                            <Modal className="signature-model"
                                                isOpen={this.state.showExaminarSignatureModel}
                                                toggle={() => this.setState({ showExaminarSignatureModel: false })}
                                            >
                                                <ModalHeader toggle={() => this.setState({ showExaminarSignatureModel: false })}>Signature</ModalHeader>
                                                <ModalBody>
                                                    <SignatureCanvas penColor='black' id="empSignature" canvasProps={{className: 'sigCanvas' }} 
                                                      ref={(ref) => {
                                                        this.examinarSignature = ref;
                                                        //signatureImgUrl
                                                      }}
                                                    />
                                                </ModalBody>
                                                <ModalFooter>
                                                    {/* {this.state.signatureImgUrl} */}
                                                    <Button color="warning" onClick={() => {this.setState({ examinarSignatureImgUrl: "" });this.examinarSignature.clear();}}>Clear</Button>
                                                    <Button color="primary" onClick={() => {
                                                        const imageData = this.examinarSignature.getTrimmedCanvas().toDataURL("image/png");
                                                        this.setState({
                                                            examinarSignatureImgUrl: imageData
                                                          });
                                                          this.setState({ showExaminarSignatureModel: false })
                                                    }}>Ok</Button>
                                                </ModalFooter>
                                            </Modal>



                                            {/* <SignatureCanvas penColor='green'
                                                    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} /> */}

                                        </div>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label htmlFor="date">Date</label>
                                                <input type="date" className="form-control" id="date" aria-describedby="dateHelp" placeholder="Enter date" onChange={(e) => this.setState({completedDate : e.target.value})}  />
                                                <small id="dateHelp" className="form-text text-muted">Date of signature</small>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row">
                                       <div className="col-12 text-right mb-3">
                                            <Button color="primary" onClick={this.handleSaveScore}  disabled={!this.state.headerFrmValid}>Submit</Button>
                                        </div>
                                        <div className="col-12">
                                            <h4>Bonus Criteria</h4>
                                            <p className="card-notic">To Qualify for the Bonus, R & M Team member must achieve atleast 90% on R/D Care/Sense or Urgency and a minimum score of 15</p>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                            </Col>
                        </Row>
                    </div>
            </div>
        );
    }
}


export default rNmPersonalCard
