import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import axios from "../api/axios";
import { useState } from "react";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    FormGroup,
    Collapse,
    Label,
    Form,
    // FormControl,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    ModalFooter,
    ModalBody,
    Button,
    ModalHeader,
  } from "reactstrap";
  import moment from "moment";
  import "../../assets/css/rentdigicare.css";
  import Breadcrumbs from "../../components/Common/Breadcrumb";
  import { toast } from "react-toastify";
  
  import FeatherIcon from "feather-icons-react";

const ListCards = () => {

    const filteParams = {
        kpiMonth : "",
        type : "",
        employee : "",
        updatedAt : ""
    }

    const [reportCards, setReportCards] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [ShowReportCard, setShowReportCard] = useState(false);
    const [SelectedReportCard, setSelectedReportCard] = useState({});
    const [deleteId, setdeleteId] = useState("");
    const [FilterParam, setFilterParam] = useState({});


    useEffect(() => {
        axios.get("/reportcard/all").then((res) => {
            if(res.data.status == 200) {
                console.log(res);
                setReportCards(res.data.reportCards);
            }
        });
    }, []);

    const deleteReportCard = (id) => {
        axios.delete(`/reportcard/delete/${id}`).then((res) => {
            const newReportCards = reportCards.filter((reportCard) => reportCard._id !== id);
            setReportCards(newReportCards);
            toast.success(res.data.message);

        });
    };

    const handleFilterTable = () => {
        
        setFilterParam(filteParams);

        axios.post(`/reportcard/filter`,filteParams).then((res) => {
            setReportCards(res.data.reportCards);
            // const newReportCards = reportCards.filter((reportCard) => reportCard._id !== id);
            // setReportCards(newReportCards);
            // toast.success(res.data.message);

        });

    }


    return (

        <React.Fragment>
    
        <div className="page-content">
                <MetaTags>
                    <title> Rentdigicare | All Report Cards</title>
                </MetaTags>
                <div className="container-fluid">
                    <Breadcrumbs title="Report Cards" breadcrumbItem="List" />
    
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader>
                                  <Row>
                                     <Col md={3}>
                                     <Label htmlFor="property">Select Month</Label>
                                        <Input type="select" name="month" id="month" onChange={(e) => filteParams.kpiMonth = e.target.value}>
                                        <option value="">Select</option>
                                        {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => (
                                            <option key={index} value={month}>{month}</option>
                                        ))}
                                        </Input>
                                        </Col>
                                        <Col md={3}>
                                            <Label htmlFor="property">Kpi Type</Label>
                                            <Input type="select" name="type" id="type" onChange={(e) => filteParams.type = e.target.value}>
                                            <option value="">Select</option>
                                                {['Employee', 'Community Leader', 'R&M Personal'].map((type, index) => (
                                                    <option key={index} value={type}>{type}</option>
                                                ))}
                                            </Input>
                                        </Col>

                                        <Col md={3}>
                                            <Label htmlFor="property">Select Employee</Label>
                                            <Input type="select" name="employee" id="employee" onChange={(e) => filteParams.employee = e.target.value}>
                                            <option value="">Select</option>
                                                {reportCards.map((card, index) => (
                                                    <option key={index} value={card.employee.value}>{card.employee.label}</option>
                                                ))}
                                            </Input>
                                        </Col>

                                        {/* <Col md={3}>

                                            <Label htmlFor="property">Select Date</Label>
                                            <Input type="date" name="date" id="date" onChange={(e) => filteParams.updatedAt = e.target.value} />
                                        </Col> */}




                                        <Col md={3}>
                                            <Button color="primary" className="createinventory-btn" onClick={handleFilterTable}>
                                              Filter</Button>
                                        </Col>
                                        
                                      </Row>
    
                                </CardHeader>
    
                                <CardBody>

                                <table className="table-striped table-bordered mb-0 reportcard-list-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Report Card For</th>
                                        <th>KPI Month</th>
                                        <th>Completed By</th>
                                        <th colSpan={2}>Action</th>
                                        {/* <th></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                        {/* <Button color="danger" size="sm" onClick={() => setShowDialog(true)}>Delete</Button> */}

                                        {reportCards.map((reportCard) => (
                                        <tr key={reportCard._id}>
                                            <td>{reportCard.employee.label}</td>
                                            <td>{reportCard.type}</td>
                                            <td>{reportCard.kpiMonth}</td>
                                            <td>{reportCard.examiner}</td>
                                            <td><Button color="primary" size="sm" onClick={() => {setShowReportCard(true); setSelectedReportCard(reportCard)}}>View</Button></td>
                                            <td><Button color="danger" size="sm" onClick={() => {setShowDialog(true); setdeleteId(reportCard._id)}}>Delete</Button></td>
                                        </tr>
                                        ))}

                                </tbody>
                                </table>
                                    
    
                                </CardBody>
                            
                            </Card>
                        </Col>
                    </Row>
    
                </div>
        </div>

            <Modal className="signature-model"
                isOpen={showDialog}
                toggle={() => setShowDialog(false)}>
                <ModalHeader toggle={() => setShowDialog(false)}>Signature</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete the report card?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => { deleteReportCard(deleteId); setShowDialog(false); }}>Yes</Button>
                    <Button color="secondary" onClick={() => setShowDialog(false)}>No</Button>
                </ModalFooter>
            </Modal>
            
            <Modal className="show-reportcard"
                isOpen={ShowReportCard}
                toggle={() => setShowReportCard(false)}>
                <ModalHeader toggle={() => setShowReportCard(false)}>Report Card for {SelectedReportCard?.employee?.label}</ModalHeader>
                <ModalBody>
                    <OpenCSReportCard reportcard={SelectedReportCard} />
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="primary" onClick={() => { deleteReportCard(deleteId); setShowReportCard(false); }}>Yes</Button>
                    <Button color="secondary" onClick={() => setShowReportCard(false)}>No</Button> */}
                </ModalFooter>
            </Modal>

    
        </React.Fragment>
    
    
      );
};

function OpenCSReportCard(props) {
    const card_ = props.reportcard;
    return (
        <div className="row">
            <div className="col-12">

                <table className="table-striped table-bordered mb-0 reportcard-list-table">
                                                <thead>
                                                    <tr>
                                                        <th>Description</th>
                                                        <th>Target</th>
                                                        <th>Actual MTD</th>
                                                        {(card_.type == "Community Leader") && <><th>Annual Target</th><th>Actual YTD</th><th>Difference YTD</th></> }
                                                        
                                                        
                                                        <th>Criteria For Grading</th>
                                                        <th>Feedback</th>
                                                        <th>Score</th>
                                                        <th>Comments</th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {card_.tableData.map((row, index) => (                                                        

                                                        <tr key={index}>
                                                            <td>{row.description}</td>
                                                            <td>{row.target}</td>
                                                            <td>{row.actualMTD}</td>
                                                            {(card_.type == "Community Leader") && <><td>{row.annualTarget}</td><td>{row.actualYTD}</td><td>{row.differenceYTD}</td></> }
                                                            <td>{row.gradingCriteria}</td>
                                                            <td>{row.feedback} </td>
                                                            <td>{row.score}</td>
                                                            <td>{row.comment}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        {(card_.type == "Community Leader") && <>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        </>
                                                        }
                                                        <td>Total Score</td>
                                                        <td>
                                                            {card_.tableData.reduce((a, b) => a + (b.score ? parseInt(b.score) : 0), 0)}
                                                        </td>


                                                        <td></td>
                                                    </tr>
                                                
                                                </tfoot>
                                            </table>
                                            <ReportCardActionsFor reportcard={card_} />
                                            <ReportCardActionsBy reportcard={card_} />


            </div>
        </div>
    );
}


function ReportCardActionsFor(props) {

    const card_ = props.reportcard;

    return (

            <div className="row table-action">
                                                    
                    <div className="col-4">
                        <label>{card_.type} Name:</label>
                        {card_.employee.label}

                    </div>

                    
                    <div className="col-4">

                    {card_.employeeSignatureImg && 
                        <div className="text-center"><img src={card_.employeeSignatureImg} className="img-thumbnail" style={{maxWidth: "150px"}} /></div> }


                    </div>
                    <div className="col-4">
                        
                            <label htmlFor="date">Date</label>
                            {moment(card_.employeeSignatureDate).format("YYYY-MM-DD")}

                    </div>
                </div>


    );
}

function ReportCardActionsBy(props) {
    const card_ = props.reportcard;

    return (

        <div className="row table-action">
                                                    
        <div className="col-4">
            <label>Confirmed By:</label>
            {card_.examiner}

        </div>

        
        <div className="col-4">

        {card_.examinerSignatureImg && 
            <div className="text-center"><img src={card_.examinerSignatureImg} className="img-thumbnail" style={{maxWidth: "150px"}} /></div> }


        </div>
        <div className="col-4">
            
                <label htmlFor="date">Date</label>
                {moment(card_.examierSignatureDate).format("YYYY-MM-DD")}

        </div>
    </div>
        

    )
}

export default ListCards;

