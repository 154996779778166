import React, { useEffect, useState } from "react";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormStep5 from "./FormStep5";
import FormStep6 from "./FormStep6";
import FormStep7 from "./FormStep7";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api/axios";
import { Card, CardBody } from "reactstrap";
import { getApplicationInfo } from "../../../store/leaseForm/actions";

const GET_LEASE_DOCUMENT = "lease-from/user/"

const LeaseDocView = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const { formData1, formData7 } = useSelector((state) => (state.leaseForm));

    const [errMsg, setErrMsg] = useState("...Loading");

    const getUserLeaseDocument = async (_token) => {
        try {
            const result = await axios.get(`${GET_LEASE_DOCUMENT}${_token}`);
            if (result && result.data && result.data.status == 403) {
                setErrMsg(result.data.message);
            } else if (result && result.data && result.data.status == 404) {
                setErrMsg(result.data.message);
            } if (result && result.data && result.data.status == 200) {
                setErrMsg("");
                const _result = result.data.data;
                const { propertyInfo, mangerInfo, formData2, formData3 } = _result;
                const _formData1 = _result.formData1;
                const _formData7 = _result.formData7;

                let _data = {
                    propertyInfo, mangerInfo,
                    formData1: {
                        ...formData1,
                        ..._formData1
                    }, formData2, formData3,
                    formData7: {
                        ...formData7,
                        ..._formData7
                    }
                }
                dispatch(getApplicationInfo(_data));
            }

        } catch (error) {
            console.error("User Lease Document : ", error);
        }
    }

    useEffect(() => {
        if (token) {
            getUserLeaseDocument(token);
        }
    }, [])


    return (
        <>
            {errMsg ? <Card>
                <CardBody className="text-center">
                    <h2>{errMsg}</h2>
                </CardBody>
            </Card> : <Card>
                <CardBody>
                    <FormStep1 readOnly={true} />
                    <hr />
                    <FormStep2 readOnly={true} />
                    <hr />
                    <FormStep3 readOnly={true} />
                    <hr />
                    <FormStep4 />
                    <hr />
                    <FormStep5 />
                    <hr />
                    <FormStep6 />
                    <hr />
                    <FormStep7 readOnly={true} />
                </CardBody>
            </Card>}
        </>
    )
}

export default LeaseDocView;