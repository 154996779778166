import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import html2PDF from 'jspdf-html2canvas';

import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap";
import { Stepper } from "react-form-stepper";
import getFormComponent from "./NotifyForm";
import FeatherIcon from "feather-icons-react";
import axios from "../../api/axios";
import { setError, setINTERCOM_SETUP, setNOTICE, setSURFACE_PARKING, setUNDERGROUND_PARKING } from "../../../store/notifyForm/actions";
import { isLoading } from "../../../store/actions";
import { toast } from "react-toastify";

const STEPS = [
  {
    id: "a_1",
    label: "INTERCOM SETUP",
    active: false,
    completed: false,
  },
  {
    id: "a_2",
    label: "UNDERGROUND PARKING",
    active: false,
    completed: false,
  },
  {
    id: "a_3",
    label: "SURFACE PARKING",
    active: false,
    completed: false,
  },
  {
    id: "a_4",
    label: "NOTICE: FREEZING PIPES",
    active: false,
    completed: false,
  },
];

const connectorStyleConfig = {
  disabledColor: "#bdbdbd",
  activeColor: "#1976d2",
  completedColor: "#1976d2",
  size: 1,
  stepSize: "2em",
  style: "solid",
};

const StepStyleDTO = {
  activeBgColor: "#1976d2",
  activeTextColor: "#ffffff",
  completedBgColor: "#1976d2",
  completedTextColor: "#ffffff",
  inactiveBgColor: "#6B7A90",
  inactiveTextColor: "#ffffff",
};

const RESIDENT_DOC = {
  "a_1": "INTERCOM_SETUP.pdf",
  "a_2": "UNDERGROUND_PARKING.pdf",
  "a_3": "SURFACE_PARKING.pdf",
  "a_4": "FREEZING_PIPES.pdf",
}

const GET_FORMS_DATA = "resident-notify/forms/";
const DOWNLOAD_RESIDENT_DOCUEMNT = "resident-notify/download-document";

const ApplicantNotifyForms = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const { INTERCOM_SETUP, UNDERGROUND_PARKING, SURFACE_PARKING, NOTICE } = useSelector((state) => state.nofityForm);

  const [formIds, setFormIds] = useState([]);
  const [propertyInfo, setPropertyInfo] = useState({});
  const [mangerInfo, setMangerInfo] = useState({});
  const [applicationId, setApplicationId] = useState("");
  const [steps, setSteps] = useState([]);
  const [stepCount, setStepCount] = useState(0);
  const [errMsg, setErrMsg] = useState("...Loading");

  const getUserDocument = async (_token) => {
    try {
      const result = await axios.get(`${GET_FORMS_DATA}${_token}`);
      if (result && result.data && result.data.status == 403) {
        setErrMsg(result.data.message);
      } else if (result && result.data && result.data.status == 404) {
        setErrMsg(result.data.message);
      }
      if (result && result.data && result.data.status == 200) {
        setErrMsg("");
        const _result = result.data.data;
        const { formIds, propertyInfo, mangerInfo, formData, applicationId } = _result;
        setPropertyInfo(propertyInfo);
        setMangerInfo(mangerInfo);
        setFormIds(formIds);
        handleSteps(formIds);
        updateNotifyReduxData(formData);
        setApplicationId(applicationId);
      }
    } catch (error) {
      console.error("User Lease Document : ", error);
    }
  };

  const updateNotifyReduxData = (data) => {
    data.map((doc) => {
      if (doc.documentKey == "a_1" && doc.formData) {
        const obj = JSON.parse(doc.formData);
        dispatch(setINTERCOM_SETUP(obj));
      }
      if (doc.documentKey == "a_2" && doc.formData) {
        const obj = JSON.parse(doc.formData);
        dispatch(setUNDERGROUND_PARKING(obj));
      }
      if (doc.documentKey == "a_3" && doc.formData) {
        const obj = JSON.parse(doc.formData);
        dispatch(setSURFACE_PARKING(obj));
      }
      if (doc.documentKey == "a_4" && doc.formData) {
        const obj = JSON.parse(doc.formData);
        dispatch(setNOTICE(obj));
      }
    })

  }

  const handleSteps = (ids) => {
    const result = ids.map((doc) => STEPS.find((item) => item.id === doc));
    setSteps(result);
  };

  const isFormIntercomValid = () => {
    // Tenant validation
    for (const [key, value] of Object.entries(INTERCOM_SETUP)) {
      if (key === "BuildingNumber" && !value) {
        dispatch(setError("Building Number field is required."));
        return false;
      }

      if (key === "PhoneNumber" && !value) {
        dispatch(setError("Phone Number field is required."));
        return false;
      }
    }

    dispatch(setError(""));
    return true;
  };

  const isFormUndergroundValid = () => {
    for (const [key, value] of Object.entries(UNDERGROUND_PARKING)) {
      if (key === "ResidentName" && !value) {
        dispatch(setError("Resident Name field is required."));
        return false;
      }

      if (key === "AddressSuite" && !value) {
        dispatch(setError("Address Suite field is required."));
        return false;
      }
      if (key === "UndergroundStall" && !value) {
        dispatch(setError("Underground Stall field is required."));
        return false;
      }
      if (key === "Phone" && !value) {
        dispatch(setError("Phone field is required."));
        return false;
      }

      if (key === "Signature" && !value) {
        dispatch(setError("Signature is required."));
        return false;
      }
    }

    dispatch(setError(""));
    return true;
  };

  const isFormSurfaceValid = () => {
    for (const [key, value] of Object.entries(SURFACE_PARKING)) {
      if (key === "ResidentName" && !value) {
        dispatch(setError("Resident Name field is required."));
        return false;
      }

      if (key === "AddressSuite" && !value) {
        dispatch(setError("Address Suite field is required."));
        return false;
      }
      if (key === "UndergroundStall" && !value) {
        dispatch(setError("Underground Stall field is required."));
        return false;
      }
      if (key === "Phone" && !value) {
        dispatch(setError("Phone field is required."));
        return false;
      }

      if (key === "Signature" && !value) {
        dispatch(setError("Signature is required."));
        return false;
      }
    }

    dispatch(setError(""));
    return true;
  };

  const isFormNoticeValid = () => {
    for (const [key, value] of Object.entries(NOTICE)) {
      if (key === "Name" && !value) {
        dispatch(setError(" Name field is required."));
        return false;
      }

      if (key === "Signature" && !value) {
        dispatch(setError("Signature field is required."));
        return false;
      }
      if (key === "Date" && !value) {
        dispatch(setError("Date field is required."));
        return false;
      }
    }

    dispatch(setError(""));
    return true;
  };

  const validationObj = {
    "a_1": isFormIntercomValid,
    "a_2": isFormUndergroundValid,
    "a_3": isFormSurfaceValid,
    "a_4": isFormNoticeValid
  }

  const handleNext = () => {
    let _stepCount = stepCount;
    const _steps = steps.slice();
    if (!validationObj[_steps[_stepCount].id]()) {
      return;
    }

    _steps[_stepCount] = {
      ..._steps[_stepCount],
      active: false,
      completed: true,
      children: <FeatherIcon icon="check" />,
    };
    _steps[_stepCount + 1] = {
      ..._steps[_stepCount + 1],
      active: true,
    };

    setSteps(_steps);
    setStepCount(_stepCount + 1);
  };

  const handleBack = () => {
    let _stepCount = stepCount;
    const _steps = steps.slice();
    _steps[_stepCount] = {
      ..._steps[_stepCount],
      active: false,
      completed: false,
      children: "",
    };
    _steps[_stepCount - 1] = {
      ..._steps[_stepCount - 1],
      active: true,
      completed: false,
      children: "",
    };
    setSteps(_steps);
    setStepCount(_stepCount - 1);
  };

  const handleFinish = async () => {
    try {
      let _stepCount = stepCount;
      const _steps = steps.slice();
      if (!validationObj[_steps[_stepCount].id]()) {
        return;
      }
      if (window.confirm("Are you sure you want to submit document ?")) {
        dispatch(isLoading(true))

        const formColleaction = []
        formIds.map((doc) => {

          if (doc == "a_1") {
            formColleaction.push({
              documentKey: "a_1",
              formData: JSON.stringify(INTERCOM_SETUP)
            })
          }
          if (doc == "a_2") {
            formColleaction.push({
              documentKey: "a_2",
              formData: JSON.stringify(UNDERGROUND_PARKING)
            })
          }
          if (doc == "a_3") {
            formColleaction.push({
              documentKey: "a_3",
              formData: JSON.stringify(SURFACE_PARKING)
            })
          }
          if (doc == "a_4") {
            formColleaction.push({
              documentKey: "a_4",
              formData: JSON.stringify(NOTICE)
            })
          }
        })

        const body = {
          propertyId: propertyInfo._id,
          managerId: mangerInfo._id,
          formColleaction
        };

        const result = await axios.post(GET_FORMS_DATA + token, body);
        if (result && result.data && result.data.status == 200) {
          for await (const doc of formColleaction) {
            await handleDownloadPdf(doc.documentKey, applicationId);
          }
          setErrMsg("Document submited successfully, Thank you.");
        }
      }
      dispatch(isLoading(false))

    } catch (error) {
      toast.error("Something went wrong !")
      dispatch(isLoading(false))
    }
  };

  const handleDownloadPdf = async (documentKey, applicantId) => {


    const { data } = await axios.get(`${DOWNLOAD_RESIDENT_DOCUEMNT}/${documentKey}/${applicantId}`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'arraybuffer'
    });
    const blob = new Blob([data], { type: 'application/pdf' });
    const a = document.createElement('a');
    const blobUrl = window.URL.createObjectURL(blob);
    a.href = blobUrl;

    // Set the filename
    a.download = RESIDENT_DOC[documentKey];

    // Trigger a click event to initiate the download
    a.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(a.href);

  };

  useEffect(() => {
    if (token) {
      getUserDocument(token);
    }
  }, []);

  return (
    <>
      {errMsg ? (
        <Card>
          <CardBody className="text-center">
            <h2>{errMsg}</h2>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardHeader className="text-center">
            <h4>User Forms Preview & Signature </h4>
          </CardHeader>
          <div id="divToPrint">
            <CardHeader>
              <Stepper
                steps={steps}
                activeStep={stepCount}
                connectorStateColors={true}
                connectorStyleConfig={connectorStyleConfig}
                styleConfig={StepStyleDTO}
              />
            </CardHeader>
            <CardBody>
              {getFormComponent(formIds[stepCount], { mangerInfo, propertyInfo })}
            </CardBody>
          </div>
          <CardFooter>
            <Row>
              <Col md={6} className="text-start">
                <Button
                  disabled={stepCount <= 0 ? true : false}
                  color="primary"
                  onClick={() => handleBack()}
                >
                  Back
                </Button>
              </Col>
              <Col md={6} className="text-end">
                {
                  stepCount == steps.length - 1 ? <Button color="primary" onClick={handleFinish}>
                    Finish
                  </Button> : <Button
                    disabled={stepCount >= steps.length - 1 ? true : false}
                    color="primary"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                }

              </Col>
            </Row>
          </CardFooter>
        </Card>
      )}

    </>
  );
};

export default ApplicantNotifyForms;
