import { useSelector } from "react-redux";

const FormFooter = () => {
    const { propertyInfo, formData7 } = useSelector((state) => (state.leaseForm));
    const { owner } = propertyInfo;

    const shortName = (name) => {
        if (name) {
            let b = "";
            const a = name.split(" ");
            if (a.length > 1) {
                b = a.map((ele) => (ele.charAt(0))).join("");
            } else {
                b = [a[0].charAt(0), a[0].charAt(1)].join("");
            }
            return b.toUpperCase();
        }
        return name;
    }


    return (<div className="btmRightSec">
        <table>
            <tr>
                <td>Landlord</td>
                <td>Tenant(s)</td>
            </tr>
            <tr>
                <td>{shortName(owner)}</td>
                <td>{formData7.nameIntitals.toUpperCase()}</td>
            </tr>
        </table>
    </div>)
}

export default FormFooter;