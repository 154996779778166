import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import axios from "../api/axios";
import moment from "moment";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    FormGroup,
    Collapse,
    Label,
    Form,
    // FormControl,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    ModalFooter,
    ModalBody,
    Button,
    ModalHeader,
    // Accordion,
    // AccordionBody,
    // AccordionHeader,
    // AccordionItem
  } from "reactstrap";
  
  import { Accordion } from "react-bootstrap";

  import jwt_decode from "jwt-decode";
  import FeatherIcon from "feather-icons-react";
  import { toast } from "react-toastify";
  import "../../assets/css/rentdigicare.css";

const PastOrders = () => {

    const decode2 = jwt_decode(window.localStorage.getItem("accessToken"));
    const FILTER_URL = `/property/filter?role=${decode2.role}&domain=${
        decode2.role == "manager" ? decode2.id : decode2.domain
      }`;

     
    const COMPANY_NAME_URL = `/company/filter/company_list`
    

    
    const [properties, setProperties] = useState([]);
    const [CompanyName, setCompanyName] = useState([]);
    const [SelectedCompanyId, setSelectedCompanyId] = useState("");
    const [SelectedPropertyId, setSelectedPropertyId] = useState("");
    const [orderLists,setOrderLists] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

  const companyName = async () => {
    const response = await axios.get(COMPANY_NAME_URL);
    const temp = (response.data.companies);
    console.log(temp);
    setCompanyName(temp)
}

const propertiesList = async (companyId) => {
    const params = {
      companyID: companyId
    }
    const response = await axios.post('/property/getAllCompanyProperties', params);
    const temp = (response.data.properties);
    if(temp) {
        setProperties(temp);
    }
   
}

const handleGetInventoryOrders = async (e) => {
    e.preventDefault();
    //setSelectedPropertyId(propertyId);
    const params = {
      companyID: SelectedCompanyId,
      propertyID: SelectedPropertyId,
      startDate : startDate,
      endDate : endDate
    };
    const response = await axios.post('/orderinventory/pastorders', params);
    if(response.status == 200 && response.data.length) {
      response.data.forEach(order => {
        const newOrderItems = order.orderItems.reduce((categories, categoryObject) => {
          console.log(categoryObject.items);
          const orderCategory = {
            category: categoryObject.categoryId.category.name,
            items: categoryObject.items.map(itemObj => {
              const foundItem = categoryObject.categoryId.category.items.find(item => item._id === itemObj.itemId);
              return {
                ...foundItem,
                itemQuantity: itemObj.itemQuantity
              };
            })
          };
          return [...categories, orderCategory];
        }, []);
        order.newOrderItems = newOrderItems;
        console.log(order);
      });
    }

      setOrderLists(response.data);
    
  };


  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  useEffect(() => {
    companyName();
    // const fetchOrders = async () => {
    //   const response = await axios.get("/orderinventory/pastorders");
    //   setOrders(response.data);
    // };
    // fetchOrders();
  }, []);

  return (
    <React.Fragment>

      <div className="page-content">
        <MetaTags>
          <title> Rentdigicare | Past Orders</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Inventory" breadcrumbItem="Past Orders" />

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>

                  <Form>
                    <FormGroup row>
                      <Col md="3">
                        <Label htmlFor="property">Select Company</Label>
                        <Input type="select" name="SelectedCompany" id="SelectedCompany" onChange={(e) => {
                          setSelectedCompanyId(e.target.value);
                          propertiesList(e.target.value);
                        }} value={SelectedCompanyId}>
                          <option value="">Select Company</option>
                          {CompanyName.map(company => (

                            <option key={company._id} value={company._id}>{company.name}</option>
                          ))}
                        </Input>

                      </Col>
                      <Col md="3">
                        <Label htmlFor="suit">Select Property</Label>
                        <Input type="select" name="property" id="property" onChange={(e) => setSelectedPropertyId(e.target.value)}
                        value={SelectedPropertyId}>
                          <option value="">Select Property</option>
                          {properties.map(property => (

                            <option key={property._id} value={property._id}>{property.title}</option>
                          ))}
                        </Input>
                      </Col>
                      
                      <Col md="3">
                        <Label htmlFor="date">Start Date</Label>
                        <Input type="date" id="date" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                      </Col>
                      <Col md="3">
                        <Label htmlFor="date">End Date</Label>
                        <Input type="date" id="date" name="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                      </Col>
                    
                      <Col md="3">
                        
                        <Button type="submit" color="primary" className="mt-1 mr-1" onClick={(e) => handleGetInventoryOrders(e)}>Filter</Button>
                      </Col>
                    </FormGroup>
                  </Form>


                </CardHeader>
                <CardBody>

             
                
                {(orderLists.length) ?
                  <>

<Accordion defaultActiveKey="0">


                  
                {orderLists.map((order, i) => (

<Card key={i}>

<Accordion.Item eventKey={i.toString()}>
<Accordion.Header> Order By : {order.orderById.ownerFirstName} {order.orderById.ownerLastName}</Accordion.Header>
<Accordion.Body>
                  <div className="table-responsive" key={i}>

                 

                    <table className="table-striped table-bordered mb-0 reportcard-list-table">
                    
                      <thead>
                        <tr>
                          <td colSpan={8}>
                            Manager : {order.orderById.ownerFirstName} {order.orderById.ownerLastName}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Item Category</th>
                          <th scope="col">Item Name</th>
                          <th scope="col">Description</th>
                          <th scope="col">Order Quantity</th>
                          <th scope="col">Order Date</th>
                          <th scope="col">Sku No.</th>
                        </tr>
                      </thead>
                      <tbody>
                       
                        {order.newOrderItems.map((obj, categoryIndex) => (


                          <React.Fragment key={categoryIndex}>
                            {obj.items.map((item, itemIndex) => (
                              <tr key={itemIndex}>
                                <td>{(itemIndex + 1)}</td>
                                <td>
                                  {itemIndex == 0 ? (
                                    obj.category
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{item.name}</td>
                                <td>{item.description}</td>
                                <td>
                                  {item.itemQuantity}
                                </td>
                                
                                <td>
                                  {moment(order.orderDate).format('DD/MM/YYYY')}
                                </td>
                                <td>{item.sku}</td>
                              </tr>
                            ))}
                          </React.Fragment>


                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                        <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col">Total Items</td>
                          <td scope="col">5</td>
                          <td scope="col"></td>
                          <td scope="col"></td>
                          
                        </tr>
                      <tr>
                          {/* <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col"></td>
                          <td scope="col"></td> */}
                          
                          <td scope="col" colSpan={6}>
                            
                                Vendor: {order.vendorId.vendorName}
                              

                          </td>
                          <td scope="col" className="order-action-btns">
                          Status : {(order.approved) ? 'Approved' : 'Rejected'}

                          </td>
                        </tr>
                        
                      </tfoot>
                    </table>
                  </div>
                  </Accordion.Body>
                  </Accordion.Item>
                  </Card>

                ))}
                </Accordion>
                </>
                  :

                <>
                  <div className="text-center"><h4>No New Order available</h4></div>
                </>

              }


                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>


      
       
      


    </React.Fragment>
  )
};

export default PastOrders;
