import React, { useEffect, useState } from "react";
import { Card, Form, FormGroup, Input, Button, CardBody, Label, Modal, ModalHeader, ModalBody } from "reactstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "../../api/axios";
import { isLoading } from "../../../store/actions";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import getFormComponent from "./NotifyForm";

const GET_FORM_DOCUMENTS = "resident-notify/list/";
const POST_RESIDENT_NOTIY_FORM = "resident-notify/forms";


const ApplicantNotify = (props) => {
    const { applicantionId, handleClose } = props;
    const dispatch = useDispatch();

    const [documetsList, setDocumentsList] = useState([]);
    const [propertyInfo, setPropertyInfo] = useState({});
    const [mangerInfo, setMangerInfo] = useState({});
    const [formDocumentTitle, setFormDocumentTitle] = useState("");
    const [subTitles, setSubTitles] = useState([]);
    const [formDocumentIds, setFormDocumentIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [previewForm, setPreviewForm] = useState(false);
    const [formId, setFormId] = useState("");

    /**
     * Send notify email to applicant
     */
    const notifyEmail = async () => {
        try {
            if (window.confirm("Are you sure you want to send notification for selected forms ?")) {
                const data = {
                    applicationId: applicantionId,
                    documentsId: formDocumentIds
                }
                dispatch(isLoading(true));
                const responce = await axios.post(POST_RESIDENT_NOTIY_FORM, data);
                dispatch(isLoading(false));
                handleClose();
                toast.success("Notification send successfully.");
            }

        } catch (error) {
            dispatch(isLoading(false));
            toast.error("Some thing went wrong!");

        }
    }

    const getFormDocumentsList = async (applicantionId) => {
        try {
            const response = await axios.get(`${GET_FORM_DOCUMENTS}${applicantionId}`);
            if (response && response.data && response.data.status == 200) {
                setDocumentsList(response.data.data.docList);
                setPropertyInfo(response.data.data.propertyInfo);
                setMangerInfo(response.data.data.mangerInfo);
            }
        } catch (error) {
            console.log("get Form Documents List :=>", error)
        }
    }



    const handleOnChangeSelect = (title) => {
        if (title) {
            const _documetsList = documetsList.slice();
            const _checkList = _documetsList.find((doc) => (doc.title == title));
            const _formDocumentIds = _checkList.checkList.map((item) => (item.id))
            setFormDocumentTitle(title);
            setSubTitles(_checkList.checkList);
            setFormDocumentIds(_formDocumentIds);
            setSelectAll(true);

        } else {
            setFormDocumentTitle("");
            setSubTitles([]);
        }
    }

    const handleSelectCheck = (event, item) => {
        const _formDocumentIds = formDocumentIds.slice();
        const _index = _formDocumentIds.findIndex((doc) => (doc == item.id))
        if (_index !== -1) {
            _formDocumentIds.splice(_index, 1);
            setFormDocumentIds(_formDocumentIds);

        } else {
            _formDocumentIds.push(item.id);
            setFormDocumentIds(_formDocumentIds);
        }
        setSelectAll(false);
    }

    const handleSelectAll = (event) => {
        const { checked } = event.target;
        let _formDocumentIds = [];
        if (checked) {
            let _subTitles = subTitles.slice();
            _subTitles.map((item) => {
                _formDocumentIds.push(item.id);
            })
            setFormDocumentIds(_formDocumentIds);
            setSelectAll(true);

        } else {
            setSelectAll(false);
            setFormDocumentIds(_formDocumentIds);
        }
    }

    const handleModal = (data) => {
        setFormId(data.id)
        setPreviewForm(true);

    }

    useEffect(() => {
        if (applicantionId) {
            getFormDocumentsList(applicantionId);
        }
    }, [applicantionId])

    return (
        <>
            <Card className="text-center">
                <CardBody>
                    <Form>
                        <FormGroup tag="fieldset" className="modalInnner">
                            <h5 >
                                Select Form For Resident
                            </h5>
                            <FormGroup>
                                <Input
                                    type="select"
                                    onChange={(event) => { handleOnChangeSelect(event.target.value) }}
                                    value={formDocumentTitle}
                                >
                                    <option id="" value={""}>Select</option>
                                    {documetsList.length > 0 && documetsList.map((doc) => (
                                        <option key={doc.id} disabled={!doc.isActive} value={doc.title}>{doc.title}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            {subTitles.length > 0 && <FormGroup className="m-3">
                                <FormGroup check>
                                    <Input type="checkbox" checked={selectAll} onChange={(event) => { handleSelectAll(event) }} />
                                    <Label check>
                                        {"Select All".toUpperCase()}
                                    </Label>
                                </FormGroup>
                                {subTitles.length > 0 && subTitles.map((item, index) => (
                                    <FormGroup key={index} check>
                                        <Input type="checkbox" checked={formDocumentIds.findIndex((doc) => (doc == item.id)) !== -1 ? true : false} onChange={(event => { handleSelectCheck(event, item) })} />
                                        <Label check >
                                            {item.title}
                                        </Label>
                                        <Button color="none" onClick={() => handleModal(item)}>Preview</Button>
                                    </FormGroup>
                                ))}
                                <Button disabled={formDocumentIds.length > 0 ? false : true} color="primary" className=" ms-3" onClick={notifyEmail}>Send</Button>
                            </FormGroup>
                            }
                        </FormGroup>
                    </Form>
                </CardBody>
                {previewForm && <Modal isOpen={previewForm} toggle={() => setPreviewForm(!previewForm)} size="xl">
                    <ModalHeader toggle={() => { setPreviewForm(!previewForm) }}>
                        Form Preview
                    </ModalHeader>
                    <ModalBody>
                        {getFormComponent(formId, { mangerInfo: mangerInfo, propertyInfo: propertyInfo, readOnly: true })}
                    </ModalBody>
                </Modal>}
            </Card>
        </>
    )

}

export default ApplicantNotify;
