import React from "react";
import { Button, ButtonGroup, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { setFormData_3 } from "../../../store/leaseForm/actions";
import FormHeaderLogo from "./FormHeaderLogo";
import FormFooter from "./FormFooter";

import "./frmStyle.scss";

const MAX_NAME = 10;

const UTILITIES_DATA = [
  {
    title: "Water Supply",
    name: "water",
    value: "water"
  },
  {
    title: "Heat Supply",
    name: "heat",
    value: "heat"
  },
  {
    title: "Electricity",
    name: "electricity",
    value: "electricity"
  },
];

const APPLIANCES_DATA = [
  {
    title: "Refrigerator",
    name: "appliances",
    value: "Refrigerator"
  },
  {
    title: "Range/Stove",
    name: "appliances",
    value: "Range/Stove"
  },
  {
    title: "Dishwasher",
    name: "appliances",
    value: "Dishwasher"
  },
  {
    title: "Washer",
    name: "appliances",
    value: "Washer"
  },
  {
    title: "Dryer",
    name: "appliances",
    value: "Dryer"
  },
  {
    title: "Blinds",
    name: "appliances",
    value: "Blinds"
  },
];


const FormStep3 = ({ readOnly }) => {

  const { formData3 } = useSelector((state) => (state.leaseForm));

  const dispatch = useDispatch();

  const handleOnChange = (event, index) => {
    const { name, value } = event.target;
    const _familyPersons = formData3.familyPersons.slice();
    _familyPersons[index][name] = value;
    dispatch(setFormData_3({ ...formData3, familyPersons: _familyPersons }));
  }

  const handleAdd = () => {
    const _familyPersons = formData3.familyPersons.slice();
    _familyPersons.push({
      name: "",
      relationship: "",
      age: ""
    })
    dispatch(setFormData_3({ ...formData3, familyPersons: _familyPersons }));
  }

  const handleRemove = () => {
    const _familyPersons = formData3.familyPersons.slice();
    _familyPersons.pop();
    dispatch(setFormData_3({ ...formData3, familyPersons: _familyPersons }));
  }

  const handleUtititiy = (event) => {
    const { name, checked } = event.target;
    let _utilities = { ...formData3.utilities, [name]: checked };
    dispatch(setFormData_3({ ...formData3, utilities: _utilities }));
  }

  const handleAppliances = (event) => {
    const { value } = event.target;
    let _appliances = formData3.appliances.slice();
    const _index = _appliances.findIndex((doc) => (doc === value))
    if (_index === -1) {
      _appliances.push(value);
    } else {
      _appliances.splice(_index, 1);
    }
    dispatch(setFormData_3({ ...formData3, appliances: _appliances }));
  }

  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <FormHeaderLogo />
            </div>
          </div>
          <div className="detailProperty from2">
            <p>
              The tenant agrees to pay, as when due, the charges to his premises
              for the following utilities and service for Electricity: Service
              Provider Account # must be provided to Management at the time of
              moving in.
            </p>


          </div>

          <div className="midContent">
            <strong>UTILITIES AND SERVICE</strong>
            <br />
            <p>
              The tenant agrees to pay, as when due, the charges to his premises
              for the following utilities and service for electricity, gas and
              water. Service Provider Account # must be provided to Management
              at the time of moving in.
            </p>
            <br />
            <Form>
              {UTILITIES_DATA.map((item, index) => (<FormGroup key={index}
                check
                inline
              >
                <Input
                  disabled={readOnly}
                  name={item.name}
                  onChange={handleUtititiy}
                  checked={formData3.utilities[item.value]}
                  type="checkbox"
                />
                <Label check>
                  {item.title}
                </Label>
              </FormGroup>))}
            </Form>
            <br />
            {/* <p>
              Drop box: <br />
              Heat and Water, means electricity will show on lease, <br />
              if they click on water, then heat and elecricitu show on lease{" "}
              <br />
              If they dont click on anything, all 3 gas water and electricity
              comes up.))))) from diff lease to be added here in form on
              dropdown
            </p> */}
            <strong>APPLIANCES</strong>
            <br />
            {!readOnly && <><Form>
              {APPLIANCES_DATA.map((item, index) => (<FormGroup key={index}
                check
                inline
              >
                <Input
                  disabled={readOnly}
                  name={item.name}
                  onChange={handleAppliances}
                  value={item.value}
                  checked={formData3?.appliances?.find((doc) => (doc == item.value)) ? true : false}
                  type="checkbox"
                />
                <Label check>
                  {item.title}
                </Label>
              </FormGroup>))}
            </Form>
              <br /></>}

            The Tenant agrees to keep clean in good condition, all assets,
            including appliances, furnishings and equipment contained at the
            time of possession in the Premises, which specifically includes,
            without limitation: {formData3?.appliances?.join(", ")}.

            <br />
            <strong>
              THER OCCUPANTS***(mandatory)-- put N/A also (Tenant will fill)
            </strong>
            <br />
            The tenant agrees that in addition to the tenant, the premises may
            be occupied only by the following persons:
          </div>
          <div className=" tenantFrm cmnfrmstyle midContent">
            <strong>Description</strong>
            {!readOnly && <ButtonGroup className="mx-3">
              <Button color="info" disabled={formData3?.familyPersons?.length < MAX_NAME ? false : true} onClick={handleAdd}>Add</Button>
              <Button color="danger" disabled={formData3?.familyPersons?.length <= 1 ? true : false} onClick={handleRemove}>Remove</Button>
            </ButtonGroup>}

            <br />
            <Form className="mb-2">
              {formData3?.familyPersons?.length > 0 && formData3?.familyPersons?.map((item, index) => (
                <Row key={item} className="mb-2">
                  <Col md={6}>
                    <FormGroup>
                      <Label>Name</Label>
                      <Input readOnly={readOnly} placeholder="Name" type="text" name="name" value={item.name} onChange={(event) => { handleOnChange(event, index) }} />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label>Relationship</Label>
                      <Input readOnly={readOnly} placeholder="Relationship" type="text" name="relationship" value={item.relationship} onChange={(event) => { handleOnChange(event, index) }} />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label>Age</Label>
                      <Input readOnly={readOnly} placeholder="Age" type="number" name="age" value={item.age} onChange={(event) => { handleOnChange(event, index) }} />
                    </FormGroup>
                  </Col>
                </Row>
              ))}

            </Form>
            <br />
            <p>
              Together with any natural increase in the tenant’s family, but not
              to exceed a total of 2 Adult unless the landlord consents in
              writing to the occupation of the premises by some other or
              additional person or persons. Each approved additional adult will
              be charged $50.00 extra per month.
            </p>
            <p>
              <strong>CONDITION REPORT</strong>
              <br />
              Prior to the tenant taking possession of the premises, the
              landlord and the tenant shall complete and execute an entry
              condition report detailing the condition of the premises, fixtures
              and appliances, and the said report shall be conclusive evidence
              of the condition of the premises, fixtures, and appliances, an
              exit report shall be similarly completed and executed and all
              charges for repair of damages and cleaning of the premises will be
              based on these reports. When Tenant is moving out- they are
              responsible to get all carpets steam cleaned and provide
              Management with a proof of receipt.
              <br />
              <strong>TENANT INSURANCE</strong> <br />
              The Tenant promises to hold a valid insurance policy, which
              includes the Tenant’s contents in the Home, including contents in
              vehicle. The policy is required to insure the Tenants and any
              guests against loss or damages, including personal injury and
              death. The Tenant promises to carry general comprehensive
              liability insurance to a minimum of $2 Million Liability coverage.
              Must be purchased through Nuera insurance online. **Proof of
              current and accurate Tenant’s Insurance is required before move in
              will be allowed** Tenant Insurance is a mandatory and can lead to
              eviction if tenant does not have it
            </p>
            <FormFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStep3;
