import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Row,
  Col,
  Table,
  Input,
  Button,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledCarousel,
} from "reactstrap";
import { Carousel } from "react-bootstrap";
import Logo_p from "../../assets/images/frmlogo.png";
// import capture from "../../assets/images/capture.png";
// import { Carousel } from "@trendyol-js/react-carousel";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "../api/axios";
import BreadcrumbV3 from "../../components/Common/BreadcrumbV3";
import SignaturePad from "../../components/Common/SignaturePad";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";
import { SERVER_URL } from "../ServerLink";
import moment from "moment";

const UPPERTABLECONTENT = ["", "IN", "IN CONDITION", "OUT", "OUT CONDITION"];
const UPPERTABLECONTENTSECOND = [
  {
    width: "20%",
    label: "Bathroom Main",
  },
  {
    width: "10%",
    label: "Move in",
  },
  {
    width: "20%",
    label: "Comments",
  },
  {
    width: "10%",
    label: " Upload",
  },
  {
    width: "10%",
    label: "Move Out",
  },

  {
    width: "20%",
    label: "Comments",
  },
  {
    width: "10%",
    label: "Upload",
  },
];

const MOVINOUT_DATA = [
  {
    key: "bathroomMain",
    title: "Bathroom Main",
    data: [
      {
        id: "a_1",
        title: "Ceiling, Walls, Trim",
      },
      {
        id: "a_2",
        title: "Closets, Doors",
      },
      {
        id: "a_3",
        title: "FlorCovering",
      },
      {
        id: "a_4",
        title: "Ceiling/Fan",
      },
      {
        id: "a_5",
        title: "Electrical Fixtures",
      },
      {
        id: "a_6",
        title: "Windows, Screens",
      },
      {
        id: "a_7",
        title: "Toilet",
      },
      {
        id: "a_8",
        title: "Bathtub, Shower",
      },
      {
        id: "a_9",
        title: "Sink, Vanity, Mirrors",
      },
      {
        id: "a_10",
        title: "Others________",
      },
    ],
  },
  {
    key: "miscellaneous",
    title: "Miscellaneous",
    data: [
      {
        id: "b_1",
        title: "Smoke Detector & Battery",
      },
      {
        id: "b_2",
        title: "Others________",
      },
    ],
  },
  {
    key: "houseDuplex",
    title: "House/Duplex",
    data: [
      {
        id: "c_1",
        title: "Furmance",
      },
      {
        id: "c_2",
        title: "Hot Water Heater",
      },
      {
        id: "c_3",
        title: "Balcony",
      },
      {
        id: "c_4",
        title: "Patio/Screen Doors",
      },
      {
        id: "c_5",
        title: "Store Room",
      },
      {
        id: "c_6",
        title: "Basement",
      },
      {
        id: "c_7",
        title: "Yard, Fence",
      },
      {
        id: "c_8",
        title: "Garage",
      },
      {
        id: "c_9",
        title: "Washer, Dryer",
      },
      {
        id: "c_10",
        title: "Others Appliances",
      },
      {
        id: "c_11",
        title: "Others________",
      },
    ],
  },
];

const GET_MIMO_INFO = "mastersheet/mov-in-out/info/";
const POST_MIMO_INFO = "mastersheet/mov-in-out/info";
const POST_MIMO_UPLOAD = "mastersheet/mov-in-out/uploadFile";
const POST_DELETE_UPLOAD = "mastersheet/mov-in-out/deleteFile";

const MoveInMoveOut = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleClose = () => setModal(false);
  const [chekPointData, setChekPointData] = useState({
    bathroomMain: [],
    miscellaneous: [],
    houseDuplex: [],
  });
  const [propertyInfo, setPropertyInfo] = useState({});
  const [managerInfo, setManagerInfo] = useState({});
  const [customerInfo, setCustomerInfo] = useState({});

  const [moveInSign, setMoveIn] = useState({
    isAggree: false,
    name: "",
    signature: null,
    date: Date.now(),
  });
  const [moveOutSign, setMoveOut] = useState({
    isAggree: false,
    name: "",
    signature: null,
    date: Date.now(),
  });

  const handleModal = (data, key, checkpoint, fieldname) => {
    setModal(true);
    const _images = data.map((doc, index) => {
      return {
        key: key,
        src: SERVER_URL + "/move-in-move-out/" + doc,
        checkpoint: checkpoint,
        fieldname: fieldname,
        real_src: doc,
      };
    });
    setImages(_images);
  };

  const handleOnChangeCheckPoint = (event, key, checkPoint) => {
    const { name, value } = event.target;
    let _chekPointData = { ...chekPointData };
    let dataArry = _chekPointData[checkPoint];
    const index = dataArry.findIndex((doc) => doc.key == key);
    if (index !== -1) {
      dataArry[index] = { ...dataArry[index], ...{ [name]: value } };
    } else {
      dataArry.push({
        key: key,
        [name]: value,
      });
    }
    _chekPointData[checkPoint] = dataArry;
    setChekPointData(_chekPointData);
  };

  const getInfo = async (id) => {
    try {
      const responce = await axios.get(GET_MIMO_INFO + id);
      if (responce && responce.data && responce.data.status === 200) {
        const _result = responce.data.data;
        const { propertyInfo, managerInfo, mimo, customerInfo } = _result;

        if (Object.keys(mimo).length > 0) {
          const { bathroomMain, miscellaneous, houseDuplex, moveIn, moveOut } =
            mimo;
          setChekPointData({ bathroomMain, miscellaneous, houseDuplex });
          setMoveIn(moveIn);
          setMoveOut(moveOut);
        }
        if (propertyInfo) {
          setPropertyInfo(propertyInfo);
        }
        if (managerInfo) {
          setManagerInfo(managerInfo);
        }
        if (customerInfo) {
          setCustomerInfo(customerInfo);
        }
      }
      dispatch(isLoading(false));
    } catch (error) {
      toast.error("No Data Found");
    }
  };

  const getValue = (dataArry, key, name) => {
    const index = dataArry.findIndex((doc) => doc.key == key);
    if (index !== -1) {
      if (name == "moveInFile" || name == "moveOutFile") {
        if (dataArry[index][name]?.length <= 0) {
          return "";
        }
      }
      return dataArry[index][name];
    }
    return "";
  };

  const hadnleSubmit = async () => {
    try {
      dispatch(isLoading(true));
      const body = {
        userId: id,
        bathroomMain: chekPointData.bathroomMain,
        miscellaneous: chekPointData.miscellaneous,
        houseDuplex: chekPointData.houseDuplex,
        moveIn: moveInSign,
        moveOut: moveOutSign,
      };
      const res = await axios.post(POST_MIMO_INFO, body);
      if (res && res.data && res.data.status == 201) {
        toast.success("Submit Successfully !");
      }
      dispatch(isLoading(false));
      setTimeout(() => window.close(), 3000);
    } catch (error) {
      toast.error("Something went wrong!");
      dispatch(isLoading(false));
    }
  };

  const handleUploadFile = async (event, key, checkPoint) => {
    try {
      dispatch(isLoading(true));
      const { name, files } = event.target;
      const formData = new FormData();
      console.log("FormData=>>>", files);

      Array.from(files).forEach((file) => {
        formData.append(name, file);
      });
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
          userid: id,
          checkpoint: checkPoint,
          key: key,
        },
      };
      const res = await axios.post(POST_MIMO_UPLOAD, formData, options);
      if (res && res.data && res.data.status == 201) {
        toast.success("File Uploaded Successfully !");
        const responce = await axios.get(GET_MIMO_INFO + id);
        console.log("Response After Upload=>>>", responce);
        if (responce && responce.data && responce.data.status === 200) {
          const _result = responce.data.data;
          const { mimo } = _result;

          if (Object.keys(mimo).length > 0) {
            const {
              bathroomMain,
              miscellaneous,
              houseDuplex,
              moveIn,
              moveOut,
            } = mimo;
            setChekPointData({ bathroomMain, miscellaneous, houseDuplex });
            setMoveIn(moveIn);
            setMoveOut(moveOut);
          }
        }
        // const value = res.data.data;
        // let _chekPointData = { ...chekPointData }
        // let dataArry = _chekPointData[checkPoint];
        // const index = dataArry.findIndex((doc) => (doc.key == key))
        // if (index !== -1) {
        //   dataArry[index] = { ...dataArry[index], ...{ [name]: value } }
        // } else {
        //   dataArry.push({
        //     "key": key,
        //     [name]: value
        //   })
        // }
        // _chekPointData[checkPoint] = dataArry
        // setChekPointData(_chekPointData)
      }
      dispatch(isLoading(false));
    } catch (error) {
      toast.error("Something went wrong!");
      dispatch(isLoading(false));
    }
  };

  useEffect(() => {
    if (id) {
      getInfo(id);
    }
  }, [id]);

  const handleDynamicSecondThead = (value) => {
    const data = [...UPPERTABLECONTENTSECOND];
    data[0]["label"] = value;
    return data;
  };

  const upperTableUi = (title, secondTitle) => {
    return (
      <>
        <tr className="bg-dark text-white text-center">
          {title.map((data, idx) => (
            <th
              key={idx}
              scope="col"
              colSpan={[2, 4].includes(idx) ? 2 : 0}
              className="p-1"
            >
              {data}
            </th>
          ))}
        </tr>
        <tr className="font-weight-bold text-center">
          {secondTitle.map((data, idx) => (
            <th key={idx} scope="col" className="p-1" width={data?.width}>
              {data?.label}
            </th>
          ))}
        </tr>
      </>
    );
  };

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const deleteItem = async () => {
    setModal(false);
    dispatch(isLoading(true));
    try {
      const options = {
        userid: id,
        checkpoint: images[activeIndex].checkpoint,
        key: images[activeIndex].key,
        path: images[activeIndex].real_src,
        fieldname: images[activeIndex].fieldname,
      };
      const res = await axios.post(POST_DELETE_UPLOAD, options);
      if (res && res.data && res.data.status == 201) {
        toast.success("File Deleted Successfully !");
        const value = res.data.data;
        let _chekPointData = { ...chekPointData };
        let dataArry = _chekPointData[images[activeIndex].checkpoint];
        const index = dataArry.findIndex(
          (doc) => doc.key == images[activeIndex].key
        );
        if (index !== -1) {
          // eslint-disable-next-line no-restricted-globals
          dataArry[index] = { ...dataArry[index], ...{ [name]: value } };
        } else {
          dataArry.push({
            key: images[activeIndex].key,
            // eslint-disable-next-line no-restricted-globals
            [name]: value,
          });
        }
        _chekPointData[images[activeIndex].checkPoint] = dataArry;
        setChekPointData(_chekPointData);
        getInfo(id);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      dispatch(isLoading(false));
    }
  };
  console.log(images);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Rentdigicare | Move In Move Out</title>
      </MetaTags>
      <div className="container-fluid fontSize12">
        <BreadcrumbV3
          title={<>Move In Move Out Form</>}
          breadcrumbItems={[
            {
              item: "Home",
              link: "/dashboard",
            },
            {
              item: "Master Sheet",
              link: "/master_table",
            },
            {
              item: "Tenant Information",
              link: `/tenant_info/${id}`,
            },
            {
              item: "Move In Move Out Form",
              link: `/move-in-move-out/${id}`,
            },
          ]}
        />
        <Row>
          <div style={{ position: "relative" }} className="mb-3">
            <img
              style={{
                top: "56px",
                left: "10px",
                height: "100px",
                width: "100px",
              }}
              src={
                propertyInfo && propertyInfo?.logoUrl
                  ? "data:image/jpeg;base64," + propertyInfo.logoUrl
                  : Logo_p
              }
            />
          </div>
          <strong>
            {propertyInfo && propertyInfo?.title ? propertyInfo.title : ""}
          </strong>
          <p>
            Tenant Name :{" "}
            {customerInfo && customerInfo.firstname
              ? customerInfo.firstname
              : ""}{" "}
            {customerInfo && customerInfo.lastname ? customerInfo.lastname : ""}
          </p>
          <p>
            Suite No. :{" "}
            {customerInfo && customerInfo.firstname ? customerInfo.suite : ""}
          </p>
          <div></div>
          <Col xl={12} style={{ marginTop: "50px" }}>
            {MOVINOUT_DATA.map((doc, docIndex) => (
              <div key={docIndex} className="table-responsive mb-5">
                <Table className="table-bordered mb-0">
                  <thead>
                    {upperTableUi(
                      UPPERTABLECONTENT,
                      handleDynamicSecondThead(doc.title)
                    )}
                  </thead>
                  <tbody>
                    {doc.data.map((item, index) => (
                      <tr key={index}>
                        <td className="p-1">{item["title"]}</td>
                        <td className="p-1">
                          <Input
                            type="select"
                            name="moveIn"
                            className="noBorder"
                            value={getValue(
                              chekPointData[doc.key],
                              item.id,
                              "moveIn"
                            )}
                            onChange={(event) => {
                              handleOnChangeCheckPoint(event, item.id, doc.key);
                            }}
                          >
                            <option value="">Select</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </Input>
                        </td>
                        <td className="p-1">
                          <Input
                            type="textarea"
                            name="moveInComment"
                            value={getValue(
                              chekPointData[doc.key],
                              item.id,
                              "moveInComment"
                            )}
                            placeholder="Commnet"
                            onChange={(event) => {
                              handleOnChangeCheckPoint(event, item.id, doc.key);
                            }}
                          />
                        </td>
                        <td className="p-1">
                          <Input
                            id="exampleFile"
                            name="moveInFile"
                            accept=".png, .jpg, .jpeg"
                            multiple={true}
                            type="file"
                            onChange={(event) => {
                              handleUploadFile(event, item.id, doc.key);
                            }}
                          />
                          {getValue(
                            chekPointData[doc.key],
                            item.id,
                            "moveInFile"
                          ) ? (
                            <Button
                              color="primary"
                              className="margin5Top"
                              onClick={() => {
                                handleModal(
                                  getValue(
                                    chekPointData[doc.key],
                                    item.id,
                                    "moveInFile"
                                  ),
                                  item.id,
                                  doc.key,
                                  "moveInFile"
                                );
                              }}
                            >
                              View
                            </Button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="p-1">
                          <Input
                            type="select"
                            className="noBorder"
                            name="moveOut"
                            value={getValue(
                              chekPointData[doc.key],
                              item.id,
                              "moveOut"
                            )}
                            onChange={(event) => {
                              handleOnChangeCheckPoint(event, item.id, doc.key);
                            }}
                          >
                            <option>Select</option>
                            <option>Yes</option>
                            <option>No</option>
                          </Input>
                        </td>
                        <td className="p-1">
                          <Input
                            type="textarea"
                            name="moveOutComment"
                            value={getValue(
                              chekPointData[doc.key],
                              item.id,
                              "moveOutComment"
                            )}
                            placeholder="Commnet"
                            onChange={(event) => {
                              handleOnChangeCheckPoint(event, item.id, doc.key);
                            }}
                          />
                        </td>
                        <td className="p-1">
                          <Input
                            id="exampleFile"
                            name="moveOutFile"
                            accept=".png, .jpg, .jpeg"
                            multiple={true}
                            type="file"
                            onChange={(event) => {
                              handleUploadFile(event, item.id, doc.key);
                            }}
                          />
                          {getValue(
                            chekPointData[doc.key],
                            item.id,
                            "moveOutFile"
                          ) ? (
                            <Button
                              color="primary"
                              className="margin5Top"
                              onClick={() => {
                                handleModal(
                                  getValue(
                                    chekPointData[doc.key],
                                    item.id,
                                    "moveOutFile"
                                  ),
                                  item.id,
                                  doc.key,
                                  "moveOutFile"
                                );
                              }}
                            >
                              View
                            </Button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ))}

            {/* <div className="table-responsive mb-5">
              <Table className="table-bordered mb-0">
                <thead>
                  <tr>
                    <th className="text-white bg-dark p-1">Keys</th>
                    <th className="p-1">
                      # of keys issued for : Suite__Blog__Entr__Garage__Mail__
                    </th>
                    <th className="p-1">
                      # of keys issued for : Suite__Blog__Entr__Garage__Mail__
                    </th>
                  </tr>
                </thead>
                <tbody></tbody>
              </Table>
            </div> */}

            <div className="mb-5">
              <div className="text-white bg-dark text-center">IN CONDITION</div>
              <div className="p-2" style={{ border: "1px solid black" }}>
                <div>
                  {/* <p>
                    Inspections should be conducted when the permises are
                    vacant unless the landord and tenant or their argents
                    otherwise agree.
                  </p>
                  <h6>
                    WHEN THE LANDLORD AND TENANTINSPECT THE PREMISES TOGETHER:
                    (complete #1 and either #2a, B or c)
                  </h6>
                  <p>
                    1) The inspection of the premises was done on
                    ______________by_____________and by____________ <br />
                    Signature of Landlord or Landlord's Agent: _____________
                  </p>
                  <p>
                    2) a) I. __________. I agree that this report fairly
                    represents the condition of the premises
                    __________________ <br />
                    OR b) I. __________. disagree that this report fairly
                    represents the condition of the premises for the following
                    reasons __________________ <br />
                    ______________________________________________________________________________________________________________{" "}
                    <br />
                    Or c) The tenant or Tenants's Agent all the inspection
                    refused to sign Tenant's Statement
                    __________________________________ <br />
                  </p>

                  <h6>WHEN THE LANDLORD INSPECTS WITHOUT THE TENANT:</h6>
                  <p>
                    3). The inspection of the premise was done on
                    _______________ by _____________ without the Tenant or
                    Tenant's Agent being present.
                    <br />
                    Signature of landlord or Landlord's Agent:
                    ____________________________ TENANT FORWARDING
                    ADDRESS__________________________{" "}
                  </p> */}

                  <Row>
                    <Col md={12}>
                      <Input
                        type="checkbox"
                        className="mx-3"
                        checked={moveInSign?.isAggree}
                        onChange={(event) => {
                          setMoveIn({
                            ...moveInSign,
                            isAggree: event.target.checked,
                          });
                        }}
                      />
                      <Label>
                        {" "}
                        I agree that this report fairly represents the condition
                        of the premises
                      </Label>
                    </Col>
                    <Col md={12}>
                      <Label>
                        INSPECTION DATE :{" "}
                        {moment(moveInSign?.date).format("DD/MM/YYYY")}
                      </Label>
                      <br />
                    </Col>
                    <Col md={6}>
                      <Label>MANAGER</Label>
                      <br />
                      <FormGroup row>
                        <Label sm={2}>Name </Label>
                        <Col sm={6}>
                          <span>
                            {managerInfo.firstname
                              ? managerInfo.firstname
                              : "" + " " + managerInfo.lastname
                              ? managerInfo.lastname
                              : ""}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={2}>Signature </Label>
                        <Col sm={10}>
                          {managerInfo.signature ? (
                            <SignaturePad
                              readOnly={true}
                              sign={managerInfo.signature}
                            />
                          ) : (
                            <span>**Please provide the manager signature</span>
                          )}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <Label>TENANT</Label>
                      <br />
                      <FormGroup row>
                        <Label for="moveInName" sm={2}>
                          Name{" "}
                        </Label>
                        <Col sm={6}>
                          <Input
                            id="moveInName"
                            type="text"
                            disabled={moveInSign?.isAggree ? false : true}
                            value={moveInSign?.name}
                            onChange={(event) => {
                              setMoveIn({
                                ...moveInSign,
                                name: event.target.value,
                              });
                            }}
                          />
                          <br />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={2}>Signature </Label>
                        <Col sm={10}>
                          <SignaturePad
                            readOnly={moveInSign?.isAggree ? false : true}
                            sign={moveInSign?.signature}
                            sendSignature={(sign) => {
                              setMoveIn({ ...moveInSign, signature: sign });
                            }}
                          />{" "}
                          <br />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div className="mb-2">
              <div className="text-white bg-dark text-center">
                OUT CONDITION
              </div>
              <div className="p-2" style={{ border: "1px solid black" }}>
                <div>
                  {/* <p>
                    Inspections should be conducted when the permises are
                    vacant unless the landord and tenant or their argents
                    otherwise agree.
                  </p>
                  <h6>
                    WHEN THE LANDLORD AND TENANTINSPECT THE PREMISES TOGETHER:
                    (complete #1 and either #2a, B or c)
                  </h6>
                  <p>
                    1) The inspection of the premises was done on
                    ______________by_____________and by____________ <br />
                    Signature of Landlord or Landlord's Agent: _____________
                  </p>
                  <p>
                    2) a) I. __________. I agree that this report fairly
                    represents the condition of the premises
                    __________________ <br />
                    OR b) I. __________. disagree that this report fairly
                    represents the condition of the premises for the following
                    reasons __________________ <br />
                    ______________________________________________________________________________________________________________{" "}
                    <br />
                    Or c) The tenant or Tenants's Agent all the inspection
                    refused to sign Tenant's Statement
                    __________________________________ <br />
                  </p>

                  <h6>WHEN THE LANDLORD INSPECTS WITHOUT THE TENANT:</h6>
                  <p>
                    3). The inspection of the premise was done on
                    _______________ by _____________ without the Tenant or
                    Tenant's Agent being present.
                    <br />
                    Signature of landlord or Landlord's Agent:
                    ____________________________ TENANT FORWARDING
                    ADDRESS__________________________{" "}
                  </p> */}
                  <Row>
                    <Col md={12}>
                      <Input
                        type="checkbox"
                        className="mx-3"
                        checked={moveOutSign?.isAggree}
                        onChange={(event) => {
                          setMoveOut({
                            ...moveOutSign,
                            isAggree: event.target.checked,
                          });
                        }}
                      />
                      <Label>
                        {" "}
                        I agree that this report fairly represents the condition
                        of the premises
                      </Label>
                    </Col>
                    <Col md={12}>
                      <Label>
                        INSPECTION DATE :{" "}
                        {moment(moveOutSign?.date).format("DD/MM/YYYY")}
                      </Label>
                      <br />
                    </Col>
                    <Col md={6}>
                      <Label>MANAGER</Label>
                      <br />
                      <FormGroup row>
                        <Label sm={2}>Name </Label>
                        <Col sm={6}>
                          <span>
                            {managerInfo.firstname
                              ? managerInfo.firstname
                              : "" + " " + managerInfo.lastname
                              ? managerInfo.lastname
                              : ""}
                          </span>
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={2}>Signature </Label>
                        <Col sm={10}>
                          {managerInfo.signature ? (
                            <SignaturePad
                              readOnly={true}
                              sign={managerInfo.signature}
                            />
                          ) : (
                            <span>**Please provide the manager signature</span>
                          )}
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <Label>TENANT</Label>
                      <br />
                      <FormGroup row>
                        <Label for="moveOutName" sm={2}>
                          Name{" "}
                        </Label>
                        <Col sm={6}>
                          <Input
                            id="moveOutName"
                            type="text"
                            disabled={moveOutSign?.isAggree ? false : true}
                            value={moveOutSign?.name}
                            onChange={(event) => {
                              setMoveOut({
                                ...moveOutSign,
                                name: event.target.value,
                              });
                            }}
                          />
                          <br />
                        </Col>
                      </FormGroup>
                      <FormGroup row>
                        <Label sm={2}>Signature </Label>
                        <Col sm={10}>
                          <SignaturePad
                            readOnly={moveOutSign?.isAggree ? false : true}
                            sign={moveOutSign?.signature}
                            sendSignature={(sign) => {
                              setMoveOut({ ...moveOutSign, signature: sign });
                            }}
                          />{" "}
                          <br />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            {/* <div className="d-flex justify-content-end align-items-center mb-5">
              <small className="mx-4 font-weight-bold">
                White Copy-Landord's
              </small>
              <small className="mx-4 font-weight-bold">
                Yellow Copy-Tenant's Move-Out
              </small>
              <small className="font-weight-bold">
                Pink Copy-Tenant's Move-In
              </small>
            </div> */}
          </Col>
          <div style={{ position: "relative" }} className="mb-3"></div>
        </Row>
      </div>
      <Row>
        <Col className="text-center">
          <Button
            color="primary"
            onClick={() => {
              hadnleSubmit();
            }}
          >
            Submit
          </Button>
        </Col>
      </Row>

      {modal && (
        <Modal
          size="lg"
          className="modal-height"
          isOpen={modal}
          toggle={toggleClose}
          transparent={true}
        >
          {/* <ModalHeader toggle={toggleClose}>Document Upload</ModalHeader> */}
          <ModalBody>
            <div className="modalInner">
              <Row>
                <Col md={12} className="mt-3">
                  <Carousel activeIndex={activeIndex} onSelect={handleSelect}>
                    {images.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={image.src}
                          alt={`Slide ${index + 1}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                deleteItem();
              }}
            >
              Delete
            </Button>
            <Button color="danger" onClick={toggleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default MoveInMoveOut;
