import React, { useState } from "react"
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { useSelector } from "react-redux";

const FormHeaderLogo = () => {
  const { propertyInfo } = useSelector((state) => (state.leaseForm));
  const [mrkk, setImgUrl] = useState("");
  if (propertyInfo.logoUrl) {
    //   var kk = `${SERVER_URL}/${propertyInfo.logoUrl}`;
    var kk = `${propertyInfo.logoUrl}`;
    let encoded = base64_encode(kk);
    var imgData = 'data:image/jpeg;base64,' + encoded;
  }
  const loadImage = async (url) => {
    if (url != undefined) {
      setImgUrl(url);
    }
  }

  //loadImage(kk);

  // const myStyle = {
  //   backgroundImage: "url(" + mrkk + ")",
  //   height: '20vh',
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   width: '130px'
  // };
  // <span style={myStyle}>
  // </span>

  //return <div style={myStyle} ></div>
  // <img style={myStyle} alt="" />

  return <img src={'data:image/jpeg;base64,' + `${propertyInfo.logoUrl}`} alt="" />
}

export default FormHeaderLogo
