import React from "react";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormStep5 from "./FormStep5";
import FormStep6 from "./FormStep6";
import FormStep7 from "./FormStep7";

const Preview = () => {
    return (
        <>
            <FormStep1 readOnly={true} />
            <hr />
            <FormStep2 readOnly={true} />
            <hr />
            <FormStep3 readOnly={true} />
            <hr />
            <FormStep4 />
            <hr />
            <FormStep5 />
            <hr />
            <FormStep6 />
            <hr />
            <FormStep7 readOnly={true} />
        </>
    )

}

export default Preview;