import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../api/axios";
import jwt_decode from "jwt-decode";
import {
  Modal,
  Label,
  Form,
  Input,
  ModalFooter,
  ModalBody,
  Button,
  ModalHeader,
} from "reactstrap";
import { toast } from "react-toastify";

const DOCUMENT_DOWNLOAD_URL = "/property/download-document";
const UPADET_PROPERTY_URL = "/layout/update";
export default function LayoutMain(props) {
  const [modal_large, setmodal_large] = useState(false);
  const [modal_large1, setmodal_large1] = useState(false);
  const [modal_large2, setmodal_large2] = useState(false);

  const [showDeleteDialoge, setShowDeleteDialoge] = useState(false);
  const [layoutID, setLayoutID] = useState("");
  const decode = jwt_decode(window.localStorage.getItem("accessToken"));

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function tog_large1() {
    setmodal_large1(!modal_large1);
    removeBodyCss();
  }
  function tog_large2() {
    setmodal_large2(!modal_large2);
    removeBodyCss();
  }

  const [col5, setcol5] = useState(false);
  const t_col5 = () => {
    setcol5(!col5);
  };

  async function deleteProperty() {
    try {
      const response = await axios.delete(
        `/layout/delete?layoutOf=${decode.id}&layoutID=${layoutID}`
      );
      if (response.data.status === 200) {
        toast.success(response.data.message);
        setLayoutID("");
        setShowDeleteDialoge(false);
        props.refresh();
      } else {
        toast.warning(response.data.message);
      }
    } catch (error) {
      // visible error
      console.log(error);
    }
  }

  // update layout
  const [title, setTitle] = useState("");
  const [targetRent, setTargetRent] = useState("");
  const [showError, setshowError] = useState(false);
  const [showError2, setshowError2] = useState(false);

  async function updateLayout(event) {
    event.preventDefault();

    if (title == "") {
      setshowError(true);
    } else {
      setshowError(false);
    }

    if (targetRent == "") {
      setshowError2(true);
    } else {
      setshowError2(false);
    }
    if (title != "" && targetRent != "") {
      try {
        const response = await axios.post(UPADET_PROPERTY_URL, {
          layoutName: title,
          targetRent: targetRent,
          id: layoutID,
        });

        if (response.data.status === 201) {
          setLayoutID("");
          setTitle("");
          setTargetRent("");
          toast.success("Update succesfully.");
          tog_large();
          props.refresh();
        }
      } catch (error) {
        toast.success("Something went wrong !");

        console.log(error); // TODO Proper Error
      }
    }
  }

  return (
    <tr>
      <td>{props.number}</td>
      <td>{props.title}</td>
      <td>{props.targetRent}</td>
      <td>
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => {
                setLayoutID(props.id);
                setTitle(props.title);
                setTargetRent(props.targetRent);
                tog_large();
              }}
              data-toggle="modal"
              data-target=".bs-example-modal-lg"
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={async (e) => {
                e.preventDefault();
                setLayoutID(props.id);
                setShowDeleteDialoge(!showDeleteDialoge);
              }}
            ></i>
          </Link>

          <Modal
            isOpen={showDeleteDialoge}
            toggle={() => {
              setLayoutID("");
              setShowDeleteDialoge(!showDeleteDialoge);
            }}
          >
            <ModalHeader
              toggle={() => {
                setLayoutID("");
                setShowDeleteDialoge(!showDeleteDialoge);
              }}
            >
              Confirm
            </ModalHeader>
            <ModalBody>Are you sure you want to delete this?</ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  setLayoutID("");
                  setShowDeleteDialoge(!showDeleteDialoge);
                }}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={async () => await deleteProperty()}
              >
                Delete
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            size="lg"
            isOpen={modal_large}
            toggle={() => {
              tog_large();
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabel">
                Update Layout
              </h5>
              <button
                onClick={() => {
                  setmodal_large(false);
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-title-input"
                      >
                        Layout Name *
                      </Label>
                      <Input
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        id="formrow-name-input"
                      />
                      {showError ? (
                        <h6 style={{ color: "red" }}>
                          Layout Name is required!
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-title-input"
                      >
                        Target Rent *
                      </Label>
                      <Input
                        value={targetRent}
                        onChange={(e) => {
                          setTargetRent(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                        id="formrow-name-input"
                      />
                      {showError2 ? (
                        <h6 style={{ color: "red" }}>
                          Target Rent is required!
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        onClick={updateLayout}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>
        </div>
      </td>
    </tr>
  );
}
