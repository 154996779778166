import React from "react";
import Logo_p from "../../../../../assets/images/frmlogo.png";
import { Col, Form, Input, Label, Row } from "reactstrap";
import SignaturePad from "../../../../../components/Common/SignaturePad";
import { useDispatch, useSelector } from "react-redux";
import "../notifyformStyle.scss";
import { setSURFACE_PARKING } from "../../../../../store/notifyForm/actions";

const SurfaceParking = (props) => {
  const { mangerInfo, propertyInfo, readOnly } = props;
  const dispatch = useDispatch();
  const { SURFACE_PARKING, error } = useSelector((state) => state.nofityForm);



  const handleOnChange = (event) => {
    const { name, value } = event.target;
    dispatch(setSURFACE_PARKING({ ...SURFACE_PARKING, [name]: value }));
  };

  const handleSignature = (sign) => {
    dispatch(
      setSURFACE_PARKING({ ...SURFACE_PARKING, Signature: sign })
    );
  };

  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <img src={propertyInfo && propertyInfo?.logoUrl ? 'data:image/jpeg;base64,' + propertyInfo.logoUrl : Logo_p} alt="" />{" "}
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="detailProperty text-start">
            <strong>{propertyInfo && propertyInfo?.title ? propertyInfo.title : ""}</strong>
            <br />
            <strong>Surface Parking Resident Acknowledgment Form:</strong>
            <br />
            <p>
              - Management will not be liable for any damages or in an event of
              theft of items in Underground Parking. Residents are solely liable
              for all items stored.
              <br />
              - Lock change replacement or Fob replacement fee will be applied
              in an event of lost key or Fob ($75 per item). Resident must not
              use their own lock.
              <br />
              - Underground parking spot change or if moving out, storage will
              be inspected for any damages. <br />
              - Management has the right to terminate UG parking space if in
              violation.
              <br />
            </p>
          </div>
          <div className="residentFrm cmnfrmstyle">
            <Form>
              <Row>
                {error && (
                  <Col
                    md={12}
                    className="d-flex align-items-center justify-content-center mb-3"
                  >
                    <Label style={{ color: "red" }}>Error : {error}</Label>
                  </Col>
                )}
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Resident Name:</Label>
                    <Input
                      placeholder="Resident Name"
                      value={SURFACE_PARKING?.ResidentName}
                      name="ResidentName"
                      onChange={handleOnChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Address and Suite #:</Label>
                    <Input
                      placeholder="Address and Suite #"
                      value={SURFACE_PARKING?.AddressSuite}
                      name="AddressSuite"
                      onChange={handleOnChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Surface Stall #: </Label>
                    <Input
                      placeholder="Surface Stall # "
                      value={SURFACE_PARKING?.UndergroundStall}
                      name="UndergroundStall"
                      onChange={handleOnChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Phone #: </Label>
                    <Input
                      placeholder="Phone #  "
                      value={SURFACE_PARKING?.Phone}
                      type="text"
                      maxLength={12}
                      name="Phone"
                      onChange={handleOnChange}
                      readOnly={readOnly}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Signature: </Label>
                    {/* <Input placeholder="Signature" /> */}
                    <SignaturePad
                      readOnly={readOnly}
                      sign={SURFACE_PARKING?.Signature}
                      sendSignature={(sign) => {
                        handleSignature(sign);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <div>
              <b>
                Thank You <br />
                {mangerInfo && mangerInfo?.firstname ? mangerInfo.firstname : ""}{" "}{mangerInfo && mangerInfo?.lastname ? mangerInfo.lastname : ""}
              </b>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurfaceParking;
