import * as React from "react"
const PhoneIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <g fill="#0e5492">
      <path
        d="M21.475 11.367h2.526A11.124 11.124 0 0 0 12.62 0v2.526a8.557 8.557 0 0 1 8.855 8.841Z"
        data-name="Path 370"
      />
      <path
        d="M12.632 7.579c2.656 0 3.789 1.133 3.789 3.789h2.526c0-4.074-2.242-6.316-6.316-6.316Zm4.323 6.875a1.264 1.264 0 0 0-1.757.054l-3.023 3.109a7.252 7.252 0 0 1-3.7-2.1 7.216 7.216 0 0 1-2.1-3.7l3.106-3.024a1.263 1.263 0 0 0 .054-1.757l-4.66-5.124a1.263 1.263 0 0 0-1.757-.11L.377 4.152a1.263 1.263 0 0 0-.366.82 19.077 19.077 0 0 0 5.42 13.6 19.127 19.127 0 0 0 13.144 5.429c.255 0 .412-.008.453-.01a1.253 1.253 0 0 0 .819-.368l2.354-2.743a1.263 1.263 0 0 0-.113-1.753Z"
        data-name="Path 371"
      />
    </g>
  </svg>
)
export default PhoneIcon;
