import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Select from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Label,
  Form,
  Pagination,
  PaginationItem,
  Collapse,
  PaginationLink,
  Input,
} from "reactstrap";
import TicketListRow from "./TicketList";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import jwt_decode from "jwt-decode";
import axios from "../api/axios";

const NewTicketList = () => {
  const decode = jwt_decode(window.localStorage.getItem("accessToken"));
  const GET_PROPERTY_URL = "/property/applicant-option";
  const GET_PROPERTY_DATA = "/ticket/getdata";
  const CUSTOMER_SEARCH_URL = "/ticket/tickets_customer";
  const MANAGER_SEARCH_URL = "/ticket/tickets_manager";
  const COMPANY_SEARCH_URL = "/ticket/tickets_company";
  const EXCEL_DOWNLOAD_URL = "/ticket/download_excel";
  const initialStates = {
    filters: {
      isOpen: false,
      fromdate: "",
      todate: "",
      requestId: "",
      status: "",
      property: "",
      propertyManager: "",
      vendor: "",
      technicalStaff: "",
      requestType: "",
      suiteName: "",
      managersList: [],
      propertyList: [],
      vendorsList: [],
      technicalStaffList: [],
      requestTypes: [],
      ticketIds: [],
    },
    page: {
      page: 1,
      totalPages: 10,
      startPage: 1,
      endPage: 10,
      pageLimit: 10,
    },
    stats: {
      total: 0,
      open: 0,
      inprogress: 0,
      completed: 0,
      unresolved: 0,
      timetaken: 0,
    },
    company: {
      url: "",
      company: "",
      copied: false,
    },
    ticket: {
      tickets: [],
      selectedFiles: [],
      notes: 0,
    },
  };
  const [states, setStates] = useState(initialStates);
  const updateStates = (newStates) => {
    setStates((prevStates) => ({
      ...prevStates,
      ...newStates,
    }));
  };

  const handlePageChange = (pageNumber) => {
    const newStartPage = Math.max(pageNumber - 5, 1); // Ensure start page is not less than 1
    const newEndPage = pageNumber + 5; // Assuming there's another mechanism to limit endPage

    updateStates({
      page: {
        ...states.page,
        pageNumber: pageNumber,
        startPage: newStartPage,
        endPage: newEndPage,
      },
    });
  };
  // Requests for Company Owner page.

  const getPropertyManagerList = async () => {
    try {
      const response = await axios.post(`/property_manager/admin`, {
        company: decode.id,
      });
      const { managers } = response.data;
      updateStates({
        filters: {
          ...states.company,
          managers,
        },
      });
    } catch (error) {
      console.error("Error fetching property managers:", error.message);
    }
  };

  const clearStates = (e) => {
    e.preventDefault();
    updateStates(initialStates);
  };
  const searchTicket = async (type) => {
    try {
      let query = {};
      let response;
      if (type === "filter") {
        updateStates({
          page: {
            ...states.page,
            page: 1, // Reset page number to 1 when filtering
          },
        });
      }
      if (decode.role === "customer") {
        query = {
          customerID: decode.id,
          pageNumber: states.page.page,
          startDate: states.filters.fromdate,
          endDate: states.filters.todate,
          status: states.filters.status,
          propertyID: states.filters.property,
          ticketID: states.filters.requestId,
          suite: states.filters.suiteName,
          requestType: states.filters.requestType,
        };
        response = await axios.post(CUSTOMER_SEARCH_URL, query);
      } else if (decode.role === "manager") {
        query = {
          manager_id: decode.id,
          page: states.page.page,
          startDate: states.filters.fromdate,
          endDate: states.filters.todate,
          status: states.filters.status,
          propertyId: states.filters.property,
          ticketId: states.filters.requestId,
          suite: states.filters.suiteName,
          requestType: states.filters.requestType,
        };
        console.log({ query }, states.page);
        response = await axios.post(MANAGER_SEARCH_URL, query);
      } else {
        query = {
          pageNumber: states.page.page,
          startDate: states.filters.fromdate,
          endDate: states.filters.todate,
          status: states.filters.status,
          propertyID: states.filters.property,
          ticketID: states.filters.requestId,
          propertyManagerID: states.filters.propertyManager,
          vendorID: states.filters.vendors,
          technicalStaffID: states.filters.technicalStaff,
          suite: states.filters.suiteName,
          companyDomain: decode.domain,
          requestType: states.filters.requestType,
        };
        response = await axios.post(COMPANY_SEARCH_URL, query);
      }
      if (response.status === 200) {
        updateStates({
          filters: {
            ...states.filters,
            requestTypes: response.data.request_types,
            propertyList: response.data.property_list,
            ticketIds: response.data.tickets.map((t) => t._id),
          },
          stats: {
            ...states.stats,
            total: response.data.total_count || 0,
            open: response.data.total?.Open || 0,
            unresolved: response.data.total?.Unresolved || 0,
            completed: response.data.total?.Completed || 0,
            inprogress: response.data.total?.Inprogress || 0,
            timetaken: response.data.totaltime || 0,
          },
          ticket: {
            ...states.ticket,
            tickets: response.data.tickets,
            notes: response.data.notes_count,
          },
        });
      }
      console.table({ response: response.data });
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    console.log("Filters",states)
  },[states])

  const fetchData = async (url, data = null) => {
    try {
      const response = data
        ? await axios.post(url, data)
        : await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error.message);
      return null;
    }
  };

  const getTicket = async () => {
    try {
      let response;
      if (decode.role === "manager") {
        response = await fetchData(GET_PROPERTY_URL, {
          domain: decode.managerOf,
        });
      } else if (decode.role === "customer") {
        response = await fetchData(GET_PROPERTY_DATA, {
          propertyID: decode.properties,
        });
      }
      if (response) {
        const properties = response.properties || response.property;
        updateStates({
          filters: {
            ...states.filters,
            propertyList: properties,
          },
        });
      }
    } catch (error) {
      console.error("Error fetching tickets:", error.message);
    }
  };

  const getCompanyUrl = async () => {
    const response = await fetchData(
      `company/get-url/?company_id=${decode.id}&role=${decode.role}`
    );
    if (response && response.status === 200) {
      const url = `${window.location.protocol}//${window.location.host}/maintenance-request/${response.data}`;
      updateStates({
        company: {
          ...states.company,
          url: url,
        },
      });
    }
  };

  const getVendors = async () => {
    const response = await fetchData(`/vendor/get_vendors/${decode.id}`);
    if (response && response.success) {
      updateStates({
        filters: {
          ...states.filters,
          vendorsList: response.vendors,
        },
      });
    }
  };

  const getTechnicalStaff = async () => {
    const response = await fetchData(
      `/technicalStaff/get_technical_staff/${decode.id}`
    );
    if (response && response.success) {
      updateStates({
        filters: {
          ...states.filters,
          technicalStaffList: response.technicalStaff,
        },
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (decode.role === "company") {
        await getPropertyManagerList();
        await getVendors();
        await getTechnicalStaff();
        await getTicket();
        await getCompanyUrl();
      }
      await searchTicket();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const updatePages = () => {
      const total_pages = states.stats.total / states.page.pageLimit;
      const newPages = Array.from(Array(Math.ceil(total_pages)).keys());
      updateStates({
        page: {
          ...states.page,
          totalPages: newPages,
        },
      });
    };

    updatePages();
    searchTicket();
  }, [states.stats.total, states.page.pageLimit, states.page.pageNumber]);

  const downloadExcel = async () => {
    try {
      const query = {
        role: decode.role,
        id: decode.id,
      };

      const response = await axios.post(EXCEL_DOWNLOAD_URL, query, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ticket.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Rentdigicare | Maintenance Request</title>
        </MetaTags>
        <div className="col-md-12 mb-3">
          {["vendor", "technical staff", "manager", "customer"].includes(
            decode.role
          ) ? null : (
            <>
              <CopyToClipboard
                text={states.company.url}
                onCopy={updateStates({
                  company: {
                    ...states.company,
                    copied: !states.company.copied,
                  },
                })}
              >
                <button
                  style={{
                    border: "0px",
                    borderRadius: "4px",
                    color: "white",
                    backgroundColor: "#0e578e",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(states.company.url);
                  }}
                >
                  Copy Maintenance Link
                </button>
              </CopyToClipboard>
              {states.company.copied && (
                <span
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    marginLeft: "4px",
                    padding: "2px 8px",
                    borderRadius: "5px",
                  }}
                >
                  Copied.
                </span>
              )}
            </>
          )}
        </div>

        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Maintenance Request" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <div className="row align-items-center">
                    <div className="col-md-6 d-flex">
                      <div className="mb mx-2">
                        <h5 className="card-title">
                          Total
                          <span className="text-muted fw-normal ms-1">
                            ({states?.stats?.total})
                          </span>
                        </h5>
                      </div>
                      <div className="mx-2">
                        <h5 className="card-title">
                          Open
                          <span className="text-muted fw-normal ms-1">
                            ({states?.stats?.open})
                          </span>
                        </h5>
                      </div>

                      <div className="mx-2">
                        <h5 className="card-title">
                          Inprogress
                          <span className="text-muted fw-normal ms-1">
                            ({states?.stats?.inprogress})
                          </span>
                        </h5>
                      </div>
                      <div className="mx-2">
                        <h5 className="card-title">
                          Completed
                          <span className="text-muted fw-normal ms-1">
                            ({states?.stats?.completed})
                          </span>
                        </h5>
                      </div>
                      <div className="mx-2">
                        <h5 className="card-title">
                          Unresolved
                          <span className="text-muted fw-normal ms-1">
                            ({states?.stats?.unresolved})
                          </span>
                        </h5>
                      </div>
                      <div className="mx-2">
                        <h5
                          className="card-title"
                          style={{ display: "inline-block", minWidth: "8rem" }}
                        >
                          Time Taken
                          <span className="text-muted fw-normal ms-1">
                            ({Math.floor(states?.stats?.timetaken / 60)} hours{" "}
                            {states?.stats?.timetaken % 60} minutes)
                          </span>
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-6 d-flex flex-wrap align-items-center justify-content-end">
                      {decode.role !== "customer" && (
                        <div className="mb">
                          <button
                            onClick={() => downloadExcel()}
                            className="btn btn-primary mo-mb-2 mr-10"
                            type="button"
                            style={{ cursor: "pointer" }}
                          >
                            Export Excel
                          </button>
                        </div>
                      )}
                      <div className="mb">
                        <button
                          className="btn btn-primary mo-mb-2 mr-10"
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            updateStates({
                              filters: {
                                ...states.filters,
                                isOpen: !states.filters.isOpen,
                              },
                            })
                          }
                        >
                          Filters
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <Collapse className="mt-4" isOpen={states.filters.isOpen}>
                        <Card>
                          <CardBody>
                            <div className="filter-sec">
                              <Form>
                                <div class="row align-items-center">
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <Label
                                        htmlFor="fromdate-date-input"
                                        className="form-Label"
                                      >
                                        From
                                      </Label>
                                      <Input
                                        className="form-control"
                                        onChange={(e) => {
                                          updateStates({
                                            filters: {
                                              ...states.filters,
                                              fromdate: e.target.value,
                                            },
                                          });
                                        }}
                                        value={states.fromdate}
                                        type="date"
                                        id="fromdate-date-input"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <Label
                                        htmlFor="todate-date-input"
                                        className="form-Label"
                                      >
                                        To
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="date"
                                        onChange={(e) => {
                                          updateStates({
                                            filters: {
                                              ...states.filters,
                                              todate: e.target.value,
                                            },
                                          });
                                        }}
                                        value={states.todate}
                                        id="todate-date-input"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <Label className="form-Label">
                                        Request Id
                                      </Label>
                                      <Select
                                        value={states.filters.requestId}
                                        options={states.filters.ticketIds?.map(
                                          (id) => {
                                            return {
                                              value: id,
                                              label: id.substring(
                                                id.length - 6
                                              ),
                                            };
                                          }
                                        )}
                                        onChange={(e) => {
                                          if (e === null) {
                                            // Assuming null is the value when cleared
                                            updateStates({
                                              filters: {
                                                ...states.filters,
                                                requestId: "", // Clear the requestId
                                              },
                                            });
                                          } else {
                                            updateStates({
                                              filters: {
                                                ...states.filters,
                                                requestId: e,
                                              },
                                            });
                                          }
                                        }}
                                        isClearable={true} // Make sure your Select component supports this prop
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <Label className="form-Label">
                                        Status
                                      </Label>
                                      <Select
                                        onChange={(e) => {
                                          if (e === null) {
                                            updateStates({
                                              filter: {
                                                ...states.filters,
                                                status: "", // Clear the status
                                              },
                                            });
                                          } else {
                                            updateStates({
                                              filter: {
                                                ...states.filters,
                                                status: e,
                                              },
                                            });
                                          }
                                        }}
                                        value={states.filters.status?.value}
                                        options={[
                                          { value: "Open", label: "Open" },
                                          {
                                            value: "Inprogress",
                                            label: "Inprogress",
                                          },
                                          {
                                            value: "Completed",
                                            label: "Completed",
                                          },
                                          {
                                            value: "Unresolved",
                                            label: "Unresolved",
                                          },
                                        ]}
                                        isClearable={true} // Assuming the Select component supports this prop
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <Label className="form-Label">
                                        Property
                                      </Label>
                                      <Select
                                        value={states.filters.property.label}
                                        options={states.filters.propertyList?.map(
                                          (item) => {
                                            return {
                                              value: item._id,
                                              label: item.title,
                                            };
                                          }
                                        )}
                                        placeholder="Search property"
                                        onChange={(e) => {
                                          console.log(e);
                                          if (e === null) {
                                            updateStates({
                                              filter: {
                                                ...states.filters,
                                                property: "", // Clear the property
                                              },
                                            });
                                          } else {
                                            updateStates({
                                              filter: {
                                                ...states.filters,
                                                property: e,
                                              },
                                            });
                                          }
                                        }}
                                        isClearable={true} // Assuming the Select component supports this prop
                                      />
                                    </div>
                                  </div>

                                  {decode.role === "company" && (
                                    <>
                                      <div className="col-md-3">
                                        <div className="mb-3">
                                          <Label className="form-Label">
                                            Property Manager
                                          </Label>
                                          <Select
                                            value={
                                              states.filters.propertyManager
                                            }
                                            options={states.filters.managersList?.map(
                                              (item) => {
                                                return {
                                                  value: item._id,
                                                  label:
                                                    item.firstname +
                                                    " " +
                                                    item.lastname,
                                                };
                                              }
                                            )}
                                            placeholder="Search  By Name"
                                            onChange={(selectedOption) => {
                                              updateStates({
                                                filter: {
                                                  ...states.filters,
                                                  property:
                                                    selectedOption.value,
                                                },
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-3">
                                        <div className="mb-3">
                                          <Label className="form-Label">
                                            Vendors
                                          </Label>
                                          <Select
                                            value={states.filters.vendor}
                                            options={states.filters.vendorsList?.map(
                                              (item) => {
                                                return {
                                                  value: item._id,
                                                  label:
                                                    item.firstname +
                                                    " " +
                                                    item.lastname,
                                                };
                                              }
                                            )}
                                            placeholder="Search  By Name"
                                            onChange={(selectedOption) => {
                                              updateStates({
                                                filter: {
                                                  ...states.filters,
                                                  vendor: selectedOption.value,
                                                },
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="mb-3">
                                          <Label className="form-Label">
                                            Technical Staff
                                          </Label>
                                          <Select
                                            value={
                                              states.filters.technicalStaff
                                            }
                                            options={states.filters.technicalStaffList?.map(
                                              (item) => {
                                                return {
                                                  value: item._id,
                                                  label:
                                                    item.first_name +
                                                    " " +
                                                    item.last_name,
                                                };
                                              }
                                            )}
                                            placeholder="Search By Name"
                                            onChange={(selectedOption) => {
                                              updateStates({
                                                filters: {
                                                  ...states.filters,
                                                  technicalStaff:
                                                    selectedOption.value,
                                                },
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-submitted-input"
                                      >
                                        Request Type
                                      </Label>
                                      <Select
                                        value={
                                          states.filters.requestType
                                            ? states.filters.requestType
                                            : ""
                                        }
                                        options={states.filters.requestTypes?.map(
                                          (item) => {
                                            console.log(item);
                                            return {
                                              value: item,
                                              label: item,
                                            };
                                          }
                                        )}
                                        placeholder="Search By Name"
                                        onChange={(e) => {
                                          if (e === null) {
                                            updateStates({
                                              filters: {
                                                ...states.filters,
                                                requestType: "", // Clear the requestType
                                              },
                                            });
                                          } else {
                                            updateStates({
                                              filters: {
                                                ...states.filters,
                                                requestType: e,
                                              },
                                            });
                                          }
                                        }}
                                        isClearable={true} // Assuming the Select component supports this prop
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-submitted-input"
                                      >
                                        Suite
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-submitted-input"
                                        value={states.filters.suiteName}
                                        placeholder="Enter a suite name"
                                        onChange={(e) => {
                                          updateStates({
                                            filters: {
                                              ...states.filters,
                                              suiteName: e.target.value,
                                            },
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-12 btn-group d-flex justify-content-end">
                                    <div className="srch-btn">
                                      <button
                                        type="submit"
                                        id="ticketfilter"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          searchTicket("filter");
                                        }}
                                        className="btn btn-primary"
                                      >
                                        Search
                                      </button>
                                    </div>
                                    <div
                                      className="srch-btn"
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <button
                                        onClick={(e) => {
                                          clearStates(e);
                                        }}
                                        className="btn btn-primary"
                                      >
                                        Clear
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table-striped table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">ID</th>
                          <th style={{ minWidth: "110px" }}>Suite No.</th>
                          <th scope="col">Type</th>
                          {decode.role !== "customer" && (
                            <>
                              <th>Property</th>
                              <th style={{ minWidth: "110px" }}>
                                Submitted By
                              </th>
                            </>
                          )}
                          <th scope="col" style={{ minWidth: "80px" }}>
                            Created
                          </th>
                          <th scope="col" style={{ minWidth: "80px" }}>
                            Age
                          </th>
                          <th scope="col" style={{ minWidth: "50px" }}>
                            Permission
                          </th>
                          <th scope="col" style={{ minWidth: "50px" }}>
                            Status
                          </th>
                          <th scope="col">Download</th>
                          <th scope="col">Action</th>
                          {decode.role == "company" && (
                            <th scope="col">Assign To</th>
                          )}
                          {decode.role == "manager" && (
                            <th scope="col">Assign To</th>
                          )}
                          {decode.role !== "customer" && (
                            <th scope="col">History</th>
                          )}
                          <th scope="col">Memo</th>
                        </tr>
                      </thead>
                      <tbody>
                        <TicketListRow
                          page={states?.page?.pageNumber || 0}
                          getTicket={getTicket}
                          totalTickets={states?.stats?.total || 0}
                          searchTicket={() => searchTicket()}
                          setTotalTickets={(value) =>
                            updateStates({
                              stats: {
                                ...states.stats,
                                total: value,
                              },
                            })
                          }
                          filteredTickets={states.ticket.tickets}
                          notesCount={states?.stats?.notes || 0}
                        />
                      </tbody>
                    </Table>
                  </div>
                  <Row className="proress-style mt-3">
                    <Col xl={3}></Col>
                    <Col xl={9}>
                      <div className="pagination-bar">
                        <Pagination>
                          <PaginationItem
                            disabled={states.page.pageNumber === 1}
                          >
                            <PaginationLink
                              first
                              onClick={() => handlePageChange(1)}
                            />
                          </PaginationItem>
                          <PaginationItem
                            disabled={states.page.pageNumber === 1}
                          >
                            <PaginationLink
                              previous
                              onClick={() =>
                                handlePageChange(states.page.pageNumber - 1)
                              }
                            />
                          </PaginationItem>
                          {Array.from(
                            { length: states.page.pageLimit },
                            (_, i) => i + states.page.startPage
                          ).map((page) => {
                            if (page > states.page.totalPages) return null;
                            console.log(page)
                            return (
                              <PaginationItem
                                key={page}
                                active={states.page.pageNumber === page}
                              >
                                <PaginationLink
                                  onClick={() => handlePageChange(page)}
                                >
                                  {page}
                                </PaginationLink>
                              </PaginationItem>
                            );
                          })}
                          <PaginationItem
                            disabled={
                              states.page.pageNumber === states.page.totalPages
                            }
                          >
                            <PaginationLink
                              next
                              onClick={() =>
                                handlePageChange(states.page.pageNumber + 1)
                              }
                            />
                          </PaginationItem>
                          <PaginationItem
                            disabled={
                              states.page.pageNumber === states.page.totalPages
                            }
                          >
                            <PaginationLink
                              last
                              onClick={() =>
                                handlePageChange(states.page.totalPages)
                              }
                            />
                          </PaginationItem>
                        </Pagination>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export { NewTicketList };
