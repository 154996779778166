import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FiUpload } from "react-icons/fi";
import { HiCheckCircle } from "react-icons/hi";
import { SERVER_URL } from "../ServerLink";

const POST_UPLOAD_DOCUMENT = "mastersheet/update-document";
const GET_DOWNLOAD_DOCUMENT = "mastersheet/download-document/";
const GET_DOCUMENTS_LIST = "mastersheet/documnet-list/";
const DOWNLOAD_LEASE_DOCUMENT = "lease-from/download-signed/";
const POST_LEASE_DOCUMENT_UPLOAD = "lease-from/upload-lease";
const DOWNLOAD_RESIDENT_DOCUEMNT = "resident-notify/download-document";

const Documents = ({ userId, docs }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [documents, setDocuments] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalView, setModalView] = useState(false);

  const [title, setTitle] = useState("");
  const [formData, setFormData] = useState({});
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [docId, setDocId] = useState("");
  const [formError, setFormError] = useState({
    title: "",
  });
  const [show, setShow] = useState({
    buttonName: "",
    show: false,
  });

  const toggleClose = () => setModal(false);
  const toggleCloseView = () => setModalView(false);

  const getDocumentList = async (id) => {
    try {
      const res = await axios.get(`${GET_DOCUMENTS_LIST}${id}`);
      if (res && res.data && res.data.status === 200) {
        setDocumentsList(res.data.data);
      }
    } catch (error) {}
  };

  const handleDocument = (data) => {
    switch (data.label) {
      case "Application Form":
        getDownloadApplicationForm(data);
        break;

      case "Tenant Insurance":
        setTitle(data.label);
        setFormData(data);
        setModalView(true);
        break;

      case "Inspection Sheet":
        history.push(`/move-in-move-out/${userId}`);
        break;

      case "Keys & Fob":
        history.push(`/kyes-fob/${userId}`);
        break;

      case "Lease Agreement":
        set_lease_step_1_modal(true);
        setFormData(data);
        break;

      case "Underground Parking":
        handleDownloadNotifyDocument(
          data.applicantId,
          data.documentKey,
          "UNDERGROUND_PARKING.pdf"
        );
        break;

      case "SS Parking":
        handleDownloadNotifyDocument(
          data.applicantId,
          data.documentKey,
          "SURFACE_PARKING.pdf"
        );
        break;
      case "New PAD":
        history.push(`/pad-info/${userId}`);
        break;
      case "Update PAD":
        history.push(`/pad-info/${userId}`);
        break;
      case "Attachments":
        history.push(`/attachments/${userId}`);
        break;
      //"New Pad","Update Pad","Attachments"
      default:
        break;
    }
  };

  const getDownloadApplicationForm = async (_data) => {
    try {
      const { data } = await axios.get(
        `${SERVER_URL}/applicant/pdf?id=${_data.applicantId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([data], { type: "application/pdf" });
      const a = document.createElement("a");
      const blobUrl = window.URL.createObjectURL(blob);
      a.href = blobUrl;

      // Set the filename
      a.download = "Applicant.pdf";
      dispatch(isLoading(false));

      // Trigger a click event to initiate the download
      a.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(a.href);
    } catch (error) {
      dispatch(isLoading(false));
      toast.error("Something went wrong!");
      console.error(error);
    }
  };

  const handleModal = (data) => {
    setModal(true);
    setFileName(data.fileName);
    setFile(null);
  };

  const handleUploadFile = async () => {
    try {
      const formData = new FormData();
      formData.append("documentFile", file, fileName);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          userid: userId,
          title: title,
          filename: fileName,
        },
      };
      dispatch(isLoading(true));
      const res = await axios.post(POST_UPLOAD_DOCUMENT, formData, config);
      if (res && res.data && res.data.status === 200) {
        toast.success("Document uploaded successfully!");
        await getDocumentList(userId);
        setModal(false);
        setFile(null);
        setFileName("");
        setTitle("");
      }
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      toast.error("Something went wrong!");
      console.error(error);
    }
  };

  const handleDownload = async (userId, title, name) => {
    const { data } = await axios.get(
      `${GET_DOWNLOAD_DOCUMENT}${userId}/${title}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "arraybuffer",
      }
    );
    const blob = new Blob([data]);
    const a = document.createElement("a");
    const blobUrl = window.URL.createObjectURL(blob);
    a.href = blobUrl;

    // Set the filename
    a.download = name;

    // Trigger a click event to initiate the download
    a.click();

    // Clean up the URL object
    window.URL.revokeObjectURL(a.href);
  };

  // Lease Aggrement Funtionality
  const [lease_step_1_modal, set_lease_step_1_modal] = useState(false);
  const [lease_step_1_modal_upload, set_lease_step_1_modal_upload] =
    useState(false);
  const [lease_file, set_lease_file] = useState(null);

  const leaseToggleClose = () => {
    set_lease_step_1_modal(false);
  };
  const leaseUploadToggleClose = () => {
    set_lease_step_1_modal_upload(false);
  };

  const handleLeaseResent = (applicationId) => {
    history.push("/lease-form/" + applicationId);
  };

  const handleLeaseDownloadClick = async (applicationId, filename) => {
    try {
      const { data } = await axios.get(
        `${DOWNLOAD_LEASE_DOCUMENT}${applicationId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([data], { type: "application/pdf" });
      const a = document.createElement("a");
      const blobUrl = window.URL.createObjectURL(blob);
      a.href = blobUrl;

      // Set the filename
      a.download = filename;

      // Trigger a click event to initiate the download
      a.click();
      dispatch(isLoading(false));

      // Clean up the URL object
      window.URL.revokeObjectURL(a.href);
    } catch (error) {
      dispatch(isLoading(false));
      toast.error("Something went wrong!");
      console.error(error);
    }
  };

  const handleLeaseUpload = async (data) => {
    try {
      const formData = new FormData();
      formData.append("leaseAggrementFile", lease_file, data.fileName);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          applicantid: data.applicantId,
          filename: data.fileName,
        },
      };
      dispatch(isLoading(true));
      const res = await axios.post(
        POST_LEASE_DOCUMENT_UPLOAD,
        formData,
        config
      );
      if (res && res.data && res.data.status === 200) {
        toast.success("Lease Document uploaded successfully!");
        await getDocumentList(userId);
        set_lease_step_1_modal_upload(false);
        set_lease_file(null);
      }
      dispatch(isLoading(false));
    } catch (error) {
      console.log("handleLeaseUpload Error :", error);
      toast.error("Something went wrong !");
      dispatch(isLoading(false));
    }
  };

  // Resident notify Document
  const handleDownloadNotifyDocument = async (
    applicationId,
    documentKey,
    filename
  ) => {
    try {
      dispatch(isLoading(true));
      const { data } = await axios.get(
        `${DOWNLOAD_RESIDENT_DOCUEMNT}/${documentKey}/${applicationId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      );
      const blob = new Blob([data], { type: "application/pdf" });
      const a = document.createElement("a");
      const blobUrl = window.URL.createObjectURL(blob);
      a.href = blobUrl;

      // Set the filename
      a.download = `${applicationId}_${filename}`;

      // Trigger a click event to initiate the download
      a.click();

      // Clean up the URL object
      window.URL.revokeObjectURL(a.href);
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      toast.error("Something went wrong!");
      console.error(error);
    }
  };

  useEffect(() => {
    setDocuments(docs);
  }, [docs]);

  useEffect(() => {
    if (userId) {
      getDocumentList(userId);
    }
  }, [userId]);

  return (
    <>
      <div className="frm_InnerSec1 mt-3">
        <h4 className="cmnHding">Documents</h4>
        <div className="innnerFrmData">
          <div>
            {documentsList.map((doc, idx) => (
              <button
                disabled={!doc.isActive}
                type="button"
                className="btn btn-light m-1"
                key={idx}
                onClick={(e) => handleDocument(doc)}
              >
                <span
                  style={{
                    color: "#0f68ba",
                    fontSize: "14px",
                  }}
                >
                  {doc?.label}
                </span>
                {doc.label === "Inspection Sheet" ||
                doc.label === "Keys & Fob" ? (
                  ""
                ) : doc.path ? (
                  <HiCheckCircle className="mx-2 " style={{ color: "green" }} />
                ) : (
                  <FiUpload className="mx-2" />
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
      {modalView && (
        <Modal centered isOpen={modalView} toggle={toggleCloseView}>
          <ModalBody>
            <Card>
              <CardHeader>{title}</CardHeader>
              <CardBody>
                <Row className="text-center">
                  <Col md={4}>
                    <Button
                      color="success"
                      onClick={() => {
                        toggleCloseView();
                        handleModal(formData);
                      }}
                    >
                      Upload
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      disabled={formData.fileName ? false : true}
                      color="primary"
                      onClick={() => {
                        handleDownload(userId, title, formData.fileName);
                        toggleCloseView();
                      }}
                    >
                      Download
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button color="danger" onClick={toggleCloseView}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      )}
      {modal && (
        <Modal isOpen={modal} toggle={toggleClose}>
          <ModalHeader toggle={toggleClose}>{title}</ModalHeader>
          <ModalBody>
            <div className="modalInner">
              <Row>
                <Col md={12} className="mt-3">
                  <FormGroup>
                    <Label for="exampleEmail">Document Name</Label>
                    <Input
                      readOnly
                      name="fileName"
                      placeholder="Document Name"
                      type="text"
                      value={fileName}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mt-3">
                  <FormGroup>
                    <Input
                      name="newDocument"
                      type="file"
                      accept=".pdf"
                      onChange={(event) => {
                        setFileName(event.target.files[0].name);
                        setFile(event.target.files[0]);
                      }}
                      multiple={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={file ? false : true}
              color="primary"
              onClick={() => handleUploadFile(docId)}
            >
              Upload
            </Button>
            <Button color="danger" onClick={toggleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {lease_step_1_modal && (
        <Modal
          size="lg"
          centered
          isOpen={lease_step_1_modal}
          toggle={leaseToggleClose}
        >
          <ModalBody>
            <Card>
              <CardHeader>{title}</CardHeader>
              <CardBody>
                <Row className="text-center">
                  <Col md={3}>
                    <Button
                      color="primary"
                      onClick={() => {
                        handleLeaseResent(formData.applicantId);
                      }}
                    >
                      Re-Send Lease
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button
                      disabled={!formData.isDownloadLease}
                      color="primary"
                      onClick={() => {
                        handleLeaseDownloadClick(
                          formData.applicantId,
                          formData.fileName
                        );
                        leaseToggleClose();
                      }}
                    >
                      Download
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button
                      disabled={!formData.isUploadLease}
                      color="success"
                      onClick={() => {
                        leaseToggleClose();
                        set_lease_step_1_modal_upload(true);
                      }}
                    >
                      Upload
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button color="danger" onClick={leaseToggleClose}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      )}

      {lease_step_1_modal_upload && (
        <Modal
          isOpen={lease_step_1_modal_upload}
          toggle={leaseUploadToggleClose}
        >
          <ModalHeader toggle={leaseUploadToggleClose}>
            {"Signed Lease Aggrement Upload"}
          </ModalHeader>
          <ModalBody>
            <div className="modalInner">
              <Row>
                <Col md={12} className="mt-3">
                  <FormGroup>
                    <Input
                      name="newDocument"
                      type="file"
                      accept=".pdf"
                      onChange={(event) => {
                        set_lease_file(event.target.files[0]);
                      }}
                      multiple={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={lease_file ? false : true}
              color="primary"
              onClick={() => handleLeaseUpload(formData)}
            >
              Upload
            </Button>
            <Button color="danger" onClick={leaseUploadToggleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default Documents;
