import React from "react";

import FormHeaderLogo from "./FormHeaderLogo";
import FormFooter from "./FormFooter";

import "./frmStyle.scss";

const FormStep6 = () => {

  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <FormHeaderLogo />
            </div>
          </div>
          <div className="">
            <br />
            <br />
            <br />
            <p>
              <strong>RULES AND REGULATIONS</strong>
              <br />
              The parties hereto mutually covenant and agree that the landlord
              may from time to time make such responsible rules and regulations
              for the care, use and cleanliness of the leased premises, the
              parking areas, the grounds and facilitation, upon or about the
              building in which the leased premises are located. The tenant
              hereby covenants to obey such rules and regulations and agrees
              that any amendments to the rules and regulations in force as of
              the date of this lease shall be deemed to be incorporated into and
              form part of this lease.
              <br />
              A. Nothing shall be thrown or placed or hung outside the windows
              or on window ledges without written permission. This specifically
              includes but is not limited to satellite dishes. Any junk removal
              items such as Mattresses, Appliances or other items left in
              garbage dump or surrounding area of the property, tenant will be
              charged $200 per item for removal. Air conditioners are not
              allowed on the window unless its portable unit approved by
              Management. <br />
              B. The tenant further hereby agrees not to place any curtains or
              drapes on the windows and specifically not to use sheets,
              blankets, tin foil, or flags to cover windows. In the event the
              landlord finds such items being used and the tenant does not
              remove them, the tenant agrees to compensate the landlord $150 for
              his time and trouble of removing such window coverings and trouble
              of sourcing and placing appropriate window coverings in the
              windows.
              <br />
              C. Balcony decks shall be for the use of barbecues (up to 20
              pounds tanks only) and plants and shall not be used for storage
              areas. Boxes, tires or other articles may not be placed on the
              balcony or rails.
              <br />
              D. The tenant agrees to remove any soiled or wet footwear in the
              lobby of the building in which the premises is located and carry
              the footwear to his suite.
              <br />
              E. No structural alterations, wall mount TV, painting, wall paper,
              decal, re-decorating or driving of nails, screws in walls, floors
              or woodwork are to be done without written consent.
              <br />
              F. No rubbish or boxes shall be left in the corridors, parking
              areas or entrance and deck areas or around garbage disposal
              containers. Furniture, large appliances and other items, which
              will not fit in the dumpster, must be carted to the dump by the
              tenant for removal of such items. <br />
              G. Windows shall not be left open to cause damage by rain, water
              or freezing to the premises at any time. The tenant shall be
              responsible for any damage caused by such an action. Smoking any
              material such as Cannabis, Tobacco, Cigar material, Vaping (or
              Marijuana of any form) is NOT allowed in suites, patios, green
              spaces, parking lot, Inside/Outside premises of the property,
              Underground Parking or Yard. No Cannabis plants to be grown on
              property also.
              <br />
              H. If negligence caused by the tenant for broken or damaged glass,
              locks or trimmings on doors or windows of the premises, tenant
              must pay for damages. No Real Trees for Christmas Trees allowed.{" "}
              <br />
              I. If the tenant is absent for an extended period or the premises
              is unoccupied, the tenant is to arrange for a regular inspection
              by a competent person or let the management know. <br />
              <br />
              <strong>LANDLORD’S AGENT</strong> <br />
              It is understood and agreed by and between the parties hereto that
              the landlord shall have the right to appoint such agents to act on
              his behalf, and deal with the tenant pursuant to this lease, as
              the landlord shall consider necessary and for the lease.
              <br />
              <br />
              <strong>LOCKS, KEYS, PARKING PASS, FOB</strong> <br />
              The cost of replacing any lost suite keys, Fobs, Mailbox keys,
              Parking Pass will be borne by the tenant. No locks are to be
              installed or changed by the tenant. All keys to the premises and
              related facilities shall be delivered up to the landlord upon
              termination of this lease. If the landlord is required to change
              any locks due to keys not being delivered promptly to the
              landlord, the tenant shall immediately pay for all costs incurred.
              (Tenants are responsible for the cost of any lock outs- $150
              one-time charge). If the Management supplies you with a
              replacement item, the charge to the tenant will be $75.00 per
              item. Full payment must be made in full prior to the landlord
              providing replacement item to the tenant.
              <br />
              <br />
              <strong>HEAT AND HOT WATER</strong>
              <br />
              The Landlord shall not be responsible for damage resulting from
              any interruption of heat or hot water. In the event of boiler
              malfunction or breakdown, the landlord shall not be responsible
              for alternate accommodation costs should they be required by the
              tenant if circumstances arise.
              <br />
              <br />
            </p>
            <FormFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStep6;
