import React from "react";
import Logo_p from "../../../../../assets/images/frmlogo.png";
import { Col, Form, Input, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "../notifyformStyle.scss";
import { setINTERCOM_SETUP } from "../../../../../store/notifyForm/actions";

const IntercomSetup = (props) => {
  const { propertyInfo, readOnly } = props;
  const dispatch = useDispatch();
  const { INTERCOM_SETUP, error } = useSelector((state) => state.nofityForm);

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    dispatch(setINTERCOM_SETUP({ ...INTERCOM_SETUP, [name]: value }));
  };

  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <img src={propertyInfo && propertyInfo?.logoUrl ? 'data:image/jpeg;base64,' + propertyInfo.logoUrl : Logo_p} alt="" />{" "}
            </div>
            <div className="propertyName">
              <strong>{propertyInfo && propertyInfo?.title ? propertyInfo.title : ""}</strong>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div className="detailProperty text-start">
            <strong>INTERCOM SETUP</strong>
            <p>
              Please fill out the information below to set up your intercom
              system. The intercom system works through your personal phone.
              This phone can be either a landline or a cell phone as long as it
              is a local number. <br />
              <br />
              To Unlock the Main Door <br />
              • Press “ 9 ” on your telephone keypad. <br />
              <br />
              To Refuse Entry <br />
              • Hang up or <br />
              • Press “ 4 “ on your telephone keypad. <br />
              <br />
              If you do not know the person on the phone we ask that you do not
              let them in the building for the safety and security of everyone.{" "}
              <br />
              <br />
            </p>
          </div>
          <div className="intercomFrm ">
            <Form>
              <Row>
                {error && (
                  <Col
                    md={12}
                    className="d-flex align-items-center justify-content-center mb-3"
                  >
                    <Label style={{ color: "red" }}>Error : {error}</Label>
                  </Col>
                )}
                <Col md={12}>
                  <div className="frmInner">
                    <Label>
                      Suite # and Building Number: <br />
                      (Will be displayed on the intercom)
                    </Label>
                    <Input
                      placeholder=""
                      type="text"
                      readOnly={readOnly}
                      value={INTERCOM_SETUP?.BuildingNumber}
                      name="BuildingNumber"
                      onChange={handleOnChange}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="frmInner">
                    <Label>Phone Number:</Label>
                    <Input
                      placeholder=""
                      type="text"
                      readOnly={readOnly}
                      maxLength={12}
                      value={INTERCOM_SETUP?.PhoneNumber}
                      name="PhoneNumber"
                      onChange={handleOnChange}
                    />
                  </div>
                </Col>
              </Row>
            </Form>
            <div>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntercomSetup;
