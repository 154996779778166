import React, { useEffect, useState } from "react";
import { useFormik, FieldArray, FormikProvider, Field } from "formik";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import * as Yup from "yup";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MetaTags from "react-meta-tags";
import jwtDecode from "jwt-decode";
import axios from "../api/axios";
import { Link, useLocation } from "react-router-dom";

const tenantValidationSchema = Yup.object({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string().required("Phone is required"),
});

const validationSchema = Yup.object({
  tenants: Yup.array()
    .of(tenantValidationSchema)
    .min(1, "At least one tenant is required")
    .max(5, "Cannot add more than 5 tenants"),
  suite: Yup.string().required("Suite No. is required"),
});

const AddTenantForm = () => {
  const location = useLocation();
  const { propertyId, buildingName = "" } = location.state || {};
  const decode = jwtDecode(window.localStorage.getItem("accessToken"));
  const [propList, setPropList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [resData, setResData] = useState({ id: "", property: "" });

  const toggleModal = () => setModal(!modal);

  const getProperties = async () => {
    const PROPERTIES_URL = `property/list`;
    let data = {
      role: decode.role,
      domain: decode.role === "company" ? decode.domain : "",
      managerID: decode.role === "manager" ? decode.id : "",
    };
    try {
      const response = await axios.post(PROPERTIES_URL, data);
      console.log(response.data.results.properties);
      if (response.data.results.properties) {
        setPropList(response.data.results.properties);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  const formik = useFormik({
    initialValues: {
      tenants: [
        {
          firstName: "",
          lastName: "",
          mobile: "",
          email: "",
        },
      ],
      properties: [propertyId],
      suite: "",
      buildingName: buildingName,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const CREATE_CUSTOMER = "customer/addCustomer";
        document.getElementById("saveSpinner").style.display = "inline-block";
        const response = await axios.post(`${CREATE_CUSTOMER}`, values);
        if (response.status === 201) {
          toast.success("Tenants added successfully!");
          setModalContent(
            "Tenants added successfully! Do you want to add more tenants or manage tenant details?"
          );
          setResData(response.data.data);
          toggleModal();
        } else if (response.status === 409) {
          toast.error(
            response.data.message || "Tenant Email or Phone already exists."
          );
        } else {
          toast.error("Failed to add tenants.");
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          toast.error(
            error.response.data.error || "Tenant Email or Phone already exists."
          );
        } else {
          toast.error(
            error.response.data.error || "An error occurred. Please try again."
          );
        }
      } finally {
        document.getElementById("saveSpinner").style.display = "none";
      }
      setSubmitting(false);
    },
  });

  const propertyName = propList.find(
    (property) => property?._id === propertyId
  );

  useEffect(() => {
      console.log(formik);
    })

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add Tenants | Based on Properties Listed</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Master Sheet" breadcrumbItem="Add Tenants" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">
                    Add Tenants - {propertyName?.title || ""}
                  </h4>
                  <p className="card-title-desc">
                    All added tenants will appear in the Master Sheet. Please
                    set up the detailed documents from the Master Sheet.
                  </p>
                </CardHeader>

                <CardBody>
                  <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                      <FieldArray name="tenants">
                        {({ push, remove, form }) => (
                          <div>
                            {formik.values.tenants.map((tenant, index) => (
                              <div key={index}>
                                <Row>
                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor={`tenants.${index}.firstName`}
                                      >
                                        First Name *
                                      </Label>
                                      <Field
                                        name={`tenants.${index}.firstName`}
                                        type="text"
                                        className="form-control"
                                        placeholder="First Name"
                                      />
                                      {formik.touched.tenants &&
                                      formik.errors.tenants &&
                                      formik.touched.tenants[index] &&
                                      formik.errors.tenants[index] &&
                                      formik.touched.tenants[index].firstName &&
                                      formik.errors.tenants[index].firstName ? (
                                        <div className="text-danger small mt-1">
                                          {
                                            formik.errors.tenants[index]
                                              .firstName
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor={`tenants.${index}.lastName`}
                                      >
                                        Last Name *
                                      </Label>
                                      <Field
                                        name={`tenants.${index}.lastName`}
                                        type="text"
                                        className="form-control"
                                        placeholder="Last Name"
                                      />
                                      {formik.touched.tenants &&
                                      formik.errors.tenants &&
                                      formik.touched.tenants[index] &&
                                      formik.errors.tenants[index] &&
                                      formik.touched.tenants[index].lastName &&
                                      formik.errors.tenants[index].lastName ? (
                                        <div className="text-danger small mt-1">
                                          {
                                            formik.errors.tenants[index]
                                              .lastName
                                          }
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor={`tenants.${index}.email`}
                                      >
                                        Email *
                                      </Label>
                                      <Field
                                        name={`tenants.${index}.email`}
                                        type="text"
                                        className="form-control"
                                        placeholder="abc@gmail.com"
                                      />
                                      {formik.touched.tenants &&
                                      formik.errors.tenants &&
                                      formik.touched.tenants[index] &&
                                      formik.errors.tenants[index] &&
                                      formik.touched.tenants[index].email &&
                                      formik.errors.tenants[index].email ? (
                                        <div className="text-danger small mt-1">
                                          {formik.errors.tenants[index].email}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>

                                  <Col md={6}>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor={`tenants.${index}.mobile`}
                                      >
                                        Phone *
                                      </Label>
                                      <Field
                                        name={`tenants.${index}.mobile`}
                                        type="text"
                                        className="form-control"
                                        placeholder="9875400000"
                                      />
                                      {formik.touched.tenants &&
                                      formik.errors.tenants &&
                                      formik.touched.tenants[index] &&
                                      formik.errors.tenants[index] &&
                                      formik.touched.tenants[index].mobile &&
                                      formik.errors.tenants[index].mobile ? (
                                        <div className="text-danger small mt-1">
                                          {formik.errors.tenants[index].mobile}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={12}>
                                    {index > 0 && (
                                      <Button
                                        color="danger"
                                        onClick={() => remove(index)}
                                      >
                                        Remove Tenant
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            ))}
                            {formik.values.tenants.length < 5 && (
                              <Button
                                color="primary"
                                onClick={() =>
                                  push({
                                    firstName: "",
                                    lastName: "",
                                    mobile: "",
                                    email: "",
                                  })
                                }
                              >
                                Add Tenant
                              </Button>
                            )}
                          </div>
                        )}
                      </FieldArray>
                      <Row>
                        <Col md={6}>
                          <div className="mt-3 mb-3">
                            <Label
                              className="form-label"
                              htmlFor="formrow-suite-input"
                            >
                              Suite *
                            </Label>
                            <input
                              name="suite"
                              type="text"
                              className="form-control"
                              id="formrow-suite-input"
                              placeholder="ZX1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.suite}
                            />
                            {formik.touched.suite && formik.errors.suite ? (
                              <div className="text-danger small mt-1">
                                {formik.errors.suite}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <div className="text-end">
                        <span
                          id="errorSpan"
                          style={{
                            display: "none",
                            color: "red",
                            marginRight: "10px",
                          }}
                        >
                          Error: here
                        </span>
                        <button
                          id="companySubmitBtn"
                          type="submit"
                          className="btn btn-success save-user"
                        >
                          Save
                          <div
                            id="saveSpinner"
                            style={{
                              display: "none",
                              height: "15px",
                              width: "15px",
                              marginLeft: "5px",
                            }}
                            className="spinner-border"
                            role="status"
                          ></div>
                          <i
                            id="failedSaveErrorIcon"
                            style={{ display: "none", marginLeft: "5px" }}
                            className="fa fa-exclamation-triangle"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </form>
                  </FormikProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>Success</ModalHeader>
            <ModalBody>{modalContent}</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  toggleModal();
                  formik.resetForm();
                }}
              >
                Add More Tenants
              </Button>
              <Button
                color="secondary"
                onClick={toggleModal}
                tag={Link}
                to={`/new_tenant_info/${resData.id}/${resData.property}`}
              >
                Manage Tenant Details
              </Button>
            </ModalFooter>
          </Modal>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};

export { AddTenantForm };
