import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import createSlug from "../utils/slugify";
import useLoading from "../hooks/useLoading";
import ConfirmationModal from "../components/ConfirmationModal";

const StepTwo = ({ blogId, Check, onPrevStep }) => {
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    author: "",
    content: "",
    image: null,
  });

  const [content, setContent] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [useExistingImage, setUseExistingImage] = useState(false);
  const imageInputRef = useRef(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/blogs/rentdigi/fetch_blog_by_id/${blogId}`
        );
        if (response.ok) {
          const blogData = await response.json();
          console.log({ blogData });
          setFormData({
            title: blogData.title,
            author: blogData.author,
            content: blogData.content,
            image: blogData.image,
          });
          setContent(blogData.content);
        } else {
          setError("Failed to fetch blog details");
        }
      } catch (error) {
        setError("Failed to fetch blog details");
      }
    };

    fetchBlogDetails();
  }, [blogId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
  };

  const handleContentChange = (value) => {
    const sanitizedValue = DOMPurify.sanitize(value);
    setContent(sanitizedValue);
    setFormData({ ...formData, content: value });
  };

 const handleSubmit = async (e) => {
   e.preventDefault();
   startLoading();

   const slug = createSlug(formData.title);

   // Check if we need to upload a new image or use the existing one
   if (useExistingImage) {
     const formDataForImage = new FormData();
     formDataForImage.append("image", formData.image);

     try {
       const imageUploadResponse = await fetch(
         `${process.env.REACT_APP_SERVER_URL}/blogs/rentdigi/blog/upload_image/image`,
         {
           method: "POST",
           body: formDataForImage,
         }
       );

       if (imageUploadResponse.ok) {
         const imageData = await imageUploadResponse.json();
         if (imageData.status) {
           const uploadedImageUrls = imageData.image;

           const blogData = {
             slug: slug,
             title: formData.title,
             author: [
               {
                 name: formData.author,
               },
             ],
             content: formData.content,
             image: uploadedImageUrls,
           };

           await editBlog(blogData);
           setFormData({
             title: "",
             author: "",
             content: "",
             image: null,
           });
           if (imageInputRef.current) {
             imageInputRef.current.value = "";
           }
           setContent("");
           setShowModal(true);
         } else {
           console.error("Image uploaded failed:", imageData.msg);
         }
       } else {
         console.error("Image upload failed:", imageUploadResponse.statusText);
       }
     } catch (error) {
       console.error("Image upload error:", error);
     } finally {
       stopLoading();
     }
   } else {
     // If using existing image, simply edit the blog without uploading a new image
     const blogData = {
       slug: slug,
       title: formData.title,
       author: [
         {
           name: formData.author,
         },
       ],
       content: formData.content,
       image: formData.image, // Use existing image URL
     };

     const Response = await editBlog(blogData);
     console.log(Response);
     setFormData({
       title: "",
       author: "",
       content: "",
       image: null,
     });
     setContent("");
     setShowModal(true);
     stopLoading();
   }
 };


  const editBlog = async (blogData) => {
    try {
      const response = await fetch(
        // `http://localhost:9000/blogs/rentdigi/update_blog/${blogId}`,
        `${process.env.REACT_APP_SERVER_URL}/blogs/rentdigi/update_blog/${blogId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(blogData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        if (result.status) {
          setError("Updated successfully");
          console.log("Blog post updated successfully:", result);
        } else {
          setError("Updation Failed");
          console.error("Blog post updation failed:", result.msg);
        }
      } else {
        setError("Updation Failed");
        console.error("Blog post updation failed:", response.statusText);
      }
    } catch (error) {
      setError("Updation Failed");
      console.error("Blog post updation error:", error);
    }
  };

  const handleReset = () => {
    setFormData({
      title: "",
      author: "",
      content: "",
      image: null,
    });

    if (imageInputRef.current) {
      imageInputRef.current.value = "";
    }

    setContent("");
  };

  return (
    <>
      <div className="container-p-y container-p-x">
        <h4 className="fw-bold py-3 mb-0">
          <span className="text-muted fw-light">Blog /</span> Edit Blog
        </h4>
        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-3 text-end"></div>
                  <div className="col-md">
                    <ToastContainer />
                  </div>
                </div>
              </div>
              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  <div className="row align-items-center mb-4">
                    <div className="col-md-3 text-end">
                      <label htmlFor="title" className="form-label m-0">
                        Blog Title
                      </label>
                    </div>
                    <div className="col-md">
                      <input
                        id="title"
                        type="text"
                        name="title"
                        placeholder="Title"
                        required
                        value={formData.title}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mb-4">
                    <div className="col-md-3 text-end">
                      <label htmlFor="author" className="form-label m-0">
                        Blog Author
                      </label>
                    </div>
                    <div className="col-md">
                      <input
                        id="author"
                        type="text"
                        name="author"
                        placeholder="Author"
                        required
                        value={formData.author}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="row align-items-center mb-4">
                    <div className="col-md-3 text-end">
                      <label for="" className="form-label m-0">
                        Blog Content
                      </label>
                    </div>
                    <div className="col-md">
                      <div className="ckEditor">
                        <ReactQuill
                          value={content}
                          onChange={handleContentChange}
                          placeholder="Write your content here"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row align-items-center mb-4">
                    {useExistingImage && (
                      <>
                        <div className="col-md-3 text-end">
                          <label htmlFor="upload" className="form-label m-0">
                            Blog Image
                          </label>
                        </div>
                        <div className="col-md">
                          <div className="d-flex align-items-start align-items-sm-center gap-4">
                            <div className="button-wrapper">
                              <label
                                htmlFor="upload"
                                className="btn me-2 mb-3"
                                tabIndex="0"
                              >
                                <input
                                  type="file"
                                  name="image"
                                  id="upload"
                                  accept="image/*"
                                  required
                                  onChange={handleImageChange}
                                  className="form-control"
                                  ref={imageInputRef}
                                />
                              </label>
                              <button
                                type="button"
                                className="btn btn-outline-secondary account-image-reset mb-3"
                                onClick={handleReset}
                              >
                                <i className="bx bx-reset d-block d-sm-none"></i>
                                <span className="d-none d-sm-block">Reset</span>
                              </button>

                              <p className="text-muted mb-0">
                                Allowed JPG, GIF or PNG. Max size of 800K
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="row align-items-center mt-2">
                      <div className="col-md-3 text-end"></div>
                      <div className="col-md">
                        {!Check && (
                          <button
                            onClick={onPrevStep}
                            className="btn btn-primary mr-3"
                          >
                            Previous
                          </button>
                        )}

                        <button
                          type="submit"
                          className="btn btn-primary account-image-reset mb-3 ms-2"
                          disabled={isLoading}
                        >
                          {isLoading ? "Editing..." : "Edit"}
                        </button>
                        <button
                          type="button"
                          className="btn btn-outline-secondary account-image-reset mb-3 ms-2"
                          onClick={() => setUseExistingImage(!useExistingImage)}
                        >
                          {useExistingImage ? "  " : " ✔ "}Use Existing Image
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {error && <p className="error">{error}</p>}
      </div>

      <ConfirmationModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        onConfirm={() => setShowModal(false)}
        isLoading={false}
        title="Blog Edited Successfully"
        message="Your blog has been edited successfully!"
        confirmText="Close"
      />
    </>
  );
};

export default StepTwo;
