import React from "react"

import FreezingPipes from "./MoveInForms/FreezingPipes";
import IntercomSetup from "./MoveInForms/IntercomSetup";
import SurfaceParking from "./MoveInForms/SurfaceParking";
import UndergroundParkingForm from "./MoveInForms/UndergroundParkingForm";

const getFormComponent = (key, data) => {

    if (key === "a_1") {
        return <IntercomSetup {...data} />
    }
    if (key === "a_2") {
        return <UndergroundParkingForm {...data} />
    }
    if (key === "a_3") {
        return <SurfaceParking {...data} />
    }
    if (key === "a_4") {
        return <FreezingPipes {...data} />
    }
}

export default getFormComponent
