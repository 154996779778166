import React from "react";
import "./style.css";

const BlueStar4_5 = () => {
  return (
    <div className="star_container">
      <img src="images/blue_star.png" />
      <img src="images/blue_star.png" />
      <img src="images/blue_star.png" />
      <img src="images/blue_star.png" />
      <img src="images/blue_half_star.png" />
    </div>
  );
};

export default BlueStar4_5;
