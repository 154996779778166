import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "../../pages/api/axios";

//Import Icons
import FeatherIcon from "feather-icons-react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import jwt_decode from "jwt-decode";
const SidebarContent = (props) => {
  const [dashboarddata, setDashboardData] = useState("");
  const [residentApplicationInfo, setResidentApplicationInfo] = useState({
    Total: 0,
    Pending: 0,
    Approved: 0,
    Denied: 0,
  });
  const [viewLeadsInfo, setViewLeadsInfo] = useState({
    total: 0,
    closed: 0,
    pending: 0,
    cancelled: 0,
    follow_up: 0,
  });
  const [appointmentsInfo, setAppointmentsInfo] = useState({
    total: 0,
    booked: 0,
    rejected: 0,
    rejected: 0,
  });
  const [maintenanceRequestInfo, setMaintenanceRequestInfo] = useState({
    total: 0,
    open: 0,
    inprogress: 0,
    completed: 0,
    unresolved: 0,
  });

  const decode = jwt_decode(window.localStorage.getItem("accessToken"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Request 1: Fetch navdata
        const navdataResponse = await axios.post(
          "/navdata/maintenancerequests",
          {
            companyDomain: decode.domain,
            managerID: decode.id,
          }
        );
        // Handle navdataResponse as needed

        // Request 2: Fetch resident applications
        const residentApplicationsResponse = await axios.post(
          "/navdata/residentapplications?page=1&limit=10",
          {
            role: decode.role,
            domain: decode.domain,
          }
        );

        // Handle residentApplicationsResponse as needed

        // Request 3: Fetch pending slots
        const pendingSlotsResponse = await axios.post(
          "/user_appointment/get-pending-slot",
          {
            manager_id: decode.id,
          }
        );
        // Update the state with fetched data
        setDashboardData({
          maintenanceTickets: navdataResponse.data?.openTicketsCount,
          residentApplications:
            residentApplicationsResponse.data.total?.Pending || 0,
          pendingSlots: pendingSlotsResponse.data,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // * Get Manager Pending slots start

  const getApplicants = async (url, data) => {
    try {
      const response = await axios.post(url, data);
      if (response.status === 200) {
        if (response.data.results.applications) {
          const temp = response.data.results.applications;
          setResidentApplicationInfo((prev) => ({
            ...prev,
            Total: response.data.total.Total || 0,
            Pending: response.data.total.Pending || 0,
            Approved: response.data.total.Approved || 0,
            Denied: response.data.total.Denied || 0,
          }));
        }
      } else {
        console.warn("Unable to fetch the resources");
      }
    } catch (error) {
      console.error("Unable to get applicants", error);
    }
  };

  const getLeads = async (e) => {
    try {
      const response = await axios.get(
        `/leads/getLeadsManager?page=1&limit=10`
      );

      if (response.data?.success && response?.data?.leads?.length > 0) {
        setViewLeadsInfo((prev) => ({
          ...prev,
          total: response.data.total.Total || 0,
          pending: response.data.total.Pending || 0,
          closed: response.data.total.Closed || 0,
          cancelled: response.data.total.Cancelled || 0,
          follow_up: response.data.total["Follow Up"] || 0,
        }));
      }
    } catch (error) {
      console.error("Error", error); // TODO proper error
    }
  };

  const searchTicket = async (decode) => {
    try {
      let query = {};
      let response;
      if (decode.role == "manager") {
        query = {
          managerID: decode.id,
        };

        response = await axios.post("/ticket/filter_tickets_manager", query);
      }
      if (response?.status === 200) {
        setMaintenanceRequestInfo((prev) => ({
          ...prev,
          total: response.data.total?.Total || 0,
          open: response.data.total?.Open || 0,
          completed: response.data.total?.Completed || 0,
          inprogress: response.data.total?.Inprogress || 0,
          unresolved: response.data.total?.Unresolved || 0,
        }));
      }
    } catch (error) {
      console.error(error); // TODO proper error
    }
  };

  const getAppointments = async (decode) => {
    let query = {
      manager_id: decode.id,
    };

    let res = await axios.post(
      "/user_appointment/get-pending-slot",
      JSON.stringify(query),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res.data;
    setAppointmentsInfo((prev) => ({
      ...prev,
      total: data?.total?.Total || 0,
      booked: data?.total?.booked || 0,
      pending: data?.total?.pending || 0,
      rejected: data?.total?.rejected || 0,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const decode = jwt_decode(window.localStorage.getItem("accessToken"));

      await getApplicants(`/applicant/list?page=1&limit=10`, {
        role: decode.role,
        managerID: decode.id,
      });

      await getLeads();
      await searchTicket(decode);
      await getAppointments(decode);
    };

    fetchData();
  }, []);

  // * Get Manager Pending slots end

  const ref = useRef();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  if (window.localStorage.getItem("accessToken")) {
    // Checking for the token for expire
    const decode = jwt_decode(window.localStorage.getItem("accessToken"));
    const expDate = new Date(decode.exp * 1000);
    if (Date.now() > expDate) {
      // remove old localstorage data
      window.localStorage.removeItem("role");
      window.localStorage.removeItem("accessToken");
      // redirect to login
      window.location.replace("/login");
    }

    const role = window.localStorage.getItem("role");
    const user =
      JSON.parse(window.localStorage.getItem("authUser"))?.userData?.company ||
      "GSK";
    if (role === "admin") {
      return (
        <React.Fragment>
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul
                className={` list-unstyled metismenu-${props.companyClass}`}
                id="side-menu"
              >
                <li className="facilities-li">
                  <Link to="/company" className="">
                    <FeatherIcon icon="briefcase" />
                    <span>{props.t("Companies")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="briefcase" />
                    <span>{props.t("RDCare Leads")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/viewRentdigiLeads">
                        {props.t("View Leads")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("RDCare Blogs")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/addblog">{props.t("Add Blogs")}</Link>
                    </li>
                    <li>
                      <Link to="/blog">{props.t("Blog List")}</Link>
                    </li>
                    <li>
                      <Link to="/editblog">{props.t("Edit Blog")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("RDCare Newsletters")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/newsletters">
                        {props.t("View Newsletters")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/vendor-specialty">
                        {props.t("Vendor Speciality")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="facilities-li">
                  <a
                    target="_blank"
                    href="http://sms.rentdigicare.com/admin"
                    className=""
                    rel="noreferrer"
                  >
                    <FeatherIcon icon="message-square" />
                    <span>{props.t("RDCare SMS")}</span>
                  </a>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );
    } else if (role === "company") {
      return (
        <React.Fragment>
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul
                className={` list-unstyled metismenu-${props.companyClass}`}
                id="side-menu"
              >
                <li>
                  <Link to="/dashboard" className="">
                    <FeatherIcon icon="grid" />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li className="facilities-li">
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="home" />
                    <span>{props.t("Properties")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/property_layout">
                        {props.t("Property Layout")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/property">{props.t("Property")}</Link>
                    </li>
                    <li>
                      <Link to="/property_manager">
                        {props.t("Property Manager")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/Accountant">{props.t("Accountant")}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="calendar" />
                    <span>{props.t("Calendar")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/calendar_reasons">
                        {props.t("Reason Types")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/calender_availabilty">
                        {props.t("Availability")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/appointments">
                        {props.t("Approve Appointments")}
                        {dashboarddata.pendingSlots
                          ? ` ( ${
                              dashboarddata.pendingSlots.total.pending ?? 0
                            } Pending )`
                          : null}
                      </Link>
                    </li>
                    <li>
                      <Link to="/apps-calendar">
                        {props.t("Calendar View")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/calendar-appointment-status">
                        {props.t("Calendar Status Types")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("Maintenance Request")} </span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/tickets_list">
                        {props.t("View Request")}
                        {dashboarddata.maintenanceTickets
                          ? ` ( ${dashboarddata.maintenanceTickets} Open )`
                          : null}
                      </Link>
                    </li>
                    <li>
                      <Link to="/request_type">{props.t("Request Types")}</Link>
                    </li>
                    <li>
                      <Link to="/permissions">
                        {props.t("Entry Permissions")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/emailnotification">
                        {props.t("Email Notification")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="monitor" />
                    <span>{props.t("Maintenance Team")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/technical_staff">
                        {props.t("View Maintenance Team")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/add_specialities">
                        {props.t("Add Category")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="users" />
                    <span>{props.t("Vendors")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/vendor-specialty">
                        {props.t("Add Category")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/viewvendor">{props.t("View Vendors")}</Link>
                    </li>
                    <li>
                      <Link to="/vendors">{props.t("Approve Vendors")}</Link>
                    </li>
                  </ul>
                </li>
                {decode.company == "GSK Properties" && (
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <FeatherIcon icon="briefcase" />
                      <span>{props.t("Leads")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/viewleads">{props.t("View Leads")}</Link>
                      </li>
                    </ul>
                  </li>
                )}
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="edit" />
                    <span>{props.t("Resident Applications")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/resident_application">
                        {props.t("Resident Applications")}
                        {dashboarddata.residentApplications
                          ? ` ( ${dashboarddata.residentApplications} Pending )`
                          : null}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/master_table">
                    <FeatherIcon icon="edit" />
                    <span> {props.t("Master sheet")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("Inventory")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/inventory/create">
                        {props.t("Add Inventory")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/inventory/vendors">
                        {props.t("Add Vendor")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/inventory/orderlist">
                        {props.t("Order List")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/inventory/pastorders">
                        {props.t("Past Order")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/inventory/consumeditems">
                        {props.t("Consumed Items")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/inventory">
                        {props.t("General Inventory")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/inventory/appliances">
                        {props.t("Appliances")}
                      </Link>
                    </li> */}
                  </ul>
                </li>

                {/* <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="trending-up" />
                    <span>Inspection Report</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/inspection-reports/common-area">
                        Common Area Inspections
                      </Link>
                    </li>
                    <li>
                      <Link to="/inspection-reports/view-inspections">
                        View Inspections
                      </Link>
                    </li>
                  </ul>
                </li> */}

                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("Report Card")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/reportcard/list">{props.t("Overview")}</Link>
                    </li>
                    <li>
                      <Link to="/reportcard/employee">
                        {props.t("CS Report Card")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/reportcard/communityleader">
                        {props.t("CL REport Card")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/reportcard/rnmpersonal">
                        {props.t("R&M Report Card")}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="settings" />
                    <span>{props.t("KPI Report")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/kpi-report">{props.t("KPI Report")}</Link>
                    </li>
                  </ul>
                </li>
                <li className="facilities-li">
                  <Link to="/widget">
                    <FeatherIcon icon="settings" />
                    <span> {props.t("Widget Settings")}</span>
                  </Link>
                </li>

                <li className="facilities-li">
                  <a
                    target="_blank"
                    href="http://sms.rentdigicare.com"
                    className=""
                    rel="noreferrer"
                  >
                    <FeatherIcon icon="message-square" />
                    <span>{props.t(`${user} SMS`)}</span>
                  </a>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );
    } else if (role === "manager") {
      return (
        <React.Fragment>
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul
                className={` list-unstyled metismenu-${props.companyClass}`}
                id="side-menu"
              >
                <li>
                  <Link to="/dashboard" className="">
                    <FeatherIcon icon="grid" />
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li className="facilities-li">
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="home" />
                    <span>{props.t("Properties")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/property">{props.t("Property")}</Link>
                    </li>
                    <li className="tanent_Information">
                      <Link to="/parking_info">{props.t("Parking")}</Link>
                    </li>
                    <li>
                      <Link to="/storage_info">{props.t("Storage")}</Link>
                    </li>
                  </ul>
                </li>
                {decode.calendarPrivilege && (
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <FeatherIcon icon="calendar" />
                      <span>{props.t("Calendar")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/apps-calendar">
                          {props.t("Calendar View")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/calender_availabilty">
                          {props.t("Availability")}
                        </Link>
                      </li>
                      <li>
                        <Link to="/appointments">
                          {props.t("Appointments")}
                          <br />
                          {appointmentsInfo &&
                          appointmentsInfo.pending !== null &&
                          appointmentsInfo.pending !== undefined &&
                          appointmentsInfo.pending !== 0
                            ? `${appointmentsInfo.pending} Pending`
                            : `0 Pending`}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
                {decode.ticketPrivilege && (
                  <>
                    <li>
                      <Link to="/#" className="has-arrow ">
                        <FeatherIcon icon="file-text" />
                        <span>{props.t("Maintenance Request")}</span>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/tickets_list">
                            {props.t("View Request")}
                            <br />
                            {maintenanceRequestInfo &&
                              maintenanceRequestInfo.open !== undefined &&
                              `${maintenanceRequestInfo.open.count} Open`}
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </>
                )}
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="monitor" />
                    <span>{props.t("Maintenance Team")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/technical_staff">
                        {props.t("View Maintenance Team")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="users" />
                    <span>{props.t("Vendors")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/viewvendor">{props.t("View Vendors")}</Link>
                    </li>
                  </ul>
                </li>
                {decode.applicationPrivilege && (
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <FeatherIcon icon="edit" />
                      <span>{props.t("Resident Applications")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/resident_application">
                          {`${props.t("Resident Applications")}`}
                          <br />
                          {`${
                            residentApplicationInfo &&
                            `${residentApplicationInfo.Pending} Pending`
                          }`}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
                {decode.managerOf == "https://www.gskproperties.ca/" && (
                  <li>
                    <Link to="/#" className="has-arrow ">
                      <FeatherIcon icon="briefcase" />
                      <span>{props.t("Leads")}</span>
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/viewleads">
                          {props.t("View Leads")}
                          <br />
                          {`${
                            viewLeadsInfo && `${viewLeadsInfo.pending} Pending`
                          }`}
                        </Link>
                      </li>
                    </ul>
                  </li>
                )}
                {decode.masterSheetPrivilege && (
                  <li>
                    <Link to="/master_table">
                      <FeatherIcon icon="settings" />
                      <span> {props.t("Master sheet")}</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/kpi-report">
                    <FeatherIcon icon="settings" />
                    <span> {props.t("KPI Report")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );
    } else if (role === "customer") {
      return (
        <React.Fragment>
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul
                className={` list-unstyled metismenu-${props.companyClass}`}
                id="side-menu"
              >
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("Maintenance Request")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/ticket_manager">
                        {props.t("Create Request")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/tickets_list">{props.t("View Requests")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );
    } else if (role === "vendor") {
      return (
        <React.Fragment>
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul
                className={` list-unstyled metismenu-${props.companyClass}`}
                id="side-menu"
              >
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("Maintenance Request")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/working-tickets">
                        {props.t("Working Request")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );
    } else if (role === "technical staff") {
      return (
        <React.Fragment>
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul
                className={` list-unstyled metismenu-${props.companyClass}`}
                id="side-menu"
              >
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="file-text" />
                    <span>{props.t("Maintenance Request")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/technical_staff_tickets">
                        {props.t("View Maintenance Request")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );
    } else if (role === "technical staff") {
      return (
        <React.Fragment>
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul
                className={` list-unstyled metismenu-${props.companyClass}`}
                id="side-menu"
              >
                <li>
                  <Link to="/#" className="has-arrow ">
                    <FeatherIcon icon="share-2" />
                    <span>{props.t("Maintenance Request")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/technical_staff_tickets">
                        {props.t("View Request")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );
    } else if (role === "accountant") {
      return (
        <React.Fragment>
          <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
            <div id="sidebar-menu">
              <ul
                className={` list-unstyled metismenu-${props.companyClass}`}
                id="side-menu"
              >
                <li>
                  <Link to="/kpi-report">
                    <FeatherIcon icon="settings" />
                    <span> {props.t("KPI Report")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/UploadKpi">
                    <FeatherIcon icon="briefcase" />
                    <span> {props.t("UploadKpi")}</span>
                  </Link>
                </li>
              </ul>
            </div>
          </SimpleBar>
        </React.Fragment>
      );
    } else {
      return <h1>last one</h1>;
    }
  } else {
    window.location.replace("/login");
  }
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
