import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";
import axios from "../api/axios";
import baseAxios from "axios";
import { toast } from "react-toastify";
import { HiDownload, HiCheckCircle } from "react-icons/hi";
import { FiUpload } from "react-icons/fi";
import { DocumentPreviewUI } from "./DocumentPreview";

const GET_DOCUMENTS_LIST = "mastersheet/documnet-list/";

const Documents = ({ userId, documents }) => {
  const dispatch = useDispatch();
  const [documentsList, setDocumentsList] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [file, setFile] = useState(null);
  const [previewURL, setPreviewURL] = useState("");
  const isMounted = useRef(true);

  const toggleClose = () => {
    setModal(false);
    window.location.reload();
  };
  const getDocumentList = useCallback(async (id) => {
    try {
      const res = await axios.get(`${GET_DOCUMENTS_LIST}${id}`);
      if (res && res.data && res.data.status === 200) {
        if (isMounted.current) {
          setDocumentsList(res.data.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleUploadFile = async () => {
    try {
      const formData = new FormData();
      formData.append("files", file, file.name);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      dispatch(isLoading(true));
      const res = await axios.post(
        `mastersheet/upload-documents/old-lease-info/${userId}/${selectedDocument.documentName}`,
        formData,
        config
      );
      if (
        res &&
        res.data &&
        res.data.message === "Files uploaded and document updated successfully"
      ) {
        toast.success("Document uploaded successfully!");
        if (isMounted.current) {
          await getDocumentList(userId);
          setModal(false);
          setFile(null);
          setSelectedDocument(null);
          window.location.reload();
        }
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.error(error);
    } finally {
      dispatch(isLoading(false));
    }
  };

  const handleDownload = async ({ documentUrl, documentName, expired }) => {
    try {
      if (expired.isExpired) {
        toast.error(
          "This document has expired. Please contact support for assistance."
        );
        return;
      }
      const resUrl = await axios.get("/mastersheet/get-content", {
        params: {
          fileUrl: documentUrl,
        },
      });
      dispatch(isLoading(true));
      window.location.href = resUrl.data.signedUrl;
      toast.success("Document download initiated.");
    } catch (error) {
      toast.error("Failed to initiate document download.");
      console.error(error);
    } finally {
      dispatch(isLoading(false));
    }
  };

  const handlePreview = async ({ documentUrl }) => {
    dispatch(isLoading(true));
    try {
      const resUrl = await axios.get("/mastersheet/get-content", {
        params: {
          fileUrl: documentUrl,
        },
      });
      if (isMounted.current) {
        setPreviewURL(resUrl.data.signedUrl);
      }
    } catch (error) {
      console.error("Error fetching content:", error);
    } finally {
      dispatch(isLoading(false));
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  useEffect(() => {
    if (userId) {
      getDocumentList(userId);
    }
  }, [userId, getDocumentList]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const findDocumentUrlByName = (name) => {
    const foundDocument = documents.find((doc) => doc.documentName === name);
    return foundDocument ? foundDocument.documentUrl : null;
  };

  const isDocumentExpired = (updatedAt, expirationInDays) => {
    const result = {
      isExpired: false,
      isDefined: expirationInDays !== undefined,
    };
    if (expirationInDays === undefined) {
      result.isExpired = true; // Treat as expired if expirationInDays is undefined
      return result;
    }
    if (expirationInDays === 0) {
      return result; // Document never expires if expirationInDays is 0
    }
    const expirationDate = new Date(updatedAt);
    expirationDate.setDate(expirationDate.getDate() + expirationInDays);
    const currentDate = new Date();
    result.isExpired = currentDate > expirationDate;
    return result;
  };

  const countDocuments = () => {
    let expiredCount = 0;
    let activeCount = 0;
    let pendingCount = documentsList.length; // Initially all are pending

    documentsList.forEach((doc) => {
      const documentData = documents.find(
        (_doc) => _doc.documentName === doc.label
      );
      if (documentData) {
        pendingCount--; // Decrement pending count as we find documents
        const isExpired = isDocumentExpired(
          documentData.updatedAt,
          documentData.expirationInDays
        );
        if (isExpired.isExpired) {
          expiredCount++;
        } else {
          activeCount++;
        }
      }
    });

    return { expiredCount, activeCount, pendingCount };
  };

  const { expiredCount, activeCount, pendingCount } = countDocuments();

  return (
    <>
      <div className="frm_InnerSec1 mt-3">
        <h4 className="cmnHding">Documents</h4>
        <div className="innnerFrmData">
          <div>
            {documentsList.map((doc, idx) => (
              <div
                key={idx}
                className={
                  isDocumentExpired(
                    documents.find((_doc) => _doc.documentName === doc.label)
                      ?.updatedAt,
                    documents.find((_doc) => {
                      return _doc.documentName === doc.label;
                    })?.expirationInDays
                  ).isDefined
                    ? "text-white btn btn-success m-1"
                    : "text-white btn btn-danger m-1"
                }
                onClick={() => {
                  const documentSelected = {
                    documentName: doc.label,
                    documentUrl: findDocumentUrlByName(doc.label),
                    expired: isDocumentExpired(
                      documents.find((_doc) => _doc.documentName === doc.label)
                        ?.updatedAt,
                      documents.find((_doc) => _doc.documentName === doc.label)
                        ?.expirationInDays
                    ),
                  };
                  setSelectedDocument(documentSelected);
                  setModal(true);
                  handlePreview({
                    documentUrl: findDocumentUrlByName(doc.label),
                  });
                }}
              >
                <span>{doc?.label}</span>
                <div>
                  {isDocumentExpired(
                    documents.find((_doc) => _doc.documentName === doc.label)
                      ?.updatedAt,
                    documents.find((_doc) => {
                      return _doc.documentName === doc.label;
                    })?.expirationInDays
                  ).isDefined ? (
                    <FiUpload className="mx-2" style={{ color: "green" }} />
                  ) : (
                    <FiUpload className="mx-2" />
                  )}
                </div>
              </div>
            ))}
            <div className="btn btn-light m-1 p-3 d-flex flex-column align-items-start">
              <div
                className="text-success font-weight-bold"
                style={{ fontSize: "16px" }}
              >
                Active: {activeCount}
              </div>
              <div
                className="text-warning font-weight-bold"
                style={{ fontSize: "16px" }}
              >
                Pending: {pendingCount}
              </div>
              <div
                className="text-danger font-weight-bold"
                style={{ fontSize: "16px" }}
              >
                Expired: {expiredCount}
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal && selectedDocument && (
        <Modal isOpen={modal} toggle={toggleClose} size={"lg"}>
          <ModalBody>
            <h5>{selectedDocument.documentName}</h5>
            <div>
              {selectedDocument.documentUrl ? (
                <>
                  <Button
                    color="primary"
                    onClick={() => {
                      handleDownload(selectedDocument);
                    }}
                  >
                    <HiDownload /> Download
                  </Button>
                </>
              ) : (
                <p>No document uploaded yet</p>
              )}
            </div>
            <div
              {...getRootProps({ className: "dropzone" })}
              className="p-3 border border-primary mt-3"
            >
              <input {...getInputProps()} />
              <div className="text-center">
                <FiUpload className="p-2" size={48} color="rgb(0 0 0)" />
                <p className="mt-2">
                  Drag & drop files here, or click to select files
                </p>
              </div>
            </div>
            {file?.name && <p>Selected File: {file?.name}</p>}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleUploadFile}>
              Upload
            </Button>
            <Button color="secondary" onClick={toggleClose}>
              Cancel
            </Button>
          </ModalFooter>

          <div className="row mt-5 p-5">
            <DocumentPreviewUI
              documentUrl={selectedDocument.documentUrl}
              previewURL={previewURL}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default Documents;
