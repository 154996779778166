import "./styles.css";
import { useEffect, useState } from "react";
import Select from "react-select";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import FeatherIcon from "feather-icons-react";
import SweetAlert from "react-bootstrap-sweetalert";
import jwt_decode from "jwt-decode";
import { Row, Collapse, Col } from "reactstrap";
import { useFormik } from "formik";
import { formatBytes } from "./helper";
import axios from "../api/axios";

const Checkbox = ({ formik, name }) => {
  const [category, subCategory] = name.split(".");
  const { needswork, file, notes } = formik.values[category][subCategory];

  const handleChange = () => {
    formik.setFieldValue(name, {
      needswork: !needswork,
      file: file,
      notes,
    });
  };

  const handleAcceptedFiles = (files) => {
    const processedFiles = files.map((file) => ({
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
      name: file.name,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      size: file.size,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath,
    }));

    formik.setFieldValue(name, {
      needswork: needswork,
      file: [...file, ...processedFiles], // Concatenate new files with existing files
      notes: notes,
    });
  };

  return (
    <>
      <div
        className="checkbox-wrapper-3"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="form-check form-switch ms-4">
          <input
            className="form-check-input fs-4"
            type="checkbox"
            id={name}
            checked={needswork}
            onChange={handleChange}
          />
        </div>
        {needswork && (
          <>
            <div className="dropzone-previews row px-4" id="file-previews">
              {file &&
                file.map((f, i) => (
                  <Row
                    className="align-items-center no-gutters"
                    key={i + "-file"}
                  >
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        alt={f.name}
                        src={f.preview}
                      />
                    </Col>
                    <Col>
                      <div
                        className="text-muted"
                        style={{ fontSize: "0.8rem" }}
                      >
                        {f.name}
                      </div>
                      <p className="mb-0" style={{ fontSize: "0.8rem" }}>
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col className="col-auto">
                      <button
                        type="button"
                        className="btn btn-soft-danger waves-effect waves-light"
                        onClick={(e) => {
                          formik.setFieldValue(name, {
                            needswork: needswork,
                            file: file.filter((_, index) => index !== i),
                            notes: notes,
                          });
                        }}
                      >
                        <i className="bx bx-trash-alt"></i>
                      </button>
                    </Col>
                  </Row>
                ))}
            </div>
            <Dropzone
              onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
              multiple
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <FeatherIcon icon="file" className="icon-lg" />
                </div>
              )}
            </Dropzone>
          </>
        )}
      </div>
    </>
  );
};

const ApiAlert = ({
  type = "confirm",
  visible = false,
  setVisible,
  formik,
}) => {
  if (!visible) return null;

  let title = "";
  let confirmButtonText = "";
  let confirmBtnBsStyle = "";
  let message = "";
  let onConfirm = () => {};

  switch (type) {
    case "confirm":
      title = "Are you sure?";
      confirmButtonText = "Confirm";
      confirmBtnBsStyle = "primary";
      message = "This action cannot be undone.";
      onConfirm = () => {
        formik.handleSubmit();
        setVisible(false);
      };
      break;
    case "success":
      title = "Success!";
      confirmButtonText = "OK";
      confirmBtnBsStyle = "success";
      message = "Operation completed successfully." + formik.status;
      onConfirm = () => {
        setVisible(false);
      };
      break;
    case "error":
      title = "Error!";
      confirmButtonText = "OK";
      confirmBtnBsStyle = "danger";
      message = "An error occurred. Please try again." + formik.status;
      onConfirm = () => {
        setVisible(false);
      };
      break;
    default:
      return null;
  }

  return (
    <SweetAlert
      title={title}
      warning={type === "confirm"}
      showCancel={type === "confirm"}
      confirmButtonText={confirmButtonText}
      confirmBtnBsStyle={confirmBtnBsStyle}
      cancelBtnBsStyle="danger"
      onConfirm={onConfirm}
      onCancel={() => setVisible(false)}
    >
      {message}
    </SweetAlert>
  );
};

const CommonAreaInspection = () => {
  const { id, role } = jwt_decode(window.localStorage.getItem("accessToken"));
  const [openAccordion, setOpenAccordion] = useState([]);
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState("confirm");

  const [staffMembers, setStaffMembers] = useState([]);
  useEffect(() => {
    const fetchStaffMembers = async () => {
      try {
        const response = await axios.get(
          `/inspection-report/staff-members/${id}`
        );
        const data = response.data;
        const manipulatedData = data.map((member) => ({
          value: member._id,
          label: `${member.Name} (${member.Type})`,
        }));
        setStaffMembers(manipulatedData);
      } catch (error) {
        console.error("Error fetching staff members:", error);
      }
    };

    fetchStaffMembers();
  }, [id]);

  const toggleAccordion = (accordionId) => {
    if (openAccordion.includes(accordionId)) {
      setOpenAccordion(openAccordion.filter((id) => id !== accordionId));
    } else {
      setOpenAccordion([...openAccordion, accordionId]);
    }
  };

  const isFieldTouched = (category) => {
    return Object.keys(category).some((subCategory) => {
      const subCat = category[subCategory];
      if (Array.isArray(subCat)) {
        return subCat.some((item) =>
          Object.values(item).some((value) => value !== "" && value !== false)
        );
      }
      return (
        subCat.needswork ||
        subCat.notes ||
        (Array.isArray(subCat.file) && subCat.file.length > 0)
      );
    });
  };

  const validationSchema = Yup.object().shape({
    interior: Yup.object().test(
      "isTouched",
      "At least one field in interior must be touched",
      (value) => isFieldTouched(value)
    ),
    exterior: Yup.object().test(
      "isTouched",
      "At least one field in exterior must be touched",
      (value) => isFieldTouched(value)
    ),
    parkade: Yup.object().test(
      "isTouched",
      "At least one field in parkade must be touched",
      (value) => isFieldTouched(value)
    ),
    mechanical_rooms: Yup.object().shape({
      issues: Yup.array().test(
        "isTouched",
        "At least one issue in mechanical rooms must be touched",
        (issues) =>
          issues.some((issue) =>
            Object.values(issue).some(
              (value) => value !== "" && value !== false
            )
          )
      ),
    }),
    miscellaneous_items: Yup.object().shape({
      issues: Yup.array().test(
        "isTouched",
        "At least one issue in miscellaneous items must be touched",
        (issues) =>
          issues.some((issue) =>
            Object.values(issue).some(
              (value) => value !== "" && value !== false
            )
          )
      ),
    }),
    monthly_gameplan: Yup.object().shape({
      issues: Yup.array().test(
        "isTouched",
        "At least one issue in monthly gameplan must be touched",
        (issues) =>
          issues.some((issue) =>
            Object.values(issue).some(
              (value) => value !== "" && value !== false
            )
          )
      ),
    }),
  });

  const formik = useFormik({
    initialValues: {
      user_id: id,
      CreatedByType: role,
      interior: {
        entrance_lobby: {
          needswork: false,
          file: [],
          notes: "",
        },
        elevator_lobby: {
          needswork: false,
          file: [],
          notes: "",
        },
        carpets: {
          needswork: false,
          file: [],
          notes: "",
        },
        walls: {
          needswork: false,
          file: [],
          notes: "",
        },
        lights: {
          needswork: false,
          file: [],
          notes: "",
        },
        hallway_doors: {
          needswork: false,
          file: [],
          notes: "",
        },
        stairwell: {
          needswork: false,
          file: [],
          notes: "",
        },
      },
      exterior: {
        entrance_lobby: {
          needswork: false,
          file: [],
          notes: "",
        },
        landscaping: {
          needswork: false,
          file: [],
          notes: "",
        },
        windows: {
          needswork: false,
          file: [],
          notes: "",
        },
        building_appearance: {
          needswork: false,
          file: [],
          notes: "",
        },
        lights: {
          needswork: false,
          file: [],
          notes: "",
        },
        entrance_doors: {
          needswork: false,
          file: [],
          notes: "",
        },
        sidewalks: {
          needswork: false,
          file: [],
          notes: "",
        },
        garage_bin_area: {
          needswork: false,
          file: [],
          notes: "",
        },
        fence: {
          needswork: false,
          file: [],
          notes: "",
        },
        parking_lot: {
          needswork: false,
          file: [],
          notes: "",
        },
        roof: {
          needswork: false,
          file: [],
          notes: "",
        },
      },
      parkade: {
        garage_doors: {
          needswork: false,
          file: [],
          notes: "",
        },
        exit_doors: {
          needswork: false,
          file: [],
          notes: "",
        },
        maintenance_doors: {
          needswork: false,
          file: [],
          notes: "",
        },
        parking_stalls: {
          needswork: false,
          file: [],
          notes: "",
        },
        elevator_lobby: {
          needswork: false,
          file: [],
          notes: "",
        },
      },
      mechanical_rooms: {
        issues: [
          {
            issue: "",
            action_taken: false,
            date: "",
            notes: "",
          },
        ],
      },
      miscellaneous_items: {
        issues: [
          {
            issue: "",
            location: "",
            notes: "",
          },
        ],
      },
      monthly_gameplan: {
        issues: [
          {
            issue: "",
            assigned_to: [],
            duedate: "",
          },
        ],
      },
    },
    // validationSchema,
    onSubmit: async (values, { setStatus, resetForm }) => {
      setType("confirm");
      setVisible(true);
      const filteredData = filterValues(values);
      const dataToUpload = extractFiles(filteredData);

      try {
        const uploadedFiles = await uploadFiles(dataToUpload);
        console.log(uploadedFiles);
        console.log(uploadedFiles, filteredData);
        replaceFilesWithUrls(filteredData, uploadedFiles);

        await axios.post("/inspection-report", filteredData);
        setStatus("Form submitted successfully");
        setType("success");
        resetForm();
      } catch (error) {
        setStatus("Error submitting form");
        setType("error");
      } finally {
        setVisible(true);
      }
    },
  });

  const filterValues = (values) => {
    const filtered = { ...values };

    Object.keys(filtered).forEach((category) => {
      if (typeof filtered[category] === "object") {
        Object.keys(filtered[category]).forEach((subCategory) => {
          if (filtered[category][subCategory].needswork === false) {
            delete filtered[category][subCategory];
          }
        });
      }
    });

    return filtered;
  };

  const extractFiles = (values) => {
    const files = [];

    Object.keys(values).forEach((category) => {
      if (typeof values[category] === "object") {
        Object.keys(values[category]).forEach((subCategory) => {
          if (
            values[category][subCategory].file &&
            values[category][subCategory].file.length > 0
          ) {
            files.push({
              category,
              subCategory,
              file: values[category][subCategory].file,
            });
          }
        });
      }
    });

    return files;
  };

const uploadFiles = async (data) => {
  const uploadedFiles = {};

  for (const item of data) {
    const formData = new FormData();
    item.file.forEach((file) => formData.append("file", file));

    try {
      const response = await axios.post(
        "/inspection-report/upload-documents",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      alert(response.data);
      uploadedFiles[`${item.category}.${item.subCategory}`] =
        response.data.fileUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  return uploadedFiles;
};


  const replaceFilesWithUrls = (values, uploadedFiles) => {
    Object.keys(uploadedFiles).forEach((key) => {
      const [category, subCategory] = key.split(".");
      values[category][subCategory].file = uploadedFiles[key];
    });
  };

  const handleFieldChange = (
    caseType = "mechanical_rooms",
    action = "add",
    index
  ) => {
    switch (action) {
      case "add":
        switch (caseType) {
          case "mechanical_rooms":
            formik.setFieldValue("mechanical_rooms.issues", [
              ...formik.values.mechanical_rooms.issues,
              {
                issue: "",
                action_taken: [],
                date: "",
                notes: "",
              },
            ]);
            break;
          case "miscellaneous_items":
            formik.setFieldValue("miscellaneous_items.issues", [
              ...formik.values.miscellaneous_items.issues,
              {
                issue: "",
                location: "",
                notes: "",
              },
            ]);
            break;
          case "monthly_gameplan":
            formik.setFieldValue("monthly_gameplan.issues", [
              ...formik.values.monthly_gameplan.issues,
              {
                issue: "",
                action_taken: [],
                date: "",
                notes: "",
              },
            ]);
            break;
          default:
            console.warn(`Unknown case type: ${caseType}`);
        }
        break;
      case "remove":
        switch (caseType) {
          case "mechanical_rooms":
            const mechanicalRoomIssues = [
              ...formik.values.mechanical_rooms.issues,
            ];
            if (index === undefined) index = mechanicalRoomIssues.length - 1;
            mechanicalRoomIssues.splice(index, 1);
            formik.setFieldValue(
              "mechanical_rooms.issues",
              mechanicalRoomIssues
            );
            break;
          case "miscellaneous_items":
            const miscellaneousItemIssues = [
              ...formik.values.miscellaneous_items.issues,
            ];
            if (index === undefined) index = miscellaneousItemIssues.length - 1;
            miscellaneousItemIssues.splice(index, 1);
            formik.setFieldValue(
              "miscellaneous_items.issues",
              miscellaneousItemIssues
            );
            break;
          case "monthly_gameplan":
            const monthlyGamePlanIssues = [
              ...formik.values.monthly_gameplan.issues,
            ];
            if (index === undefined) index = monthlyGamePlanIssues.length - 1;
            monthlyGamePlanIssues.splice(index, 1);
            formik.setFieldValue(
              "monthly_gameplan.issues",
              monthlyGamePlanIssues
            );
            break;
          default:
            console.warn(`Unknown case type: ${caseType}`);
        }
        break;
      default:
        console.warn(`Unknown action type: ${action}`);
    }
  };
  return (
    <div>
      <div
        className=""
        style={{
          marginTop: "4.5rem",
          padding: "1rem",
          backgroundColor: "#0D568F",
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between", // Added this line
          alignItems: "center", // Optional: Align items vertically
        }}
      >
        <h2
          className="text-white"
          style={{
            fontWeight: "400",
          }}
        >
          Common Area Inspection Report
        </h2>
        <img src="/images/rentdigicare-logo.svg" alt="rdcare-logo" />
      </div>
      <div className="container-fluid p-5">
        <div
          className="d-flex align-items-center justify-content-between mb-4"
          onClick={() => toggleAccordion(2)}
        >
          <h5
            className="text-black"
            style={{ fontWeight: "500", marginRight: "auto" }}
          >
            INTERIOR
          </h5>
          <button className="btn btn-link">
            <FeatherIcon
              icon={openAccordion.includes(2) ? "chevron-up" : "chevron-down"}
            />
          </button>
        </div>
        <Collapse className="p-10" isOpen={openAccordion.includes(2)}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-3">CATEGORY</th>
                  <th className="col-3">NEEDS WORK</th>
                  <th className="col-6">NOTES</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="col-2">ENTRANCE LOBBY</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="interior.entrance_lobby" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.interior.entrance_lobby.notes}
                      onChange={formik.handleChange}
                      name="interior.entrance_lobby.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">ELEVATOR LOBBY</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="interior.elevator_lobby" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.interior.elevator_lobby.notes}
                      onChange={formik.handleChange}
                      name="interior.elevator_lobby.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">CARPETS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="interior.carpets" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.interior.carpets.notes}
                      onChange={formik.handleChange}
                      name="interior.carpets.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">WALLS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="interior.walls" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.interior.walls.notes}
                      onChange={formik.handleChange}
                      name="interior.walls.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">LIGHTS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="interior.lights" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.interior.lights.notes}
                      onChange={formik.handleChange}
                      name="interior.lights.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">HALLWAY DOORS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="interior.hallway_doors" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.interior.hallway_doors.notes}
                      onChange={formik.handleChange}
                      name="interior.hallway_doors.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">STAIRWELL</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="interior.stairwell" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.interior.stairwell.notes}
                      onChange={formik.handleChange}
                      name="interior.stairwell.notes"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
        <div
          className=" d-flex align-items-center justify-content-between mb-3"
          onClick={() => toggleAccordion(3)}
        >
          <h5
            className="text-black"
            style={{ fontWeight: "500", marginRight: "auto" }}
          >
            EXTERIOR
          </h5>
          <button className="btn btn-link">
            <FeatherIcon
              icon={openAccordion.includes(3) ? "chevron-up" : "chevron-down"}
            />
          </button>
        </div>
        <Collapse className="p-10" isOpen={openAccordion.includes(3)}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-2">CATEGORY</th>
                  <th className="col-5">NEEDS WORK</th>
                  <th className="col-5">NOTES</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="col-2">ENTRANCE LOBBY</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.entrance_lobby" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.entrance_lobby.notes}
                      onChange={formik.handleChange}
                      name="exterior.entrance_lobby.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">LANDSCAPING</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.landscaping" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.entrance_lobby.notes}
                      onChange={formik.handleChange}
                      name="exterior.entrance_lobby.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">WINDOWS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.windows" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.windows.notes}
                      onChange={formik.handleChange}
                      name="exterior.windows.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">BUILDING APPEARANCE</td>
                  <td className="col-5">
                    <Checkbox
                      formik={formik}
                      name="exterior.building_appearance"
                    />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.building_appearance.notes}
                      onChange={formik.handleChange}
                      name="exterior.building_appearance.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">LIGHTS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.lights" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.lights.notes}
                      onChange={formik.handleChange}
                      name="exterior.lights.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">ENTRANCE DOORS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.entrance_doors" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.entrance_doors.notes}
                      onChange={formik.handleChange}
                      name="exterior.entrance_doors.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">SIDEWALKS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.sidewalks" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.sidewalks.notes}
                      onChange={formik.handleChange}
                      name="exterior.sidewalks.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">GARAGE BIN AREA</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.garage_bin_area" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.garage_bin_area.notes}
                      onChange={formik.handleChange}
                      name="exterior.garage_bin_area.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">FENCE</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.fence" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.fence.notes}
                      onChange={formik.handleChange}
                      name="exterior.fence.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">PARKING LOT</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.parking_lot" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.parking_lot.notes}
                      onChange={formik.handleChange}
                      name="exterior.parking_lot.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">ROOF</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="exterior.roof" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.exterior.roof.notes}
                      onChange={formik.handleChange}
                      name="exterior.roof.notes"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
        <div
          className="d-flex align-items-center justify-content-between mb-3"
          onClick={() => toggleAccordion(4)}
        >
          <h5
            className="text-black"
            style={{ fontWeight: "500", marginRight: "auto" }}
          >
            PARKADE
          </h5>
          <button className="btn btn-link">
            <FeatherIcon
              icon={openAccordion.includes(4) ? "chevron-up" : "chevron-down"}
            />
          </button>
        </div>
        <Collapse className="p-10" isOpen={openAccordion.includes(4)}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-2">CATEGORY</th>
                  <th className="col-5">NEEDS WORK</th>
                  <th className="col-5">NOTES</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="col-2">GARAGE DOORS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="parkade.garage_doors" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.parkade.garage_doors.notes}
                      onChange={formik.handleChange}
                      name="parkade.roof.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">EXIT DOORS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="parkade.exit_doors" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.parkade.exit_doors.notes}
                      onChange={formik.handleChange}
                      name="parkade.exit_doors.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">MAINTENANCE DOORS</td>
                  <td className="col-5">
                    <Checkbox
                      formik={formik}
                      name="parkade.maintenance_doors"
                    />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.parkade.maintenance_doors.notes}
                      onChange={formik.handleChange}
                      name="parkade.maintenance_doors.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">PARKING STALLS</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="parkade.parking_stalls" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.parkade.parking_stalls.notes}
                      onChange={formik.handleChange}
                      name="parkade.parking_stalls.notes"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="col-2">ELEVATOR LOBBY</td>
                  <td className="col-5">
                    <Checkbox formik={formik} name="parkade.elevator_lobby" />
                  </td>
                  <td className="col-5">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Notes"
                      value={formik.values.parkade.elevator_lobby.notes}
                      onChange={formik.handleChange}
                      name="parkade.elevator_lobby.notes"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
        <div
          className="d-flex align-items-center justify-content-between mb-3"
          onClick={() => toggleAccordion(5)}
        >
          <h5
            className="text-black"
            style={{ fontWeight: "500", marginRight: "auto" }}
          >
            MECHANICAL ROOMS
          </h5>
          <button className="btn btn-link">
            <FeatherIcon
              icon={openAccordion.includes(5) ? "chevron-up" : "chevron-down"}
            />
          </button>
        </div>
        <Collapse className="p-10" isOpen={openAccordion.includes(5)}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-4">ISSUE</th>
                  <th className="col-2">ACTION TAKEN</th>
                  <th className="col-2">DATE</th>
                  <th className="col-4">NOTES</th>
                </tr>
              </thead>
              <tbody>
                {formik.values.mechanical_rooms.issues.map((issue, index) => (
                  <tr key={index}>
                    <td className="col-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Issue Description"
                        value={issue.issue}
                        onChange={(e) => {
                          const newIssues = [
                            ...formik.values.mechanical_rooms.issues,
                          ];
                          newIssues[index].issue = e.target.value;
                          formik.setFieldValue(
                            "mechanical_rooms.issues",
                            newIssues
                          );
                        }}
                      />
                    </td>
                    <td className="col-2">
                      <Select
                        options={[
                          { value: true, label: "Yes" },
                          { value: false, label: "No" },
                        ]}
                        value={
                          issue.action_taken === "yes"
                            ? { value: true, label: "Yes" }
                            : { value: false, label: "No" }
                        }
                        onChange={(selectedOption) => {
                          const newIssues = [
                            ...formik.values.mechanical_rooms.issues,
                          ];
                          newIssues[index].action_taken = selectedOption.value;
                          formik.setFieldValue(
                            "mechanical_rooms.issues",
                            newIssues
                          );
                        }}
                      />
                    </td>
                    <td className="col-2">
                      <input
                        type="date"
                        className="form-control"
                        value={issue.date}
                        onChange={(e) => {
                          const newIssues = [
                            ...formik.values.mechanical_rooms.issues,
                          ];
                          newIssues[index].date = e.target.value;
                          formik.setFieldValue(
                            "mechanical_rooms.issues",
                            newIssues
                          );
                        }}
                      />
                    </td>
                    <td className="col-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Notes"
                        value={issue.notes}
                        onChange={(e) => {
                          const newIssues = [
                            ...formik.values.mechanical_rooms.issues,
                          ];
                          newIssues[index].notes = e.target.value;
                          formik.setFieldValue(
                            "mechanical_rooms.issues",
                            newIssues
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        handleFieldChange("mechanical_rooms", "add")
                      }
                    >
                      ADD MORE ISSUE +
                    </button>
                  </td>
                  {formik.values.mechanical_rooms.issues.length >= 1 && (
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() =>
                          handleFieldChange("mechanical_rooms", "remove")
                        }
                      >
                        REMOVE -
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
        <div
          className="d-flex align-items-center justify-content-between mb-3"
          onClick={() => toggleAccordion(6)}
        >
          <h5
            className="text-black"
            style={{ fontWeight: "500", marginRight: "auto" }}
          >
            MISCELLANEOUS ITEMS/OTHER IMPROVEMENTS/LAUNDRY LIST ITEMS/
          </h5>
          <button className="btn btn-link">
            <FeatherIcon
              icon={openAccordion.includes(6) ? "chevron-up" : "chevron-down"}
            />
          </button>
        </div>
        <Collapse className="p-10" isOpen={openAccordion.includes(6)}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-3">ISSUE</th>
                  <th className="col-3">LOCATION</th>
                  <th className="col-6">NOTES</th>
                </tr>
              </thead>
              <tbody>
                {formik.values.miscellaneous_items.issues.map(
                  (issue, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Issue Description"
                          value={issue.issue}
                          onChange={(e) => {
                            const newIssues = [
                              ...formik.values.miscellaneous_items.issues,
                            ];
                            newIssues[index].issue = e.target.value;
                            formik.setFieldValue(
                              "miscellaneous_items.issues",
                              newIssues
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Issue Description"
                          value={issue.location}
                          onChange={(e) => {
                            const newIssues = [
                              ...formik.values.miscellaneous_items.issues,
                            ];
                            newIssues[index].location = e.target.value;
                            formik.setFieldValue(
                              "miscellaneous_items.issues",
                              newIssues
                            );
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Notes"
                          value={issue.notes}
                          onChange={(e) => {
                            const newIssues = [
                              ...formik.values.miscellaneous_items.issues,
                            ];
                            newIssues[index].notes = e.target.value;
                            formik.setFieldValue(
                              "mechanical_rooms.issues",
                              newIssues
                            );
                          }}
                        />
                      </td>
                    </tr>
                  )
                )}
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        handleFieldChange("miscellaneous_items", "add")
                      }
                    >
                      ADD MORE ISSUE +
                    </button>
                  </td>
                  {formik.values.mechanical_rooms.issues.length >= 1 && (
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() =>
                          handleFieldChange("miscellaneous_items", "remove")
                        }
                      >
                        REMOVE -
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
        <div
          className="d-flex align-items-center justify-content-between mb-3"
          onClick={() => toggleAccordion(7)}
        >
          <h5
            className="text-black"
            style={{ fontWeight: "500", marginRight: "auto" }}
          >
            MONTHLY GAMEPLAN - LEAVE BLANK UNTIL REPORT MEETING
          </h5>
          <button className="btn btn-link">
            <FeatherIcon
              icon={openAccordion.includes(7) ? "chevron-up" : "chevron-down"}
            />
          </button>
        </div>
        <Collapse className="p-10" isOpen={openAccordion.includes(7)}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="col-3">ISSUE</th>
                  <th className="col-3">ASSIGNED TO</th>
                  <th className="col-6">DUE DATE</th>
                </tr>
              </thead>
              <tbody>
                {formik.values.monthly_gameplan.issues.map((issue, index) => (
                  <tr key={index}>
                    <td className="col-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Issue Description"
                        value={issue.issue}
                        onChange={(e) => {
                          const newIssues = [
                            ...formik.values.monthly_gameplan.issues,
                          ];
                          newIssues[index].issue = e.target.value;
                          formik.setFieldValue(
                            "monthly_gameplan.issues",
                            newIssues
                          );
                        }}
                      />
                    </td>
                    <td className="col-4">
                      <Select
                        isMulti
                        options={staffMembers}
                        value={formik.values.monthly_gameplan?.issues[
                          index
                        ]?.assigned_to?.map((loc) => ({
                          value: loc,
                          label: loc,
                        }))} // Ensure the value is an array of objects with 'value' and 'label' properties
                        onChange={(selectedOptions) => {
                          const newIssues = [
                            ...formik.values.monthly_gameplan.issues,
                          ];
                          newIssues[index].assigned_to = selectedOptions.map(
                            (option) => option.value
                          );
                          formik.setFieldValue(
                            "monthly_gameplan.issues",
                            newIssues
                          );
                        }}
                        placeholder="Select Staff Members"
                      />
                    </td>
                    <td className="col-4">
                      <input
                        type="date"
                        className="form-control"
                        placeholder="Enter Date"
                        value={issue.date}
                        onChange={(e) => {
                          const newIssues = [
                            ...formik.values.monthly_gameplan.issues,
                          ];
                          newIssues[index].date = e.target.value;
                          formik.setFieldValue(
                            "monthly_gameplan.issues",
                            newIssues
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        handleFieldChange("monthly_gameplan", "add")
                      }
                    >
                      ADD MORE ISSUE +
                    </button>
                  </td>
                  {formik.values.monthly_gameplan.issues.length >= 1 && (
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() =>
                          handleFieldChange("monthly_gameplan", "remove")
                        }
                      >
                        REMOVE -
                      </button>
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </Collapse>
        <button
          type="button"
          className="mb-5 btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary"
          onClick={() => {
            setVisible(true);
            setType("confirm");
          }}
        >
          Submit Report
        </button>
      </div>
      <ApiAlert
        type={type}
        visible={visible}
        setVisible={setVisible}
        formik={formik}
      />
    </div>
  );
};

export { CommonAreaInspection };
