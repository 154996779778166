import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import jwt_decode from "jwt-decode";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import { useParams } from "react-router-dom";
import BreadcrumbV3 from "../../components/Common/BreadcrumbV3";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  CardHeader,
  FormGroup,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";

import axios from "../api/axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";
import { SERVER_URL } from "../ServerLink";

const GET_ACCOUNTED_LIST = "/accountand/list";
const POST_CREATE_PAD = "/paddInfo/add";
const GET_PAD_LIST = "/paddInfo/allPadds";


const TenantAttachments = () => {

  const dispatch = useDispatch();
  const decode = jwt_decode(window.localStorage.getItem("accessToken"));
  const { userData } = JSON.parse(window.localStorage.getItem("authUser"));
  const [fileName, setFileName] = useState("");
  const [fileComment, setfileComment] = useState("");
  const [file, setFile] = useState(null);

  // form states

  const [documentList, setDocumentList] = useState([]);

  const [createNewPadInfo, setNewPadInfo] = useState({
    newAmount: 0.0,
    changeAmount: 0.0,
    padStatus: "",
    padComment: "",
    userId: ""
  });

  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [modal_large, setmodal_large] = useState(false);

  function tog_large() {
    debugger;
    setmodal_large(!modal_large);
  }

  const handleOnChangePersonal = (event) => {
    const { name, value } = event.target;
    setNewPadInfo({ ...createNewPadInfo, [name]: value });
  }

  const handleUploadFile = async () => {
    try {
      debugger;
      const formData = new FormData();
      // formData.append("images", image);

      formData.append("documentFile", file,fileName);
      //formData.append("comment", fileComment);

      dispatch(isLoading(true));
      const res = await axios.post("/mastersheet/upload-tenant-Documents", formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            "comment": fileComment,
            "userid":id,
            "filename": fileName

          }
        });

      debugger;
      dispatch(isLoading(false));

      //const res = await axios.post("", formData, config);
      if (res && res.data && res.data.status === 200) {
        toast.success("Document uploaded successfully!");
          await loadDocumentsList();
        tog_large();
        // setFile(null);
        // setFileName("");
      }
      dispatch(isLoading(false));
    } catch (error) {
      debugger;

      dispatch(isLoading(false));
      toast.error("Something went wrong!");
      console.error(error);
    }
  }

  const handleCloseModal = () => {
    tog_large();
  };



  useEffect(() => {
    loadDocumentsList();
  }, []);


  const loadDocumentsList = async () => {
    try {
      debugger;
      const res = await axios.get("/mastersheet/tenant-documnet-list" + "/" + id);
      debugger;
      setLoading(false)
      if (res.data.status === 200) {
        setDocumentList(res.data.data)
      }
      else
        toast.error("Something went wrong!");

    }
    catch (error) {
      setLoading(false)
      // dispatch(isLoading(false));
      toast.error("Something went wrong!");
      console.error(error);
    }

  }

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  const dateBodyTemplate = (rowData) => {
    debugger;
    return formatDate(rowData.createdAt);
  };

  const imageBodyTemplate = (item) => {
    debugger;
    return <img src={SERVER_URL +"/Document/Customer"+`/${item.docName}`}  style={{width:"250px", height:"150px"}} className="w-6rem shadow-2 border-round" />;
};


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Rentdigicare | Tenant Attachments</title>
        </MetaTags>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Home" breadcrumbItem="Tenant Attachments" /> */}

          <BreadcrumbV3
            title={
              <>
                Tenant Attachments
              </>
            }
            breadcrumbItems={[
              {
                item: "Home",
                link: "/dashboard",
              },
              {
                item: "Master Sheet",
                link: "/master_table",
              },
              {
                item: "Tenant Information",
                link: `/tenant_info/${id}`,
              },
            ]}
          />



          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <div className="row align-items-center">
                    <div className="col-md-12 d-flex flex-wrap align-items-end justify-content-end">
                      <div className="mb">
                        {(userData.role === "company") ? (
                          <button
                            type="button"
                            onClick={() => {
                              tog_large();
                            }}
                            className="btn btn-light "
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg" >
                            <i className="bx bx-plus me-1"></i>Add
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    {/* <DataTable value={paddsData} showGridlines stripedRows paginator rows={10} dataKey="id"
                      filterDisplay="row" loading={loading} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                       emptyMessage="No customers found.">
                        <Column field="newAmount" sortable header="New Amount" style={{ minWidth: '12rem' }} />
                        <Column field="changeAmount" header="Change Amount" sortable  ></Column>
                        <Column field="paddStatus" header="Status" sortable filterField="paddStatus" />
                        <Column field="comment" sortable header="Comment" style={{ minWidth: '12rem' }} />
                        <Column field="createdAt" sortable header="Created On" dataType="date"  body={dateBodyTemplate}  style={{ minWidth: '12rem' }} />
                      </DataTable> */}


                      <DataTable value={documentList}   paginator rows={5} showGridlines  stripedRows
                       rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="docUrl" header="Image" body={imageBodyTemplate} sortable style={{ width: '25%' }}></Column>
                        <Column field="docComment" header="Comment" sortable style={{ width: '25%' }}></Column>
                      </DataTable>

                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Edit Update modal for the Accountants */}
      {modal_large && (<Modal
        size="lg"
        isOpen={modal_large}
        toggle={() => {
          handleCloseModal();
        }}
      >
        <ModalHeader >Upload Files</ModalHeader>
        <ModalBody>
          <div className="modalInner">
            <Row>
              <Col md={5} className="mt-3">
                <Input
                  name="newDocument"
                  type="file"
                  onChange={(event) => {
                    setFileName(event.target.files[0].name);
                    setFile(event.target.files[0])
                  }}
                  multiple={false}
                />
              </Col>

              <Col md={6} className="mt-3">
                <FormGroup>
                  <Input
                    name="fileComment"
                    type="text" placeholder="type here"
                    style={{ height: "70px", width: "400px" }}
                    onChange={(event) => {
                      setfileComment(event.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={7} className="mt-3">
                <img src=""
                  style={{ height: "100px", width: "200px", display: "none" }}
                ></img>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleUploadFile()}>
            Upload
          </Button>
          <Button color="danger" onClick={() => handleCloseModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>)}
    </React.Fragment>
  );

};

export default TenantAttachments;
