import React, { useRef, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import Sign from "../../assets/images/Sign.png";

const SignaturePad = (props) => {
    const signatureRef = useRef(null);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const clearSignature = () => {
        signatureRef.current.clear();
        props.sendSignature("");
    };

    const saveSignature = () => {
        // You can use signatureRef.current.toDataURL() to get the signature as a data URL
        const signatureDataURL = signatureRef.current.toDataURL();
        props.sendSignature(signatureDataURL);
        setModal(false);
    };

    return (
        <>
            <img className={props.className} style={{ width: "100px", height: "50px" }} src={props.sign ? props.sign : Sign} alt="Signature" onClick={() => { !props.readOnly && toggle() }} />
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader toggle={toggle}>Signature Pad</ModalHeader>
                <ModalBody>
                    <SignatureCanvas
                        ref={signatureRef}
                        canvasProps={{ width: 463, height: 200, className: 'signature-canvas' }}
                        backgroundColor={'rgba(240, 242, 242)'}
                        penColor="blue"
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="info" onClick={saveSignature}>
                        Done
                    </Button>
                    <Button color="secondary" onClick={clearSignature}>
                        Clear
                    </Button>
                    <Button color="danger" onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>

    )
}

export default SignaturePad;