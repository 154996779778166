import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import axios from "../api/axios";
import { useState } from "react";
import moment from "moment";
import Select from "react-select";
import { Link } from "react-router-dom";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    FormGroup,
    Collapse,
    Label,
    Form,
    FormFeedback,
    // FormControl,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    ModalFooter,
    ModalBody,
    Button,
    ModalHeader,
  } from "reactstrap";
  import jwt_decode from "jwt-decode";
  import Dropzone from "react-dropzone";
  import FeatherIcon from "feather-icons-react";
  import { toast } from "react-toastify";
  import "../../assets/css/rentdigicare.css";
  const Inventory = () => {

    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState([]);
    const [CompanyName, setCompanyName] = useState([]);
    const [SelectedCompanyId, setSelectedCompanyId] = useState("");
    const [SelectedPropertyId, setSelectedPropertyId] = useState("");
    const [InventoryConfig, setInventoryConfig] = useState({});

    const [totalQuantity, settotalQuantity] = useState(0);
    const [totalOrderQuantity, setTotalOrderQuantity] = useState(0);

    const [orderModal, setOrderModal] = useState(false);
    const [consumeModal, setConsumeModal] = useState(false);
    const [defaultQnty, setDefaultQnty] = useState(0);

    const [consumeItems, setConsumeItems] = useState({
        suit : "",
        itemQuantity : 0,
        comment : "",
        item : {}
    })

    const [orderEmailContent,setOrderEmailContent] = useState({subject : "", message : ""});


    const decode2 = jwt_decode(window.localStorage.getItem("accessToken"));
    const FILTER_URL = `/property/filter?role=${decode2.role}&domain=${
        decode2.role == "manager" ? decode2.id : decode2.domain
      }`;

    const COMPANY_NAME_URL = `/company/filter/company_list`



      const companyName = async () => {
        const response = await axios.get(COMPANY_NAME_URL);
        const temp = (response.data.companies);
        console.log(temp);
        setCompanyName(temp)
    }

    const propertiesList = async (companyId) => {
        const params = {
          companyID: companyId
        }
        const response = await axios.post('/property/getAllCompanyProperties', params);
        const temp = (response.data.properties);
        if(temp) {
            setProperties(temp);
        }
       
    }


    const getInventoryByType = async (inventoryType) => {
        const response = await axios.get(`/inventory/type/${inventoryType}`)
       
        if (response.status === 200 && response.data) {
            setInventoryConfig(response.data)
        }
    };

    const [enableOrderQnty, setEnableOrderQnty] = useState([]);

    const handleOrderNew = (categoryIdx, itemIdx) => {
      const updatedCategories = [...InventoryConfig.categories];
      updatedCategories[categoryIdx].items[itemIdx] = { orderNew: true };
      setInventoryConfig({ ...InventoryConfig, categories: updatedCategories });
    };

const toggleOrderNew = (categoryIndex, itemIndex) => {
    const updatedCategories = [...InventoryConfig];
    const updatedItem = {...updatedCategories[categoryIndex].category.items[itemIndex]};
          updatedItem.orderNew = !updatedItem.orderNew;
          updatedItem.orderDate = updatedItem.orderNew ? moment(new Date()).format('DD/MM/YY') : null;
    // const updatedItem2 = {
    //     ...updatedCategories[categoryIndex].category.items[itemIndex],
    //     orderNew: !updatedItem.orderNew,
    //     orderDate: updatedItem.orderNew ? moment(new Date()).format('DD/MM/YY') : null
    // };
    updatedCategories[categoryIndex].category.items[itemIndex] = updatedItem;
    setInventoryConfig(updatedCategories);
}

const handleOrderQtyChange = (categoryIndex, itemIndex, event) => {
    const updatedCategories = [...InventoryConfig];
    const updatedItem = {
        ...updatedCategories[categoryIndex].category.items[itemIndex],
        newOrderQty: event.target.value,
    };
    console.log(updatedItem);
    updatedCategories[categoryIndex].category.items[itemIndex] = updatedItem;
    setInventoryConfig(updatedCategories);
    setTotalOrderQuantity(prevTotalOrderQuantity => updatedItem.orderNew ? prevTotalOrderQuantity + parseInt(event.target.value) : prevTotalOrderQuantity);
}


    const handleSubmitConsumedItems = async() => {
        const consumedItem = {
            company : SelectedCompanyId,
            property : SelectedPropertyId,
            consumedQuantity: consumeItems.itemQuantity,
            comment: consumeItems.comment,
            item: consumeItems.item,
            usedBy : decode2.id,
            suit : consumeItems.suit
        };
        setConsumeItems({itemQuantity: 0, comment: "", item: {}});
        setConsumeModal(false);
        // Do something with consumedItem
        const response = await axios.post('/inventoryconsumed/create', consumedItem);
        if (response.status === 200 && response.data) {
            toast.success('Updated Successfully');
            getInventoryByType('general');

        }
    };




    const makeOrder = async () => {
      const order = {

        orderItems: InventoryConfig.filter(obj => obj.category.items.filter(item => item.orderNew).length > 0).map(obj => {
          return {
            categoryId: obj._id,
            items: obj.category.items.filter(item => item.orderNew).map(item => {
              return {
                itemId: item._id,
                itemQuantity: item.newOrderQty
              }
            })
          }
        })
      }
      order.orderById = decode2.id;
      order.orderEmail = orderEmailContent;
      order.company = SelectedCompanyId;
      order.property = SelectedPropertyId;
      console.log(order);
      const response = await axios.post('/orderinventory/order', order);
      if (response.status === 200) {
        setOrderModal(false);
        toast.success('Ordered Successfully');
      }
    }



      useEffect(() => {
        companyName();
        getInventoryByType('general');
      }, []);

    return (
        <React.Fragment>

            <div className="page-content">
                    <MetaTags>
                        <title> Rentdigicare | Inventory</title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs title="Home" breadcrumbItem="Inventory" />

                        <Row>
                            <Col xl={12}>
                            <Card>
                                <CardHeader>

                                <Form>
                                <FormGroup row>
                                    <Col md="3">
                                    <Label htmlFor="property">Select Company</Label>
                                    <Input type="select" name="SelectedCompany" id="SelectedCompany" onChange={(e) =>{
                                        setSelectedCompanyId(e.target.value);
                                        propertiesList(e.target.value);
                                        }} value={SelectedCompanyId}>
                                        <option value="">Select Company</option>
                                        {CompanyName.map(company => (
                                            
                                          <option key={company._id} value={company._id}>{company.name}</option>
                                        ))}
                                    </Input>

                                    </Col>
                                    <Col md="3">
                                    <Label htmlFor="suit">Select Property</Label>
                                    <Input type="select" name="property" id="property" onChange={(e) =>setSelectedPropertyId(e.target.value)}>
                                        <option value="">Select Property</option>
                                        {properties.map(property => (
                                            
                                            <option key={property._id} value={property._id}>{property.title}</option>
                                        ))}
                                    </Input>
                                    </Col>
                                </FormGroup>                               
                                </Form>


                                </CardHeader>
                                <CardBody>
                                        <div className="table-responsive">
                                            <div>
                                            {/* <select
                                                onChange={(e) => handleSorting(e)}
                                                style={{
                                                borderRadius: "4px",
                                                marginBottom: "10px",
                                                borderColor: "#d1d1d5",
                                                }}
                                            >
                                                <option>Sorting </option>
                                                <option value={1}>A-Z</option>
                                                <option value={2}>Z-A</option>
                                                <option value={3}>Created Latest</option>
                                                <option value={4}>Created Oldest</option>
                                                <option value={5}>Modified Latest</option>
                                                <option value={6}>Modified Oldest</option>
                                            </select> */}
                                            </div>

                                            

                                            <Table className="table-striped table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Item Category</th>
                                                            <th scope="col">Item Name</th>
                                                            <th scope="col">Description</th>
                                                            <th scope="col">Avail Quantity</th>
                                                            <th scope="col">Order New</th>
                                                            <th scope="col">Order Date</th>
                                                            <th scope="col">Sku No.</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody> 
                                                                   
                                                                   
                                                        {(InventoryConfig && InventoryConfig.length > 0) &&
                                                            InventoryConfig.map((obj,categoryIndex) => (
                                                                         (
                                                                            <React.Fragment key={categoryIndex}>
                                                                                {obj.category.items.map((item,itemIndex) => (
                                                                                    <tr key={itemIndex}>
                                                                                        <td>
                                                                                            {itemIndex == 0 ? (
                                                                                                obj.category.name
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </td>
                                                                                        <td>{item.name}</td>
                                                                                        <td>{item.description}</td>
                                                                                        <td>
                                                                                            <Row>
                                                                                            <Col sm={6}>
                                                                                                {item.defaultQuantity}
                                                                                            </Col>
                                                                                            <Col sm={6}>
                                                                                                <button className="btn btn-sm  btn-primary" onClick={() => {setConsumeModal(true); 
                                                                                                setDefaultQnty(item.defaultQuantity)
                                                                                                
                                                                                                const newConsumeItems = {...consumeItems};
                                                                                                newConsumeItems.item = item;
                                                                                                setConsumeItems(newConsumeItems);
                                                                                                    
                                                                                                }}>Consume</button>
                                                                                            </Col>
                                                                                            </Row>
                                                                                            
                                                                                            
                                                                                            {/* <input type="number" className="form-control" placeholder="Order Qty" onBlur={(e) => 
                                                                                                {
                                                                                                    item.orderQuantity =  Number(e.target.value);
                                                                                                    totalQuantity = totalQuantity + Number(e.target.value);
                                                                                                    settotalQuantity(totalQuantity);
                                                                                                }                                                                                                
                                                                                            }/> */}
                                                                                        </td>
                                                                                        <td>
                                                                                            <Row>
                                                                                                <Col md="3">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        checked={item.orderNew}
                                                                                                        onChange={() => toggleOrderNew(categoryIndex,itemIndex)}
                                                                                                    />

                                                                                                </Col>
                                                                                                <Col md="9">
                                                                                                  
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="form-control quantity-field"
                                                                                                        placeholder="Order Qty"
                                                                                                        defaultValue={null}
                                                                                                        disabled={!item.orderNew}
                                                                                                        onChange={(e) => handleOrderQtyChange(categoryIndex,itemIndex,e)}
                                                                                                    />
                                                                                                </Col>
                                                                                            </Row>
                                                                                                



                                                                                        </td>
                                                                                        <td>
                                                                                            {item.orderDate}
                                                                                        </td>
                                                                                        <td>{item.sku}</td>
                                                                                    </tr>
                                                                                ))}
                                                                            </React.Fragment>
                                                                        )))}
                                                                 
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th></th>
                                                            
                                                            <th></th>
                                                            <th>Total Items</th>
                                                            
                                                            <th>
                                                                
                                                                            {/* {InventoryConfig.categories.map(category => (
                                                                                category.items.reduce((acc, item) => acc + parseInt(item.defaultQuantity), 0)
                                                                            ))
                                                                                .reduce((acc, sum) => acc + sum, 0)
                                                                            } */}

                                                            </th>
                                                            <th>
                                                            {/* {InventoryConfig.categories.map(category => (
                                                                                category.items.reduce((acc, item) => ( (item.newOrderQty) ? acc + parseInt(item.newOrderQty) : 0, 0)
                                                                            ))
                                                                                .reduce((acc, sum) => acc + sum, 0)
                                                                            } */}
                                                            </th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </tfoot>
                                            </Table>

                                        {/* <Table className="table-striped table-bordered mb-0">
                                            <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Item Category</th>
                                                        <th scope="col">Item Name</th>
                                                        <th scope="col">Description</th>
                                                        <th scope="col">Avail Quantity</th>
                                                        <th scope="col">Order New</th>
                                                        <th scope="col">Order Date</th>
                                                        <th scope="col">Sku No.</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">1</th>
                                                        <td>Plumbing Toilet</td>
                                                        <td>Flush Valve</td>
                                                        <td>Flush Valve Item for bathroom</td>
                                                        <td>10</td>
                                                        <td>
                                                            <input type="checkbox" />

                                                        </td>
                                                        <td>2/2/2024</td>
                                                        <td>MTRD0102</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">2</th>
                                                        <td></td>
                                                        <td>Fill Valve</td>
                                                        <td>Item for bathroom</td>
                                                        <td>10</td>
                                                        <td>
                                                            <input type="checkbox" />

                                                        </td>
                                                        <td>2/2/2024</td>
                                                        <td>MTRD0102</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">3</th>
                                                        <td></td>
                                                        <td>Flapper</td>
                                                        <td>Item for bathroom</td>
                                                        <td>10</td>
                                                        <td>
                                                            <input type="checkbox" />

                                                        </td>
                                                        <td>2/2/2024</td>
                                                        <td>MTRD0102</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">4</th>
                                                        <td></td>
                                                        <td>Toilet Handle</td>
                                                        <td>Item for bathroom</td>
                                                        <td>10</td>
                                                        <td>
                                                            <input type="checkbox" />

                                                        </td>
                                                        <td>2/2/2024</td>
                                                        <td>MTRD0102</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">5</th>
                                                        <td></td>
                                                        <td>Wax Seal</td>
                                                        <td>Item for bathroom</td>
                                                        <td>10</td>
                                                        <td>
                                                            <input type="checkbox" />

                                                        </td>
                                                        <td>2/2/2024</td>
                                                        <td>MTRD0102</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">6</th>
                                                        <td></td>
                                                        <td>Toilet Seat</td>
                                                        <td>Item for bathroom</td>
                                                        <td>10</td>
                                                        <td>
                                                            <input type="checkbox" />

                                                        </td>
                                                        <td>2/2/2024</td>
                                                        <td>MTRD0102</td>
                                                    </tr>

                                                </tbody>
                                                <tfoot className="inventorylist-footer">
                                                    <tr>
                                                        <th></th>
                                                        <th>Total Items</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>60</th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </tfoot>
                                        </Table> */}
                                        <div className="email-container">
                                            <p> 
                                                <a onClick={() => setOrderModal(true) }>Send Order Email &nbsp;&nbsp;&nbsp;
                                                    <FeatherIcon icon="mail" />
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </CardBody>
                                </Card>
                            </Col>
                            </Row>
                        </div>

                    </div>

                        
                        <Modal isOpen={orderModal} toggle={() => setOrderModal(false)}>
                            <ModalHeader toggle={() => setOrderModal(false)}>Send Order Email</ModalHeader>
                            <ModalBody>
                                <Form className="form-horizontal">
                                    <FormGroup row>
                                        
                                        <Col md={12}>
                                            <Label htmlFor="subject">Subject:</Label>
                                            <Input type="text" className="form-control" id="subject" name="subject" placeholder="Order Request" onChange={(e) =>
                                                {
                                                    const orderEmailContent_ = orderEmailContent;
                                                    orderEmailContent_.subject = e.target.value;
                                                    setOrderEmailContent(orderEmailContent_);
                                                }
                                            } />
                                        </Col>
                                    </FormGroup>
                                    <br/>
                                    <FormGroup row>
                                        
                                        <Col md={12}>
                                            <Label htmlFor="message">Message:</Label>
                                            <Input type="textarea" id="message" name="message" className="form-control" rows={5}  onChange={(e) =>
                                                {
                                                    const orderEmailContent_ = orderEmailContent;
                                                    orderEmailContent_.message = e.target.value;
                                                    setOrderEmailContent(orderEmailContent_);
                                                }
                                            } />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={() => makeOrder()}>Send</Button>{' '}
                                <Button color="secondary" onClick={() => setOrderModal(false)}>Cancel</Button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={consumeModal} toggle={() => setConsumeModal(!consumeModal)}>
                            <ModalHeader toggle={() => setConsumeModal(!consumeModal)}>Consume Items</ModalHeader>
                            <ModalBody>

                            {(SelectedCompanyId === '' || SelectedPropertyId === '') ? 
                                <>
                                <Row>
                                    <Col md={12}>
                                        <h4>Select Company and Property First</h4>
                                    </Col>
                                </Row>
                                </>
                            : 
                            <>

                            <Form noValidate>
                                <FormGroup row>
                                    <Col md={12}>
                                        <Label htmlFor="suit" className="required">Suit:</Label>
                                        <Input type="text" id="suit" name="suit" className="form-control" onChange={(e) => {
                                            const consumeItems_ = consumeItems;
                                            consumeItems_.suit = e.target.value;
                                            setConsumeItems(consumeItems_);
                                        }
                                        } />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Col md={12}>
                                        <Label htmlFor="quantity" className="required">Quantity:</Label>
                                        <Input
                                            type="number"
                                            min={2}
                                            max={defaultQnty}
                                            id="quantity"
                                            name="itemQuantity"
                                            className="form-control"
                                            required
                                            invalid={(!consumeItems.itemQuantity || consumeItems.itemQuantity == 0 || consumeItems.itemQuantity > defaultQnty)}
                                            onChange={(e) => {
                                                const newConsumeItems = { ...consumeItems };
                                                newConsumeItems.itemQuantity = e.target.value;
                                                setConsumeItems(newConsumeItems);
                                            }
                                            }
                                        />
                                        <FormFeedback>Please enter quantity less then {defaultQnty + 1}</FormFeedback>
                                    </Col>
                                    <Col md={12}>
                                        <Label htmlFor="comment" className="required">Describe use case:</Label>
                                        <Input type="textarea" id="comment" name="comment" className="form-control" rows={5} required
                                            invalid={(consumeItems.comment === "")}
                                            onChange={(e) => {
                                                const newConsumeItems = { ...consumeItems };
                                                newConsumeItems.comment = e.target.value;
                                                setConsumeItems(newConsumeItems);
                                            }
                                            } />
                                        <FormFeedback>Please enter Use case</FormFeedback>
                                    </Col>
                                </FormGroup>
                            </Form>

                            </>
                            }

                                
                            </ModalBody>
                            {(SelectedCompanyId !== '' && SelectedPropertyId !== '') &&
                            <ModalFooter>
                                <Button color="primary"
                                        disabled={(!consumeItems.itemQuantity || consumeItems.itemQuantity == 0 || consumeItems.itemQuantity > defaultQnty ) || consumeItems.comment === ""}
                                        onClick={() => handleSubmitConsumedItems()}
                                >Consume</Button>
                                {' '}
                                <Button color="secondary" onClick={() => setConsumeModal(!consumeModal)}>Cancel</Button>
                            </ModalFooter>
                            }
                        </Modal>




    </React.Fragment>
    )
   
  }

  const handleSorting = (e) => {
    let value = [1, -1];
    let newData = {};
    if (e.target.value == 1) {
      newData["title"] = value[0];
    } else if (e.target.value == 2) {
      newData["title"] = value[1];
    } else if (e.target.value == 3) {
      newData["createdAt"] = value[1];
    } else if (e.target.value == 4) {
      newData["createdAt"] = value[0];
    } else if (e.target.value == 5) {
      newData["modifiedAt"] = value[1];
    } else if (e.target.value == 6) {
      newData["modifiedAt"] = value[0];
    } else {
      newData[" "] = " ";
    }

   // getProperties(newData);
  };

 

  export default Inventory