import React, { useState, useEffect } from "react";
import { Row,Col,Card, CardBody,CardHeader, Button, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, CardFooter,Table } from "reactstrap";
import axios from "../api/axios";
import MetaTags from "react-meta-tags";
import FeatherIcon from "feather-icons-react";
import "../../assets/css/rentdigicare.css";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";





const CreateVendors = (props) => {

    const [vendorList, setVendorList] = useState([
      {
        vendorName : "",
        vendorEmail : "",
        vendorPhone : "",
        description: ""
      }
    ])

    const [selectedItemindex, setSelectedItemIndex] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const handleClickToggleModel = () => setShowConfirmDialog(!showConfirmDialog);

    const addVendor = () => {
      let newList = [...vendorList]
      newList.push({
        vendorName : "",
        vendorEmail : "",
        vendorPhone : "",
        description: ""
      })
      setVendorList(newList)
    }

    
    const handleItemChange = (e,i,field) => {
      let newList = [...vendorList]
      newList[i][field] = e.target.value
      setVendorList(newList)
    }

    const saveVendors = async () => {
      try {
        const response = await axios.post('/inventoryvendors/bulkUpdate/', {vendorList});
        console.log(response);
        if (response.status == 201 && response.data) {          
          toast.success("Vendors saved successfully");
          getVendorsList();
        }
      } catch (err) {
        console.log(err)
      }
    }


    const getVendorsList = async () => {
      try {
        const response = await axios.get('/inventoryvendors/');
        console.log(response.data);
        if (response.status == 200 && response.data.length) {
          setVendorList(response.data);
        }
      } catch (err) {
        console.log(err)
      }
    }



    const deleteVendor = async () => {
      
      const selectedVendor = vendorList[selectedItemindex];

      if(selectedVendor._id) {
        const id = selectedVendor._id;

        try {
          const response = await axios.delete(`/inventoryvendors/${id}`);
          if (response.status == 200) {
            let newList = vendorList.filter(item => item._id != id)
            if(!newList.length) {
              newList = [{
                vendorName : "",
                vendorEmail : "",
                vendorPhone : "",
                description: ""
              }];
            }
            setVendorList(newList)
            toast.success("Vendor deleted successfully");
          }
        } catch (err) {
          console.log(err)
        }

      } else {
        let newList = vendorList.filter((item, index) => index !== selectedItemindex);
        setVendorList(newList)
        toast.success("Vendor deleted successfully");
      }

     
    }

   


    useEffect(() => {
      getVendorsList();
    }, [])


  
    return (

      <React.Fragment>

        <div className="page-content">
          <MetaTags>
            <title> Rentdigicare | Create Vendor</title>
          </MetaTags>
          <div className="container-fluid">
            <Breadcrumbs title="Create Vendor" breadcrumbItem="Vendor" />

            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={8}>
                        <Button color="primary" className="createinventory-btn" onClick={addVendor}>
                          <FeatherIcon icon="plus-circle" />  Add Vendor</Button>
                      </Col>
                    </Row>

                  </CardHeader>

                  <CardBody>

                    <div className="inventory-container">

                     
                        <div className="row cat-container">
                          <table className="item-table">
                            <tbody>
                            {vendorList.map((item, i) => (
                                <tr key={i}>
                                  <td>
                                    <Label htmlFor="vendorname">Name</Label>
                                    <Input type="text" name="vendorname" placeholder="Vendor Name" value={item.vendorName} onChange={e => handleItemChange(e,i,"vendorName")} />
                                  </td>
                                  <td>
                                    <Label htmlFor="vendoremail">Email</Label>
                                    <Input type="text" name="vendoremail" placeholder="Vendor Email" value={item.vendorEmail} onChange={e => handleItemChange(e, i, "vendorEmail")} />
                                  </td>
                                  <td>
                                    <Label htmlFor="vendorphone">Phone</Label>
                                    <Input type="text" name="vendorphone" placeholder="Vendor Phone" value={item.vendorPhone} onChange={e => handleItemChange(e, i, "vendorPhone")} />
                                  </td>
                                  <td>
                                    <Label htmlFor="description">Description</Label>
                                    <Input type="text" name="description" placeholder="Description" value={item.description} onChange={e => handleItemChange(e, i, "description")} />
                                  </td>
                                  <td>
                                    <Button className="float-right" color="danger" onClick={() => {setSelectedItemIndex(i); handleClickToggleModel()}}> <FeatherIcon icon="trash-2" className="float-right" /></Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          
                        </div>
                   

                    </div>

                    <Modal isOpen={showConfirmDialog} toggle={handleClickToggleModel} centered>
                      <ModalHeader toggle={handleClickToggleModel}>Confirm Delete</ModalHeader>
                      <ModalBody>
                        Are you sure you want to delete this item?
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={() => { deleteVendor(); handleClickToggleModel() }}>Yes</Button>{' '}
                        <Button color="secondary" onClick={handleClickToggleModel}>No</Button>
                      </ModalFooter>
                    </Modal>



                  </CardBody>
                  <CardFooter>
                     <Button color="primary" onClick={saveVendors}>Submit</Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );

  

};

export default CreateVendors;
