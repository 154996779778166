import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import jwt_decode from "jwt-decode";
import moment from "moment";
import FeatherIcon from "feather-icons-react";
import { useParams } from "react-router-dom";
import BreadcrumbV3 from "../../components/Common/BreadcrumbV3";

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";

import axios from "../api/axios";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/actions";

const GET_ACCOUNTED_LIST = "/accountand/list";
const POST_CREATE_PAD = "/paddInfo/add";

const GET_PAD_LIST = "/paddInfo/allPadds";


const PUT_UPDATE_ACCOUNTED = `/accountand/update`;
const PUT_DELETE_ACCOUNTENT = `/accountand/delete`;

const Padd_Info = () => {
  const dispatch = useDispatch();
  const decode = jwt_decode(window.localStorage.getItem("accessToken"));
  const { userData } = JSON.parse(window.localStorage.getItem("authUser"));

  // form states

  const [paddsData, setPaddsData] = useState([]);

  const [createNewPadInfo, setNewPadInfo] = useState({
    newAmount: 0.0,
    changeAmount: 0.0,
    padStatus: "",
    padComment: "",
    userId:""
  });

  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS }
});

  const [modal_large, setmodal_large] = useState(false);



  function tog_large() {
    setmodal_large(!modal_large);
  }

  const handleOnChangePersonal = (event) => {
    const { name, value } = event.target;
    setNewPadInfo({ ...createNewPadInfo, [name]: value });
  }


  const handleCloseModal = () => {
     tog_large();

    // setManagerFirstName("");
    // setManagerLastName("");
    // setManagerStatus(true);
    // setManagerEmail("");
    // setManagerPhone("");
    // setEditManagerId("");
  };

  useEffect(() => {
    loadPaddList();
  }, []);

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
};


const  addPaddInfo= async (event)=>{
  event.preventDefault();
debugger;
  if (createNewPadInfo.newAmount === "") {
    toast.error("New Amount is required");
    return;
  }

  if (createNewPadInfo.changeAmount === "") {
    toast.error("Change Amount is required");
    return;
  }

  if (createNewPadInfo.padStatus === "") {
    toast.error("Status is required");
    return;
  }

  if (createNewPadInfo.padComment === "") {
    toast.error("Comment is required");
    return;
  }

  createNewPadInfo.userId=id;
  try {

    const bodyData = {
      userId: id,
      newAmount: createNewPadInfo.newAmount,
      changeAmount: createNewPadInfo.changeAmount,
      padStatus: createNewPadInfo.padStatus,
      padComment: createNewPadInfo.padComment,
    }

    const responce = await axios.post(POST_CREATE_PAD, bodyData);
    if (responce.data.status === 201) {
      toast.success("Tenant information is updated successfully.");

      setNewPadInfo({
        newAmount: 0.0,
        changeAmount: 0.0,
        padStatus: "",
        padComment: "",
        userId:""
      })

      await loadPaddList();
    }
    //dispatch(isLoading(false));
  } catch (error) {
   // dispatch(isLoading(false));
    toast.error("Something went wrong!");
    console.error(error);
  }

}

const loadPaddList= async()=>{
  try {
  const res = await axios.get(GET_PAD_LIST +"/"+id);
  debugger;
  setLoading(false)
  if(res.data.status === 200)
  {
    setPaddsData(res.data.data)
  }
  else
  toast.error("Something went wrong!");

  }
  catch (error) {
  setLoading(false)
    // dispatch(isLoading(false));
     toast.error("Something went wrong!");
     console.error(error);
   }

}

const formatDate = (value) => {
  return new Date(value).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
  });
};

const dateBodyTemplate = (rowData) => {
  debugger;
  return formatDate(rowData.createdAt);
};

  const renderHeader = () => {
    return (
        <div className="flex justify-content-end">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
            </span>
        </div>
    );
};

const header = renderHeader();


    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title> Rentdigicare | Padd Info</title>
          </MetaTags>
          <div className="container-fluid">
            {/* <Breadcrumbs title="Home" breadcrumbItem="Padd Info" /> */}
            <BreadcrumbV3
            title={
              <>
                Padd Info
              </>
            }
            breadcrumbItems={[
              {
                item: "Home",
                link: "/dashboard",
              },
              {
                item: "Master Sheet",
                link: "/master_table",
              },
              {
                item: "Tenant Information",
                link: `/tenant_info/${id}`,
              },
            ]}
          />
            <Row>
              <Col xl={12}>
                <Card>
                  <CardHeader>
                    <div className="row align-items-center">
                      <div className="col-md-12 d-flex flex-wrap align-items-end justify-content-end">
                        <div className="mb">
                          {(userData.role === "company") ? (
                            <button
                              type="button"
                              onClick={() => {
                                tog_large();
                              }}
                              className="btn btn-light "
                              data-toggle="modal"
                              data-target=".bs-example-modal-lg"
                            >
                              <i className="bx bx-plus me-1"></i>Add
                            </button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">

                      <DataTable value={paddsData} showGridlines stripedRows paginator rows={10} dataKey="id" filters={filters}
                      filterDisplay="row" loading={loading} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                      header={header}   emptyMessage="No customers found.">
                        <Column field="newAmount" sortable header="New Amount" style={{ minWidth: '12rem' }} />
                        <Column field="changeAmount" header="Change Amount" sortable  ></Column>
                        <Column field="paddStatus" header="Status" sortable filterField="paddStatus" />
                        <Column field="comment" sortable header="Comment" style={{ minWidth: '12rem' }} />
                        <Column field="createdAt" sortable header="Created On" dataType="date"  body={dateBodyTemplate}  style={{ minWidth: '12rem' }} />
                      </DataTable>

{/*
                      <DataTable  paginator rows={5} showGridlines  stripedRows
                       rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="name" header="Name" sortable style={{ width: '25%' }}></Column>
                        <Column field="country.name" header="Country" sortable style={{ width: '25%' }}></Column>
                        <Column field="company" header="Company" sortable style={{ width: '25%' }}></Column>
                        <Column field="representative.name" sortable header="Representative" style={{ width: '25%' }}></Column>
                      </DataTable> */}

                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>

        {/* Edit Update modal for the Accountants */}
        {modal_large && (<Modal
          size="lg"
          isOpen={modal_large}
          toggle={() => {
            handleCloseModal();
          }}
        >
          <div className="modal-header">
            {/* <h5 className="modal-title mt-0" id="myLargeModalLabel">
              {editData ? "Edit " : "Add"} Accountant
            </h5> */}
            <button
              onClick={() => {
                handleCloseModal();
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={addPaddInfo}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                    >
                      New Amount*
                    </Label>
                    <Input
                      placeholder="Enter New Amount"
                      type="text"
                      className="form-control"
                      name="newAmount"
                      value={createNewPadInfo.newAmount}
                      onChange={handleOnChangePersonal}
                    />

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label
                      className="form-label"
                      htmlFor="formrow-facility-input"
                    >
                      Change Amount *
                    </Label>
                    <Input
                      placeholder="Enter Change Amount"
                      value={createNewPadInfo.changeAmount}
                      type="text"
                      className="form-control"
                     name="changeAmount"
                      onChange={handleOnChangePersonal}
                    />

                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="form-Label">
                      Status *
                    </Label>
                    <select
                    name="padStatus"
                      value={createNewPadInfo.padStatus}
                      onChange={handleOnChangePersonal}
                      className="form-select"
                    >
                      <option value="Active">Active</option>
                      <option value="Not Done">In-active</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="mb-3">
                    <Label className="form-Label">
                      Comment *
                    </Label>
                   <textarea cols={49} rows={3} name="padComment" value={createNewPadInfo.padComment} onChange={handleOnChangePersonal}></textarea>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="text-end">
                    <span
                      id="errorSpan"
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        marginRight: "10px",
                        display: "none",
                      }}
                    ></span>
                    <button
                      id="managerSaveBtn"
                      type="submit"
                      className="btn btn-success save-user"
                    >
                      Save
                      <div
                        id="saveSpinner"
                        style={{
                          display: "none",
                          height: "15px",
                          width: "15px",
                          marginLeft: "5px",
                        }}
                        className="spinner-border"
                        role="status"
                      ></div>
                      <i
                        id="failedSaveErrorIcon"
                        style={{
                          display: "none",
                          marginLeft: "5px",
                        }}
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Modal>)}
      </React.Fragment>
    );

};

export default Padd_Info;
