import React from "react";

import FormHeaderLogo from "./FormHeaderLogo";
import FormFooter from "./FormFooter";

import "./frmStyle.scss";

const FormStep5 = () => {

  return (
    <div className="cmnFrmstyle divToPrint">
      <div className="wrapper">
        <div className="cmnfrmbg">
          <div className="topsec">
            <div className="logoImg">
              {" "}
              <FormHeaderLogo />
            </div>
          </div>
          <div className="">
            <br />
            <br />
            <br />
            <p><strong>DAMAGE & DEFECTS</strong> <br />
              The tenant will not do or permit significant damage to the leased
              premises or common areas of the property. If this policy is not
              adhered, tenant will be given 24-hour eviction notice. The tenant
              must give the landlord prompt notice of any accident, damage, leak
              or defect in the water pipes, heating apparatus, electrical wiring
              or apparatus on the premises.
              <br />
              <br />
              <strong>EXTENSION </strong>
              <br />
              The tenant will vacate the premises at the expiration or termination
              of the term of this lease unless a lease extension is signed by both
              the landlord and the tenant.
              <br />
              <br />
              <strong>QUIET ENJOYMENT & RIGHT OF ENTRY</strong>
              <br />
              The landlord and the tenant covenant that subject to the rights
              contained herein, the landlord will not in any significant manor
              disturb the tenant’s quiet possession or peaceful enjoyment of the
              leased premises. It is mutually agreed that the landlord is entitled
              to enter the leased premises without written consent or notice if
              he/she has reasonable grounds to believe that any emergency requires
              him to enter the premises or that the tenant has abandoned the
              premises. The landlord is further entitled to enter the leased
              premises after giving a 24-hour written notice to the tenant: <br />
              (Landlord is permitted for inspection of a suite every 3-6 months
              with given notice to tenant)
              <br />
              A. To inspect the state of repair of the leased premises.
              <br />
              B. To make repairs to the leased premises
              <br />
              C. To show the premises to prospective purchasers or mortgagees of
              the premises.
              <br />
              D. To show the premises to prospective tenants after either party
              has served a notice of termination of the tenancy. It is further
              mutually agreed that after notice of termination has been served
              that the tenant agrees to allow the landlord to show the leased
              premises to prospective tenants with the requirement of the 24-hour
              notice in writing as contained in this clause providing such
              showings are carried on between 9 am. and 6 pm (Monday to Saturday)
              and the landlord verbally advises the tenant that he is actively
              advertising the premises for rent.
              <br />
              <br />
              <strong>BREECH OF AGREEMENT & COLLECTION</strong> <br />
              It is mutually agreed between the landlord and the tenant that if
              the tenant commits a breach of this lease agreement or the
              Residential Tenancies Act, the landlord may:
              <br />
              A. Recover arrears of rent
              <br />
              B. Recover damages resulting from the breach. <br />
              C. Recover compensation for the use of the occupation by the tenant
              should he hold after the tenancy has expired or terminated
              <br />
              D. Recover possession of the premises
              <br />
              E. Should the rent become overdue or the tenant damage the premises
              and the landlord sue for the same or sue for possession or
              non-payment of rent the tenant shall pay for all court costs and all
              legal fees associated with such action on a client solicitor basis.
              In addition, the tenant hereby agrees to pay all costs associated
              with the landlord having to hire a collection agency to collect
              overdue or unpaid rent.
              <br />
              <br />
              <strong>INDEMNITY</strong>
              <br />
              The tenant will indemnify and save harmless the landlord for, and in
              relation to any and all loss, damage or personal injury caused by or
              sustained by the tenant or the tenants guests or invitees, through
              neglect, misuse, or carelessness and the tenant shall indemnify and
              save harmless the landlord and agents for and from all action,
              claims for damage or injury of any nature, kind and description
              whatsoever arising out of or in connection with the tenants
              occupation of the leased premises the facilities, parking areas and
              grounds located in, upon or associated with the leased premises, and
              the tenant acknowledges that the landlord shall not be responsible
              for ant loss of the tenants property, however caused and that the
              tenant is responsible for insuring his property against loss from
              any cause.</p>
            <FormFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormStep5;
