import React, { useEffect, useState } from "react";
import car from "../../assets/images/home.png";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "../api/axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { SERVER_URL } from "../ServerLink";
import moment from "moment";
import { useDispatch } from "react-redux";
import { isLoading } from "../../store/layout/actions";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import BreadcrumbV3 from "../../components/Common/BreadcrumbV3";
import Swal from "sweetalert2";

const GET_PROPERTYS_LIST = "parking/property-list";
const GET_TENANTS_LIST = "parking/tenants-list";
const GET_STORAGE_LIST = "storage/list";
const GET_STORAGE_DOWNLOAD = "storage/download";
const POST_STORAGE_UPDATE_LOCKER = "storage/update-storage-locker";
const POST_STORAGE_INFO_REFRESH = "storage/info-refresh";
const GET_STORAGE_INFO = "storage/storage-info";

const Storage = () => {
  const location = useLocation();
  const activeProperty = location?.state?.activeProperty;
  const dispatch = useDispatch();
  const history = useHistory();
  const { userData } = JSON.parse(window.localStorage.getItem("authUser"));
  const { id } = useParams();

  const [propertyList, setPropertyList] = useState([]);
  const [selectPropertyName, setSelectPropertyName] = useState("");
  const [tenantsList, setTenantsList] = useState([]);
  const [selectTenants, setSelectTenants] = useState({
    _id: "",
    value: "",
    label: "",
  });

  const [parkingList, setParkingList] = useState([]);
  const [propertyID, setPropertyID] = useState("");
  const [floorType, setFloorType] = useState("");
  const [floor, setFloor] = useState(0);
  const [floors, setFloors] = useState([]);
  const [parkingLots, setParkingLots] = useState([]);
  const [parkingInfo, setParkingInfo] = useState([]);
  const [modal, setModal] = useState(false);
  const [isAddAnother, setIsAddAnother] = useState(false);
  const [isNewAddTenant, setIsNewAddTenant] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [modalData, setModalData] = useState({
    lockerNo: "",
    amount: "",
    effectiveDate: "",
  });
  const [modalReadData, setModalReadData] = useState({
    name: "",
    email: "",
    mobile: "",
  });

  const [formError, setFormError] = useState({
    ownerName: "",
    amount: "",
    effectiveDate: "",
  });

  const toggleClose = () => setModal(false);

  const getPropertyList = async () => {
    try {
      const response = await axios.get(
        `${GET_PROPERTYS_LIST}?role=${userData.role}&id=${userData.id}`
      );
      const result = response.data.data;
      if (result && result.length > 0 && Array.isArray(result)) {
        // Find the property matching activeProperty.id (if any)
        const activePropertyMatch = result.find(
          (property) => property._id === activeProperty
        );
        // Set property list and potentially default property based on match
        setPropertyList(result);
        if (activePropertyMatch) {
          setSelectPropertyName({
            id: activePropertyMatch._id,
            value: activePropertyMatch.title,
            label: `${activePropertyMatch.title}, ${activePropertyMatch.location}`,
          });
          setPropertyID(activePropertyMatch._id);
        } else {
          // Handle the case where no matching property is found
          console.log("No property found matching activeProperty.id");
          // You can choose to set default property or handle it differently
        }

        // If id is provided and a matching property exists, fetch parking info
        if (id && activePropertyMatch) {
          await getParkingInfoById(id, result);
        } else {
          // Handle the case where id is provided but no matching property is found
          console.log(
            "No property found matching both id and activeProperty.id"
          );
        }
      }
    } catch (error) {}
  };

  const getParkingList = async () => {
    try {
      const response = await axios.get(
        `${GET_STORAGE_LIST}?id=${selectPropertyName.id}`
      );
      const result2 = response.data.data.storage;
      if (result2) {
        const result = response.data.data.storage.storageData;
        setParkingList(result);
        setFloors(result[0]["floors"]);
        setParkingInfo(response.data.data.storageInfo);
        if (!id) {
          setFloorType(result[0]["floorType"]);
          setFloor(result[0]["floors"][0]["floor"]);
          getParkingInfo(
            response.data.data.storageInfo,
            result[0]["floorType"],
            result[0]["floors"][0]["floor"]
          );
        }
      } else {
        setFloorType("");
        setFloor("");
        setParkingList([]);
        setParkingInfo([]);
        setParkingLots([]);
      }
    } catch (error) {}
  };

  const getParkingUpdatedList = async (_id, _floorType, _floor) => {
    try {
      const response = await axios.get(`${GET_STORAGE_LIST}?id=${_id}`);
      const result = response.data.data.storage.storageData;
      if (result && result.length && Array.isArray(result)) {
        const _result = result.slice();
        setParkingList(_result);
        const floors = _result.find((item) => item.floorType == _floorType);
        setFloors(floors["floors"]);
        getParkingInfo(response.data.data.storageInfo, _floorType, _floor);
        return response.data.data.storageInfo;
      }
      return [];
    } catch (error) {}
  };

  const getParkingInfoById = async (_id, _propertyList) => {
    try {
      const res = await axios.get(`${GET_STORAGE_INFO}?id=${_id}`);
      if (res && res.data && res.data.status == 200) {
        const response = await axios.get(
          `${GET_PROPERTYS_LIST}?role=${userData.role}&id=${userData.id}`
        );
        if (response && response.data && response.data.status == 201) {
          const _propertyList = response.data.data;
          const _result = res.data.data;

          const _property = _propertyList.find(
            (doc) => doc._id == _result.propertyID
          );
          const defaultProperty = {
            id: _property._id,
            value: _property.title,
            label: `${_property.title}, ${_property.location}`,
          };
          setSelectPropertyName(defaultProperty);
          setPropertyID(_property._id);
          setFloorType(_result["floorType"]);
          setFloor(_result["floor"]);
          const _parkingInfo = await getParkingUpdatedList(
            _result.propertyID,
            _result["floorType"],
            _result["floor"]
          );
          setIsReset(true);
          handleModel(
            _result["lockerNo"],
            _result.propertyID,
            getParkingInfo(_parkingInfo, _result["floorType"], _result["floor"])
          );
        }
      }
    } catch (error) {}
  };

  const getParkingInfo = (data, _floorType, _floor) => {
    const lotsInfo = data.filter(
      (item) => item.floorType == _floorType && item.floor == _floor
    );
    setParkingLots(lotsInfo);
    return lotsInfo;
  };

  const handleSelectFloortype = (event) => {
    const { value } = event.target;
    const _parkingList = parkingList.slice();
    const floors = _parkingList.find((item) => item.floorType == value);
    setFloorType(floors["floorType"]);
    setFloors(floors["floors"]);
    setFloor(floors["floors"][0]["floor"]);
    getParkingInfo(parkingInfo.slice(), value, floors["floors"][0]["floor"]);
  };

  const handleSelectFloor = (event) => {
    const { value } = event.target;
    const _floors = floors.slice();
    const lots = _floors.find((item) => item.floor == value);
    setFloor(lots["floor"]);
    getParkingInfo(parkingInfo.slice(), floorType, lots["floor"]);
  };

  const modalDataSetInState = (data) => {
    console.log("=====>", data);
    const {
      lockerNo,
      _id,
      suite,
      firstname,
      lastname,
      email,
      mobile,
      effectiveDate,
      amount,
    } = data;
    setSelectTenants({
      _id: _id ? _id : "",
      value: suite ? suite : "",
      label: suite ? suite : "",
    });
    setModalData({
      lockerNo: lockerNo,
      amount: amount,
      effectiveDate: effectiveDate
        ? moment(effectiveDate).format("YYYY-MM-DD")
        : "",
    });
    setModalReadData({
      name:
        firstname || lastname
          ? `${firstname ? firstname : ""} ${lastname ? lastname : ""}`
          : "",
      email: email ? email : "",
      mobile: mobile ? mobile : "",
    });
  };

  const handleModel = async (data, _propertyID, _parkingLots) => {
    setModal(true);
    setIsAddAnother(false);
    setIsNewAddTenant(false);
    const tenantsList = await axios.get(
      `${GET_TENANTS_LIST}?id=${_propertyID}`
    );
    let prarkingDetails = { lockerNo: data };
    if (tenantsList.data.data.length > 0) {
      setTenantsList(tenantsList.data.data);
      const _data = _parkingLots.find((item) => item.lockerNo == data);
      if (_data) {
        setIsAddAnother(true);
        prarkingDetails = {
          ...prarkingDetails,
          ..._data.lockerUser[0],
          amount: _data.amount,
          effectiveDate: _data.effectiveDate,
        };
      } else {
        prarkingDetails = { ...prarkingDetails };
      }
    }
    modalDataSetInState(prarkingDetails);
  };

  const handleModalChange = (event) => {
    const { name, value } = event.target;
    setModalData({ ...modalData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const isValidation = (formData) => {
    const { ownerName, effectiveDate, amount } = formData;
    if (!ownerName) {
      setFormError({ ...formError, ownerName: "Please Select Suite Number" });
      return false;
    }

    if (!amount) {
      setFormError({ ...formError, amount: "Amount is required!" });
      return false;
    }

    if (!effectiveDate) {
      setFormError({
        ...formError,
        effectiveDate: "Effective Date is required!",
      });
      return false;
    }

    return true;
  };

  const handleUpdate = async () => {
    try {
      // Validate data before proceeding
      if (
        !isValidation({
          ownerName: selectTenants._id,
          effectiveDate: modalData.effectiveDate,
          amount: modalData.amount,
        })
      ) {
        return; // Exit function if validation fails
      }

      // Confirm user action using SweetAlert2
      const result = await Swal.fire({
        title: `Are you sure you want to ${
          isNewAddTenant ? "add new" : "update"
        } storage?`,
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, proceed!",
        cancelButtonText: "No, cancel!",
      });

      if (!result.isConfirmed) {
        return; // Exit function if user cancels
      }

      // Proceed with the storage locker update
      const bodyData = {
        propertyID: propertyID,
        floorType: floorType,
        floor: floor,
        lockerNo: modalData.lockerNo,
        updateData: {
          addAnother: isNewAddTenant,
          amount: modalData.amount,
          effectiveDate: modalData.effectiveDate,
          userId: selectTenants._id,
        },
      };

      dispatch(isLoading(true));

      const response = await axios.post(POST_STORAGE_UPDATE_LOCKER, bodyData);

      // Reset modal data after successful update
      setModalData({
        lockerNo: "",
        amount: "",
        effectiveDate: "",
      });

      // Fetch updated storage list
      const response1 = await getParkingUpdatedList(
        propertyID,
        floorType,
        floor
      );

      // Close the modal
      toggleClose();

      // Dispatch loading state
      dispatch(isLoading(false));

      // Show success message
      toast.success(
        `Storage Locker ${
          isNewAddTenant ? "add new tenant" : "update"
        } successfully.`
      );

      // Navigate back to the previous URL
      history.goBack();
    } catch (error) {
      // Handle errors
      toggleClose();
      toast.error("Something went wrong!");
      dispatch(isLoading(false));
    }
  };

  const handleAddAnother = (data) => {
    try {
      let prarkingDetails = { lockerNo: data };
      modalDataSetInState(prarkingDetails);
      setIsNewAddTenant(true);
    } catch (error) {
      toggleClose();
      toast.error("Something went wrong!");
    }
  };

  const handleReset = async () => {
    try {
      // Confirm user action using SweetAlert2
      const result = await Swal.fire({
        title: "Are you sure you want to reset storage?",
        text: "This action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "No, cancel!",
      });

      if (result.isConfirmed) {
        const bodyData = {
          propertyID: propertyID,
          floorType: floorType,
          floor: floor,
          lockerNo: modalData.lockerNo,
          updateData: {
            effectiveDate: "",
            amount: "",
            userId: null,
          },
        };

        // Perform the storage locker reset operation
        const response = await axios.post(POST_STORAGE_UPDATE_LOCKER, bodyData);

        // Reset modal data after successful reset
        setModalData({
          lockerNo: "",
          amount: "",
          effectiveDate: "",
        });

        // Fetch updated storage list
        const response1 = await getParkingUpdatedList(
          propertyID,
          floorType,
          floor
        );

        // Close the modal
        toggleClose();

        // Show success message
        toast.success("Storage Locker reset successfully.");

        // Redirect to storage_info page
        history.push("/storage_info");
      }
    } catch (error) {
      // Handle errors
      toggleClose();
      toast.error("Something went wrong!");
    }
  };
  
  const handleRefresh = async () => {
    try {
      dispatch(isLoading(true));
      setFloorType("");
      setFloor("");
      setParkingList([]);
      setParkingInfo([]);
      setParkingLots([]);
      await axios.get(`${POST_STORAGE_INFO_REFRESH}?id=${propertyID}`);
      await getParkingList();
      dispatch(isLoading(false));
    } catch (error) {
      dispatch(isLoading(false));
      console.error(error);
    }
  };

  useEffect(() => {
    if (!id) {
      if (selectPropertyName) {
        getParkingList();
      }
    }
  }, [selectPropertyName]);

  useEffect(() => {
    if (!id) {
      getPropertyList();
    }
  }, []);

  useEffect(() => {
    if (id) {
      getParkingInfoById(id);
    }
  }, [id]);

  const getStorageSpace = (__floor) => {
    const _floors = floors.slice();
    const _floor = _floors.find((item) => item.floor == __floor);
    const _parkingLots = parkingLots.slice();
    const lockerObject = [];
    if (_floor)
      for (let item = _floor.from; item <= _floor.to; item++) {
        lockerObject.push(
          <div
            key={item}
            onClick={() => {
              handleModel(_floor.alphabet + item, propertyID, _parkingLots);
            }}
            className={
              _parkingLots.find(
                (_item) => _item.lockerNo == _floor.alphabet + item
              )?.userId
                ? "edit_parking"
                : "add_parking"
            }
          >
            <div className="inner_parking_sec">
              <span className="parkingNo">{_floor.alphabet + item}</span>
              <div className="parkingIcn">
                <img src={car} alt="" />
              </div>
              <div className="actioBtn"></div>
            </div>
          </div>
        );
      }

    return lockerObject;
  };

  return (
    <div className="page-content">
      <div className="parking_page">
        <MetaTags>
          <title>Rentdigicare | Storage</title>
        </MetaTags>
        <div className="container-fluid">
          <BreadcrumbV3
            title={"Storage"}
            breadcrumbItems={[
              {
                item: "Home",
                link: "/dashboard",
              },
              {
                item: "Storage",
                link: "/storage_info",
              },
            ]}
          />
          <Card>
            <CardBody>
              <Row className="mb-3">
                <Col md={12} className="text-end">
                  <Button
                    color="primary"
                    disabled={parkingList.length > 0 ? false : true}
                    className="mx-3"
                    onClick={handleRefresh}
                  >
                    Refresh
                  </Button>
                  <a
                    className={parkingList.length ? "" : "isaDisable"}
                    href={`${SERVER_URL}/${GET_STORAGE_DOWNLOAD}?id=${propertyID}&name=${selectPropertyName.value}`}
                    target="_blank"
                  >
                    <span className="btn btn-primary">Export</span>
                  </a>
                </Col>
              </Row>
              <div className="parkingTop_sec">
                <Form>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label className="form-Label">Property Name</Label>
                        <Select
                          value={selectPropertyName}
                          options={propertyList?.map((item) => {
                            return {
                              id: item._id,
                              value: item.title,
                              label: `${item.title}, ${item.location}`,
                            };
                          })}
                          placeholder="Search By Name"
                          onChange={(event) => {
                            setSelectPropertyName(event);
                            setPropertyID(event.id);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {parkingList.length > 0 && (
                      <>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="exampleEmail">Storage Type</Label>
                            <Input
                              id="exampleSelect"
                              name="select"
                              value={floorType}
                              type="select"
                              onChange={handleSelectFloortype}
                            >
                              {parkingList &&
                                parkingList.map(
                                  (item, index) =>
                                    item.isActive && (
                                      <option
                                        key={index}
                                        value={item.floorType}
                                      >
                                        {item.floorType}
                                      </option>
                                    )
                                )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="exampleEmail">Storage Floor</Label>
                            <Input
                              id="exampleSelect"
                              name="select"
                              value={floor}
                              type="select"
                              onChange={handleSelectFloor}
                            >
                              {floors &&
                                floors.map(
                                  (item, index) =>
                                    item.isActive && (
                                      <option key={index} value={item.floor}>
                                        {item.floor}
                                      </option>
                                    )
                                )}
                            </Input>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                </Form>
              </div>
              {parkingList.length > 0 ? (
                <div className="parkingFiling_sec">
                  {floors && getStorageSpace(floor)}
                </div>
              ) : (
                <h3 className="text-center"> No Storage Found</h3>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      {modal && (
        <Modal isOpen={modal} toggle={toggleClose}>
          <ModalHeader toggle={toggleClose}>Storage</ModalHeader>
          <ModalBody>
            <div className="modalInner">
              <Row>
                <Col md={12} className="mt-3">
                  <FormGroup>
                    <Label for="exampleEmail">Locker No</Label>
                    <Input
                      readOnly
                      name="parkingLotNo"
                      placeholder="Parking No"
                      type="text"
                      value={modalData.lockerNo}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mt-3">
                  <FormGroup>
                    <Label for="exampleEmail">Suite Number *</Label>
                    <Select
                      value={selectTenants}
                      options={tenantsList?.map((item) => {
                        return {
                          _id: item._id,
                          value: `${item.suite ? item.suite : ""}`,
                          label: `${item.suite ? item.suite : ""}`,
                        };
                      })}
                      placeholder="Search By suite"
                      onChange={(event) => {
                        setSelectTenants(event);
                        const { firstname, lastname, email, mobile } =
                          tenantsList.find((doc) => doc._id == event._id);
                        setModalReadData({
                          name:
                            firstname || lastname
                              ? `${firstname ? firstname : ""} ${
                                  lastname ? lastname : ""
                                }`
                              : "",
                          email: email ? email : "",
                          mobile: mobile ? mobile : "",
                        });
                        setFormError({ ...formError, ["ownerName"]: "" });
                      }}
                    />
                    {formError["ownerName"] ? (
                      <Label style={{ color: "red" }}>
                        {formError["ownerName"]}
                      </Label>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                </Col>
                <Col md={12} className="mt-3">
                  <FormGroup>
                    <Label for="exampleEmail">Name</Label>
                    <Input
                      readOnly
                      name="name"
                      placeholder="Name"
                      type="text"
                      value={modalReadData.name}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mt-3">
                  <FormGroup>
                    <Label for="exampleEmail">Email</Label>
                    <Input
                      readOnly
                      name="email"
                      placeholder="Email"
                      type="text"
                      value={modalReadData.email}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mt-3">
                  <FormGroup>
                    <Label for="exampleEmail">Mobile No.</Label>
                    <Input
                      readOnly
                      name="mobile"
                      placeholder="Mobile No."
                      type="text"
                      value={modalReadData.mobile}
                    />
                  </FormGroup>
                </Col>
                <Col md={12} className="mt-3">
                  <Label htmlFor="example-date-input" className="form-Label">
                    Amount *
                  </Label>
                  <Input
                    name="amount"
                    className="form-control"
                    type="number"
                    value={modalData.amount}
                    id="example-date-input"
                    onChange={handleModalChange}
                  />
                  {formError["amount"] ? (
                    <Label style={{ color: "red" }}>
                      {formError["amount"]}
                    </Label>
                  ) : (
                    ""
                  )}
                </Col>
                <Col md={12} className="mt-3">
                  <Label htmlFor="example-date-input" className="form-Label">
                    Effective Date *
                  </Label>
                  <Input
                    name="effectiveDate"
                    className="form-control"
                    type="date"
                    value={modalData.effectiveDate}
                    id="example-date-input"
                    onChange={handleModalChange}
                  />
                  {formError["effectiveDate"] ? (
                    <Label style={{ color: "red" }}>
                      {formError["effectiveDate"]}
                    </Label>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            {isReset && (
              <Button
                disabled={tenantsList.length ? false : true}
                color="success"
                onClick={handleReset}
              >
                Reset
              </Button>
            )}
            {isAddAnother && (
              <Button
                disabled={tenantsList.length ? false : true}
                color="primary"
                onClick={() => {
                  isNewAddTenant
                    ? handleUpdate()
                    : handleAddAnother(modalData.parkingLotNo);
                }}
              >
                Add Another
              </Button>
            )}
            {!isNewAddTenant && (
              <Button
                disabled={tenantsList.length ? false : true}
                color="primary"
                onClick={handleUpdate}
              >
                Update
              </Button>
            )}{" "}
            <Button color="danger" onClick={toggleClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default Storage;
