import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import axios from "../api/axios";
import { useState } from "react";
import moment from "moment";
import Select from "react-select";
import { Link } from "react-router-dom";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    CardHeader,
    FormGroup,
    Collapse,
    Label,
    Form,
    FormFeedback,
    // FormControl,
    Input,
    Pagination,
    PaginationItem,
    PaginationLink,
    ModalFooter,
    ModalBody,
    Button,
    ModalHeader,
  } from "reactstrap";
  import jwt_decode from "jwt-decode";
  import Dropzone from "react-dropzone";
  import FeatherIcon from "feather-icons-react";
  import { toast } from "react-toastify";
  import "../../assets/css/rentdigicare.css";

const ConsumedItems = () => {

    
    const decode2 = jwt_decode(window.localStorage.getItem("accessToken"));
    const FILTER_URL = `/property/filter?role=${decode2.role}&domain=${
        decode2.role == "manager" ? decode2.id : decode2.domain
      }`;

    const COMPANY_NAME_URL = `/company/filter/company_list`
    
  const [consumedItems, setConsumedItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState([]);
  const [CompanyName, setCompanyName] = useState([]);
  const [SelectedCompanyId, setSelectedCompanyId] = useState("");
  const [SelectedPropertyId, setSelectedPropertyId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  const companyName = async () => {
    const response = await axios.get(COMPANY_NAME_URL);
    const temp = (response.data.companies);
    console.log(temp);
    setCompanyName(temp)
}

const propertiesList = async (companyId) => {
    const params = {
      companyID: companyId
    }
    const response = await axios.post('/property/getAllCompanyProperties', params);
    const temp = (response.data.properties);
    if(temp) {
        setProperties(temp);
    }
   
}


  const handleFilterConsumedItems = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/inventoryconsumed/filter  ', {
        companyId: SelectedCompanyId,
        propertyId: SelectedPropertyId,
        startDate: startDate,
        endDate: endDate,
      })
      if (response.status === 200) {
        setConsumedItems(response.data);
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const fetchConsumedItems = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/inventoryconsumed");
        if (response.status === 200) {
          setConsumedItems(response.data);
        }
      } catch (error) {
        console.error(error);
        toast.error("Error fetching consumed items");
      } finally {
        setLoading(false);
      }
    };
    companyName();
    fetchConsumedItems();
  }, []);

  return (
    <div className="page-content">
           <MetaTags>
               <title> Rentdigicare | Consumed Items</title>
           </MetaTags>
           <div className="container-fluid">
               <Breadcrumbs title="Home" breadcrumbItem="Inventory" breadcrumbItem2="Consumed Items" />

               <Row>
                   <Col xl={12}>
                   <Card>
                       <CardHeader>

                              <Form>
                                  <FormGroup row>
                                      <Col md="3">
                                          <Label htmlFor="property">Select Company</Label>
                                          <Input type="select" name="SelectedCompany" id="SelectedCompany" onChange={(e) => {
                                              setSelectedCompanyId(e.target.value);
                                              propertiesList(e.target.value);
                                          }} value={SelectedCompanyId}>
                                              <option value="">Select Company</option>
                                              {CompanyName.map(company => (

                                                  <option key={company._id} value={company._id}>{company.name}</option>
                                              ))}
                                          </Input>

                                      </Col>
                                      <Col md="3">
                                          <Label htmlFor="suit">Select Property</Label>
                                          <Input type="select" name="property" id="property" onChange={(e) => setSelectedPropertyId(e.target.value)}
                                              value={SelectedPropertyId}>
                                              <option value="">Select Property</option>
                                              {properties.map(property => (

                                                  <option key={property._id} value={property._id}>{property.title}</option>
                                              ))}
                                          </Input>
                                      </Col>

                                      <Col md="3">
                                          <Label htmlFor="date">Start Date</Label>
                                          <Input type="date" id="date" name="startDate" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                                      </Col>
                                      <Col md="3">
                                          <Label htmlFor="date">End Date</Label>
                                          <Input type="date" id="date" name="endDate" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                                      </Col>

                                      <Col md="3">

                                          <Button type="submit" color="primary" className="mt-1 mr-1" onClick={(e) => handleFilterConsumedItems(e)}>Filter</Button>
                                      </Col>
                                  </FormGroup>
                              </Form>

                       </CardHeader>
                       <CardBody>

                              <div className="row">
                                  <div className="col-12">

                                      <table className="table-striped table-bordered mb-0 reportcard-list-table">
                                          <thead>
                                              <tr>
                                                  <th>Property Name</th>
                                                  <th>Suit Name</th>
                                                  <th>Date of Use</th>
                                                  <th>Maintance Team</th>
                                                  <th>Item Consumed</th>
                                                  <th>Consumed Qty</th>
                                                  

                                              </tr>
                                          </thead>
                                          <tbody>
                                          {consumedItems.map((obj, index) => (
                                                <tr key={index}>
                                                    <td>{obj.property.title}</td>
                                                    <td>{obj.suit}</td>
                                                    <td>{moment(obj.updatedAt).format('DD-MM-YYYY')}</td>
                                                    <td></td>
                                                    <td>{obj.item.name}</td>
                                                    <td>{obj.consumedQuantity}</td>
                                                </tr>
                                          ))}
                                             
                                          </tbody>
                                         
                                      </table>
                                     


                                  </div>
                              </div>
                        
                       </CardBody>
                   </Card>
                   </Col>
               </Row>
           </div>
   </div>
);
};

export default ConsumedItems;
