import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  CardHeader,
  Collapse,
  Label,
  Form,
  Input,
  Button,
} from "reactstrap";
import logoSvg from "../../assets/images/logo-sm.svg";
import Dropzone from "react-dropzone";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SearchableDropdown from "../../components/Common/SearchableDropdown";
const options = [
  { value: 1, label: " 1" },
  { value: 2, label: " 2" },
  { value: 3, label: " 3" },
  // Add more options here
];
const ParkingList = () => {
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const optionMulti = [
    { label: "Manager", value: "Manager" },
    { label: "Manager 1", value: "Manager 1" },
    { label: "Manager 2", value: "Manager 2" },
  ];
  const [modal_large, setmodal_large] = useState(false);
  const [modal_large1, setmodal_large1] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleSelect = (value) => {
    // Do something with the selected value
    console.log("Selected:", value);
  };
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }

  const [col5, setcol5] = useState(false);
  const t_col5 = () => {
    setcol5(!col5);
  };
  function tog_large1() {
    setmodal_large1(!modal_large1);
    removeBodyCss();
  }

  const [selectedFiles, setselectedFiles] = useState([]);
  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Rentdigicare | Parking List</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Parking List" />
          <Row>
            <Col xl={12}>
              <Card>
                {
                  <CardHeader>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="mb">
                          <h5 className="card-title">
                            Total Parking
                            <span className="text-muted fw-normal ms-2">
                              (6)
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="col-md-6 d-flex flex-wrap align-items-center justify-content-end">
                        <div className="mb">
                          {/* <button
                        onClick={t_col5}
                        className="btn btn-primary mo-mb-2 mr-10"
                        type="button"
                        style={{ cursor: "pointer" }}
                    >
                        Filters
                    </button>   */}
                          <button
                            onClick={(e) => {
                              setShowFilter(!showFilter);
                            }}
                            className="btn btn-primary mo-mb-2 mr-10"
                            type="button"
                            style={{ cursor: "pointer" }}
                          >
                            Filters
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              tog_large();
                            }}
                            className="btn btn-light "
                            data-toggle="modal"
                            data-target=".bs-example-modal-lg"
                          >
                            <i className="bx bx-plus me-1"></i> Add Parking
                          </button>
                          <Modal
                            size="lg"
                            isOpen={modal_large}
                            toggle={() => {
                              tog_large();
                            }}
                          >
                            <div className="modal-header">
                              <h5
                                className="modal-title mt-0"
                                id="myLargeModalLabel"
                              >
                                Add Parking
                              </h5>
                              <button
                                onClick={() => {
                                  setmodal_large(false);
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Form>
                                <div className="row align-items-center">
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-name-input"
                                      >
                                        Tenant
                                      </Label>
                                      <select className="form-select">
                                        <option>Select</option>
                                        <option>Simran</option>
                                        <option>Sunny</option>
                                        <option>Lucky</option>
                                      </select>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-name-input"
                                      >
                                        Parking For
                                      </Label>
                                      <select className="form-select">
                                        <option>Self</option>
                                        <option>Visitor</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-plate-input"
                                      >
                                        Vehicle Number
                                      </Label>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-plate-input"
                                        placeholder="Vehicle Number"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-facility-input"
                                      >
                                        Parking Type
                                      </Label>
                                      <select className="form-select">
                                        <option>Select</option>
                                        <option>UG Parkig</option>
                                        <option>SS Parkig</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-facility-input"
                                      >
                                        Parking Floor
                                      </Label>
                                      <select className="form-select">
                                        <option>Select</option>
                                        <option>1st Floor</option>
                                        <option>2nd Floor</option>
                                        <option>Oak Tower</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="formrow-suite-input"
                                      >
                                        Parking Number
                                      </Label>
                                      {/* <Input
                                        type="text"
                                        className="form-control"
                                        id="formrow-suite-input"
                                        placeholder="Parking Number"
                                      /> */}
                                      <SearchableDropdown
                                        options={options}
                                        onSelect={handleSelect}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label
                                        htmlFor="example-date-input"
                                        className="form-Label"
                                      >
                                        Effective Date
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="date"
                                        defaultValue="2019-08-19"
                                        id="example-date-input"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="mb-3">
                                      <Label
                                        htmlFor="example-date-input"
                                        className="form-Label"
                                      >
                                        End Date
                                      </Label>
                                      <Input
                                        className="form-control"
                                        type="date"
                                        defaultValue="2020-08-19"
                                        id="example-date-input"
                                      />
                                    </div>
                                  </div>

                                  {/* <div className="col-md-6">
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="formrow-email-input">Email</Label>
                                    <Input type="text" className="form-control" id="formrow-email-input" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="formrow-stall-input">Visitor Parking Stall Number</Label>
                                    <Input type="text" className="form-control" id="formrow-stall-input" />
                                </div>
                            </div> */}
                                  {/* <div className="col-md-6">
                                <div className="mb-3">
                                    <Label htmlFor="example-date-input" className="form-Label">Date</Label>
                                    <Input className="form-control" type="date" defaultValue="2019-08-19" id="example-date-input" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <Label htmlFor="example-time-input" className="form-Label">From</Label>
                                    <Input className="form-control" type="time" defaultValue="13:45:00" id="example-time-input" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <Label htmlFor="example-time-input" className="form-Label">To</Label>
                                    <Input className="form-control" type="time" defaultValue="13:45:00" id="example-time-input" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="formrow-make-input">Car Make</Label>
                                    <Input type="text" className="form-control" id="formrow-make-input" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <Label className="form-label" htmlFor="formrow-model-input">Car Model</Label>
                                    <Input type="text" className="form-control" id="formrow-model-input" />
                                </div>
                            </div> */}
                                </div>
                                <div className="row align-items-center modalBtn mt-4">
                                  <div className="text-end">
                                    <Button
                                      color="primary"
                                      type="submit"
                                      className="  save-user"
                                    >
                                      Save
                                    </Button>
                                  </div>
                                  <div className="text-end ">
                                    <Button
                                      color="light"
                                      onClick={() => {
                                        setmodal_large(false);
                                      }}
                                      type="button"
                                      className=" cancel-user"
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </Modal>
                          {/* delete modal */}
                          <Modal
                            size="md"
                            isOpen={deleteModal}
                            toggle={() => {
                              setDeleteModal();
                            }}
                          >
                            <div className="modal-header">
                              {/* <h5
                                className="modal-title mt-0"
                                id="myLargeModalLabel"
                              >
                                Add Parking
                              </h5> */}
                              <button
                                onClick={() => {
                                  setDeleteModal(false);
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body text-center ">
                              <p>
                                {" "}
                                Are you sure you want to delete this parking
                              </p>
                              <div className="row align-items-center modalBtn justify-content-center mt-4">
                                <div className="text-end">
                                  <Button
                                    color="primary"
                                    type="submit"
                                    className="  save-user"
                                    onClick={() => {
                                      setDeleteModal(false);
                                    }}
                                  >
                                    Delete 
                                  </Button>
                                </div>
                                <div className="text-end ">
                                  <Button
                                    color="light"
                                    onClick={() => {
                                      setDeleteModal(false);
                                    }}
                                    type="button"
                                    className=" cancel-user"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Modal>
                        </div>
                      </div>
                      <Collapse className="mt-4" isOpen={showFilter}>
                        <Card>
                          <CardBody>
                            <div className="row align-items-center mb-3">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <Label
                                    htmlFor="example-date-input"
                                    className="form-Label"
                                  >
                                    From
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="date"
                                    value={selectedStartDate}
                                    onChange={(e) => {
                                      setSelectedStartDate(e.target.value);
                                    }}
                                    //   defaultValue={neew}
                                    id="example-date-input"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <Label
                                    htmlFor="example-date-input"
                                    className="form-Label"
                                  >
                                    To
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="date"
                                    value={selectedEndDate}
                                    onChange={(e) => {
                                      setSelectedEndDate(e.target.value);
                                    }}
                                    //  defaultValue={FromFilter}
                                    id="example-date-input"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 searchClear-btns justify-content-end d-flex">
                              <div className="srch-btn">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Search
                                </button>
                              </div>
                              <div className="srch-btn">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  onClick={() => window.location.reload()}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Collapse>
                    </div>
                  </CardHeader>
                }
                <CardBody>
                  <div className="table-responsive">
                    <div>
                      <select
                        // onChange={(e) => handleSorting(e)}
                        style={{
                          borderRadius: "4px",
                          marginBottom: "10px",
                          borderColor: "#d1d1d5",
                        }}
                      >
                        <option>Sorting </option>
                        <option value={1}>A-Z</option>
                        <option value={2}>Z-A</option>
                        <option value={3}>Created Latest</option>
                        <option value={4}>Created Oldest</option>
                        <option value={5}>Modified Latest</option>
                        <option value={6}>Modified Oldest</option>
                      </select>
                    </div>
                    <Table className="table-striped table-bordered mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          {/* <th scope="col">Building</th> */}
                          <th scope="col">Parking Type</th>
                          <th scope="col">Parking Floor</th>
                          <th scope="col">Parking Number</th>
                          <th scope="col">Name</th>
                          <th scope="col">Phone Number</th>
                          {/* <th scope="col">Email</th> */}
                          {/* <th scope="col">Stall Number</th> */}
                          {/* <th scope="col">Date</th>
                          <th scope="col">From</th> */}
                          {/* <th scope="col">To</th>
                          <th scope="col">Car Make</th>
                          <th scope="col">Car Model</th> */}
                          <th scope="col">Vehicle Number</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          {/* <td>Building Name</td> */}
                          <td>UG Parking</td>
                          <td>1st Floor</td>
                          <td>1253</td>
                          <td>Gaurav</td>
                          <td>8556055809</td>
                          {/* <td>abc@gmail.com</td> */}
                          {/* <td>120</td>
                            <td>2019-08-19</td>
                            <td>01:20 AM</td>
                            <td>09:20 PM</td>
                            <td>Toyoto</td>
                            <td>Innova</td>  */}
                          <td>Pb 65 4455</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    tog_large();
                                  }}
                                  data-toggle="modal"
                                  data-target=".bs-example-modal-lg"
                                ></i>
                              </Link>
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setDeleteModal(true);
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          {/* <td>Building Name</td> */}
                          <td>UG Parking</td>
                          <td>1st Floor</td>
                          <td>1253</td>
                          <td>Gaurav</td>
                          <td>8556055809</td>
                          {/* <td>abc@gmail.com</td> */}
                          {/* <td>120</td>
                            <td>2019-08-19</td>
                            <td>01:20 AM</td>
                            <td>09:20 PM</td>
                            <td>Toyoto</td>
                            <td>Innova</td>  */}
                          <td>Pb 65 4455</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    tog_large();
                                  }}
                                  data-toggle="modal"
                                  data-target=".bs-example-modal-lg"
                                ></i>
                              </Link>
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setDeleteModal(true);
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          {/* <td>Building Name</td> */}
                          <td>UG Parking</td>
                          <td>1st Floor</td>
                          <td>1253</td>
                          <td>Gaurav</td>
                          <td>8556055809</td>
                          {/* <td>abc@gmail.com</td> */}
                          {/* <td>120</td>
                            <td>2019-08-19</td>
                            <td>01:20 AM</td>
                            <td>09:20 PM</td>
                            <td>Toyoto</td>
                            <td>Innova</td>  */}
                          <td>Pb 65 4455</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    tog_large();
                                  }}
                                  data-toggle="modal"
                                  data-target=".bs-example-modal-lg"
                                ></i>
                              </Link>
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setDeleteModal(true);
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          {/* <td>Building Name</td> */}
                          <td>UG Parking</td>
                          <td>1st Floor</td>
                          <td>1253</td>
                          <td>Gaurav</td>
                          <td>8556055809</td>
                          {/* <td>abc@gmail.com</td> */}
                          {/* <td>120</td>
                            <td>2019-08-19</td>
                            <td>01:20 AM</td>
                            <td>09:20 PM</td>
                            <td>Toyoto</td>
                            <td>Innova</td>  */}
                          <td>Pb 65 4455</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    tog_large();
                                  }}
                                  data-toggle="modal"
                                  data-target=".bs-example-modal-lg"
                                ></i>
                              </Link>
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setDeleteModal(true);
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          {/* <td>Building Name</td> */}
                          <td>UG Parking</td>
                          <td>1st Floor</td>
                          <td>1253</td>
                          <td>Gaurav</td>
                          <td>8556055809</td>
                          {/* <td>abc@gmail.com</td> */}
                          {/* <td>120</td>
                            <td>2019-08-19</td>
                            <td>01:20 AM</td>
                            <td>09:20 PM</td>
                            <td>Toyoto</td>
                            <td>Innova</td>  */}
                          <td>Pb 65 4455</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    tog_large();
                                  }}
                                  data-toggle="modal"
                                  data-target=".bs-example-modal-lg"
                                ></i>
                              </Link>
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setDeleteModal(true);
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>6</td>
                          {/* <td>Building Name</td> */}
                          <td>UG Parking</td>
                          <td>1st Floor</td>
                          <td>1253</td>
                          <td>Gaurav</td>
                          <td>8556055809</td>
                          {/* <td>abc@gmail.com</td> */}
                          {/* <td>120</td>
                            <td>2019-08-19</td>
                            <td>01:20 AM</td>
                            <td>09:20 PM</td>
                            <td>Toyoto</td>
                            <td>Innova</td>  */}
                          <td>Pb 65 4455</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    tog_large();
                                  }}
                                  data-toggle="modal"
                                  data-target=".bs-example-modal-lg"
                                ></i>
                              </Link>
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setDeleteModal(true);
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>7</td>
                          {/* <td>Building Name</td> */}
                          <td>UG Parking</td>
                          <td>1st Floor</td>
                          <td>1253</td>
                          <td>Gaurav</td>
                          <td>8556055809</td>
                          {/* <td>abc@gmail.com</td> */}
                          {/* <td>120</td>
                            <td>2019-08-19</td>
                            <td>01:20 AM</td>
                            <td>09:20 PM</td>
                            <td>Toyoto</td>
                            <td>Innova</td>  */}
                          <td>Pb 65 4455</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link className="text-success" to="#">
                                <i
                                  className="mdi mdi-pencil font-size-18"
                                  id="edittooltip"
                                  onClick={() => {
                                    tog_large();
                                  }}
                                  data-toggle="modal"
                                  data-target=".bs-example-modal-lg"
                                ></i>
                              </Link>
                              <Link className="text-danger" to="#">
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                  onClick={() => {
                                    setDeleteModal(true);
                                  }}
                                ></i>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ParkingList;
