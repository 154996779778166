import React, { useState, useEffect } from "react";
import View from "../../../assets/images/eye.svg";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { MetaTags } from "react-meta-tags";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "../../api/axios";
import { useDispatch } from "react-redux";
import PaginationConponent from "../../../components/Common/Pagination";
import { LIMIT } from "../../../constants/comman";
import { SERVER_URL } from "../../ServerLink";
import { isLoading } from "../../../store/actions";
import { Link, NavLink } from "react-router-dom";
import { toast } from "react-toastify";

const GET_TENANTS_LIST = "parking/tenants-list";
const GET_PROPERTYS_LIST = "parking/property-list";
const POST_MASTER_SHEET_LIST = "mastersheet/";
const GET_MASTER_SHEET_DOWNLOAD = "mastersheet/download";
const POST_MASTER_SHEET_ARCHIVE = "mastersheet/archive";

const SORTING = [
  {
    id: 1,
    label: "A-Z",
    value: { firstname: 1, lastname: 1 },
  },
  {
    id: 2,
    label: "Z-A",
    value: { firstname: -1, lastname: -1 },
  },
  {
    id: 3,
    label: "Created Latest",
    value: { createdAt: -1 },
  },
  {
    id: 4,
    label: "Created Oldest",
    value: { createdAt: 1 },
  },
];

const Master_data = () => {
  const { userData } = JSON.parse(window.localStorage.getItem("authUser"));
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState({
    name: "",
    email: "",
    suite: "",
    leaseStart: new Date(),
    leaseEnd: new Date(),
    isArchive: false,
  });

  const [propertyList, setPropertyList] = useState([]);
  const [selectPropertyName, setSelectPropertyName] = useState("");
  const [propertyID, setPropertyID] = useState("");
  const [buildingName, setBuildingName] = useState("");

  const [sortingValue, setSortingValue] = useState("");
  const [sorting, setSorting] = useState("");

  const [tenantList, setTenantList] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalSum, setTotalSum] = useState({});
  const [page, setPage] = useState(1);

  const [modal, setModal] = useState(false);
  const [comment, setComment] = useState("");
  const [tannetId, setTannetId] = useState("");

  const toggleClose = () => setModal(false);

  const handleModal = (id, data) => {
    setModal(true);
    setComment(data);
    setTannetId(id);
  };

  const handleArchive = async () => {
    try {
      dispatch(isLoading(true));
      const res = await axios.post(POST_MASTER_SHEET_ARCHIVE, {
        id: tannetId,
        comment: comment,
      });
      if (res && res.data && res.data.status == 201) {
        await getMasterSheetList(page, filter);
        toast.success("Tanent Archive Successfully.");
        setModal(false);
        dispatch(isLoading(false));
      }
    } catch (error) {
      dispatch(isLoading(false));
    }
  };

  const getPropertyList = async () => {
    try {
      const response = await axios.get(
        `${GET_PROPERTYS_LIST}?role=${userData.role}&id=${userData.id}`
      );
      const result = response.data.data;
      if (result && result.length && Array.isArray(result)) {
        setPropertyList(result);
        const defaultProperty = {
          id: result[0]._id,
          value: result[0].title,
          label: `${result[0].title}, ${result[0].location}`,
          building: result[0].building || [],
        };
        setSelectPropertyName(defaultProperty);
        setPropertyID(result[0]._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getMasterSheetList = async (_page, _filter) => {
    try {
      dispatch(isLoading(true));
      const body = {
        propertyID,
        buildingName,
        page: _page,
        limit: LIMIT,
        filter: _filter,
        sort: sorting,
      };
      const result = await axios.post(POST_MASTER_SHEET_LIST, body);
      console.log({ result });
      dispatch(isLoading(false));
      if (result.data && result.data.status === 200) {
        const responce = result.data.data;
        setTenantList(responce.data);
        setTotal(responce.total);
        setTotalSum(responce.totalSum);
      }
    } catch (error) {
      console.error(error);
      dispatch(isLoading(false));
    }
  };

  const handlePage = (page) => {
    setPage(page);
  };

  const handleSorting = (event) => {
    const { value } = event.target;
    setSortingValue(value);
    if (value) {
      const sort = SORTING.find((doc) => doc.id == value);
      setSorting(sort.value);
    } else {
      setSorting("");
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleClear = async () => {
    setFilter({
      name: "",
      email: "",
      suite: "",
      leaseStart: new Date(),
      leaseEnd: new Date(),
      isArchive: false,
    });
    const _filter = {
      name: "",
      email: "",
      suite: "",
      leaseStart: new Date(),
      leaseEnd: new Date(),
      isArchive: false,
    };
    dispatch(isLoading(true));
    await getMasterSheetList(1, _filter);
    dispatch(isLoading(false));
  };

  const handleSearch = async () => {
    dispatch(isLoading(true));
    await getMasterSheetList(1, filter);
    dispatch(isLoading(false));
  };

  useEffect(() => {
    if (propertyID) {
      getMasterSheetList(page, filter);
    }
  }, [propertyID, buildingName, page, sorting]);

  useEffect(() => {
    getPropertyList();
  }, []);

  return (
    <div className="page-content">
      <div className="master_page">
        <MetaTags>
          <title>Rentdigicare | Master Sheet</title>
        </MetaTags>
        <div className="container-fluid fontSize12">
          <Breadcrumbs title="Home" breadcrumbItem="Master Sheet" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <Row className="align-items-center mb-3">
                    <Row className="col-6">
                      <Col md={6}>
                        <FormGroup>
                          <Label className="form-Label">Property Name</Label>
                          <Select
                            value={selectPropertyName}
                            options={propertyList?.map((item) => {
                              return {
                                id: item._id,
                                value: item.title,
                                label: `${item.title}, ${item.location}`,
                                building: item.building || [],
                              };
                            })}
                            placeholder="Search By Name"
                            onChange={(event) => {
                              setSelectPropertyName(event);
                              setBuildingName("");
                              setPropertyID(event.id);
                              setPage(1);
                              setSorting("");
                              setSortingValue("");
                              setShowFilter(false);
                              setFilter({
                                name: "",
                                email: "",
                                suite: "",
                                leaseStart: "",
                                leaseEnd: "",
                                isArchive: false,
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        {selectPropertyName.building &&
                          selectPropertyName.building.length > 0 && (
                            <FormGroup>
                              <Label className="form-Label">
                                Building Name
                              </Label>
                              <Select
                                value={{
                                  value: buildingName,
                                  label: buildingName,
                                }}
                                options={selectPropertyName.building?.map(
                                  (item) => {
                                    return {
                                      value: item.buildingName,
                                      label: item.buildingName,
                                    };
                                  }
                                )}
                                placeholder="Search By Building Name"
                                onChange={(event) => {
                                  setBuildingName(event.value);
                                  setPage(1);
                                  setSorting("");
                                  setSortingValue("");
                                  setShowFilter(false);
                                  setFilter({
                                    name: "",
                                    email: "",
                                    suite: "",
                                    leaseStart: "",
                                    leaseEnd: "",
                                    isArchive: false,
                                  });
                                }}
                              />
                            </FormGroup>
                          )}
                      </Col>
                    </Row>

                    <Col className="text-end">
                      <a
                        className={tenantList.length > 0 ? "" : "isaDisable"}
                        href={`${SERVER_URL}/${GET_MASTER_SHEET_DOWNLOAD}?id=${propertyID}&name=${selectPropertyName.value}`}
                        target="_blank"
                      >
                        <span className="btn btn-primary">Export</span>
                      </a>
                      <Button
                        disabled={tenantList.length > 0 ? false : true}
                        onClick={(e) => {
                          setShowFilter(!showFilter);
                        }}
                        color="primary"
                        className="mo-mb-2 mr-10 mx-3"
                      >
                        Filters
                      </Button>
                      {propertyID !== "" &&
                      (selectPropertyName.building?.length === 0 ||
                        buildingName !== "") ? (
                        <NavLink
                          to={{
                            pathname: "/add-tenants",
                            state: {
                              propertyId: propertyID,
                              buildingName: buildingName,
                            },
                          }}
                        >
                          <Button
                            color="primary"
                            className="mo-mb-2 mr-10 mx-3"
                            children={`Add New Tenants - ${
                              selectPropertyName?.value || ""
                            }`}
                          />
                        </NavLink>
                      ) : (
                        <Button
                          color="primary"
                          className="mo-mb-2 mr-10 mx-3"
                          children={`Add New Tenants - ${
                            selectPropertyName?.value || ""
                          }`}
                          disabled={true}
                        />
                      )}
                    </Col>
                    <Collapse className="mt-4" isOpen={showFilter}>
                      <Card>
                        <CardBody>
                          <Row className="align-items-center mb-3">
                            <Col md={3}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="formrow-Name-input"
                                >
                                  Tenant Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  onChange={handleOnChange}
                                  value={filter.name}
                                  placeholder="Search by name"
                                  id="formrow-Name-input"
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="formrow-Name-input"
                                >
                                  Tenant Email
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="email"
                                  onChange={handleOnChange}
                                  value={filter.email}
                                  placeholder="Search by email"
                                  id="formrow-Name-input"
                                />
                              </div>
                            </Col>
                            <Col md={3}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="formrow-Name-input"
                                >
                                  Suite.
                                </Label>

                                <Input
                                  type="text"
                                  className="form-control"
                                  name="suite"
                                  onChange={handleOnChange}
                                  value={filter.suite}
                                  placeholder="Search by suite."
                                  id="formrow-Name-input"
                                />
                                {/* <Label
                                  className="form-label"
                                  htmlFor="formrow-Name-input"
                                >
                                  Tanent Mobile No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="mobile"
                                  onChange={handleOnChange}
                                  value={filter.mobile}
                                  placeholder="Search by mobile no."
                                  id="formrow-Name-input"
                                /> */}
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="formrow-Name-input"
                                >
                                  Lease Start.
                                </Label>

                                <Input
                                  type="date"
                                  className="form-control"
                                  name="leaseStart"
                                  onChange={handleOnChange}
                                  value={filter.leaseStart}
                                  placeholder="Search by lease start date."
                                  id="formrow-Name-input"
                                />
                                {/* <Label
                                  className="form-label"
                                  htmlFor="formrow-Name-input"
                                >
                                  Tanent Mobile No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="mobile"
                                  onChange={handleOnChange}
                                  value={filter.mobile}
                                  placeholder="Search by mobile no."
                                  id="formrow-Name-input"
                                /> */}
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="formrow-Name-input"
                                >
                                  Lease End
                                </Label>

                                <Input
                                  type="date"
                                  className="form-control"
                                  name="leaseEnd"
                                  onChange={handleOnChange}
                                  value={filter.leaseEnd}
                                  placeholder="Search by lease end date."
                                  id="formrow-Name-input"
                                />
                                {/* <Label
                                  className="form-label"
                                  htmlFor="formrow-Name-input"
                                >
                                  Tanent Mobile No.
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  name="mobile"
                                  onChange={handleOnChange}
                                  value={filter.mobile}
                                  placeholder="Search by mobile no."
                                  id="formrow-Name-input"
                                /> */}
                              </div>
                            </Col>
                            <Col md={3} className="text-center">
                              <FormGroup check inline>
                                <Input
                                  type="checkbox"
                                  checked={filter.isArchive}
                                  onChange={(event) => {
                                    setFilter({
                                      ...filter,
                                      isArchive: event.target.checked,
                                    });
                                  }}
                                />
                                <Label check>Archive</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <div className="col-md-12 searchClear-btns justify-content-end d-flex">
                            <div className="srch-btn">
                              <Button color="primary" onClick={handleSearch}>
                                Search
                              </Button>
                            </div>
                            <div className="srch-btn">
                              <Button color="primary" onClick={handleClear}>
                                Clear
                              </Button>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Collapse>
                  </Row>
                </CardHeader>
                <CardBody>
                  {propertyList.length > 0 ? (
                    <>
                      <div>
                        <select
                          value={sortingValue}
                          onChange={(event) => handleSorting(event)}
                          style={{
                            borderRadius: "4px",
                            marginBottom: "10px",
                            borderColor: "#d1d1d5",
                          }}
                        >
                          <option value={""}>Sorting </option>
                          {SORTING.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.label}
                            </option>
                          ))}
                          {/* <option value={5}>Modified Latest</option>
                      <option value={6}>Modified Oldest</option> */}
                        </select>
                        <span className="mx-3">Scroll right to see more</span>
                      </div>
                      <div className="table-responsive">
                        <table className="table-striped table-bordered mb-0 table">
                          <thead>
                            <tr>
                              <th className="fixed" scope="row">
                                #
                              </th>
                              <th className="fixed" scope="row">
                                Style
                              </th>
                              {/* <th className="fixed" scope="row">
                                RENO Satus
                              </th>
                              <th className="fixed" scope="row">
                                View
                              </th>
                              <th className="fixed" scope="row">
                                Sq. Ft.
                              </th> */}
                              <th className="" scope="row">
                                Suite
                              </th>
                              <th className="" scope="col">
                                Tenant
                              </th>
                              <th scope="col">Target Rent</th>
                              <th scope="col">Basic Rent ($)</th>
                              <th scope="col">Parking Fee ($) </th>
                              <th scope="col">Storage Fee ($)</th>
                              <th scope="col">Cat Fee ($)</th>
                              <th scope="col">Dog Fee ($)</th>
                              <th scope="col">Others ($)</th>
                              <th scope="col">Total Rent ($)</th>
                              {/* <th scope="col">Lease Incentive</th> */}
                              <th scope="col">Lease Start (D-M-Y)</th>
                              <th scope="col">Lease End (D-M-Y)</th>
                              {/* <th scope="col">Status</th> */}
                              <th scope="col">Insurance Expiry (D-M-Y)</th>
                              <th scope="col">Insurance Company</th>
                              <th scope="col">Parking Lot</th>
                              <th scope="col">Storage Locker</th>
                              <th scope="col">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {tenantList.length > 0 ? (
                              tenantList.map((item, index) => (
                                <tr
                                  key={index}
                                  class={item.isArchive ? "table-danger" : ""}
                                >
                                  <td className="fixed" scope="row">
                                    {(page - 1) * LIMIT + (index + 1)}
                                  </td>
                                  <td className="fixed" scope="row">
                                    {item.style ? item.style : "--"}
                                  </td>
                                  {/* <td className="fixed" scope="row">
                                    {item.renoStatus ? item.renoStatus : "--"}
                                  </td>
                                  <td className="fixed" scope="row">
                                    {item.view ? item.view : "--"}
                                  </td>
                                  <td className="fixed" scope="row">
                                    {item.sqFt ? item.sqFt : "--"}
                                  </td> */}
                                  <td className="" scope="row">
                                    {item.suite ? item.suite : "--"}{" "}
                                  </td>
                                  <td>
                                    {item.name ? item.name : ""}
                                    {item.email ? ", " + item.email : ""}{" "}
                                    {item.mobile ? ", " + item.mobile : ""}{" "}
                                  </td>
                                  <td>
                                    {item.targetRent ? item.targetRent : "--"}
                                  </td>
                                  <td>
                                    {item.basicRent ? item.basicRent : "--"}
                                  </td>
                                  <td>
                                    {item.parkingFee ? item.parkingFee : "--"}
                                  </td>
                                  <td>
                                    {item.storageFee ? item.storageFee : "--"}
                                  </td>
                                  <td>{item.catFee ? item.catFee : "--"}</td>
                                  <td>{item.dogFee ? item.dogFee : "--"}</td>
                                  <td>{item.others ? item.others : "--"}</td>
                                  <td>
                                    {item.totalRent ? item.totalRent : "--"}
                                  </td>
                                  {/* <td>
                                    {item.incentives ? item.incentives : "--"}
                                  </td> */}
                                  <td>
                                    {item.leaseStart ? item.leaseStart : "--"}
                                  </td>
                                  <td>
                                    {item.leaseEnd ? item.leaseEnd : "--"}
                                  </td>
                                  {/* <td>{item.comment ? item.comment : "--"}</td> */}
                                  <td>
                                    {item.insuranceExpiryDate
                                      ? item.insuranceExpiryDate
                                      : "--"}
                                  </td>{" "}
                                  <td>
                                    {item.insuranceCompanyName
                                      ? item.insuranceCompanyName
                                      : "--"}
                                  </td>
                                  <td>
                                    {item.parkingLot
                                      ? item.parkingLot +
                                        " (" +
                                        item.parkingInfo[0].floorType +
                                        ")"
                                      : "--"}
                                  </td>
                                  <td>
                                    {item.storageLocker
                                      ? item.storageLocker
                                      : "--"}
                                  </td>
                                  <td>
                                    <div className="d-flex gap-3">
                                      <Link
                                        className=" btn btn-primary"
                                        to={`/new_tenant_info/${item._id}/${selectPropertyName.id}`}
                                      >
                                        View
                                      </Link>
                                      <Button
                                        disabled={item.isArchive ? true : false}
                                        color="primary"
                                        onClick={() => {
                                          handleModal(item._id, item.comment);
                                        }}
                                      >
                                        Archive
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td className="text-center" colSpan={25}>
                                  No Data Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                          {/* <tfoot>
                            <tr className="table-info">
                              <td colSpan={7} className="text-end">
                                Total Sum($)
                              </td>
                              <td>
                                {totalSum.targetRentSum
                                  ? totalSum.targetRentSum
                                  : "--"}
                              </td>
                              <td>
                                {totalSum.basicRentSum
                                  ? totalSum.basicRentSum
                                  : "--"}
                              </td>
                              <td>
                                {totalSum.parkingFeeSum
                                  ? totalSum.parkingFeeSum
                                  : "--"}
                              </td>
                              <td>
                                {totalSum.storageFeeSum
                                  ? totalSum.storageFeeSum
                                  : "--"}
                              </td>
                              <td>
                                {totalSum.petFeeSum ? totalSum.petFeeSum : "--"}
                              </td>
                              <td>
                                {totalSum.totalRent ? totalSum.totalRent : "--"}
                              </td>
                              <td colSpan={6}></td>
                            </tr>
                          </tfoot> */}
                        </table>
                      </div>
                      {total > LIMIT && (
                        <PaginationConponent
                          total={total}
                          handlePage={handlePage}
                        />
                      )}
                    </>
                  ) : (
                    <h3 className="text-center">Data Not Found</h3>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        {modal && (
          <Modal isOpen={modal} toggle={toggleClose}>
            <ModalHeader toggle={toggleClose}>Archive Comment</ModalHeader>
            <ModalBody>
              <div className="modalInner">
                <Row>
                  <Col md={12} className="mt-3">
                    <FormGroup>
                      <Label for="exampleEmail">Comment</Label>
                      <Input
                        name="comment"
                        placeholder="Comment"
                        type="text"
                        value={comment}
                        onChange={(event) => {
                          setComment(event.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                disabled={comment ? false : true}
                color="primary"
                onClick={() => handleArchive()}
              >
                Submit
              </Button>
              <Button color="danger" onClick={toggleClose}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Master_data;
