import React, { useEffect, useState } from "react";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import FormStep3 from "./FormStep3";
import FormStep4 from "./FormStep4";
import FormStep5 from "./FormStep5";
import FormStep6 from "./FormStep6";
import FormStep7 from "./FormStep7";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../api/axios";
import { Button, Card, CardBody, CardFooter } from "reactstrap";
import { getApplicationInfo, setReset } from "../../../store/leaseForm/actions";
import { isLoading } from "../../../store/actions";

const GET_LEASE_DOCUMENT = "lease-from/user/"
const POST_LEASE_DOCUMENT = "lease-from/user/"
const DOWNLOAD_LEASE_DOCUMENT = "lease-from/download-signed/"

const UserPreview = () => {
    const { token } = useParams();
    const dispatch = useDispatch();
    const { formData1, formData7 } = useSelector((state) => (state.leaseForm));

    const [errMsg, setErrMsg] = useState("...Loading");
    const [isSigned, setIsSigned] = useState(true);
    const [applicationId, setApplicationId] = useState("");


    const getUserLeaseDocument = async (_token) => {
        try {
            const result = await axios.get(`${GET_LEASE_DOCUMENT}${_token}`);
            if (result && result.data && result.data.status == 403) {
                setErrMsg(result.data.message);
            } else if (result && result.data && result.data.status == 404) {
                setErrMsg(result.data.message);
            } if (result && result.data && result.data.status == 200) {
                setErrMsg("");
                const _result = result.data.data;
                const { applicationId, isSigned, propertyInfo, mangerInfo, formData2, formData3 } = _result;
                const _formData1 = _result.formData1;
                const _formData7 = _result.formData7;

                let _data = {
                    propertyInfo, mangerInfo,
                    formData1: {
                        ...formData1,
                        ..._formData1
                    }, formData2, formData3,
                    formData7: {
                        ...formData7,
                        ..._formData7
                    }
                }
                setIsSigned(isSigned);
                setApplicationId(applicationId);
                dispatch(getApplicationInfo(_data));
            }

        } catch (error) {
            console.error("User Lease Document : ", error);
        }
    }

    const isValid = () => {
        const { tenant } = formData1;
        let isvalid = true;
        tenant.map((item) => {
            if (!item.signature) {
                isvalid = false
            }
        });
        return isvalid;
    }

    const handleDownloadClick = async () => {

        const { data } = await axios.get(`${DOWNLOAD_LEASE_DOCUMENT}${applicationId}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'arraybuffer'
        });
        const blob = new Blob([data], { type: 'application/pdf' });
        const a = document.createElement('a');
        const blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;

        // Set the filename
        a.download = 'LeaseAgreement.pdf';

        // Trigger a click event to initiate the download
        a.click();

        // Clean up the URL object
        window.URL.revokeObjectURL(a.href);
    };

    const handleSubmit = async (_token) => {
        try {

            if (!isValid()) {
                return;
            }

            if (window.confirm("Are you sure you want to submit document ?")) {
                dispatch(isLoading(true))
                const { tenant } = formData1;
                const { nameIntitals } = formData7;

                const bodyData = {
                    tenant, nameIntitals
                }
                const res = await axios.post(`${POST_LEASE_DOCUMENT}${_token}`, bodyData);
                if (res && res.data && res.data.status == 200) {
                    await handleDownloadClick();
                    setErrMsg("Document submited successfully, Thank you.");
                    dispatch(setReset());

                }
                dispatch(isLoading(false))
            }

        } catch (error) {
            dispatch(isLoading(false));
        }
    }

    useEffect(() => {
        if (token) {
            getUserLeaseDocument(token);
        }
    }, [])


    return (
        <>
            <div id="divToPrint">
                {errMsg ? <Card>
                    <CardBody className="text-center">
                        <h2>{errMsg}</h2>
                    </CardBody>
                </Card> : <Card>
                    <CardBody>
                        <FormStep1 readOnly={true} />
                        <hr />
                        <FormStep2 readOnly={true} />
                        <hr />
                        <FormStep3 readOnly={true} />
                        <hr />
                        <FormStep4 />
                        <hr />
                        <FormStep5 />
                        <hr />
                        <FormStep6 />
                        <hr />
                        <FormStep7 readOnly={isSigned} />
                    </CardBody>
                    {!isSigned && <CardFooter className="text-center">
                        <Button disabled={!isValid()} color="primary" onClick={() => { handleSubmit(token) }}>Submit</Button>
                    </CardFooter>}

                </Card>}
            </div>
        </>
    )
}

export default UserPreview;
