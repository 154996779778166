import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Form, Input, Label, Row } from "reactstrap";

const initialValue = {
  style: "1 Bdrm",
  renoStatus: "1-Mar ",
  view: "E",
  sqFt: 725,
  suite: 201,
  tenant: "Kristin Drvaric",
  sd: 1200,
  targetrent: 1325,
  basicrent: 1275,
  telusaffordable: "",
  telusdeal: "",
  parkingUG: 100,
  petfee: "",
  leaseIncentive: "",
  totalRent: 100,
  moveIn: "11/26/2022",
  leaseStart: "12/1/2022",
  leaseEnd: "12/1/2022",
  statusRenewal: "JUNE $1279 - PARKING ADDED",
  padFormStatus: "",
  parking: "A-227",
  storageLocker: "",
};
const ViewMsterTable = () => {
  const [formData, setFormData] = useState(initialValue);
  return (
    <div className="page-content">
      <div className=" master_page">
        <MetaTags>
          <title>Rentdigicare | View Master Sheet</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem=" View Master Sheet" />
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Style
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Style"
                        readOnly
                        value="1 Bdrm"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Reno Status
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        readOnly
                        value="1-Mar"
                        id="formrow-suite-input"
                        placeholder="Reno Status"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        View
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        readOnly
                        value="E"
                        placeholder="View"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Sq Ft
                      </Label>
                      <Input
                        type="number"
                        className="form-control"
                        readOnly
                        value="725"
                        id="formrow-suite-input"
                        placeholder="Sq Ft"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Suite
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        readOnly
                        value="725"
                        placeholder="Suite"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Tenant
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Tenant"
                        readOnly
                        value="Kayla Mcdougall"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        SD
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="SD"
                        readOnly
                        value="  200 "
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Target Rent
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        readOnly
                        value="  1200 "
                        placeholder="Target Rent"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Basic Rent
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        readOnly
                        value=" 800 "
                        id="formrow-suite-input"
                        placeholder="Basic Rent"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Telus Affordable
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Telus Affordable"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Telus Deal
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Telus Deal"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Parking UG
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        readOnly
                        value="60"
                        placeholder=" Parking UG"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Pet Fee
                      </Label>
                      <Input
                        type="text"
                        readOnly
                        value="1Cat"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Pet Fee"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Lease Incentive
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Lease Incentive"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Total Rent
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        readOnly
                        value="1500"
                        placeholder="Total Rent"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Move In
                      </Label>
                      <Input
                        type="date" 
                        readOnly
                        value="13/0/2021"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Move In"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Lease Start
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        readOnly
                        value="13/0/2021"
                        id="formrow-suite-input"
                        placeholder="Lease Start"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Lease End
                      </Label>
                      <Input
                        type="date"
                        className="form-control"
                        readOnlyid="formrow-suite-input"                        
                        value="13/0/2021"

                        placeholder="Lease End"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Status Lease/Renewal
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Status Lease/Renewal"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        PAD Form Status
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="PAD Form Status"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Parking
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        readOnly
                        value="A-206"
                        id="formrow-suite-input"
                        placeholder="Parking"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="formrow-suite-input"
                      >
                        Storage Locker
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="formrow-suite-input"
                        placeholder="Storage Locker"
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
              <div className="backBtn">
                <a href="/master_table">Back to Master Sheet</a>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ViewMsterTable;
