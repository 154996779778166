import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
} from "reactstrap";
import axios from "../api/axios";
import MetaTags from "react-meta-tags";
import FeatherIcon from "feather-icons-react";
import "../../assets/css/rentdigicare.css";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Common/Breadcrumb";

const CreateInventory = (props) => {
  const [newInventory, setNewInventory] = useState({
    title: "",
    description: "",
  });

  const inventoryTypes = [
    {
      title: "General Inventory",
      name: "general",
    },
    {
      title: "Appliances",
      name: "appliances",
    },
  ];

  let categoryName = {
    name: "",
    items: [
      {
        name: "",
        description: "",
        sku: "",
        defaultQuantity: "10",
      },
    ],
  };

  let defaultInventoryConfig = [
    {
      inventoryType: "general",
      category: {
        name: "",
        items: [
          {
            name: "",
            description: "",
            sku: "",
            defaultQuantity: "10",
          },
        ],
      },
      // categoryName : "",
      // items : [
      //   {
      //     name : "",
      //     description : "",
      //     sku : "",
      //     defaultQuantity : "10"
      //   }
      // ]
    },
  ];

  const [InventoryConfig, setInventoryConfig] = useState(
    defaultInventoryConfig
  );
  const [InventoryId, setInventoryId] = useState("");

  useEffect(() => {
    getInventoryByType("general");
  }, []);

  const changeHandler = (e) => {
    setNewInventory({ ...newInventory, [e.target.name]: e.target.value });
  };

  const pushCategory = () => {
    const newCategory = { ...defaultInventoryConfig[0] };
    const updatedCategories = [...InventoryConfig, newCategory];
    setInventoryConfig(updatedCategories);
  };

  const handleAddItem = (categoryIndex) => {
    const newCategories = [...InventoryConfig];
    const newItem = {
      name: "",
      description: "",
      sku: "",
      defaultQuantity: "10",
    };
    newCategories[categoryIndex].category.items.push(newItem);
    setInventoryConfig(newCategories);
  };

  const handleItemChange = (event, categoryIndex, itemIndex, field) => {
    const updatedCategories = [...InventoryConfig];
    const updatedItem = {
      ...updatedCategories[categoryIndex].category.items[itemIndex],
      [field]: event.target.value,
    };
    updatedCategories[categoryIndex].category.items[itemIndex] = updatedItem;
    setInventoryConfig(updatedCategories);
  };

  const handleRemoveItem = (categoryIndex, itemIndex) => {
    const updatedCategories = [...InventoryConfig];
    updatedCategories[categoryIndex].category.items.splice(itemIndex, 1);
    setInventoryConfig(updatedCategories);
  };

  const handleRemoveCategory = (categoryIndex) => {
    const updatedCategories = InventoryConfig.filter(
      (category, i) => i !== categoryIndex
    );
    setInventoryConfig(updatedCategories);
  };

  const handleSaveInventory = async (e) => {
    console.log(InventoryConfig);
  };

  const saveInventory = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.parse(JSON.stringify(InventoryConfig));
    const response = await axios.post("/inventory/bulkUpdate", body, config);
    if (response.data.status === 201) {
      toast.success(response.data.message);
    }
    // props.toggle();
    // props.refresh();
  };

  const getInventoryByType = async (inventoryType) => {
    const response = await axios.get(`/inventory/type/${inventoryType}`);

    if (response.status === 200 && response.data) {
      const updatedCategories = response.data;
      setInventoryConfig(updatedCategories);
    } else {
      setInventoryConfig({ ...defaultInventoryConfig });
    }
  };

  const updateInventory = async (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log(InventoryConfig);
    const body = JSON.stringify(InventoryConfig);
    const response = await axios.post(
      `/inventory/update/${InventoryId}`,
      body,
      config
    );
    if (response.data.status === 200) {
      toast.success(response.data.message);
    }
    // props.toggle();
    // props.refresh();
  };

  const resetInventoryConfig = () => {
    setInventoryConfig(defaultInventoryConfig);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Rentdigicare | Create Inventory</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            title="Create Inventory"
            breadcrumbItem="Inventory"
            breadcrumbItem2="Create"
          />

          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col md={8}>
                      <Button
                        color="primary"
                        className="createinventory-btn"
                        onClick={pushCategory}
                      >
                        <FeatherIcon icon="plus-circle" /> Add Category
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div className="inventory-container">
                    {InventoryConfig.map((obj, i) => (
                      <div className="row cat-container" key={i}>
                        <div className="col-6">
                          <Label htmlFor="catname">Category Name</Label>
                          <Input
                            className="category-name"
                            type="text"
                            name="categoryName"
                            placeholder="Category name"
                            value={obj.category.name}
                            onChange={(e) =>
                              setInventoryConfig(
                                InventoryConfig.map((category, index) =>
                                  index === i
                                    ? {
                                        ...category,
                                        category: {
                                          ...category.category,
                                          name: e.target.value,
                                        },
                                      }
                                    : category
                                )
                              )
                            }
                          />

                          <table className="item-table">
                            <tbody>
                              {obj.category.items.map((item, j) => (
                                <tr key={j}>
                                  <td>
                                    <Label htmlFor="item_name">Item Name</Label>
                                    <Input
                                      type="text"
                                      name="item_name"
                                      placeholder="Item name"
                                      value={item.name}
                                      onChange={(e) =>
                                        handleItemChange(e, i, j, "name")
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Label htmlFor="item_desc">
                                      Item Description
                                    </Label>
                                    <Input
                                      type="text"
                                      name="item_desc"
                                      placeholder="Item description"
                                      value={item.description}
                                      onChange={(e) =>
                                        handleItemChange(e, i, j, "description")
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Label htmlFor="item_sku">Item Sku</Label>
                                    <Input
                                      type="text"
                                      name="item_sku"
                                      placeholder="SKU"
                                      value={item.sku}
                                      onChange={(e) =>
                                        handleItemChange(e, i, j, "sku")
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Label htmlFor="default_quantity">
                                      Default Quantity
                                    </Label>
                                    <Input
                                      type="text"
                                      name="default_quantity"
                                      placeholder="Default Quantity"
                                      value={item.defaultQuantity}
                                      onChange={(e) =>
                                        handleItemChange(
                                          e,
                                          i,
                                          j,
                                          "defaultQuantity"
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      className="float-right"
                                      color="danger"
                                      onClick={() => handleRemoveItem(i, j)}
                                    >
                                      {" "}
                                      <FeatherIcon
                                        icon="trash-2"
                                        className="float-right"
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="col-6">
                          <Button
                            color="primary"
                            onClick={() => handleAddItem(i)}
                          >
                            <span className="mr-2">
                              <FeatherIcon icon="plus-circle" />
                            </span>{" "}
                            Add Item
                          </Button>
                          <Button
                            color="danger"
                            className="float-right"
                            onClick={() => handleRemoveCategory(i)}
                          >
                            <FeatherIcon
                              icon="trash-2"
                              className="float-right"
                            />{" "}
                            Remove Category
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </CardBody>

                <CardFooter>
                  {/* {InventoryConfig._id && 
                                <Button color="primary" onClick={updateInventory}
                                 disabled={(!InventoryConfig.categories[0].name || InventoryConfig.categories[0].name == "") || (!InventoryConfig.categories[0].items[0].name || InventoryConfig.categories[0].items[0].name == "")}
                                >Update</Button>
                              } */}

                  <Button
                    color="primary"
                    onClick={saveInventory}
                    disabled={
                      !InventoryConfig[0]?.category?.name ||
                      InventoryConfig[0]?.category?.name == "" ||
                      !InventoryConfig[0]?.category?.items[0]?.name ||
                      InventoryConfig[0]?.category?.items[0]?.name == ""
                    }
                  >
                    Submit
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateInventory;
