import * as React from "react"
const InstagramIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24.959} height={25} {...props}>
    <g fill="#0e5492" transform="translate(-5.994 -5.962)">
      <path
        d="M30.872 13.341a9.05 9.05 0 0 0-.581-3.071 6.389 6.389 0 0 0-3.649-3.65 9.156 9.156 0 0 0-3.03-.582c-1.333-.06-1.756-.076-5.141-.076s-3.818 0-5.142.076a9.156 9.156 0 0 0-3.029.582 6.385 6.385 0 0 0-3.648 3.65 9.084 9.084 0 0 0-.58 3.03c-.06 1.335-.078 1.757-.078 5.142s0 3.817.078 5.142a9.141 9.141 0 0 0 .581 3.031 6.389 6.389 0 0 0 3.647 3.646 9.125 9.125 0 0 0 3.028.624c1.335.06 1.757.078 5.142.078s3.818 0 5.142-.078a9.134 9.134 0 0 0 3.03-.581 6.4 6.4 0 0 0 3.649-3.649 9.128 9.128 0 0 0 .581-3.031c.06-1.333.078-1.756.078-5.142s0-3.815-.078-5.141ZM18.464 24.84a6.407 6.407 0 1 1 6.407-6.407 6.406 6.406 0 0 1-6.407 6.407Zm6.662-11.558a1.494 1.494 0 1 1 1.493-1.494 1.493 1.493 0 0 1-1.493 1.495Z"
        data-name="Path 373"
      />
      <circle
        cx={4.162}
        cy={4.162}
        r={4.162}
        data-name="Ellipse 6"
        transform="translate(14.302 14.271)"
      />
    </g>
  </svg>
)
export default InstagramIcon;
